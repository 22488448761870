/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ClientNotesModal = ({ showModal, toggleModal, clientNotes }) => {
  return (
    <Modal size="md" isOpen={showModal} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>Client Notes</ModalHeader>
      <ModalBody>
        <p>{clientNotes}</p>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggleModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClientNotesModal;
