import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { userService, api } from "../../../_services";
import moment from "moment";
import Timeline from "react-timeline-9000";
import "react-timeline-9000/lib/style.css";
import _ from "lodash";
import { DateTimeHelper } from "../../../_helpers/dateTimeHelper";
import { currencyFormatter } from "../../../_services/currencyFormatter";

import {
  Row,
  Button,
  Col,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import Loader from "react-loaders";
import swal from "sweetalert";
import MobileHelper from "../../../_helpers/mobileHelper";
import Loading from "../../../Components/Loading";

const { TIMELINE_MODES } = Timeline;

const ITEM_DURATIONS = [
  moment.duration(6, "hours"),
  moment.duration(12, "hours"),
  moment.duration(18, "hours"),
];

const COLORS = [
  "#0099cc",
  "#f03a36",
  "#06ad96",
  "#fce05b",
  "#dd5900",
  "#cc6699",
];

const customItemRenderer = (props) => {
  const { item, ...rest } = props;
  const text = `${item.start.format("HH:mm")} - ${item.end.format("HH:mm")}`;
  return <span {...rest}> {text} </span>;
};

class MatterDetails extends React.Component {
  constructor(props) {
    super(props);

    const startDate = moment(DateTimeHelper.getUsersNow("YYYY-MM-DD"));
    const endDate = moment(
      startDate
        .clone()
        .add(moment.duration(1, "days"))
        .format("YYYY-MM-DD")
    ); //moment(startDate.add(moment.duration(1, 'days')));
    this.matterId = parseInt(props.match.params.matterId);
    this.userId =
      props.match.params.userId == undefined
        ? undefined
        : parseInt(props.match.params.userId);
    this.currentUser = userService.getLoggedInUser();

    this.state = {
      isGridLoading: true,
      isMatterLoading: true,
      matter: {
        matterId: -1,
        matterName: "",
        totalToday: "",
        totalMonth: "",
        totalYear: "",
        remainingBudget: "",
      },
      matterList: [],
      selectedActivity: {},
      activityList: [],
      selectedMatterId: this.matterId,
      selectedItems: [],
      rows: 10,
      groups: [],
      items: [],
      items_per_row: 1,
      snap: 60,
      startDate,
      endDate,
      message: "",
      timelineMode: TIMELINE_MODES.SELECT,
      originaluserMatterList: [],
      showActivityDetailsModal: false,
      isActivitySaving: false,
    };
    this.reRender = this.reRender.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.toggleCustomRenderers = this.toggleCustomRenderers.bind(this);
    this.toggleSelectable = this.toggleSelectable.bind(this);
    this.toggleDraggable = this.toggleDraggable.bind(this);
    this.toggleResizable = this.toggleResizable.bind(this);

    document.querySelectorAll(".rct9k-items-inner").forEach((e) => {
      e.removeEventListener("mouseover");
      e.removeEventListener("mouseout");
    });
  }

  componentDidMount() {
    this.loadData(this.matterId);
    this.loadMatterList();
  }

  loadData(matterId) {
    this.reRender(matterId);
    this.loadMatter(matterId, this.state.startDate);
  }

  loadBillerActivitiesList(matterId) {
    if (this.userId == undefined) {
      return api.getBillerListByMatterIdIncludeActivities(matterId);
    } else {
      return api.getBillerListByMatterIdUserIdIncludeActivities(
        matterId,
        this.userId
      );
    }
  }

  toggleActivityDetailsModal() {
    this.setState({
      showActivityDetailsModal: !this.state.showActivityDetailsModal,
    });
  }

  loadMatter(matterId, date) {
    api
      .getMatterByMatterIdDate(
        matterId,
        date.format("YYYY-MM-DDTHH:mm:ss"),
        this.currentUser.currencyId
      )
      .then((data) => {
        this.setState({ matter: data, isMatterLoading: false });
      });
  }

  loadMatterList() {
    api.getMatterListByUserId(this.currentUser.userId, 0).then((data) => {
      this.setState({ matterList: data });
    });
  }

  customGroupRenderer = (props) => {
    const { group, ...rest } = props;
    let name = group.title.split("-")[0];
    let rate = group.title.split("-")[1];
    return (
      <span data-group-index={group.id} {...rest}>
        <span>{name}</span>
        <br />
        {(this.currentUser.entityRoleId == 1 ||
          this.currentUser.systemRoleId == 2 ||
          this.state.originaluserMatterList.find(
            (x) =>
              x.userMatter.userId == this.currentUser.userId &&
              x.userMatter.roleId !== 2 &&
              this.state.matter.matterId == x.userMatter.matterId
          ) !== undefined) && <span>{rate}</span>}
      </span>
    );
  };

  reRender(matterId) {
    const list = [];
    const groups = [];
    this.key = 0;
    const actList = [];
    this.setState({ isGridLoading: true });
    this.loadBillerActivitiesList(matterId).then((ret) => {
      var data = [];
      if (ret !== undefined && ret !== null) {
        if (ret.length == undefined) data.push(ret);
        else data = ret;
        for (var i = 0; i < data.length; i++) {
          groups.push({
            id: i,
            title: `${data[i].userMatter.name} - ${currencyFormatter(
              data[i].userMatter.currencySymbol,
              data[i].userMatter.matterRate,
              true
            )}`,
          });

          if (data[i].activityList.length > 0) {
            for (let j = 0; j < data[i].activityList.length; j++) {
              if (
                !data[i].activityList[j].isDeleted &&
                data[i].suspensionReason !== 0
              ) {
                // && data[i].activityList[j].effectiveDuration !== null && data[i].activityList[j].effectiveDuration > 0) {
                actList.push(data[i].activityList[j]);
                this.key += 1;
                let color =
                  data[i].activityList[j].suspensionReason > 0
                    ? "gray"
                    : data[i].activityList[j].endTime == null
                    ? "green"
                    : "darkred";

                if (this.currentUser.entityRoleId == 1) {
                  if (data[i].activityList[j].clientFlag) {
                    color = "blue";
                  }
                }

                let start = moment(
                  moment(
                    DateTimeHelper.getUsersDateTimeStringFromUtc(
                      data[i].activityList[j].startTime
                    )
                  ).valueOf()
                );
                let end =
                  data[i].activityList[j].endTime !== null
                    ? moment(
                        DateTimeHelper.getUsersDateTimeStringFromUtc(
                          data[i].activityList[j].endTime
                        ).valueOf()
                      )
                    : moment(DateTimeHelper.getUsersNow().valueOf());
                var duration = moment.duration(end.diff(start));
                if (duration.asMinutes() < 30)
                  end = moment(end).add(30, "minutes");
                list.push({
                  key: this.key,
                  title: data[i].activityList[j].activityCodeTitle,
                  color,
                  row: i,
                  start: start,
                  end: end,
                  activityId: data[i].activityList[j].activityId,
                });
              }
            }
          }
        }
        this.forceUpdate();
        this.setState({ items: list, groups, originaluserMatterList: data });
      }
      this.setState({ isGridLoading: false, activityList: actList });
    });
  }

  hoverdiv(e, text) {
    let t = e.clientY - 50;
    var left = e.clientX + "px";
    var top = t + "px";

    var div = document.getElementById("hoverer");
    div.id = "hoverer";
    div.style.left = left;
    div.style.top = top;
    div.style.display = "block";
    div.style.position = "absolute";
    div.style.background = "darkred";
    div.style.color = "white";
    div.style.borderRadius = "5px";
    div.style.padding = "5px";
    div.style.zIndex = "9999999999";
    let p = document.createElement("p");
    p.textContent = text;
    div.appendChild(p);
    document.body.appendChild(div);
    // $("#hoverer").toggle();
    return false;
  }

  handleRowClick = (e, rowNumber, clickedTime, snappedClickedTime) => {
    const message = `Row Click row=${rowNumber} @ time/snapped=${clickedTime.toString()}/${snappedClickedTime.toString()}`;
    this.setState({ selectedItems: [], message });
  };
  zoomIn() {
    let currentMins = this.state.endDate.diff(this.state.startDate, "minutes");
    let newMins = currentMins / 2;
    this.setState({
      endDate: this.state.startDate.clone().add(newMins, "minutes"),
    });
  }
  zoomOut() {
    let currentMins = this.state.endDate.diff(this.state.startDate, "minutes");
    let newMins = currentMins * 2;
    this.setState({
      endDate: this.state.startDate.clone().add(newMins, "minutes"),
    });
  }

  toggleCustomRenderers(checked) {
    this.setState({ useCustomRenderers: checked });
  }

  toggleSelectable() {
    const { timelineMode } = this.state;
    let newMode = timelineMode ^ TIMELINE_MODES.SELECT;
    this.setState({
      timelineMode: newMode,
      message: "Timeline mode change: " + timelineMode + " -> " + newMode,
    });
  }
  toggleDraggable() {
    const { timelineMode } = this.state;
    let newMode = timelineMode ^ TIMELINE_MODES.DRAG;
    this.setState({
      timelineMode: newMode,
      message: "Timeline mode change: " + timelineMode + " -> " + newMode,
    });
  }
  toggleResizable() {
    const { timelineMode } = this.state;
    let newMode = timelineMode ^ TIMELINE_MODES.RESIZE;
    this.setState({
      timelineMode: newMode,
      message: "Timeline mode change: " + timelineMode + " -> " + newMode,
    });
  }
  handleItemClick = (e, key) => {
    const message = `Item Click ${key}`;
    let item = this.state.items.find((x) => x.key == key);
    if (item !== undefined && item !== null) {
      this.setState(
        {
          selectedActivity: this.state.activityList.find(
            (x) => x.activityId == item.activityId
          ),
        },
        () => this.displayActivityInformation()
      );
    }
  };

  handleItemHover = (e, key) => {};

  displayActivityInformation = () => {
    this.toggleActivityDetailsModal();
  };

  saveAndClose = () => {
    this.toggleActivityDetailsModal();
    this.setState({ isActivitySaving: true });
    api.updateActivity(this.state.selectedActivity).then(
      (data) => {
        this.reRender(this.matterId);
        this.setState({ isActivitySaving: false });
      },
      (err) => {
        this.setState({ isActivitySaving: false });
      }
    );
  };

  handleItemDoubleClick = (e, key) => {
    const message = `Item Double Click ${key}`;
    this.setState({ message });
  };

  handleItemContextClick = (e, key) => {
    const message = `Item Context ${key}`;
    this.setState({ message });
  };

  handleRowContextClick = (e, rowNumber, clickedTime, snappedClickedTime) => {
    const message = `Row Click row=${rowNumber} @ time/snapped=${clickedTime.toString()}/${snappedClickedTime.toString()}`;
    this.setState({ message });
  };

  handleInteraction = (type, changes, items) => {
    /**
     * this is to appease the codefactor gods,
     * whose wrath condemns those who dare
     * repeat code beyond the sacred 5 lines...
     */
    function absorbChange(itemList, selectedItems) {
      itemList.forEach((item) => {
        let i = selectedItems.find((i) => {
          return i.key == item.key;
        });
        if (i) {
          item = i;
          item.title = moment.duration(item.end.diff(item.start)).humanize();
        }
      });
    }

    switch (type) {
      case Timeline.changeTypes.dragStart: {
        return this.state.selectedItems;
      }
      case Timeline.changeTypes.dragEnd: {
        const newItems = _.clone(this.state.items);

        absorbChange(newItems, items);
        this.setState({ items: newItems });
        break;
      }
      case Timeline.changeTypes.resizeStart: {
        return this.state.selectedItems;
      }
      case Timeline.changeTypes.resizeEnd: {
        const newItems = _.clone(this.state.items);

        // Fold the changes into the item list
        absorbChange(newItems, items);

        this.setState({ items: newItems });
        break;
      }
      case Timeline.changeTypes.itemsSelected: {
        this.setState({ selectedItems: _.map(changes, "key") });
        break;
      }
      default:
        return changes;
    }
  };

  render() {
    const {
      selectedItems,
      rows,
      items_per_row,
      snap,
      startDate,
      endDate,
      items,
      groups,
      message,
      useCustomRenderers,
      timelineMode,
    } = this.state;
    const rangeValue = [startDate, endDate];

    const selectable =
      (TIMELINE_MODES.SELECT & timelineMode) === TIMELINE_MODES.SELECT;
    const draggable =
      (TIMELINE_MODES.DRAG & timelineMode) === TIMELINE_MODES.DRAG;
    const resizeable =
      (TIMELINE_MODES.RESIZE & timelineMode) === TIMELINE_MODES.RESIZE;

    const rowLayers = [];
    for (let i = 0; i < rows; i += 1) {
      if (i % 5 === 0 && i !== 0) {
        continue;
      }
      let curDate = startDate.clone();
      while (curDate.isSameOrBefore(endDate)) {
        const dayOfWeek = Number(curDate.format("d")); // 0 -> 6: Sun -> Sat
        let bandDuration = 0; // days
        let color = "";
        if (dayOfWeek % 6 === 0) {
          // color = 'darkred';
          bandDuration = dayOfWeek === 6 ? 2 : 1; // 2 if sat, 1 if sun
        } else {
          // color = 'green';
          bandDuration = 6 - dayOfWeek;
        }

        rowLayers.push({
          start: curDate.clone(),
          end: curDate.clone().add(bandDuration, "days"),
          style: { opacity: "0.3" },
          rowNumber: i,
        });
        curDate.add(bandDuration, "days");
      }
    }

    return (
      <Fragment>
        <div id="hoverer" />
        <Row style={{marginBottom:'20px'}}>
          <Col md="12">
            <h2>
              <b>{this.state.matter.matterName}</b>
            </h2>
            <div style={{ display: "inline-block" }}>
              <Button
                color="primary"
                onClick={() => {
                  this.props.history.push({
                    pathname: "/dashboard",
                  });
                }}
              >
                &lt;&lt; Dashboard
              </Button>

              <Button
                color="primary"
                onClick={() => {
                  this.props.history.push({
                    pathname: `/matterActivities/${this.matterId}`,
                  });
                }}
                style={{ marginLeft: "10px" }}
              >
                List View
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            {this.state.matterList.length > 0 && (
              <CustomInput
                type="select"
                id="matterSelect"
                value={this.state.selectedMatterId}
                name="matterSelect"
                onChange={(e) => {
                  this.setState({ selectedMatterId: e.target.value });
                  this.loadData(e.target.value);
                }}
              >
                {this.state.matterList.map((matter) => {
                  return (
                    <option key={matter.matterId} value={matter.matterId}>
                      {matter.matterName}
                    </option>
                  );
                })}
              </CustomInput>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <div className="col-3">
            <Button
              color="primary"
              className="pull-right"
              onClick={() => {
                this.setState(
                  {
                    startDate: moment(this.state.startDate).add(-1, "days"),
                    endDate: moment(this.state.endDate).add(-1, "days"),
                    isMatterLoading: true,
                  },
                  () => this.loadMatter(this.matterId, this.state.startDate)
                );
              }}
            >
              Previous Day
            </Button>
          </div>
          <div className="col-6">
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                value={this.state.startDate}
                onChange={(date) => {
                  this.setState(
                    {
                      startDate: moment(date),
                      endDate: moment(date).add(moment.duration(1, "days")),
                      isMatterLoading: true,
                    },
                    () => this.loadMatter(this.matterId, this.state.startDate)
                  );
                }}
                InputProps={{ className: "form-control" }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="col-3">
            <Button
              color="primary"
              onClick={() => {
                this.setState(
                  {
                    startDate: moment(this.state.startDate).add(1, "days"),
                    endDate: moment(this.state.endDate).add(1, "days"),
                    isMatterLoading: true,
                  },
                  () => this.loadMatter(this.matterId, this.state.startDate)
                );
              }}
            >
              Next Day
            </Button>
          </div>
        </Row>
        <Row>
          {this.state.isMatterLoading && (
            <Loader style={{}} type="ball-pulse" active />
          )}
          {!this.state.isMatterLoading &&
            (this.currentUser.entityRoleId == 1 ||
              this.currentUser.systemRoleId == 2 ||
              this.state.originaluserMatterList.find(
                (x) =>
                  x.userMatter.userId == this.currentUser.userId &&
                  x.userMatter.roleId !== 2 &&
                  this.state.matter.matterId == x.userMatter.matterId
              ) !== undefined) && (
              <Fragment>
                <Col md="4">
                  <h5 style={{ fontWeight: "bold" }}>
                    Total for {this.state.startDate.format("MMMM")},{" "}
                    {this.state.startDate.format("DD")} -{" "}
                    {this.state.matter.matterId > 0
                      ? currencyFormatter(
                          this.state.matter.currencySymbol,
                          this.state.matter.todayTotal
                        )
                      : ""}
                  </h5>
                </Col>
                <Col md="4">
                  <h5 style={{ fontWeight: "bold" }}>
                    Total for {this.state.startDate.format("MMMM")} -{" "}
                    {this.state.matter.matterId > 0
                      ? currencyFormatter(
                          this.state.matter.currencySymbol,
                          this.state.matter.monthTotal
                        )
                      : ""}
                  </h5>
                </Col>
                <Col md="4">
                  <h5 style={{ fontWeight: "bold" }}>
                    Remaining Budget -{" "}
                    {this.state.matter.matterId > 0
                      ? this.state.matter.activeBudgetAmount == 0
                        ? "N/A"
                        : currencyFormatter(
                            this.state.matter.currencySymbol,
                            this.state.matter.activeBudgetAmount -
                              this.state.matter.currentSpend
                          )
                      : ""}
                  </h5>
                </Col>
              </Fragment>
            )}
        </Row>
        {this.state.isGridLoading && (
          <Loader style={{}} type="ball-pulse" active />
        )}
        {!this.state.isGridLoading && (
          <Timeline
            shallowUpdateCheck={false}
            items={items}
            groups={groups}
            startDate={startDate}
            endDate={endDate}
            rowLayers={rowLayers}
            //selectedItems={selectedItems}
            timelineMode={timelineMode}
            snapMinutes={snap}
            onItemClick={this.handleItemClick}
            onItemHover={this.handleItemHover}
            onItemDoubleClick={this.handleItemDoubleClick}
            onItemContextClick={this.handleItemContextClick}
            onInteraction={this.handleInteraction}
            onRowClick={this.handleRowClick}
            onRowContextClick={this.handleRowContextClick}
            onRowDoubleClick={this.handleRowDoubleClick}
            //itemRenderer={useCustomRenderers ? customItemRenderer : undefined}
            groupRenderer={this.customGroupRenderer}
            //groupTitleRenderer={useCustomRenderers ? () => <div>Group title</div> : undefined}
          />
        )}

        <Modal
          size="md"
          isOpen={this.state.showActivityDetailsModal}
          toggle={() => this.toggleActivityDetailsModal()}
          backdrop="static"
        >
          <ModalHeader toggle={() => this.toggleActivityDetailsModal()}>
            <Fragment>
              <h4>Activity Details</h4>
            </Fragment>
          </ModalHeader>
          <ModalBody>
            <Row>
              {this.state.selectedActivity !== undefined &&
                this.state.selectedActivity !== null &&
                this.state.selectedActivity.activityId > 0 && (
                  <Col>
                    <div style={{ textAlign: "center", fontSize: "16px" }}>
                      {this.state.selectedActivity.clientFlag}
                      <div>
                        Matter: {this.state.selectedActivity.matterName}
                      </div>
                      <div>
                        Activity:{" "}
                        {this.state.selectedActivity.activityCodeTitle}
                      </div>
                      <div>
                        Start Time:{" "}
                        {DateTimeHelper.getUsersDateTimeStringFromUtc(
                          this.state.selectedActivity.startTime,
                          "MM/DD/YYYY hh:mm A"
                        )}
                      </div>
                      <div>
                        End Time:{" "}
                        {this.state.selectedActivity.endTime == null
                          ? "-"
                          : DateTimeHelper.getUsersDateTimeStringFromUtc(
                              this.state.selectedActivity.endTime,
                              "MM/DD/YYYY hh:mm A"
                            )}
                      </div>
                      {this.state.matter !== undefined &&
                        this.state.matter !== null &&
                        this.state.matter.matterId > 0 &&
                        (this.currentUser.entityRoleId == 1 ||
                          this.state.originaluserMatterList.find(
                            (x) =>
                              x.userMatter.userId == this.currentUser.userId &&
                              x.userMatter.roleId !== 2 &&
                              this.state.matter.matterId ==
                                x.userMatter.matterId
                          ) !== undefined) && (
                          <Fragment>
                            <div>
                              Rate:{" "}
                              {currencyFormatter(
                                this.state.matter.currencySymbol,
                                this.state.selectedActivity.rate
                              )}
                            </div>
                            <div>
                              {this.state.selectedActivity.effectiveCost == null
                                ? ""
                                : `Total cost: ${currencyFormatter(
                                    this.state.matter.currencySymbol,
                                    this.state.selectedActivity.effectiveCost
                                  )}`}
                            </div>
                          </Fragment>
                        )}

                      {this.currentUser.entityRoleId == 1 &&
                        !this.state.selectedActivity.clientFlag && (
                          <Row
                            style={{
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Button
                              color="primary"
                              onClick={() => {
                                this.setState((prev) => ({
                                  ...prev,
                                  selectedActivity: {
                                    ...prev.selectedActivity,
                                    clientFlag: true,
                                  },
                                }));
                              }}
                            >
                              Flag Activity
                            </Button>
                          </Row>
                        )}

                      {this.currentUser.entityRoleId == 1 &&
                        this.state.selectedActivity.clientFlag && (
                          <Fragment>
                            <Row
                              style={{
                                justifyContent: "center",
                                marginTop: "20px",
                              }}
                            >
                              <div
                                className="form-group"
                                style={{ width: "80%" }}
                              >
                                <label htmlFor="clientNotes">Flag Notes</label>
                                <textarea
                                  className="form-control"
                                  type="text"
                                  value={
                                    this.state.selectedActivity.clientFlagNotes
                                  }
                                  onChange={(e) => {
                                    let { value } = e.target;
                                    this.setState((prev) => ({
                                      ...prev,
                                      selectedActivity: {
                                        ...prev.selectedActivity,
                                        clientFlagNotes: value,
                                      },
                                    }));
                                  }}
                                />
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    this.setState((prev) => ({
                                      selectedActivity: {
                                        ...prev.selectedActivity,
                                        clientFlag: false,
                                      },
                                    }));
                                  }}
                                >
                                  Remove Flag
                                </Button>
                              </Col>
                            </Row>
                          </Fragment>
                        )}

                      {this.currentUser.entityRoleId == 1 && (
                        <Row>
                          <Col />
                          <Col>
                            <Loading isLoading={this.state.isActivitySaving} />
                            <Button
                              color="link"
                              className="pull-right"
                              onClick={this.saveAndClose}
                            >
                              Save and close
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Col>
                )}
            </Row>
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default MatterDetails;
