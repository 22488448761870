import React, { Fragment, useState, useEffect } from 'react';
import GeneralGrid from '../../../../Components/GeneralGrid';
import {
    Button, Modal, ModalHeader, Card, CardBody,
    ModalBody, Row, Col, CustomInput, CardTitle
} from 'reactstrap';
import Loader from 'react-loaders';
import { userService, api } from '../../../../_services';

const SelectChildMattersModal = ({ showModal, toggleModal, matter, refreshChildMatters }) => {

    const currentUser = userService.getLoggedInUser();
    let [isLoading, setIsLoading] = useState(false);
    let [matters, setMatters] = useState([]);
    let [lawfirms, setLawfirms] = useState([]);
    let [selectedLawfirm, setSelectedLawfirm] = useState("");

    useEffect(() => {
        if (showModal) {
            setIsLoading(true);
            api.getMatterListByPartnershipIdEntityId(0, currentUser.entityId, currentUser.userId).then(data => {
                setIsLoading(false);
                setMatters(data.filter(x => (!x.isMasterMatter && x.masterMatterId == 0) || x.masterMatterId == matter.matterId));
                let distinctLawfirms = [];
                for (let i = 0; i < data.length; i++) {
                    if (distinctLawfirms.find(x => x == data[i].billerEntityName) == undefined) {
                        distinctLawfirms.push(data[i].billerEntityName);
                    }
                }
                setLawfirms(distinctLawfirms.sort());
            })
        }
    }, [showModal]);

    const changeChildMatterMasterMatter = (matterId) => {
        let list = [...matters];
        let selMatter = list.find(x => x.matterId == matterId);
        selMatter.masterMatterId = selMatter.masterMatterId == 0 ? matter.matterId : 0;
        setMatters(list);

        api.updateMatterMasterMatterId(matterId, selMatter.masterMatterId).then(d => {
            refreshChildMatters();
        })
    }

    const matterColumns = [
        {
            dataField: 'billerEntityName',
            text: 'Lawfirm Name',
            sort: true,
            headerStyle: () => {
                return { width: "25%" };
            }
        },
        {
            dataField: 'matterName',
            text: 'Name',
            sort: true,
            headerStyle: () => {
                return { width: "25%" };
            }
        },
        {
            dataField: 'responsibleLawyers',
            text: 'Responsible Lawyers',
            sort: true,
            headerStyle: () => {
                return { width: "25%" };
            }
        },
        {
            dataField: 'masterMatterId',
            text: '',
            headerStyle: () => {
                return { width: "25%" };
            },
            formatter: (cell, row) => {
                return matter.matterId == row.masterMatterId ?
                    <Button id={`button-remove-${row.matterId}`} color="danger" onClick={() => changeChildMatterMasterMatter(row.matterId)}>Remove</Button> :
                    <Button id={`button-add-${row.matterId}`} color="success" onClick={() => changeChildMatterMasterMatter(row.matterId)}>Add</Button>
            }
        }
    ];

    return (
        <Modal size="lg" isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>Select Child Matters</ModalHeader>
            <ModalBody>
                {isLoading && <Loader style={{}} type="ball-pulse" active />}
                {!isLoading &&
                    <>
                        <Row>
                            <Col md="6">
                                <CustomInput type="select" id="partnershipSelect" value={selectedLawfirm}
                                    name="partnershipSelect" onChange={(e) => setSelectedLawfirm(e.target.value)}>
                                    <option key={0} value={""}>All Lawfirms</option>
                                    {lawfirms.map((p, i) => {
                                        return <option key={i} value={p}>{p}</option>
                                    })}
                                </CustomInput>
                            </Col>
                        </Row>
                        <Row>
                            <GeneralGrid colWidth={12}
                                title=""
                                keyField="matterId"
                                defaultSortedColumn="partnershipName"
                                dataSource={selectedLawfirm == "" ? matters : matters.filter(x => x.billerEntityName == selectedLawfirm)}
                                columns={matterColumns}
                            />
                        </Row>
                    </>
                }
                <Row>
                    <Col md="12">
                        <Button color="link" onClick={() => toggleModal()}>Close</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default SelectChildMattersModal;