
import React, { Fragment, useState, useEffect } from 'react';
import { api, userService } from '../../../_services';
import GeneralGrid from '../../../Components/GeneralGrid';
import { CustomInput, Card, CardBody, Table, Input, Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import {
    toast
} from 'react-toastify';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfoCircle } from '@fortawesome/fontawesome-free-solid';

const UserMatter = ({ userId, currencyList }) => {

    let [matterRoleList, setMatterRoleList] = useState([]);
    let [partnershipList, setPartnershipList] = useState([]);
    let [selectedPartnershipId, setSelectedPartnershipId] = useState(-1);
    let [defaultUserPartnerships, setDefaultUserPartnerships] = useState([]);
    const currentUser = userService.getLoggedInUser();

    const successToastOpts = {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: false
    };

    useEffect(() => {
        if (userId > -1)
            getList();
    }, [userId])

    const getList = () => {
        api.getUserPartnershipDefaultRates(userId).then(userPartnerships => {
            setDefaultUserPartnerships(userPartnerships);
            api.getMatterRoleList(userId, currentUser.entityId).then(data => {
                // console.log([...new Set(data.map(x => x.partnershipId))]);
                setMatterRoleList(data.map(x => {
                    x.currencyId = x.currencyId == null ? 3 : x.currencyId;
                    if (x.matterRate !== null)
                        x.matterRate = x.matterRate.toString().indexOf('.') > -1 ? x.matterRate.toString() : x.matterRate.toString() + '.00';
                    return x;
                })
                );

                let partnershipList = Array.from(new Set(data.map(x => x.partnershipId))).map(id => {
                    return {
                        partnershipId: id,
                        partnershipName: data.find(x => x.partnershipId == id).partnershipName
                    }
                })
                setPartnershipList(partnershipList);
                if (partnershipList.length > 0)
                    setSelectedPartnershipId(partnershipList[0].partnershipId);
            })
        })

    }

    const save = () => {
        var found = matterRoleList.find(x => (x.roleId == 2 || x.roleId == 3) && (x.matterRate == null || x.matterRate == ''));
        if (found)
            swal("Cannot save a user without a default rate. Please provide a rate for each relation.");
        else
            api.saveMatterRoleList(userId, matterRoleList).then(data => {
                toast("User Matter relations saved successfuly", successToastOpts);
            })
    }

    return (
        <Fragment>
            <h5>User Roles at the Matter Level {' '}
                <span id="usermattertooltip" style={{ cursor: 'pointer' }}><FontAwesomeIcon style={{ color: 'darkred' }} icon={faInfoCircle} /></span>
                <UncontrolledTooltip target="usermattertooltip">
                    •	Here you can create or modify the rate for the Biller at the Matter Level.  You may need to do this if the Biller has different rates for a Client depending on the matter or work.
                    <br />
                    •	Note that you must select whether the User is a Biller, Responsible Lawyer or Viewer.  A Responsible Lawyer on a matter sees all Billers, their rates, accrued time and accrued  billings.  A Biller only sees their time (not their rate, nor the rate or time of anyone else on the matter).
                    <br />
                    Remember to Save any changes before moving on to other areas within this page or LeGuard.
                </UncontrolledTooltip></h5>
            <Row>
                <Col md="12">
                    <CustomInput type="select" id="partnershipId" value={selectedPartnershipId}
                        name="partnershipSelect" onChange={e => {
                            setSelectedPartnershipId(e.target.value);
                        }}>
                        {/* <option key={-1} value={-1}>Select {currentUser.entityRoleId == 1 ? 'Law Firm' : 'Client'}</option> */}
                        {partnershipList.map(partnership => {
                            return <option key={partnership.partnershipId} value={partnership.partnershipId}
                            >{partnership.partnershipName}</option>
                        })}
                    </CustomInput>
                </Col>
            </Row>
            <Card className="main-card mb-3" style={{ marginTop: '10px' }}>
                <CardBody>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th width='30%'>Matter</th>
                                <th width='7%'>Viewer</th>
                                {currentUser.entityRoleId !== 1 &&
                                    <Fragment>
                                        <th width='15%'>Resp Lawyer</th>
                                        <th width='7%'>Biller</th>
                                        <th width='16%'>Rate / h</th>
                                        <th width='25%'>Currency</th>
                                    </Fragment>
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {matterRoleList.length > 0 &&
                                matterRoleList
                                    .filter(x => selectedPartnershipId == -1 || x.partnershipId == selectedPartnershipId)
                                    .map(p => {
                                        return (
                                            // style={p.canEdit ? { display: 'table-row' } : { display: 'none' }}
                                            <tr key={p.matterId} >
                                                <td>{p.matterName}</td>
                                                <td>
                                                    <CustomInput type="checkbox" id={`vv-${p.matterId}`} checked={p.roleId !== 0 && p.roleId == 1}
                                                        onChange={(e) => {
                                                            let partnership = matterRoleList.find(x => x.matterId == p.matterId);
                                                            partnership.roleId = partnership.roleId == 1 ? 0 : 1;
                                                            let lst = [...matterRoleList];
                                                            let index = lst.findIndex(x => x.matterId == partnership.matterId);
                                                            lst[index] = partnership;
                                                            setMatterRoleList(lst);
                                                        }}
                                                    />
                                                </td>
                                                {currentUser.entityRoleId !== 1 &&
                                                    <Fragment>
                                                        <td>
                                                            <CustomInput type="checkbox" id={`m-${p.matterId}`} checked={p.roleId !== 0 && p.roleId == 3}
                                                                onChange={(e) => {
                                                                    let partnership = matterRoleList.find(x => x.matterId == p.matterId);
                                                                    partnership.roleId = partnership.roleId == 3 ? 0 : 3;
                                                                    let lst = [...matterRoleList];
                                                                    let index = lst.findIndex(x => x.matterId == partnership.matterId);
                                                                    lst[index] = partnership;
                                                                    if (partnership.matterRate == undefined || partnership.matterRate == null || partnership.matterRate == "") {
                                                                        var defaultUP = defaultUserPartnerships.find(x => x.partnershipId == partnership.partnershipId);
                                                                        if (defaultUP != undefined) {
                                                                            partnership.matterRate = defaultUP.rate;
                                                                        }
                                                                    }
                                                                    setMatterRoleList(lst);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <CustomInput type="checkbox" id={`mm-${p.matterId}`} checked={p.roleId !== 0 && p.roleId == 2}
                                                                onChange={(e) => {
                                                                    let partnership = matterRoleList.find(x => x.matterId == p.matterId);
                                                                    partnership.roleId = partnership.roleId == 2 ? 0 : 2;
                                                                    let lst = [...matterRoleList];
                                                                    let index = lst.findIndex(x => x.matterId == partnership.matterId);
                                                                    lst[index] = partnership;
                                                                    if (partnership.matterRate == undefined || partnership.matterRate == null || partnership.matterRate == "") {
                                                                        var defaultUP = defaultUserPartnerships.find(x => x.partnershipId == partnership.partnershipId);
                                                                        if (defaultUP != undefined) {
                                                                            partnership.matterRate = defaultUP.rate;
                                                                        }
                                                                    }
                                                                    setMatterRoleList(lst);
                                                                }}
                                                            />
                                                        </td>

                                                        <td>
                                                            {(p.roleId == 2 || p.roleId == 3) &&
                                                                <Input value={p.matterRate} readOnly={(p.userMatterId > 0 && p.matterRate && p.matterRate > 0) ? true : false}
                                                                    onChange={(e) => {
                                                                        let partnership = matterRoleList.find(x => x.matterId == p.matterId);
                                                                        partnership.matterRate = e.target.value;
                                                                        let lst = [...matterRoleList];
                                                                        let index = lst.findIndex(x => x.matterId == partnership.matterId);
                                                                        lst[index] = partnership;
                                                                        setMatterRoleList(lst);
                                                                    }}
                                                                />
                                                            }
                                                        </td>
                                                        <td>
                                                            {(p.roleId == 2 || p.roleId == 3) &&
                                                                <CustomInput type="select" id={`m-${p.matterId}-currencySelect`}
                                                                    name={`${p.matterId}-currencySelect`} className='form-control'
                                                                    onChange={(e) => {
                                                                        let partnership = matterRoleList.find(x => x.matterId == p.matterId);
                                                                        partnership.currencyId = e.target.value;
                                                                        let lst = [...matterRoleList];
                                                                        let index = lst.findIndex(x => x.matterId == partnership.matterId);
                                                                        lst[index] = partnership;
                                                                        setMatterRoleList(lst);
                                                                    }}
                                                                    disabled={(p.userMatterId > 0 && p.matterRate && p.matterRate > 0) ? true : false}
                                                                    value={p.currencyId}>
                                                                    {currencyList.map(currency => {
                                                                        return <option key={`currency${p.matterId}-${currency.currencyId}`} value={currency.currencyId}>{currency.displayValue}</option>
                                                                    })}
                                                                </CustomInput>
                                                            }
                                                        </td>
                                                    </Fragment>
                                                }

                                            </tr>
                                        )
                                    })}

                        </tbody>
                    </Table>
                    <Row>
                        <Col md="12">
                            <Button color="primary" onClick={save}>Save</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </Fragment>
    );

}

export default UserMatter;