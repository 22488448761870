import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { api } from "../../../../_services";
import { toast } from "react-toastify";

const VendorModal = ({ showModal, toggleModal, reload }) => {
  const [vendorName, setVendorName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (showModal) {
      setVendorName("");
      setErrorMessage("");
    }
  }, [showModal]);

  const saveVendor = () => {
    setErrorMessage("");
    api.createVendor(vendorName).then((errMsg) => {
      if (errMsg == undefined || errMsg == null || errMsg == "") {
        toast("Vendor saved successfuly.", {
          autoClose: 3000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: false,
        });
        reload();
        toggleModal()
      } else {
        setErrorMessage(errorMessage);
      }
    });
  };

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Add Vendor</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="12">
            <Input
              type="text"
              value={vendorName}
              onChange={(e) => {
                setVendorName(e.target.value);
              }}
            />
            {errorMessage !== "" && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errorMessage}
              </div>
            )}
          </Col>
        </Row>
      </ModalBody>
      <div style={{margin:'10px'}}>
        <Button color="link" onClick={() => toggleModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={saveVendor}
          style={{ marginLeft: ".25rem" }}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default VendorModal;
