import React, { Fragment } from "react";
import { Col, Row, CustomInput, UncontrolledAlert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { authenticationService } from "../_services/authentication.service";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../assets/utils/images/logo.png";
import swal from "sweetalert";
import { userService } from "../_services";
import { PersistentFilters } from "./Invoice/InvoiceList";

class Login extends React.Component {
  constructor(props) {
    super(props);

    const invoiceFilters = new PersistentFilters();
    invoiceFilters.clearAll();

    this.state = {
      username: "",
      password: "",
      rememberMe: false,
      forgotPasswordModeVisible: false,
    };
  }

  componentDidMount() {
    let rememberMe = localStorage.getItem("rememberMe") == "true";
    if (rememberMe) {
      this.setState({
        rememberMe: rememberMe,
        username: localStorage.getItem("username") || "",
        password: localStorage.getItem("password") || "",
      });
    } else {
      this.setState({ rememberMe: false });
    }
  }

  onCheckboxChange(changeEvent) {
    this.setState({ rememberMe: !this.state.rememberMe });
  }

  toggleForgotPassword() {
    this.setState({
      forgotPasswordModeVisible: !this.state.forgotPasswordModeVisible,
    });
  }

  render() {
    const isIE = window.MSCompatibleInfo != null;

    return (
      <Fragment>
        <div className="h-100 bg-leguard-red bg-animation">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <Col md="8" className="mx-auto app-login-box">
              <div
                className="mx-auto mb-3"
                style={{
                  fontWeight: "bold",
                  fontSize: "x-large",
                  textAlign: "center",
                  color: "white",
                }}
              >
                LeGuard <img src={logo} />
              </div>
              <div
                className="mx-auto mb-3"
                style={{
                  fontWeight: "bold",
                  fontSize: "medium",
                  textAlign: "center",
                  color: "white",
                }}
              >
                Tracking Legal Fees in Real Time® <br />
                <span style={{ fontSize: "12px", fontStyle: "italic" }}>
                  For
                </span>{" "}
                <br />
                Legal Spend Integrity®
              </div>

              <div className="modal-dialog w-100 mx-auto">
                {isIE && (
                  <UncontrolledAlert color="danger">
                    <h4>
                      <FontAwesomeIcon icon={faInfo} /> Alert
                    </h4>
                    LeGuard supports Chrome, Edge and Firefox browsers, please
                    use one of these in order to log in the system.
                  </UncontrolledAlert>
                )}
                {!isIE && (
                  <div className="modal-content">
                    {!this.state.forgotPasswordModeVisible && (
                      <div className="modal-body">
                        <div className="h5 modal-title text-center">
                          <h4 className="mt-2">
                            <div>Welcome back,</div>
                            <span>Please sign in to your account.</span>
                          </h4>
                        </div>

                        <Formik
                          enableReinitialize
                          initialValues={{
                            username: this.state.username,
                            password: this.state.password,
                          }}
                          validationSchema={Yup.object().shape({
                            username: Yup.string().required(
                              "Username is required"
                            ),
                            password: Yup.string().required(
                              "Password is required"
                            ),
                          })}
                          onSubmit={(
                            { username, password },
                            { setStatus, setSubmitting }
                          ) => {
                            setStatus();
                            if (this.state.rememberMe) {
                              localStorage.setItem("username", username);
                              localStorage.setItem("password", password);
                              localStorage.setItem("rememberMe", true);
                            } else {
                              localStorage.removeItem("username");
                              localStorage.removeItem("password");
                              localStorage.setItem("rememberMe", false);
                            }
                            authenticationService
                              .login(username, password)
                              .then(
                                (user) => {
                                  let currentUser =
                                    userService.getLoggedInUser();
                                  if (
                                    currentUser.tempPasswordSetDate !==
                                      undefined &&
                                    currentUser.tempPasswordSetDate !== null
                                  )
                                    this.props.history.push({
                                      pathname: `/changePassword`,
                                    });
                                  else
                                    this.props.history.push({
                                      pathname: `/dashboard`,
                                    });
                                  //window.location.reload();
                                },
                                (error) => {
                                  setSubmitting(false);
                                  setStatus("Username or password incorrect");
                                }
                              );
                          }}
                          render={({
                            errors,
                            status,
                            touched,
                            isSubmitting,
                          }) => (
                            <Form>
                              <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <Field
                                  name="username"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.username && touched.username
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="username"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field
                                  name="password"
                                  type="password"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="form-group">
                                <CustomInput
                                  type="checkbox"
                                  id="rememberMe"
                                  checked={this.state.rememberMe}
                                  label="Remember me"
                                  onChange={(e) => this.onCheckboxChange(e)}
                                />
                              </div>
                              <div className="form-group">
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleForgotPassword();
                                  }}
                                >
                                  Forgot my password
                                </a>
                              </div>
                              <div className="divider" />
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Login
                                </button>
                                {isSubmitting && (
                                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                )}
                              </div>
                              {status && (
                                <div className={"alert alert-danger"}>
                                  {status}
                                </div>
                              )}
                            </Form>
                          )}
                        />
                      </div>
                    )}
                    {this.state.forgotPasswordModeVisible && (
                      <div className="modal-body">
                        <UncontrolledAlert color="danger">
                          <h4>
                            <FontAwesomeIcon icon={faInfo} /> Alert
                          </h4>
                          If you have saved any activity notes they were
                          encrypted using your password and this new password
                          will not be able to decrypt those notes, so they will
                          be lost. Please make every effort to find or remember
                          your password if you don’t want to lose those notes.
                          Click cancel to go back to the login screen.
                        </UncontrolledAlert>
                        <div className="h5 modal-title text-center">
                          <h4 className="mt-2">
                            <div>Forgot Password</div>
                            <span>
                              Please enter your email below. We will send you an
                              email with additional information.
                            </span>
                          </h4>
                        </div>

                        <Formik
                          initialValues={{ username: this.state.username }}
                          validationSchema={Yup.object().shape({
                            username:
                              Yup.string().required("Email is required"),
                          })}
                          onSubmit={(
                            { username },
                            { setStatus, setSubmitting }
                          ) => {
                            setStatus();
                            if (username !== "") {
                              authenticationService
                                .resetPassword(username)
                                .then(
                                  (data) => {
                                    if (data == null || data == "") {
                                      swal(
                                        `An email has been sent to you with information on how to proceed.`,
                                        {
                                          buttons: {
                                            ok: {
                                              text: "Ok",
                                              value: "ok",
                                              className: "btn-success",
                                            },
                                          },
                                          icon: "success",
                                        }
                                      ).then((value) => {
                                        this.toggleForgotPassword();
                                      });
                                    } else {
                                      setSubmitting(false);
                                      setStatus(
                                        "Could not reset password for the email you entered."
                                      );
                                    }
                                  },
                                  (error) => {
                                    setSubmitting(false);
                                    setStatus(
                                      "Something went wrong while trying to reset your password."
                                    );
                                  }
                                );
                            }
                          }}
                          render={({
                            errors,
                            status,
                            touched,
                            isSubmitting,
                          }) => (
                            <Form>
                              <div className="form-group">
                                <label htmlFor="username">Email</label>
                                <Field
                                  name="username"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.username && touched.username
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="username"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="divider" />
                              <div className="form-group">
                                <button
                                  className="btn btn-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.toggleForgotPassword();
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Reset password
                                </button>
                                {isSubmitting && (
                                  <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                )}
                              </div>
                              {status && (
                                <div className={"alert alert-danger"}>
                                  {status}
                                </div>
                              )}
                            </Form>
                          )}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="text-center text-white opacity-8 mt-3">
                Copyright &copy; LeGuard Inc.
              </div>
            </Col>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
