import React, { Fragment, useEffect, useState } from "react";
import {
  CustomInput,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
} from "reactstrap";
import { userService, api } from "../../../_services";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import moment from "moment";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import GeneralGrid from "../../../Components/GeneralGrid";
import Moment from "react-moment";
import { currencyFormatter } from "../../../_services/currencyFormatter";
import ExpenseModal from "./Components/ExpenseModal";
import { toast } from "react-toastify";
import download from "downloadjs";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import { isThisSecond } from "date-fns";
import AvatarAddress from "./Components/AvatarAddress";
import { MultiSelect } from "react-multi-select-component";
import EditActivityModal, {
  getDurationInHours,
} from "../../Activity/Components/EditActivityModal";
import { TextareaAutosize } from "@material-ui/core";
import ClientNotesModal from "./Components/ClientNotesModal";

const ActivityDescription = ({ activity, saveActivity }) => {
  const [display, setDisplay] = useState("none");
  const [description, setDescription] = useState(activity.description);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const cancelEdit = () => {
    setDisplay("none");
    setDescription(activity.description);
  };

  const save = () => {
    saveActivity({ ...activity, description: description });
    setDisplay("none");
  };

  return (
    <div>
      <textarea
        rows={3}
        onFocus={() => setDisplay("inline-block")}
        onChange={handleDescriptionChange}
        value={description}
        className="activityDescriptionInput"
        style={{ width: "100%" }}
      />
      <button
        className="activityActionButtons"
        style={{ backgroundColor: "white", display: display }}
        onClick={() => save()}
      >
        <FontAwesomeIcon
          style={{ cursor: "pointer", color: "green" }}
          icon={faCheck}
        />
      </button>
      <button
        className="activityActionButtons"
        style={{ backgroundColor: "white", display: display }}
        onClick={() => cancelEdit()}
      >
        <FontAwesomeIcon
          style={{ cursor: "pointer", color: "red" }}
          icon={faTimes}
        />
      </button>
    </div>
  );
};

const InvoiceSettings = (props) => {
  const currentUser = userService.getLoggedInUser();
  const invoiceId = parseInt(props.match.params.invoiceId);
  const newInvoice = {
    invoiceId: -1,
    includeNotes: true,
    invoiceNumber: "",
    invoiceStatusId: 0,
    partnershipId: -1,
    invoiceTotal: 0,
    discount: 0,
    discountType: "none",
    isDeleted: 0,
    currencyId: currentUser.currencyId,
    updateUserId: currentUser.userId,
    startDate: new Date(),
    endDate: new Date(),
    previousBalance: 0,
    invoiceFormat: "Detail",
    includeActivityCodes: true,
    includeExcludedActivities: true,
    includeRates: true,
    emailTo: "",
    emailCc: "",
    deliveryNotes: "",
  };
  const [showEditActivityModal, setShowEditActivityModal] = useState(false);
  const [activityToEdit, setActivityToEdit] = useState({ activityId: -1 });
  const [entity, setEntity] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [activitiesTotal, setActivitiesTotal] = useState("");
  const [expensesTotal, setExpensesTotal] = useState("");
  const [discountTotal, setDiscountTotal] = useState(0);
  const [total, setTotal] = useState("");
  const [partnership, setPartnership] = useState(null);
  const [invoice, setInvoice] = useState({ ...newInvoice });
  const [isPartnershipValid, setIsPartnershipValid] = useState(true);
  const [isMattersValid, setIsMattersValid] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState(0);
  const [partnershipList, setPartnershipList] = useState([]);
  const [matterList, setMatterList] = useState([]);
  const [multiSelectMatterListOptions, setMultiSelectMatterListOptions] =
    useState([]);
  const [selectedMatterList, setSelectedMatterList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState(
    newInvoice.currencyId
  );
  const [expenseList, setExpenseList] = useState([]);
  const [matters, setMatters] = useState([]);
  const [excludedActivitiesTotal, setExcludedActivitiesTotal] = useState(0);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [invoiceStatusList, setInvoiceStatusList] = useState([]);
  const [activityIdsToExcludeFromInvoice, setActivityIdsToExcludeFromInvoice] =
    useState([]);
  const [previousBalance, setPreviousBalance] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState("");
  const [invoiceDiscount, setInvoiceDiscount] = useState(0);
  const [totalPreviouslyBilled, setTotalPreviouslyBilled] = useState(0);
  const [expense, setExpense] = useState({
    expenseId: -1,
    invoiceId: invoiceId,
    matterId: -1,
    matterName: "",
    expenseName: "",
    notes: "",
    expenseAmount: "",
    currencyId: 3,
    expenseDate: new Date(),
    currentyId: currentUser.currencyId,
  });
  const [clientNotes, setClientNotes] = useState("");
  const [showClientNotesModal, setShowClientNotesModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    calculateTotals(invoice);
  }, [activityList, expenseList]);

  useEffect(() => {
    if (invoice != null) {
      var dates = getDates(invoice);
      let inv = {
        ...invoice,
        startDate: moment(dates.startDate).toDate(),
        endDate: moment(dates.endDate).toDate(),
      };
      setInvoice(inv);
      loadMatters(inv.partnershipId, dates.startDate, dates.endDate);
    }
  }, [selectedDateRange]);

  const loadData = () => {
    api
      .getPartnershipListByEntityId(currentUser.entityId, currentUser.userId)
      .then((data) => {
        setPartnershipList(data);
      });
    api.getCurrencyList().then((data) => {
      setCurrencyList(data);
      loadInvoice(invoiceId);
    });
    api.getInvoiceStatuses().then((s) => {
      setInvoiceStatusList(
        currentUser.systemRoleId == 2
          ? s
          : s.filter(
              (x) =>
                x.name.toLowerCase() == "pre-bill" ||
                x.name.toLowerCase() == "reviewed"
            )
      );
    });
  };

  const loadInvoice = (invoiceId) => {
    let entityId = currentUser.entityId;
    let userId = currentUser.userId;
    if (invoiceId <= 0) {
      setInvoice({ ...newInvoice });
      setIsLoading(false);
    } else {
      api.getTotalPreviouslyBilled(invoiceId).then((total) => {
        setTotalPreviouslyBilled(total);
      });
      api.getInvoiceById(invoiceId).then((data) => {
        loadMatters(
          data.partnershipId,
          data.startDate,
          data.endDate,
          data.matterIds
        );

        data.startDate = new Date(data.startDate);
        data.endDate = new Date(data.endDate);

        setInvoice(data);
        setSelectedCurrencyId(data.currencyId);
        setIsLoading(false);

        loadExpenses(invoiceId);
        loadActivities(invoiceId);

        loadPartnership(data.partnershipId);
      });
    }
  };

  const loadPartnership = (partnershipId) => {
    api.getPartnershipById(partnershipId).then((p) => {
      setPartnership(p);
      api.getEntityById(p.clientEntityId).then((e) => {
        setClientNotes(e.partnerNotes);
        // console.log(e.partnerNotes)
      });
    });
  };

  const loadMatters = (
    partnershipId,
    startDate,
    endDate,
    matterIdsOnInvoice
  ) => {
    api
      .getMatterListWithActivitiesByPartnershipIdDateRange(
        partnershipId,
        startDate,
        endDate
      )
      .then((data) => {
        let multiSelectList = data.map((x) => {
          return { label: x.matterName, value: x.matterId };
        });

        let selectedMatterList = [];

        if (
          matterIdsOnInvoice !== undefined &&
          matterIdsOnInvoice !== null &&
          matterIdsOnInvoice.length > 0
        ) {
          selectedMatterList = matterIdsOnInvoice.split(",").map((x) => {
            let matter = data.find((m) => m.matterId == x);
            if (matter !== undefined && matter !== null) {
              return { label: matter.matterName, value: parseInt(x) };
            } else {
              return null;
            }
          });
          selectedMatterList = selectedMatterList.filter((x) => x !== null);
        }
        setMatterList(data);
        setSelectedMatterList(selectedMatterList);
        setMultiSelectMatterListOptions(multiSelectList);
      });
  };

  const loadLastInvoiceValues = (selectedMatterList) => {
    let matterIds =
      selectedMatterList == undefined ||
      selectedMatterList == null ||
      selectedMatterList.length == 0
        ? ""
        : selectedMatterList.map((x) => x.value).join(",");
    api
      .getLastInvoiceInfoValues(invoice.partnershipId, matterIds)
      .then((data) => {
        setInvoice({
          ...invoice,
          emailTo: data.emailTo,
          emailCc: data.emailCc,
          deliveryNotes: data.deliveryNotes,
        });
      });
  };

  const loadActivities = (invoiceId) => {
    return api
      .getActivityListByInvoiceId(invoiceId, selectedCurrencyId)
      .then((data) => {
        let list = [...new Set(data.map((x) => x.matterId))];
        let ret = [];
        list.forEach((matterId) => {
          let matter = {};
          matter.matterId = matterId;
          matter.matterName = data.find(
            (x) => x.matterId == matterId
          ).matterName;
          matter.activityList = data.filter((x) => x.matterId == matterId);
          matter.totalSpend =
            matter.activityList == undefined || matter.activityList.length == 0
              ? 0
              : matter.activityList.reduce(
                  (acc, item) => acc + item.effectiveCost,
                  0
                );
          matter.totalTime =
            matter.activityList == undefined || matter.activityList.length == 0
              ? 0
              : matter.activityList.reduce(
                  (acc, item) => acc + item.effectiveDuration,
                  0
                );
          ret.push(matter);
        });

        let excludedActivitiesFromInvoice = data
          .filter((x) => x.excludeFromInvoice)
          .map((x) => x.activityId);

        setActivityList(data);
        setMatters(ret);
        setActivityIdsToExcludeFromInvoice(excludedActivitiesFromInvoice);
      });
  };

  const loadExpenses = (invoiceId) => {
    return api
      .getExpenseListByInvoiceId(invoiceId, selectedCurrencyId)
      .then((data) => {
        setExpenseList(data);
      });
  };

  const calculateTotals = (inv) => {
    let expensesTotal = expenseList.reduce((a, b) => a + b.expenseAmount, 0);
    let includedActivitiesTotal = activityList.reduce(
      (a, b) => a + b.effectiveCost,
      0
    );
    let total = expensesTotal + includedActivitiesTotal;

    let excludedActTotal = activityList
      .filter((x) => x.excludeFromInvoice)
      .reduce((a, b) => a + b.effectiveCost, 0);

    let discount =
      inv.discountType == "none"
        ? 0
        : inv.discountType == "amount"
        ? inv.discount
        : (inv.discount * (includedActivitiesTotal - excludedActTotal)) / 100;
    total =
      total -
      discount -
      excludedActTotal +
      (inv.previousBalance == null ? 0 : parseFloat(inv.previousBalance));

    let symbol = currencyList.find(
      (x) => x.currencyId == selectedCurrencyId
    )?.symbol;

    setActivitiesTotal(
      "Total Activities: " +
        currencyFormatter(symbol, includedActivitiesTotal, true)
    );
    setExpensesTotal(
      "Total Expense: " + currencyFormatter(symbol, expensesTotal, true)
    );
    setTotal("Total: " + currencyFormatter(symbol, total, true));
    setPreviousBalance(
      "Previous Balance: " +
        currencyFormatter(
          symbol,
          inv.previousBalance == null ? 0 : inv.previousBalance,
          true
        )
    );
    setDiscountTotal(discount);
    setExcludedActivitiesTotal(excludedActTotal);
    setCurrencySymbol(symbol);
    setInvoice({ ...inv, invoiceTotal: total });
  };

  const toggleExpenseModal = (exp) => {
    if (invoice.invoiceId <= 0)
      swal("Please save the invoice first.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    else {
      if (exp == undefined) {
        setShowExpenseModal(!showExpenseModal);
      } else {
        setExpense(exp);
        setShowExpenseModal(!showExpenseModal);
      }
    }
  };

  const handleSubmit = (e, errors, values, submitToClient) => {
    let customErrors = 0;
    if (invoice.partnershipId <= 0) {
      customErrors++;
      setIsPartnershipValid(false);
    }
    if (selectedMatterList.length == 0) {
      customErrors++;
      setIsMattersValid(false);
    }
    if (errors.length == 0 && customErrors == 0) {
      api.updateEntity(entity).then((data) => {
        api.updatePartnership(currentUser.userId, partnership).then((d) => {
          saveInvoice(invoice, submitToClient);
        });
      });
    }
  };

  const submitToClient = () => {
    swal(
      "An email will be sent to the client and they will have access to the invoice in LeGuard. Please confirm.",
      {
        buttons: {
          confirm: "Confirm",
          cancel: "Cancel",
        },
      }
    ).then((res) => {
      if (res) {
        handleSubmit(null, [], null, true);
      }
    });
  };

  const saveInvoice = (invoice, submitToClient) => {
    let dates = getDates(invoice);
    if (invoice.invoiceId == -1) {
      invoice.startDate = dates.startDate;
      invoice.endDate = dates.endDate;
    }
    let payload = {
      ...invoice,
      matterIds: selectedMatterList.map((x) => x.value).join(","),
      currencyId: selectedCurrencyId,
    };
    if (invoice.invoiceId > 0) {
      api.updateInvoice(payload, currentUser.userId).then((data) => {
        if (invoice.isDeleted) props.history.goBack();
        else {
          if (submitToClient) {
            api.sendInvoiceByEmail(invoice.invoiceId);
          }

          toast("Invoice saved successfuly", {
            autoClose: 3000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
          });
          loadInvoice(invoice.invoiceId);
        }
      });
    } else {
      api.createInvoice(payload, currentUser.userId).then((response) => {
        if (response.errorMessage === "") {
          let id = response.newId;
          if (submitToClient) {
            api.sendInvoiceByEmail(id);
          }

          toast("Invoice saved successfuly", {
            autoClose: 3000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
          });
          props.history.replace({
            pathname: `/invoice/${id}`,
          });
          loadInvoice(id);
        } else {
          toast(response.errorMessage, {
            autoClose: 3000,
            type: toast.TYPE.ERROR,
            hideProgressBar: false,
          });
        }
      });
    }
  };

  const saveExpense = (expense, files) => {
    expense.invoiceId = invoice.invoiceId;
  };

  const toggleEditActivityModal = () => {
    setShowEditActivityModal(!showEditActivityModal);
  };

  const getDates = (inv) => {
    let startDate, endDate;
    let userNow = moment.tz("UTC").tz(currentUser.timezoneIanaName);
    switch (parseInt(selectedDateRange)) {
      case 0: //from today
        startDate = userNow.format("YYYY-MM-DD");
        endDate = userNow.format("YYYY-MM-DD");
        break;
      case 1: //from yesterday
        startDate = userNow.clone().subtract(1, "days").format("YYYY-MM-DD");
        endDate = userNow.format("YYYY-MM-DD");
        break;
      case 2: //from last 7 days
        startDate = userNow.clone().subtract(7, "days").format("YYYY-MM-DD");
        endDate = userNow.format("YYYY-MM-DD");
        break;
      case 3: //from last 30 days
        startDate = userNow.clone().subtract(30, "days").format("YYYY-MM-DD");
        endDate = userNow.format("YYYY-MM-DD");
        break;
      case 4: //this month
        startDate = userNow.clone().startOf("month").format("YYYY-MM-DD");
        endDate = userNow.clone().endOf("month").format("YYYY-MM-DD");
        break;
      case 5: //last month
        startDate = userNow
          .clone()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = userNow
          .clone()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case 6: //custom
        startDate = moment(inv.startDate).format("YYYY-MM-DD");
        endDate = moment(inv.endDate).format("YYYY-MM-DD");
        break;
    }
    return {
      startDate,
      endDate,
    };
  };

  const handleChange = (prop, val) => {
    setInvoice({ ...invoice, [prop]: val });
  };

  const getNewExpense = () => {
    let newExpense = {
      expenseId: -1,
      invoiceId: invoiceId,
      matterId: -1,
      matterName: "",
      expenseName: "",
      notes: "",
      expenseAmount: "",
      currencyId: 3,
      expenseDate: new Date(),
    };
    return newExpense;
  };
  const getTimeInHoursMinutes = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    return hours > 0 ? `${hours} hours ${minutes} min` : `${minutes} min`;
  };
  const getInvoicePdf = () => {
    setIsDownloading(true);
    let inv = { ...invoice };
    let dates = getDates(inv);
    if (inv.invoiceId == -1) {
      inv.startDate = dates.startDate;
      inv.endDate = dates.endDate;
    }
    let payload = {
      ...inv,
      currencyId: selectedCurrencyId,
    };
    if (inv.invoiceId > 0) {
      api.updateInvoice(payload, currentUser.userId).then(
        (data) => {
          api.getInvoicePdf(invoice.invoiceId).then(
            (blob) => {
              setIsDownloading(false);
              download(blob, `Invoice_${invoice.invoiceNumber}.pdf`);
            },
            (err) => setIsDownloading(false)
          );
        },
        (err) => setIsDownloading(false)
      );
    }
  };

  const applyDiscount = () => {
    calculateTotals(invoice);
  };

  const changeActivityDescription = (activityId, prop, val, callback) => {
    let activities = [...activityList]; // JSON.parse(JSON.stringify(activityList));
    for (let i = 0; i < activities.length; i++) {
      if (activities[i].activityId == activityId) {
        activities[i][prop] = val;
        break;
      }
    }

    let mats = [...matters]; // JSON.parse(JSON.stringify(matters));
    for (let i = 0; i < mats.length; i++) {
      for (let j = 0; j < mats[i].activityList.length; j++) {
        if (mats[i].activityList[j].activityId == activityId) {
          mats[i].activityList[j][prop] = val;
          break;
        }
      }
    }

    setActivityList(activities);
    setMatters(mats);

    callback && callback(activityId, val);

    // setState(
    //   { activityList: activities, matters: matters },
    //   () => callback && callback(activityId, val)
    // );
  };

  const keepActionButtonsVisible = (activityId) => {
    // document.getElementById("mockButton").focus();
    // let element = document.getElementById(activityId);
    // element.focus();
    // element.setSelectionRange(element.value.length, element.value.length);
  };

  const cancelActivityDescriptionEdit = (activityId) => {
    api.getActivity(activityId).then((act) => {
      let acts = [...activityList];
      for (var i = 0; i < acts.length; i++) {
        if (acts[i].activityId == activityId) {
          acts[i] = act;
          break;
        }
      }

      let mats = [...matters]; ///JSON.parse(JSON.stringify(matters));
      for (let i = 0; i < mats.length; i++) {
        for (let j = 0; j < mats[i].activityList.length; j++) {
          if (mats[i].activityList[j].activityId == activityId) {
            mats[i].activityList[j] = act;
            break;
          }
        }
      }

      setActivityList(acts);
      setMatters(mats);
      hideActivityDescriptionActionButtons();
    });
  };

  const onActivityDescriptionFocus = (e) => {
    hideActivityDescriptionActionButtons();
    document.getElementById(e.target.id + "-save").style.display =
      "inline-block";
    document.getElementById(e.target.id + "-cancel").style.display =
      "inline-block";
  };

  const hideActivityDescriptionActionButtons = () => {
    var actionButtons = document.getElementsByClassName(
      "activityActionButtons"
    );
    if (actionButtons != null && actionButtons.length > 0) {
      for (var i = 0; i < actionButtons.length; i++) {
        actionButtons[i].style.display = "none";
      }
    }
  };

  const saveActivity = (act) => {
    api.updateActivity(act).then((res) => {
      toast("Activity notes saved successfuly", {
        autoClose: 1000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: false,
      });
    });
  };

  const removeActivity = (activity, callback) => {
    activity.isDeleted = true;
    saveActivityChanges(activity, callback);
  };

  const saveActivityChanges = (activity, callback, addAnother) => {
    if (activity.activityId > -1) {
      api.updateActivity(activity).then((data) => {
        if (data !== undefined && data !== null && data !== "") {
          callback && callback(data);
        } else {
          loadActivities(invoiceId);
          if (!addAnother) {
            toggleEditActivityModal();
          }
          callback && callback("");
        }
      });
    } else {
      let act = {
        startTime: activity.startTime,
        endTime: activity.endTime,
        authBillerId:
          activity.authBillerId != undefined && activity.authBillerId > 0
            ? activity.authBillerId
            : currentUser.userId,
        activityCodeId: activity.activityCodeId,
        matterId: activity.matterId,
        cost: 0,
        description: activity.description,
        password: activity.password,
        encryptedNotes: currentUser.encryptNotes,
        timePeriod: activity.timePeriod,
        isTimePeriodSet: activity.isTimePeriodSet,
        date: activity.date,
        explanationCodeID: activity.explanationCodeID,
        activityFixedPrice: activity.activityFixedPrice,
      };
      api.startActivity(act).then((data) => {
        if (data !== undefined && data !== null && data !== "") {
          callback && callback(data);
        } else {
          loadActivities(invoiceId);
          if (!addAnother) {
            toggleEditActivityModal();
          }
          callback && callback("");
        }
      });
    }
  };

  const loadNextInvoiceNumber = (partnershipId) => {
    if (
      partnershipId > 0 &&
      (invoice.invoiceNumber == undefined ||
        invoice.invoiceNumber == null ||
        invoice.invoiceNumber == "")
    ) {
      api
        .getNextInvoiceNumber(partnershipId, currentUser.entityId)
        .then((nextNumber) => {
          setInvoice({
            ...invoice,
            invoiceNumber: nextNumber,
            partnershipId: partnershipId,
          });
        });
    }
  };

  const computeInvoiceBalance = () => {
    if (invoice.partnershipId > -1) {
      api.computeInvoiceBalance(invoice.partnershipId).then((b) => {
        let inv = { ...invoice, previousBalance: b };
        calculateTotals(inv);
      });
    } else {
      swal("Please select a Client first.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    }
  };

  const expenseColumns = [
    {
      dataField: "matterName",
      text: "Matter",
      sort: true,
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    {
      dataField: "expenseName",
      text: "Expense Description",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cellContent, row) => {
        return (
          <a
            key={row.expenseId}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleExpenseModal(row);
            }}
          >
            {cellContent}
          </a>
        );
      },
    },
    {
      dataField: "expenseDate",
      text: "Date",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cellContent, row) => {
        return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
      },
    },
    {
      dataField: "expenseAmount",
      text: "Amount",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cellContent, row) => {
        return currencyFormatter(
          currencyList.find((x) => x.currencyId == selectedCurrencyId).symbol,
          cellContent,
          true
        );
      },
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      headerStyle: () => {
        return { width: "25%" };
      },
      formatter: (cellContent, row) => {
        return cellContent;
      },
    },
  ];

  ///////////////////////////////

  const columns = [
    {
      dataField: "matterName",
      text: "Matter",
      sort: true,
      headerStyle: () => {
        return { width: "60%" };
      },
    },
    {
      dataField: "totalSpend",
      text: "Total Spend",
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
      formatter: (cell, row) => {
        return currencyFormatter(currentUser.currencySymbol, cell);
      },
    },
    {
      dataField: "totalTime",
      text: "Total Time",
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      },
      formatter: (cell, row) => {
        return getTimeInHoursMinutes(cell);
      },
    },
  ];

  const matterStyleRow = (row, rowIndex) => {
    const style = {};

    if (row.activityList !== undefined) {
      style.backgroundColor = "darkred";
      style.fontWeight = "bold";
      // style.fontSize = 'large';
      style.color = "#fff";
    }

    return style;
  };

  const defaultSorted = [
    {
      dataField: "matterName",
      order: "asc",
    },
  ];

  const options = {
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
    ],
    hideSizePerPage: true,
  };

  const activityColumns = [
    {
      dataField: "matterName",
      text: "Matter",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      footer: "",
    },
    {
      text: "Biller / Rate",
      dataField: "authBillerName",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cellContent, row) => {
        return `${row.authBillerName} (${currencyFormatter(
          currencyList.find((x) => x.currencyId == selectedCurrencyId).symbol,
          row.rate
        )} / hr)`;
      },
      footer: "",
    },
    {
      dataField: "activityCodeTitle",
      text: "Activity",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
      formatter: (cellContent, row) => {
        return (
          <a
            key={row.activityId}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setActivityToEdit(row);
              toggleEditActivityModal();
            }}
          >
            {cellContent}
          </a>
        );
      },
      footer: "",
    },
    {
      dataField: "startTime",
      text: "Date",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cellContent, row) => {
        return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
      },
      footer: "",
    },
    {
      dataField: "effectiveDuration",
      text: "Duration",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cellContent, row) => {
        return getDurationInHours(row.startTime, row.endTime) + " h";
      },
      footer: "",
    },
    {
      dataField: "effectiveCost",
      text: "Amount",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cellContent, row) => {
        return currencyFormatter(
          currencyList.find((x) => x.currencyId == selectedCurrencyId).symbol,
          row.effectiveCost,
          true
        );
      },
      footer: (columnData) =>
        currencyFormatter(
          currentUser.currencySymbol,
          columnData.reduce((acc, item) => acc + item, 0)
        ),
    },
    {
      dataField: "description",
      text: "Description",
      headerStyle: () => {
        return { width: "30%" };
      },
      formatter: (cellContent, row) => {
        if (!cellContent.encryptedNotes) {
          return (
            <ActivityDescription activity={row} saveActivity={saveActivity} />
          );
        }
      },
    },
    {
      dataField: "excludeFromInvoice",
      text: "Exclude",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cellContent, row, i, extraData) => {
        let existing = extraData.find((x) => x == row.activityId);
        return (
          <CustomInput
            id={`exclude-${row.activityId}`}
            key={`exclude-${row.activityId}`}
            type="checkbox"
            checked={existing !== undefined ? true : false}
            onChange={(e) => {
              let activityIds = [];
              var actList = [...activityList];
              var act = actList.find((x) => x.activityId == row.activityId);

              if (extraData.find((x) => x == row.activityId) !== undefined) {
                activityIds = extraData.filter((x) => x !== row.activityId);
                act.excludeFromInvoice = false;
              } else {
                activityIds = [...extraData, row.activityId];
                act.excludeFromInvoice = true;
              }

              api
                .excludeActivityFromInvoice(
                  act.activityId,
                  act.excludeFromInvoice
                )
                .then((data) => {});

              setActivityIdsToExcludeFromInvoice(activityIds);
              setActivityList(actList);
            }}
          />
        );
      },
      footer: "",
      formatExtraData: activityIdsToExcludeFromInvoice,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div className="table-responsive">
        <BootstrapTable
          bordered={false}
          striped
          responsive
          bootstrap4
          keyField="activityId"
          data={row.activityList}
          columns={activityColumns}
        />
      </div>
    ),
    // expanded: expandedIndexes,
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faMinus} />;
      }
      return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPlus} />;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faMinus} />;
      }
      return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPlus} />;
    },
  };

  //////////////////

  return (
    <Fragment>
      <button id="mockButton" style={{ display: "none" }} />
      <Row>
        <PageTitle heading={`Invoice: ${invoice.invoiceNumber}`} />
      </Row>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <AvForm onSubmit={handleSubmit}>
                <Row>
                  <Col md="6">
                    <AvField
                      name="invoicenumber"
                      label="Invoice # *"
                      value={invoice.invoiceNumber}
                      disabled={currentUser.systemRoleId != 2}
                      onChange={(e) =>
                        handleChange("invoiceNumber", e.target.value)
                      }
                      placeholder="Enter..."
                      required
                    />
                    <div className="form-group">
                      <label htmlFor="status">Invoice Status</label>
                      <CustomInput
                        type="select"
                        id="statusSelect"
                        value={invoice.invoiceStatusId}
                        name="statusSelect"
                        onChange={(e) => {
                          handleChange("invoiceStatusId", e.target.value);
                        }}
                      >
                        {invoiceStatusList.map((s) => {
                          return (
                            <option
                              key={s.invoiceStatusId}
                              value={s.invoiceStatusId}
                            >
                              {s.name}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </div>
                    <div className="form-group">
                      <label htmlFor="currency">Currency *</label>
                      <CustomInput
                        type="select"
                        id="currencySelect"
                        name="currencySelect"
                        className="form-control"
                        disabled={currentUser.systemRoleId != 2}
                        onChange={(e) =>
                          setSelectedCurrencyId(parseInt(e.target.value))
                        }
                        value={selectedCurrencyId}
                      >
                        {currencyList.map((currency) => {
                          return (
                            <option
                              key={currency.currencyId}
                              value={currency.currencyId}
                            >
                              {currency.displayValue}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </div>
                    <div className="form-group">
                      <label>Format</label>
                      <CustomInput
                        type="select"
                        className="form-control"
                        onChange={(e) =>
                          handleChange("invoiceFormat", e.target.value)
                        }
                        value={invoice.invoiceFormat}
                      >
                        <option value="Detail">Detail</option>
                        <option value="Block">Block</option>
                      </CustomInput>
                    </div>
                    <>
                      <h5>{activitiesTotal}</h5>
                      <h5>{expensesTotal}</h5>
                      <h5>
                        Line Item Discount:{" "}
                        {currencyFormatter(
                          currencySymbol,
                          excludedActivitiesTotal
                        )}
                      </h5>
                      <h5>
                        Percent / Flat Discount:{" "}
                        {selectedCurrencyId > 0 && currencyList.length > 0
                          ? currencyFormatter(
                              currencyList.find(
                                (x) => x.currencyId == selectedCurrencyId
                              ).symbol,
                              discountTotal,
                              true
                            )
                          : 0}
                      </h5>
                      <h5>{previousBalance ?? 0}</h5>
                      <h5>{total}</h5>
                    </>
                  </Col>
                  <Col md="6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <div className="form-group" style={{width:'100%'}}>
                        <label htmlFor="partner">
                          {currentUser.entityRoleId == 1
                            ? "Law Firm *"
                            : "Client *"}
                        </label>
                        {partnershipList.length > 0 && (
                          <CustomInput
                            type="select"
                            id="partnershipSelect"
                            value={invoice.partnershipId}
                            disabled={invoice.invoiceId > 0}
                            name="partnershipSelect"
                            onChange={(e) => {
                              setIsPartnershipValid(true);
                              loadPartnership(e.target.value);
                              handleChange("partnershipId", e.target.value);
                              loadNextInvoiceNumber(e.target.value);
                            }}
                          >
                            <option key={0} value={-1}>
                              Select{" "}
                              {currentUser.entityRoleId == 1
                                ? "Law Firm"
                                : "Client"}
                            </option>
                            {partnershipList.map((partnership) => {
                              return (
                                <option
                                  key={partnership.partnershipId}
                                  value={partnership.partnershipId}
                                >
                                  {partnership.partnershipName}
                                </option>
                              );
                            })}
                          </CustomInput>
                        )}
                        {!isPartnershipValid && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Please select a{" "}
                            {currentUser.entityRoleId == 1
                              ? "Law Firm"
                              : "Client"}
                          </div>
                        )}
                      </div>
                      {clientNotes != "" && clientNotes != null && (
                        <Button
                          color="primary"
                          style={{ width: "160px" }}
                          onClick={() => setShowClientNotesModal(true)}
                        >
                          Client Notes
                        </Button>
                      )}
                    </div>

                    {invoice.invoiceId <= 0 && (
                      <div className="form-group">
                        <label htmlFor="dateRange">Date Range *</label>
                        <CustomInput
                          type="select"
                          id="dateRange"
                          name="dateRange"
                          value={selectedDateRange}
                          onChange={(e) => {
                            setSelectedDateRange(e.target.value);
                          }}
                        >
                          <option key={`dr-${0}`} value={0}>
                            Today
                          </option>
                          <option key={`dr-${1}`} value={1}>
                            Yesterday
                          </option>
                          <option key={`dr-${2}`} value={2}>
                            Last 7 Days
                          </option>
                          <option key={`dr-${3}`} value={3}>
                            Last 30 Days
                          </option>
                          <option key={`dr-${4}`} value={4}>
                            This Month
                          </option>
                          <option key={`dr-${5}`} value={5}>
                            Last Month
                          </option>
                          <option key={`dr-${6}`} value={6}>
                            Custom Range
                          </option>
                        </CustomInput>
                        {selectedDateRange == 6 && (
                          <Row>
                            <div className="form-group col-md-6">
                              <label htmlFor="startDate">Start Date</label>

                              <DatePicker
                                className="form-control"
                                selected={moment(invoice.startDate).toDate()}
                                onChange={(date) => {
                                  let inv = { ...invoice, startDate: date };
                                  setInvoice(inv);
                                  var dates = getDates(inv);
                                  loadMatters(
                                    invoice.partnershipId,
                                    dates.startDate,
                                    dates.endDate
                                  );
                                }}
                                placeholderText="Start date..."
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="endDate">End Date</label>
                              <DatePicker
                                className="form-control"
                                selected={moment(invoice.endDate).toDate()}
                                onChange={(date) => {
                                  let inv = { ...invoice, endDate: date };
                                  setInvoice(inv);
                                  var dates = getDates(inv);
                                  loadMatters(
                                    invoice.partnershipId,
                                    dates.startDate,
                                    dates.endDate
                                  );
                                }}
                                placeholderText="End date..."
                              />
                            </div>
                          </Row>
                        )}
                      </div>
                    )}

                    <label>Date range</label>
                    <Row>
                      <Col md="6">
                        <h5>
                          <Moment format="MM/DD/YYYY">
                            {invoice.startDate}
                          </Moment>
                        </h5>
                      </Col>
                      <Col md="6">
                        <h5>
                          {invoice.endDate !== null &&
                          invoice.endDate !== "" ? (
                            <Moment format="MM/DD/YYYY">
                              {invoice.endDate}
                            </Moment>
                          ) : (
                            "N/A"
                          )}
                        </h5>
                      </Col>
                    </Row>

                    {/* {multiSelectMatterListOptions.length > 0 && ( */}
                    <div className="form-group">
                      <label>Matters *</label>
                      <MultiSelect
                        options={multiSelectMatterListOptions}
                        value={selectedMatterList}
                        disabled={
                          currentUser.systemRoleId != 2 ||
                          invoice.invoiceId > -1
                        }
                        className={invoice.invoiceId > -1 ? "disabled" : ""}
                        onChange={(selected) => {
                          setSelectedMatterList(selected);
                          loadLastInvoiceValues(selected);
                          setIsMattersValid(selected.length > 0 ? true : false);
                        }}
                        labelledBy="Select"
                      />
                      {!isMattersValid && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Please select at least one matter. If none appears,
                          then there are no matters with activities in the
                          selected date range.
                        </div>
                      )}
                    </div>
                    {/* )} */}

                    {/* {invoice.invoiceId > 0 && ( */}
                    <Fragment>
                      <Row>
                        <Col md="12">
                          <div className="form-group">
                            <label htmlFor="discountType">Discount Type</label>
                            <CustomInput
                              type="select"
                              id="discountType"
                              name="discountType"
                              className="form-control"
                              value={invoice.discountType}
                              onChange={(e) => {
                                if (e.target !== null) {
                                  let inv = {
                                    ...invoice,
                                    discountType: e.target.value,
                                  };
                                  if (inv.discountType == "none") {
                                    inv.discount = 0;
                                  }
                                  calculateTotals(inv);
                                }
                              }}
                            >
                              <option key={"none"} value="none">
                                None
                              </option>
                              <option key={"amount"} value="amount">
                                Amount
                              </option>
                              <option key={"percent"} value="percent">
                                Percent
                              </option>
                            </CustomInput>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <AvField
                              name="prevbalance"
                              label="Previous Balance"
                              value={invoice.previousBalance}
                              onChange={(e) => {
                                let val = e.target.value;
                                let inv = {
                                  ...invoice,
                                  previousBalance: val,
                                };
                                calculateTotals(inv);
                              }}
                              placeholder="Enter..."
                            />
                            <Button
                              color="primary"
                              onClick={() => computeInvoiceBalance()}
                            >
                              Compute
                            </Button>
                          </div>
                          {invoice.invoiceId > 0 && (
                            <p>
                              <b>
                                Total Previously Billed:{" "}
                                {currencyFormatter(
                                  "$",
                                  totalPreviouslyBilled,
                                  true
                                )}
                              </b>
                            </p>
                          )}

                          {(invoice.discountType == "amount" ||
                            invoice.discountType == "percent") && (
                            <>
                              <AvField
                                type="number"
                                name="invoiceDiscount"
                                label={
                                  invoice.discountType == "amount"
                                    ? "Amount"
                                    : "Percent"
                                }
                                value={invoice.discount}
                                onChange={(e) => {
                                  if (
                                    e.target !== null &&
                                    e.target.value !== "-" &&
                                    e.target.value > 0
                                  ) {
                                    let inv = {
                                      ...invoice,
                                      discount: e.target.value,
                                    };
                                    calculateTotals(inv);
                                  } else if (
                                    e.target !== null &&
                                    e.target.value < 0
                                  ) {
                                    swal({
                                      text: "Discount cannot be negative",
                                    });
                                    let inv = {
                                      ...invoice,
                                      discount: 0,
                                    };
                                    calculateTotals(inv);
                                  }
                                }}
                                placeholder="Enter..."
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage:
                                      "If you don’t know the amount of discount, select “None” as the discount type.  You can add the discount in later if you want.",
                                  },
                                }}
                              />
                              <Button
                                color="primary"
                                onClick={() => applyDiscount()}
                              >
                                Apply Discount
                              </Button>
                            </>
                          )}
                        </Col>
                      </Row>
                    </Fragment>
                    <Row>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <CustomInput
                            id="includeNotesCheckbox"
                            type="checkbox"
                            checked={invoice.includeNotes}
                            label="Include Description on Invoice"
                            disabled={currentUser.systemRoleId != 2}
                            onChange={(e) => {
                              setInvoice({
                                ...invoice,
                                includeNotes: !invoice.includeNotes,
                              });
                            }}
                          />
                          <CustomInput
                            id="includeExcludedActivitiesCheckbox"
                            type="checkbox"
                            checked={invoice.includeExcludedActivities}
                            label="Include Excluded Activities"
                            onChange={(e) => {
                              setInvoice({
                                ...invoice,
                                includeExcludedActivities:
                                  !invoice.includeExcludedActivities,
                              });
                            }}
                          />
                          <CustomInput
                            id="includeActCodesCheckbox"
                            type="checkbox"
                            checked={invoice.includeActivityCodes}
                            label="Include Activity Codes"
                            onChange={(e) => {
                              setInvoice({
                                ...invoice,
                                includeActivityCodes:
                                  !invoice.includeActivityCodes,
                              });
                            }}
                          />
                          <CustomInput
                            id="includeRates"
                            type="checkbox"
                            checked={invoice.includeRates}
                            label="Include Rates"
                            onChange={(e) => {
                              setInvoice({
                                ...invoice,
                                includeRates: !invoice.includeRates,
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <AvatarAddress
                      partnershipId={invoice.partnershipId}
                      entity={entity}
                      setEntity={setEntity}
                    />
                    <Row className="mb-3">
                      <Col>
                        <AvField
                          type="text"
                          name="emailto"
                          label="Email To"
                          value={invoice.emailTo}
                          onChange={(e) => {
                            var val = e.target.value;
                            setInvoice({ ...invoice, emailTo: val });
                          }}
                        />
                        <AvField
                          type="text"
                          name="emailcc"
                          label="Email Cc"
                          value={invoice.emailCc}
                          onChange={(e) => {
                            var val = e.target.value;
                            setInvoice({ ...invoice, emailCc: val });
                          }}
                        />
                        <div className="form-group">
                          <label>Delivery Notes</label>
                          <TextareaAutosize
                            className="form-control"
                            minRows={2}
                            value={invoice.deliveryNotes}
                            onChange={(e) => {
                              var val = e.target.value;
                              setInvoice({ ...invoice, deliveryNotes: val });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button color="primary" type="submit">
                          Save
                        </Button>{" "}
                        {invoice.invoiceId > 0 && (
                          <Button
                            color="primary"
                            onClick={() => getInvoicePdf()}
                            disabled={isDownloading}
                          >
                            View PDF
                          </Button>
                        )}
                        {isDownloading && (
                          <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        )}
                        <Button
                          color="link"
                          onClick={() => {
                            props.history.goBack();
                          }}
                        >
                          Cancel
                        </Button>{" "}
                        <Button
                          color="danger"
                          disabled={currentUser.systemRoleId != 2}
                          onClick={() => {
                            let inv = invoice;
                            inv.isDeleted = true;
                            saveInvoice(inv);
                          }}
                        >
                          Delete
                        </Button>{" "}
                        {invoice !== null &&
                          invoice.invoiceId > 0 &&
                          invoice.invoiceStatusId == 2 && (
                            <Button color="danger" onClick={submitToClient}>
                              Submit to Client
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {currencyList.length > 0 && (
        <Row>
          <GeneralGrid
            colWidth={12}
            title="Expenses"
            keyField="expenseId"
            dataSource={expenseList}
            columns={expenseColumns}
            defaultSortedColumn="matterName"
            showAddButton={currentUser.systemRoleId != 2}
            buttonText={
              invoice.invoiceStatusId == 120 ? undefined : "Add Expense"
            }
            onAdd={() => toggleExpenseModal(getNewExpense())}
          />
        </Row>
      )}
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              {currentUser.entityRoleId == 2 &&
                currentUser.systemRoleId == 2 &&
                invoice.invoiceStatusId != 120 && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setActivityToEdit({
                        activityId: -1,
                        matterName:
                          selectedMatterList.length > 0
                            ? selectedMatterList[0].label
                            : "Not selected",
                        matterId:
                          selectedMatterList.length > 0
                            ? selectedMatterList[0].value
                            : -1,
                        startTime: moment(invoice.endDate)
                          .startOf("day")
                          .format("MM/DD/YYYY hh:mm A"),
                        endTime: moment(invoice.endDate)
                          .startOf("day")
                          .format("MM/DD/YYYY hh:mm A"),
                        encryptedNotes: currentUser.encryptNotes,
                        date: moment(invoice.endDate)
                          .startOf("day")
                          .format("MM/DD/YYYY"),
                        timePeriod: 0,
                      });
                      toggleEditActivityModal();
                    }}
                  >
                    Add New Activity
                  </Button>
                )}
              {matters.length > 0 && currencyList.length > 0 && (
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  striped
                  responsive
                  bordered={false}
                  bootstrap4
                  keyField="matterId"
                  data={matters}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  expandRow={expandRow}
                  rowStyle={matterStyleRow}
                />
              )}
              {activityList.length == 0 && <p>No activities to display.</p>}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <EditActivityModal
        showModal={showEditActivityModal}
        toggleModal={toggleEditActivityModal}
        activityId={activityToEdit.activityId}
        onRemove={removeActivity}
        // onHide={hideActivity}
        onSave={saveActivityChanges}
        showSaveAndAddAnother={false}
        preselectedData={activityToEdit}
      />
      <ExpenseModal
        expenseObj={expense}
        showModal={showExpenseModal}
        toggleModal={toggleExpenseModal}
        matterList={matterList}
        invoice={invoice}
        onSave={saveExpense}
        reload={() => loadExpenses(invoice.invoiceId)}
      />
      <ClientNotesModal
        showModal={showClientNotesModal}
        toggleModal={() => setShowClientNotesModal(!showClientNotesModal)}
        clientNotes={clientNotes}
      />
    </Fragment>
  );
};

export default InvoiceSettings;
