import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../Layout/AppMain/PageTitle";
import Loader from "react-loaders";
import { api, userService } from "../../_services";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
  Input,
  Label,
} from "reactstrap";
import Moment from "react-moment";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import DatePicker from "react-datepicker";
import ExpenseModal from "../Invoice/InvoiceSettings/Components/ExpenseModal";
import { currencyFormatter } from "../../_services/currencyFormatter";

const ExpenseList = (props) => {
  const currentUser = userService.getLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [partnershipList, setPartnershipList] = useState([]);
  const [selectedPartnershipId, setSelectedPartnershipId] = useState(-1);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());
  const [matterList, setMatterList] = useState([]);

  const [expenseToEdit, setExpenseToEdit] = useState(null);
  const [showExpenseModal, setShowExpenseModal] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (partnershipList.length > 0) {
      loadExpenses();
    }
  }, [selectedPartnershipId]);

  const loadExpenses = () => {
    api.getExpenseListByPartnershipIds(getPartnershipIds()).then((e) => {
      setExpenses(e);
    });
  };

  const loadData = () => {
    setIsLoading(true);
    api
      .getPartnershipListByEntityId(currentUser.entityId, currentUser.userId)
      .then((p) => {
        setPartnershipList(p);
        api
          .getExpenseListByPartnershipIds(
            p.map((x) => x.partnershipId).join(",")
          )
          .then((e) => {
            setExpenses(e);
          });
        api
          .getMatterListByPartnershipIdsForAdmin(
            p.map((x) => x.partnershipId).join(",")
          )
          .then((m) => {
            setMatterList(m);
            setIsLoading(false);
          });
      });
  };

  const getPartnershipIds = () => {
    return parseInt(selectedPartnershipId) > 0
      ? selectedPartnershipId
      : partnershipList.map((x) => x.partnershipId).join(",");
  };

  const addNewExpense = () => {
    let newExpense = {
      expenseId: -1,
      invoiceId: 0,
      matterId: -1,
      matterName: "",
      expenseName: "",
      notes: "",
      expenseAmount: "",
      currencyId: 3,
      expenseDate: new Date(),
    };
    setExpenseToEdit(newExpense);
    setShowExpenseModal(true);
  };

  return (
    <Fragment>
      <PageTitle heading="Expenses" />
      {isLoading && <Loader style={{}} type="ball-pulse" active />}
      {!isLoading && (
        <Fragment>
          <Row>
            {partnershipList.length > 0 && (
              <Col md="4">
                <div className="form-group">
                  <Label>Client</Label>
                  <CustomInput
                    type="select"
                    value={selectedPartnershipId}
                    onChange={(e) => setSelectedPartnershipId(e.target.value)}
                  >
                    <option key={0} value={-1}>
                      All Clients
                    </option>
                    {partnershipList.map((p) => {
                      return (
                        <option key={p.partnershipId} value={p.partnershipId}>
                          {p.partnershipName}
                        </option>
                      );
                    })}
                  </CustomInput>
                </div>
              </Col>
            )}
            <Col md="3">
              <div className="form-group">
                <Label>Start Date</Label>
                <DatePicker
                  selected={startDate}
                  onChange={(e) =>
                    setStartDate(moment(e).startOf("day").toDate())
                  }
                  className="form-control"
                />
              </div>
            </Col>
            <Col md="3">
              <div className="form-group">
                <Label>End Date</Label>
                <DatePicker
                  selected={endDate}
                  onChange={(e) => setEndDate(moment(e).endOf("day").toDate())}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  addNewExpense();
                }}
                style={{ marginLeft: "10px" }}
              >
                Create New Expense
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <div className="table-responsive">
                    {expenses.length > 0 && (
                      <BootstrapTable
                        bootstrap4
                        striped
                        bordered={false}
                        keyField="expenseId"
                        data={expenses.filter(
                          (x) =>
                            moment(x.expenseDate).toDate() <= endDate &&
                            moment(x.expenseDate).toDate() >= startDate
                        )}
                        columns={[
                          {
                            dataField: "expenseName",
                            text: "Expense",
                            sort: true,
                            headerStyle: () => {
                              return { width: "15%" };
                            },
                            formatter: (cellContent, row) => {
                              return (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e && e.preventDefault();
                                    setExpenseToEdit(row);
                                    setShowExpenseModal(true);
                                  }}
                                >
                                  {cellContent}
                                </a>
                              );
                            },
                          },
                          {
                            dataField: "matterName",
                            text: "Matter",
                            sort: true,
                            headerStyle: () => {
                              return { width: "20%" };
                            },
                          },
                          {
                            dataField: "expenseDate",
                            text: "Date",
                            sort: true,
                            headerStyle: () => {
                              return { width: "15%" };
                            },
                            formatter: (cellContent, row) => {
                              return cellContent == null
                                ? ""
                                : moment(cellContent).format("MM/DD/YYYY");
                            },
                          },
                          {
                            dataField: "expenseAmount",
                            text: "Amount",
                            sort: true,
                            headerStyle: () => {
                              return { width: "20%" };
                            },
                            formatter: (cellContent, row) => {
                              return currencyFormatter("$", cellContent, true);
                            },
                          },
                          {
                            dataField: "notes",
                            text: "Description",
                            sort: true,
                            headerStyle: () => {
                              return { width: "40%" };
                            },
                          },
                        ]}
                        defaultSorted={[
                          {
                            dataField: "expenseDate",
                            order: "asc",
                          },
                        ]}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
      {expenseToEdit != null && (
        <ExpenseModal
          expenseObj={expenseToEdit}
          showModal={showExpenseModal}
          toggleModal={() => setShowExpenseModal(!showExpenseModal)}
          matterList={matterList}
          reload={loadExpenses}
        />
      )}
    </Fragment>
  );
};

export default ExpenseList;
