import React, { Fragment } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
    Button, Modal, ModalHeader, Popover, UncontrolledTooltip,
    ModalBody, Row, Col, CustomInput
} from 'reactstrap';
import Loader from 'react-loaders'
import { userService } from '../../_services/user.service';
import 'react-dropdown/style.css';
import { api } from '../../_services/api.service';
import PageTitle from '../../Layout/AppMain/PageTitle';
import UserForm from '../../Components/UserForm';
import GeneralGrid from '../../Components/GeneralGrid';
import UserPartnership from './Components/UserPartnership';
import UserMatter from './Components/UserMatter';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfoCircle } from '@fortawesome/fontawesome-free-solid';

class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            showUserProfilePopover: false,
            showUserPartnershipPopover: false,
            showUserMatterPopover: false,
            showModal: false,
            isResendingInvitation: false,
            entityList: [],
            allUserList: [],
            userList: [],
            partnershipList: [],
            selectedPartnership: { id: -1, title: 'All Users' },
            loadingUsers: false,
            userId: -1,
            userStatus: 1,
            userToEdit: {
                userId: -1,
                name: '',
                isDeleted: false
            },
            roleList: [
                {
                    systemRoleId: 2,
                    systemRole: 'Administrator'
                },
                {
                    systemRoleId: 1,
                    systemRole: 'User'
                }
            ],
            currencyList: [],
            timezoneList: [],
            userTypeList: []
        }

        this.newUser = {
            userId: -1,
            name: '',
            email: '',
            systemRole: '',
            systemRoleId: 1,
            canManageMatters: false,
            entityId: '',
            currencyId: 3,
            timezoneId: '',
            postal: '',
            isDeleted: false
        };
        this.selectedEntityId = -1;
        this.currentUser = userService.getLoggedInUser();
        this.newUser.currencyId = this.currentUser.currencyId;
        this.newUser.timezoneId = this.currentUser.timezoneId;
    }

    componentDidMount() {
        api.getEntityListByEntityId(this.currentUser.entityId).then(list => {
            this.setState({ entityList: list });
            this.newUser.entityId = list[0].entityId;
        });
        api.getUserTypes().then(list => {
            this.setState({ userTypeList: list });
        })
        api.getCurrencyList().then(list => {
            this.setState({ currencyList: list });
        });
        api.getTimezoneList().then(list => {
            this.setState({ timezoneList: list });
        })

        api.getPartnershipListByEntityId(this.currentUser.entityId, this.currentUser.userId).then(data => {
            let list = data.map(d => {
                return { id: d.partnershipId, title: d.partnershipName + ' users' };
            });
            list.splice(data.length > 0 ? data.length : 0, 0, { id: -1, title: 'All Users' });
            let part = this.currentUser.systemRoleId == 2 ? list[list.length - 1] : list[0];
            this.setState({ partnershipList: list, selectedPartnership: part }, () => this.loadUsers(this.selectedEntityId));
        })
    }


    loadUsers(entityId) {
        this.setState({ loadingUsers: true });
        api.getUserListByUserId(this.currentUser.userId, entityId, this.state.selectedPartnership.id).then(users => {
            //console.log(this.state.userStatus == 0 ? users : users.filter(x => this.state.userStatus == 1 ? !x.isDeleted : x.isDeleted));
            this.setState({ allUserList: users, userList: this.state.userStatus == 0 ? users : users.filter(x => this.state.userStatus == 1 ? !x.isDeleted : x.isDeleted), isLoading: false, loadingUsers: false });
        })
    }

    showModal(userToEdit, e) {
        e && e.preventDefault();
        this.setState({ showModal: !this.state.showModal, userToEdit: userToEdit, userId: userToEdit.userId });
    }

    closeModal() {
        this.showModal(this.newUser);
    }

    onEntitySelectionChange(e) {
        this.selectedEntityId = parseInt(e.target.value);
        this.loadUsers(this.selectedEntityId);
    }

    resendInvitation = () => {
        this.setState({ isResendingInvitation: true });
        api.resendInvitationEmail(this.currentUser.userId, this.state.userToEdit.userId).then(data => {
            this.setState({ isResendingInvitation: false });
            if (data == '')
                swal("Invitation successfuly sent.", {
                    buttons: {
                        ok: {
                            text: "Ok",
                            value: "ok",
                            className: "btn-success"
                        }
                    },
                    icon: 'success'
                });
            else
                swal(data, {
                    buttons: {
                        ok: {
                            text: "Ok",
                            value: "ok",
                            className: "btn-success"
                        }
                    },
                    icon: 'warning'
                });
        })
    }

    changeDeletion() {

    }

    setUserId(userId) {
        this.setState({ userId: userId });
    }

    onUserStatusChanged(e) {
        this.setState({ userStatus: e.target.value }, () => {
            this.loadUsers(this.selectedEntityId);
        });
    }

    render() {

        const columns = [
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                headerStyle: () => {
                    return { width: "20%" };
                },
                formatter: (cellContent, row) => {
                    return (
                        <a key={row.userId} href="#" onClick={(e) => this.showModal(row, e)}>{cellContent}</a>
                    );
                }
            },
            {
                dataField: 'email',
                text: 'Email',
                headerStyle: () => {
                    return { width: "30%" };
                },
                sort: true
            },
            {
                dataField: 'entityName',
                text: 'Company / Dept',
                headerStyle: () => {
                    return { width: "20%" };
                },
                sort: true
            },
            {
                dataField: 'systemRole',
                headerStyle: () => {
                    return { width: "10%" };
                },
                text: 'System Role'
            },
            {
                dataField: 'status',
                text: 'Status',
                sort: true,
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    let bg = '', text = '';
                    if (row.status == 0) {
                        bg = 'orange';
                        text = 'Invited'
                    } else {
                        bg = 'green';
                        text = 'Registered';
                    }
                    return <label style={{ backgroundColor: bg, padding: '2px', borderRadius: '3px', color: 'white' }}>{text}</label>
                }
            },
            {
                dataField: 'isDeleted',
                headerStyle: () => {
                    return { width: "10%" };
                },
                text: 'Active',
                formatter: (cellContent, row) => {
                    return (
                        <CustomInput readOnly={true} type="checkbox" id={row.userId} checked={!row.isDeleted} />
                    );
                }
            }
        ];

        return (
            <Fragment>


                <PageTitle
                    heading="Manage Users"
                />

                {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
                {!this.state.isLoading &&
                    <ReactCSSTransitionGroup
                        component="div"
                        transitionName="TabsAnimation"
                        transitionAppear={true}
                        transitionAppearTimeout={0}
                        transitionEnter={false}
                        transitionLeave={false}>

                        <Row>
                            <Col md="6">
                                <Button className="mb-2 mr-2" color="primary" onClick={() => this.showModal(this.newUser)}>Create New User</Button>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: '30px' }}>
                            <Col md="4">
                                {this.state.entityList.length > 0 &&
                                    <CustomInput type="select" id="entitySelect"
                                        name="entitySelect" onChange={(e) => this.onEntitySelectionChange(e)}>
                                        <option key={0} value={-1}>All Entities</option>
                                        {this.state.entityList.map(entity => {
                                            return <option key={entity.entityId} value={entity.entityId}>{entity.name}</option>
                                        })}
                                    </CustomInput>}
                            </Col>
                            {this.currentUser.entityRoleId == 2 &&
                                <Col md="4">
                                    {this.state.partnershipList.length > 0 &&
                                        <CustomInput type="select" id="part" value={this.state.selectedPartnership.id}
                                            name="part" onChange={(e) => {
                                                let p = this.state.partnershipList.find(x => x.id == e.target.value);
                                                this.setState({ selectedPartnership: p }, () => this.loadUsers(this.selectedEntityId));
                                            }}>
                                            {this.state.partnershipList.map(p => {
                                                return <option key={p.id} value={p.id}>{p.title}</option>
                                            })}
                                        </CustomInput>
                                    }
                                </Col>
                            }
                            <Col md="4">
                                <CustomInput type="select" id="active" value={this.state.userStatus}
                                    name="activeSelect" onChange={(e) => this.onUserStatusChanged(e)}>
                                    <option key={0} value={0}>All Users</option>
                                    <option key={1} value={1}>Active Users</option>
                                    <option key={2} value={2}>Inactive Users</option>
                                </CustomInput>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: '30px' }}>
                            {this.state.loadingUsers && <Loader style={{}} type="ball-pulse" active />}
                            {!this.state.loadingUsers &&
                                <GeneralGrid colWidth={12}
                                    title=""
                                    keyField="userId"
                                    defaultSortedColumn="name"
                                    dataSource={this.state.userList}
                                    filterColumns={["name", "email", "systemRole", "entityName"]}
                                    columns={columns}
                                />
                            }
                        </Row>
                    </ReactCSSTransitionGroup>

                }



                <Modal size="lg" isOpen={this.state.showModal} toggle={() => this.closeModal()} className={this.props.className} backdrop="static">

                    <ModalHeader toggle={() => this.closeModal()}>User Profile {' '}
                        <span id="userprofiletoottip" style={{ cursor: 'pointer' }}><FontAwesomeIcon style={{ color: 'darkred' }} icon={faInfoCircle} /></span>
                        <UncontrolledTooltip target="userprofiletoottip">
                            •	System Role determines whether a person is a User (Biller, Responsible Lawyer or can just View) or an Administrator.  An Administrator has all the rights of a User and can also manage all information (e.g., add matters, billers, rates) except entering activities for the company.
                        <br />
                        •	Selecting Yes for “User can Manage Matters” gives a user the right to add or update matters.
                        <br />
                        •	Biller Type is simply a classification of the user but does not change their rights in the system.
                        <br />
                        Remember to Save any changes before moving on to other areas within this page or LeGuard.
                        </UncontrolledTooltip>
                    </ModalHeader>
                    <ModalBody>

                        {this.state.userToEdit.userId > 0 && this.state.userToEdit.status == 0 &&
                            <Row>
                                <Col md="12">
                                    <Button color="warning" className="pull-right" disabled={this.state.isResendingInvitation} onClick={() => this.resendInvitation()}>Resend invitation</Button>
                                    {this.state.isResendingInvitation &&
                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                </Col>
                            </Row>
                        }
                        <UserForm
                            user={this.state.userToEdit}
                            roleList={this.state.roleList}
                            timezoneList={this.state.timezoneList}
                            currencyList={this.state.currencyList}
                            entityList={this.state.entityList}
                            userTypeList={this.state.userTypeList}
                            closeModal={() => this.closeModal()}
                            reloadUsers={() => this.loadUsers(this.selectedEntityId)}
                            setUserId={(userId) => this.setUserId(userId)}
                        />
                        {this.state.userId > -1 &&
                            <Fragment>
                                <div className="divider"></div>

                                <UserPartnership
                                    currencyList={this.state.currencyList}
                                    userId={this.state.userId}
                                />

                                <div className="divider"></div>

                                <UserMatter
                                    currencyList={this.state.currencyList}
                                    userId={this.state.userId}
                                />
                            </Fragment>
                        }
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

export default Users;



