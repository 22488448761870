//import 'react-app-polyfill/ie11';
import React from 'react';
import {render} from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './Views/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
const rootElement = document.getElementById('root');


render(
    <Provider store={store}>
      <HashRouter>
        <Main />
      </HashRouter>
    </Provider>,
    rootElement
)

serviceWorker.unregister();

