export const currencyFormatter = (currencySymbol, value, showCents) => {
    let parts = value.toString().split(".");
    let cents = '';
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (showCents) {
        cents = '.00';
        if (parts[1] !== undefined) {
            if (parts[1].length == 1) {
                cents = '.' + parts[1] + '0';
            } else if (parts[1].length > 1) {
                cents = '.' + parts[1].substring(0, 2);
            }
        }
        //cents = parts[1] == undefined ? '.00' : '.' + parts[1];
    }
    //return `${currencySymbol}${parts[1] == undefined ? parts.join(".") + '.00' : parts.join(".")}`;
    return `${currencySymbol == undefined ? '$' : currencySymbol}${parts[0]}${cents}`;

}
