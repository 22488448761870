import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, ModalHeader,
    ModalBody, Row, Col,
} from 'reactstrap';
import { api, userMatterRolesArray, userService } from '../../../_services';
import { GeneralForm } from '../../../Components/GeneralForm';
import EditRateModal from '../../Matter/MatterSettings/Components/EditRateModal';
import swal from 'sweetalert';

const AddEditUserModal = ({ user, onSave, currencyList, showModal, toggleModal, showSearchUsersModal, refreshUsers, partnership, matter, rateLabel }) => {

    const currentUser = userService.getLoggedInUser();
    let [userToEdit, setUserToEdit] = useState({});
    let [userMatterRoles, setUserMatterRoles] = useState(userMatterRolesArray);
    let [showRateModal, setShowRateModal] = useState(false);
    let [isResendingInvitation, setIsResendingInvitation] = useState(false);

    useEffect(() => {
        if (showModal) {
            setUserToEdit(user);
            let roles = userMatterRolesArray;
            if (user.userId <= 0) {
                user.sendInvitation = true;
                if (currentUser.entityRoleId == 1)
                    roles = userMatterRolesArray.filter(x => x.id == 1);
                //this.setState({ userMatterRoles: roles, user: nextProps.user });
            }
            setUserMatterRoles(roles);
            // this.setState({ userMatterRoles: roles, user: nextProps.user });
        }
    }, [showModal])

    useEffect(() => {
        if (user !== undefined) {
            setUserToEdit(user);
        }
    }, [user])

    const saveUser = (user) => {
        onSave(user);
    }

    const handleChange = (prop, val) => {
        let usr = userToEdit;
        usr[prop] = val;
        setUserToEdit(usr);
    }

    const onChangeRate = (e) => {
        e && e.preventDefault();
    }

    const toggleRateModal = () => {
        setShowRateModal(!showRateModal);
    }

    const isReadonly = userToEdit.userId > -1 && userToEdit.isSaved;

    const resendInvitation = (fromUserId, toUserId) => {
        setIsResendingInvitation(true);
        api.resendInvitationEmail(fromUserId, toUserId).then(data => {
            setIsResendingInvitation(false);
            if (data == '')
                swal("Invitation successfuly sent.", {
                    buttons: {
                        ok: {
                            text: "Ok",
                            value: "ok",
                            className: "btn-success"
                        }
                    },
                    icon: 'success'
                });
            else
                swal(data, {
                    buttons: {
                        ok: {
                            text: "Ok",
                            value: "ok",
                            className: "btn-success"
                        }
                    },
                    icon: 'warning'
                });
        })
    }


    const userFormProps = [
        {
            type: 'input',
            property: 'name',
            label: 'Name *',
            isVisible: true,
            isReadonly: isReadonly,
            isRequired: true,
            width: 12
        },
        {
            type: 'dropdown',
            property: 'roleId',
            label: 'Role in this Matter *',
            isVisible: true,
            isReadonly: isReadonly,
            isRequired: true,
            dropdownData: isReadonly ? userMatterRoles : (currentUser.entityRoleId == 1 ? userMatterRoles.filter(x => x.id == 1) : userMatterRoles)
        },
        {
            type: 'input',
            fieldType: 'email',
            property: 'email',
            label: 'Email *',
            isVisible: true,
            isReadonly: isReadonly,
            isRequired: true
        },
        {
            type: 'input',
            fieldType: 'number',
            property: 'rate',
            label: rateLabel + ' *',
            isVisible: true,
            isReadonly: isReadonly,
            isVisibleFunc: (obj) => {
                return obj.roleId == 1 ? false : true;
            },
            specialElement:
                currentUser.entityId == userToEdit.entityId ? <a href='#' onClick={(e) => { e.preventDefault(); toggleRateModal() }}>Change</a> : ''
            ,
            isRequired: true
        },
        {
            type: 'dropdown',
            property: 'currencyId',
            label: 'Default Currency *',
            isVisible: true,
            isReadonly: isReadonly,
            isRequired: true,
            isVisibleFunc: (obj) => {
                return obj.roleId == 1 ? false : true;
            },
            dropdownData: currencyList.map(x => {
                x.id = x.currencyId;
                x.name = x.displayValue;
                return x;
            })
        },
        {
            type: 'checkbox',
            property: 'sendInvitation',
            label: 'Send Invitation Email'
        }
    ]

    return (
        <Fragment>
            <Modal isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
                <ModalHeader toggle={() => toggleModal()}>{!isReadonly ? 'Add ' : 'Update '}{userToEdit.roleId > 0 ? userMatterRolesArray.find(x => x.id == userToEdit.roleId).name : ''}</ModalHeader>
                <ModalBody>
                    {/* <Button color="primary" onClick={() => showSearchUsersModal()} disabled={isReadonly}>Search</Button> */}
                    {userToEdit.status == 0 && <Row><Col md='12'><Button color="warning" className="pull-right" style={{ color: 'white' }} disabled={isResendingInvitation} onClick={() => resendInvitation(currentUser.userId, userToEdit.userId)}>Resend Invitation</Button></Col></Row>}
                    <Row style={{ marginTop: '30px' }}>
                        <Col md="12">
                            
                            <GeneralForm data={userToEdit}
                                propertyTypes={userFormProps}
                                onSave={(user) => { let usr = user; usr.isDeleted = false; saveUser(usr) }}
                                onDelete={!isReadonly ? undefined : (currentUser.entityId == userToEdit.entityId ? (user) => { let usr = user; usr.isDeleted = true; saveUser(usr); } : undefined)}
                                showSave={!isReadonly}
                                //showDelete={isReadonly}
                                onCancel={toggleModal}
                            />

                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <EditRateModal refreshUsers={refreshUsers}
                showModal={showRateModal}
                toggleModal={toggleRateModal}
                partnership={partnership}
                matter={matter}
                userId={userToEdit.userId}
                currency={currencyList.find(x => x.currencyId == userToEdit.currencyId)}
            />
        </Fragment>
    );

}

AddEditUserModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    currencyList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    rateLabel: PropTypes.string.isRequired,
    showSearchUsersModal: PropTypes.func,
    onSave: PropTypes.func
}
export default AddEditUserModal;