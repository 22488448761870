import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import { api, userService } from "../_services";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DateTimeHelper } from "../_helpers/dateTimeHelper";
import { currencyFormatter } from "../_services/currencyFormatter";
import swal from "sweetalert";
import { getDurationInHours } from "../Views/Activity/Components/EditActivityModal";

const CopyActivitiesModal = ({ showModal, toggleModal }) => {
  const currentUser = userService.getLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [matters, setMatters] = useState([]);
  const [selectedMatterIdFrom, setSelectedMatterIdFrom] = useState(-1);
  const [selectedMatterIdTo, setSelectedMatterIdTo] = useState(-1);
  const [activities, setActivities] = useState([]);
  const [expenses, setExpenses] = useState([]);

  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("day").toDate());

  const [copyActivities, setCopyActivities] = useState(true);
  const [copyExpenses, setCopyExpenses] = useState(true);

  // 0 - copy, 1 - move
  const [copyOrMove, setCopyOrMove] = useState(0);
  const [allActivitiesSelected, setAllActivitiesSelected] = useState(false);
  const [allExpensesSelected, setAllExpensesSelected] = useState(false);

  useEffect(() => {
    if (showModal) {
      loadMatters();
      setAllActivitiesSelected(false)
      setAllExpensesSelected(false)
    }
  }, [showModal]);

  useEffect(() => {
    if (selectedMatterIdFrom == -1) {
      setActivities([]);
    } else {
      loadActivities(selectedMatterIdFrom);
    }
  }, [selectedMatterIdFrom, startDate, endDate]);

  const loadActivities = async (mId) => {
    setIsLoading(true);
    let a = await api.getActivitiesByMatterIdDateRange(
      mId,
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
    );
    setActivities(
      a.sort(function (x, y) {
        return new Date(x.startTime) - new Date(y.startTime);
      })
    );
    let e = await api.getExpenseListByMatterIdDateRange(
      mId,
      moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
      moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
    );
    setExpenses(
      e.sort(function (x, y) {
        return new Date(x.expenseDate) - new Date(y.expenseDate);
      })
    );
    setIsLoading(false);
  };

  const loadMatters = async () => {
    let m = await api.getMatterListByBillerEntityId(currentUser.entityId);
    setMatters(m);
  };

  const save = async () => {
    if (
      selectedMatterIdTo != -1 &&
      selectedMatterIdTo !== selectedMatterIdFrom
    ) {
      swal("Are you sure you want to perform this operation?", {
        buttons: {
          yes: {
            text: "Yes",
            value: "yes",
          },
          no: {
            text: "Cancel",
            value: "no",
          },
        },
      }).then(async (value) => {
        if (value == "yes") {
          let activityIds =
            activities.find((x) => x.isSelected) !== undefined
              ? activities
                  .filter((x) => x.isSelected)
                  .map((x) => x.activityId)
                  .join(",")
              : "";
          let expenseIds =
            expenses.find((x) => x.isSelected) !== undefined
              ? expenses
                  .filter((x) => x.isSelected)
                  .map((x) => x.expenseId)
                  .join(",")
              : "";
          await api.copyOrMoveActivitiesAndExpenses(
            selectedMatterIdTo,
            copyOrMove,
            activityIds,
            expenseIds
          );
          toast("Operation completed", {
            autoClose: 3000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
          });
        }
      });
    }
  };

  return (
    <Modal
      isOpen={showModal}
      size="lg"
      toggle={() => {
        toggleModal();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Copy Activities
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-3">
          <Col>
            <label>Operation</label>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <CustomInput
                  id={`setcopy`}
                  type="radio"
                  value="0"
                  label="Copy"
                  checked={copyOrMove == 0}
                  onChange={(e) => setCopyOrMove(0)}
                ></CustomInput>
              </div>
              <div style={{ marginLeft: "20px" }}>
                <CustomInput
                  id={`setmove`}
                  type="radio"
                  value="1"
                  label="Move"
                  checked={copyOrMove == 1}
                  onChange={(e) => setCopyOrMove(1)}
                ></CustomInput>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="form-group col-md-6">
            <label htmlFor="startDate">Start Date</label>

            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              placeholderText="Start date..."
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="endDate">End Date</label>
            <DatePicker
              className="form-control"
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
              placeholderText="End date..."
            />
          </div>
        </Row>
        <Row>
          <Col md="6">
            <div className="form-group">
              <label>From Matter</label>
              <CustomInput
                type="select"
                className="form-control"
                onChange={(e) => {
                  setSelectedMatterIdFrom(e.target.value);
                }}
                value={selectedMatterIdFrom}
              >
                <option value="-1">Select Matter</option>
                {matters.map((m) => {
                  return (
                    <option key={m.matterId} value={m.matterId}>
                      {m.matterName}
                    </option>
                  );
                })}
              </CustomInput>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label>To Matter</label>
              <CustomInput
                type="select"
                className="form-control"
                onChange={(e) => {
                  setSelectedMatterIdTo(e.target.value);
                }}
                value={selectedMatterIdTo}
              >
                <option value="-1">Select Matter</option>
                {matters.map((m) => {
                  return (
                    <option key={m.matterId} value={m.matterId}>
                      {m.matterName}
                    </option>
                  );
                })}
              </CustomInput>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <CustomInput
                  id="uerf9ui34ji"
                  type="checkbox"
                  label="Activities"
                  style={{ cursor: "pointer" }}
                  value={copyActivities}
                  checked={copyActivities}
                  onClick={(e) => {
                    setCopyActivities(!copyActivities);
                    setActivities(
                      activities.map((x) => {
                        x.isSelected = false;
                        return x;
                      })
                    );
                  }}
                />
              </div>
              <div style={{ marginLeft: "20px" }}>
                <CustomInput
                  id="fhu4h948r"
                  type="checkbox"
                  label="Expenses"
                  style={{ cursor: "pointer" }}
                  value={copyExpenses}
                  checked={copyExpenses}
                  onClick={(e) => {
                    setCopyExpenses(!copyExpenses);
                    setExpenses(
                      expenses.map((x) => {
                        x.isSelected = false;
                        return x;
                      })
                    );
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        {isLoading && <Loader style={{}} type="ball-pulse" active />}
        {copyExpenses && (
          <Row>
            <Col>
              <Card style={{ width: "100%" }}>
                <h5>Expenses</h5>
                <CardBody style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {!isLoading && expenses.length > 0 && (
                    <table className="table-stripped" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}>
                            <div
                              onClick={(e) => {
                                setExpenses(
                                  expenses.map((exp) => {
                                    exp.isSelected = !allExpensesSelected;
                                    return exp;
                                  })
                                );
                                setAllExpensesSelected(!allExpensesSelected);
                              }}
                            >
                              <CustomInput
                                type="checkbox"
                                checked={allExpensesSelected}
                              />
                            </div>
                          </th>
                          <th style={{ width: "20%" }}>Date</th>
                          <th style={{ width: "50%" }}>Expense</th>
                          <th style={{ width: "20%" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenses.map((a, idx) => (
                          <tr
                            key={idx}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              let copy = [...expenses];
                              let exp = copy.find(
                                (x) => x.expenseId == a.expenseId
                              );
                              exp.isSelected = !exp.isSelected;
                              setExpenses(copy);
                            }}
                          >
                            <td>
                              <CustomInput
                                type="checkbox"
                                checked={a.isSelected}
                              />
                            </td>
                            <td>
                              {moment(a.expenseDate).format("MM/DD/YYYY")}
                            </td>
                            <td>{a.expenseName}</td>
                            <td>{currencyFormatter("$", a.expenseAmount)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {!isLoading && expenses.length == 0 && <p>No Expenses</p>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {copyActivities && (
          <Row>
            <Col>
              <Card style={{ width: "100%" }}>
                <h5>Activities</h5>
                <CardBody style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {!isLoading && activities.length > 0 && (
                    <table className="table-stripped" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}> <div
                              onClick={(e) => {
                                setActivities(
                                  activities.map((exp) => {
                                    exp.isSelected = !allActivitiesSelected;
                                    return exp;
                                  })
                                );
                                setAllActivitiesSelected(!allActivitiesSelected);
                              }}
                            >
                              <CustomInput
                                type="checkbox"
                                checked={allActivitiesSelected}
                              />
                            </div></th>
                          <th style={{ width: "20%" }}>Date</th>
                          <th style={{ width: "20%" }}>Duration (hrs)</th>
                          <th style={{ width: "50%" }}>Activity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {activities.map((a, idx) => (
                          <tr
                            key={idx}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              let copy = [...activities];
                              let act = copy.find(
                                (x) => x.activityId == a.activityId
                              );
                              act.isSelected = !act.isSelected;
                              setActivities(copy);
                            }}
                          >
                            <td>
                              <CustomInput
                                type="checkbox"
                                checked={a.isSelected}
                              />
                            </td>
                            <td>{moment(a.startTime).format("MM/DD/YYYY")}</td>
                            <td>
                              {getDurationInHours(a.startTime, a.endTime)}
                            </td>
                            <td>
                              {a.activityCodeTitle} ({a.authBillerName})
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {!isLoading && activities.length == 0 && <p>No Activities</p>}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export default CopyActivitiesModal;
