import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import {
  Button,
  Col,
  CustomInput,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { currencyFormatter } from "../../../_services/currencyFormatter";

const InvoicePaymentModal = ({
  showModal,
  toggleModal,
  invoiceList,
  invoicePaymentToEdit,
  changeInvoicePaymentProp,
  onSave,
  setInvoicePaymentToEdit,
  removeInvoicePayment,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (showModal) {
      console.log(invoicePaymentToEdit)
    }
  }, [showModal]);

  const saveInvoicePayment = () => {
    if (invoicePaymentToEdit.invoiceId == -1) {
      setErrorMessage("Please select an invoice first");
    } else {
      onSave();
    }
  };

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Invoice Payment</ModalHeader>
      {invoicePaymentToEdit != null && (
        <ModalBody>
          <Row>
            <Col md="12">
              <div className="form-group">
                <label>Invoice *</label>
                <CustomInput
                  type="select"
                  value={invoicePaymentToEdit.invoiceId}
                  onChange={(e) => {
                    if (e.target.value != -1) {
                      setInvoicePaymentToEdit({
                        ...invoicePaymentToEdit,
                        invoiceId: e.target.value,
                        invoiceNumber: invoiceList.find(
                          (x) => x.invoiceId == e.target.value
                        ).invoiceNumber,
                        isDirty: true,
                      });
                    } else {
                      setInvoicePaymentToEdit({
                        ...invoicePaymentToEdit,
                        invoiceId: e.target.value,
                        isDirty: true,
                      });
                    }
                    setErrorMessage("");
                  }}
                >
                  <option key={0} value={-1}>
                    Select Invoice
                  </option>
                  {invoiceList
                    .filter(
                      (x) =>
                        invoicePaymentToEdit.invoicePaymentId !== -1 ? true :
                        x.outstandingAmount > 0
                    )
                    .map((inv) => {
                      return (
                        <option key={inv.invoiceId} value={inv.invoiceId}>
                          {inv.invoiceNumber}{" "}
                          {inv.clientEntityName !== null &&
                            `(${inv.clientEntityName})`}{" "}
                          {`(Bal: ${currencyFormatter(
                            "$",
                            inv.outstandingAmount,
                            true
                          )})`}
                        </option>
                      );
                    })}
                </CustomInput>
              </div>
              <div className="form-group">
                <label>Amount Applied</label>
                <CurrencyInput
                  className="form-control"
                  value={invoicePaymentToEdit.amountApplied}
                  onValueChange={(value, name, values) => {
                    changeInvoicePaymentProp("amountApplied", values.value);
                  }}
                  prefix={"$"}
                />
              </div>
              <div className="form-group">
                <CustomInput
                  id="32d32w323d2"
                  type="checkbox"
                  label="Write Off"
                  checked={invoicePaymentToEdit.writeOff}
                  value={invoicePaymentToEdit.writeOff}
                  onChange={() =>
                    changeInvoicePaymentProp(
                      "writeOff",
                      !invoicePaymentToEdit.writeOff
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label>Comment</label>
                <Input
                  name="notes"
                  type="textarea"
                  value={invoicePaymentToEdit.comments}
                  onChange={(e) =>
                    changeInvoicePaymentProp("comments", e.target.value)
                  }
                  placeholder="Comments..."
                />
              </div>
              {errorMessage != "" && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {errorMessage}
                </div>
              )}
            </Col>
          </Row>
        </ModalBody>
      )}
      <div style={{ margin: "10px" }}>
        <Button color="link" onClick={() => toggleModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={saveInvoicePayment}
          style={{ marginLeft: ".25rem" }}
        >
          Save
        </Button>
        <Button
          color="danger"
          style={{ marginLeft: ".25rem" }}
          className="pull-right"
          onClick={removeInvoicePayment}
        >
          Remove
        </Button>
      </div>
    </Modal>
  );
};

export default InvoicePaymentModal;
