import React, { Component, Fragment } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/fontawesome-free-solid";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { api, userService } from "../_services";

class TreeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      geoCategoryList: [],
      //entityTypeList: [],
      businessActivityList: [],
      entityToEdit: {
        entityId: -1,
        name: "",
        isDeleted: false,
        //entityTypeId: 1,
        parentId: -1,
        //geoCategoryId: -1,
        //businessActivityId: 1,
        entityRoleId: 1,
        updateUserId: "",
        contactName: "",
        contactEmail: "",
        requiresEBillerId: false,
      },
    };
    this.newEntity = {
      entityId: -1,
      name: "",
      isDeleted: false,
      //entityTypeId: 1,
      parentId: -1,
      //geoCategoryId: -1,
      //businessActivityId: 1,
      entityRoleId: 1,
      updateUserId: "",
      contactName: "",
      contactEmail: "",
      requiresEBillerId: false,
      enforceClientFlags: false
    };
    this.entityList = this.props.entityList;
    this.currentUser = userService.getLoggedInUser();

    this.formRef = React.createRef();
  }

  componentDidMount() {
    api
      .getItemListByEntityId("GeoCategory", this.currentUser.entityId)
      .then((list) => {
        this.setState({ geoCategoryList: list });
      });
    // api.getEntityTypeList().then(list => {
    //     this.setState({ entityTypeList: list });
    // });
    api
      .getItemListByEntityId("BusinessActivity", this.currentUser.entityId)
      .then((list) => {
        this.setState({ businessActivityList: list });
      });
  }

  toggle = (event) => {
    const id = event.target.getAttribute("id");
    this.setState((state) => ({ [id]: !state[id] }));
  };

  mapper = (nodes, parentId, lvl) => {
    return nodes.map((node, index) => {
      const id = `${node.name}-${parentId ? parentId : "top"}`.replace(
        /[^a-zA-Z0-9-_]/g,
        ""
      );
      const item = (
        <Fragment key={node.entityId}>
          <ListGroupItem
            style={{ zIndex: 0 }}
            className={`${
              parentId ? `rounded-0 ${lvl ? "border-bottom-0" : ""}` : ""
            }`}
          >
            {
              <div style={{ paddingLeft: `${25 * lvl}px` }}>
                {node.nodes.length > 0 && (
                  <i
                    className={
                      this.state[id] ? "pe-7s-angle-down" : "pe-7s-angle-right"
                    }
                    style={{
                      fontSize: "xx-large",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    color="link"
                    id={id}
                    onClick={this.toggle}
                  />
                )}
                {node.entityRoleId == 1 && (
                  <FontAwesomeIcon
                    icon={Icons.faUsers}
                    style={{ marginRight: "10px" }}
                  />
                )}
                {node.entityRoleId == 2 && (
                  <FontAwesomeIcon
                    icon={Icons.faBalanceScale}
                    style={{ marginRight: "10px" }}
                  />
                )}
                <a
                  href="#"
                  onClick={(e) => this.showModal(node, e)}
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  {node.name}
                </a>
              </div>
            }
          </ListGroupItem>
          {node.nodes && (
            <Collapse isOpen={this.state[id]}>
              {this.mapper(node.nodes, id, (lvl || 0) + 1)}
            </Collapse>
          )}
        </Fragment>
      );

      return item;
    });
  };

  onDropdownSelectionChange(e) {
    let propName = e.target.name;
    let propVal = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
      entityToEdit: {
        ...prevState.entityToEdit,
        [propName]: propVal,
      },
    }));
  }

  showModal(entity, e) {
    e && e.preventDefault();
    if (entity.parentId == -1)
      entity.parentId =
        this.entityList.length == 0
          ? 0
          : this.entityList.find((x) => x.parentId == 0) == undefined
          ? 0
          : this.entityList.find((x) => x.parentId == 0).entityId;
    this.setState({ showModal: !this.state.showModal, entityToEdit: entity });
  }

  closeModal() {
    this.showModal(this.newEntity);
  }

  changeDeletion() {
    this.setState(
      {
        entityToEdit: {
          ...this.state.entityToEdit,
          isDeleted: !this.state.entityToEdit.isDeleted,
        },
      },
      () => {
        this.updateEntity(this.state.entityToEdit);
      }
    );
  }

  handleSubmit() {
    this.formRef.current.submitForm();
  }

  updateEntity(entity) {
    api.updateEntity(entity).then((msg) => {
      this.props.reloadEntities();
      this.closeModal();
    });
  }

  createEntity(entity) {
    api.createEntity(entity).then((msg) => {
      this.props.reloadEntities();
      this.closeModal();
    });
  }

  render() {
    let list = this.props.list;

    return (
      <Fragment>
        <ListGroup style={{ marginBottom: "30px" }}>
          {this.mapper(list)}
        </ListGroup>
        {(this.currentUser.entityId == 0 ||
          this.currentUser.systemRoleId == 2) && (
          <Button
            color="primary"
            onClick={() => this.showModal(this.newEntity)}
          >
            Add New Company or Department
          </Button>
        )}
        <Modal
          isOpen={this.state.showModal}
          toggle={() => this.showModal(this.newEntity)}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader toggle={() => this.showModal(this.newEntity)}>
            Company Information
          </ModalHeader>
          <ModalBody>
            <Formik
              ref={this.formRef}
              initialValues={{ name: this.state.entityToEdit.name }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required"),
              })}
              onSubmit={({ name }, { setStatus, setSubmitting }) => {
                setStatus();
                this.setState(
                  (prevState) => ({
                    ...prevState,
                    entityToEdit: {
                      ...prevState.entityToEdit,
                      name: name,
                    },
                  }),
                  () => {
                    if (this.state.entityToEdit.entityId > -1)
                      this.updateEntity(this.state.entityToEdit);
                    else this.createEntity(this.state.entityToEdit);
                  }
                );
              }}
              render={({
                errors,
                status,
                touched,
                isSubmitting,
                submitForm,
              }) => {
                return (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="name">Name *</label>
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {/* 
                                        <div className="form-group">
                                            <label htmlFor="entityTypeId">Entity Type</label>
                                            <CustomInput type="select" id="entityTypeId" value={this.state.entityToEdit.entityTypeId}
                                                name="entityTypeId" onChange={(e) => this.onDropdownSelectionChange(e)}>
                                                {this.state.entityTypeList.map(et => {
                                                    return <option key={et.entityTypeId} value={et.entityTypeId}>{et.entityTypeName}</option>
                                                })}
                                            </CustomInput>
                                        </div> */}
                    {((this.state.entityToEdit.entityId > -1 &&
                      this.state.entityToEdit.parentId !== 0) ||
                      this.state.entityToEdit.entityId == -1) && (
                      <div className="form-group">
                        <label htmlFor="parentId">Parent Company *</label>
                        <CustomInput
                          type="select"
                          id="parentId"
                          value={this.state.entityToEdit.parentId}
                          name="parentId"
                          onChange={(e) => this.onDropdownSelectionChange(e)}
                        >
                          {(this.state.entityToEdit.entityId == -1 ||
                            this.state.entityToEdit.parentId == 0) &&
                            this.currentUser.entityId == 0 && (
                              <option key={0} value={0}>
                                This is a parent
                              </option>
                            )}
                          {/* {this.entityList.filter(e =>{
                                                    return e.entityId !== this.state.entityToEdit.entityId;
                                                }).map(et =>{
                                                    return <option key={et.entityId} value={et.entityId}>{et.name}</option>
                                                })} */}
                          {this.entityList.map((et) => {
                            return (
                              et.parentId !==
                                this.state.entityToEdit.entityId &&
                              et.entityId !==
                                this.state.entityToEdit.entityId &&
                              et.parentId == 0 && (
                                <option key={et.entityId} value={et.entityId}>
                                  {et.name}
                                </option>
                              )
                            );
                          })}
                        </CustomInput>
                      </div>
                    )}
                    {/* <div className="form-group">
                                            <label htmlFor="geoCategoryId">Geographic Location</label>
                                            <CustomInput type="select" id="geoCategoryId" value={this.state.entityToEdit.geoCategoryId}
                                                name="geoCategoryId" onChange={(e) => this.onDropdownSelectionChange(e)}>
                                                {this.state.geoCategoryList.map(gc => {
                                                    if (gc.isDeleted == false || gc.id == this.state.entityToEdit.geoCategoryId)
                                                        return <option key={gc.id} value={gc.id}>{gc.name}</option>
                                                })}
                                            </CustomInput>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="businessActivityId">Business Unit</label>
                                            <CustomInput type="select" id="businessActivityId" value={this.state.entityToEdit.businessActivityId}
                                                name="businessActivityId" onChange={(e) => this.onDropdownSelectionChange(e)}>
                                                {this.state.businessActivityList.map(ba => {
                                                    if (ba.isDeleted == false || ba.id == this.state.entityToEdit.businessActivityId)
                                                        return <option key={ba.id} value={ba.id}>{ba.name}</option>
                                                })}
                                            </CustomInput>
                                        </div> */}

                    <div className="form-group">
                      <label htmlFor="entityRoleId">
                        The company primarily:
                      </label>
                      <CustomInput
                        type="select"
                        id="entityRoleId"
                        value={this.state.entityToEdit.entityRoleId}
                        name="entityRoleId"
                        onChange={(e) => this.onDropdownSelectionChange(e)}
                      >
                        <option value={1}>
                          Is a client company that monitors billing
                        </option>
                        <option value={2}>
                          Is a Law Firm that bills to clients
                        </option>
                      </CustomInput>
                    </div>

                    <CustomInput
                      type="checkbox"
                      id="sendEmail"
                      checked={this.state.entityToEdit.requiresEBillerId}
                      label="Company requires MatterID For linking to external system"
                      onChange={(e) =>
                        this.setState({
                          entityToEdit: {
                            ...this.state.entityToEdit,
                            requiresEBillerId: e.target.checked,
                          },
                        })
                      }
                    />

                    <CustomInput
                      type="checkbox"
                      id="enforceClientFlags"
                      checked={this.state.entityToEdit.enforceClientFlags}
                      label="Enforce Client Flags as 0 hours for Invoice Checks"
                      onChange={(e) =>
                        this.setState({
                          entityToEdit: {
                            ...this.state.entityToEdit,
                            enforceClientFlags: e.target.checked,
                          },
                        })
                      }
                    />

                    <div className="form-footer" style={{ marginTop: "15px" }}>
                      <Button color="link" onClick={() => this.closeModal()}>
                        Cancel
                      </Button>
                      {this.state.entityToEdit.entityId > -1 && (
                        <Button
                          color={
                            this.state.entityToEdit.isDeleted
                              ? "success"
                              : "danger"
                          }
                          style={{ marginLeft: ".25rem" }}
                          className="pull-right"
                          onClick={() => this.changeDeletion()}
                        >
                          {this.state.entityToEdit.isDeleted
                            ? "Activate"
                            : "Inactivate"}
                        </Button>
                      )}
                      <Button
                        color="primary"
                        onClick={() => this.handleSubmit()}
                        style={{ marginLeft: ".25rem" }}
                      >
                        Save
                      </Button>{" "}
                    </div>

                    {status && (
                      <div className={"alert alert-danger"}>{status}</div>
                    )}
                  </Form>
                );
              }}
            />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default TreeView;
