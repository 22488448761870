/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import { api, userService } from "../../../../_services";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorElement } from "../../../../Components/ErrorElement";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import VendorModal from "./VendorModal";

const ExpenseModal = ({
  expenseObj,
  showModal,
  toggleModal,
  matterList,
  invoice,
  onSave,
  reload,
}) => {
  const currentUser = userService.getLoggedInUser();
  let [expense, setExpense] = useState({
    expenseId: -1,
    invoiceId: invoice === undefined ? 0 : invoice.invoiceId,
    matterId: -1,
    matterName: "",
    expenseName: "",
    notes: "",
    expenseAmount: "",
    currencyId: 3,
    expenseDate: new Date(),
    currentyId: currentUser.currencyId,
    explanationCodeID: 0,
    vendorID: 0,
  });
  const [explanationCodes, setExplanationCodes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [expenseDocuments, setExpenseDocuments] = useState([]);

  const [isOnFinalInvoice, setIsOnFinalInvoice] = useState(false);
  const [expenseInvoice, setExpenseInvoice] = useState(null);

  const toggleVendorModal = () => setShowVendorModal(!showVendorModal);

  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: expense,
    resolver: yupResolver(
      Yup.object().shape({
        matterId: Yup.number().test(
          "matterId",
          "Please select a matter",
          (value) => {
            if (matterList && matterList.length > 0) {
              return value !== -1;
            }

            return true;
          }
        ),
        expenseName: Yup.string().required("Expense Name is required"),
        expenseAmount: Yup.number().required("Expense Amount is required"),
        expenseDate: Yup.date().required("Expense Date is required"),
        notes: Yup.string(),
        explanationCodeID: Yup.number(),
        vendorID: Yup.number(),
      })
    ),
  });

  useEffect(() => {
    loadExplanationCodes();
    loadVendors();
  }, []);

  useEffect(() => {
    if (showModal) {
      setFiles([]);
      setExpenseDocuments([]);
      setExpense(expenseObj);
      if (expenseObj.expenseId !== undefined && expenseObj.expenseId > 0) {
        loadExpenseDocuments(expenseObj.expenseId);
      }
      reset(expenseObj);
      setValue("expenseDate", moment(expenseObj.expenseDate).toDate());
      loadExpenseInvoice(expenseObj);
    }
  }, [reset, setValue, showModal, expenseObj]);

  useEffect(() => {
    // Retrieve the entire form data object
    const subscription = watch((value) => setExpense(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const loadExplanationCodes = () => {
    api.getExplanationCodes().then((expCodes) => {
      setExplanationCodes(expCodes);
    });
  };

  const loadExpenseInvoice = (exp) => {
    if (exp.expenseId > 0 && exp.invoiceId > 0) {
      api.getInvoiceById(exp.invoiceId).then((inv) => {
        setExpenseInvoice(inv);
        if (inv !== undefined && inv !== null && inv.invoiceStatusId == 120) {
          setIsOnFinalInvoice(true);
        } else {
          setIsOnFinalInvoice(false);
        }
      });
    } else {
      setIsOnFinalInvoice(false);
      setExpenseInvoice(null);
    }
  };

  const loadVendors = () => {
    api.getVendors().then((v) => {
      setVendors(v);
    });
  };

  const removeExpenseDocument = (expenseDoc) => {
    api.deleteExpenseDocument(expenseDoc.expenseDocumentID).then((d) => {
      loadExpenseDocuments(expenseDoc.expenseID);
    });
  };

  const loadExpenseDocuments = (id) => {
    api.getExpenseDocuments(id).then((f) => {
      setExpenseDocuments(f);
    });
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  // data is the actual expense object
  const onSubmit = (data) => {
    console.log("data", data);
    if (invoice === undefined) {
      saveExpense(expense, files);
    } else if (
      invoice.startDate !== undefined &&
      invoice.startDate !== null &&
      invoice.endDate !== undefined &&
      invoice.endDate !== null
    ) {
      var date = new Date(moment(data.expenseDate).format("YYYY-MM-DD"));
      date.setHours(0, 0, 0);
      if (
        new Date(invoice.startDate).setHours(0, 0, 0) <= date &&
        new Date(invoice.endDate).setHours(0, 0, 0) >= date
      ) {
        saveExpense(data, files);
      } else {
        swal({
          text: "This Expense is outside the Invoice Date Range.  Please confirm that is correct",
          buttons: {
            confirm: {
              text: "Confirm",
              value: "Confirm",
            },
            return: {
              text: "Cancel",
              value: "Cancel",
            },
          },
        }).then((value) => {
          if (value === "Confirm") {
            saveExpense(expense, files);
          }
        });
      }
    }
  };

  const saveExpense = (expense, files) => {
    onSave && onSave(expense, files);
    if (expense.expenseId > 0) {
      if (expense.isDeleted) {
        api.deleteExpense(expense, currentUser.userId).then((data) => {
          toggleModal();
          reload();
        });
      } else {
        api.updateExpense(expense, currentUser.userId).then((data) => {
          if (files.length > 0) {
            let requests = [];
            files.forEach((f) => {
              requests.push(api.uploadExpenseDocument(expense.expenseId, f));
            });
            Promise.all(requests).then(
              (r) => {
                toggleModal();
                reload();
              },
              (err) => {
                toggleModal();
                reload();
              }
            );
          } else {
            toggleModal();
            reload();
          }
        });
      }
    } else {
      api.createExpense(expense, currentUser.userId).then((id) => {
        if (files.length > 0) {
          let requests = [];
          files.forEach((f) => {
            requests.push(api.uploadExpenseDocument(id, f));
          });
          Promise.all(requests).then(
            (r) => {
              toggleModal();
              reload();
            },
            (err) => {
              toggleModal();
              reload();
            }
          );
        } else {
          toggleModal();
          reload();
        }
      });
    }
  };

  return (
    <Modal size="md" isOpen={showModal} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>
        {expense.expenseId <= 0 ? "Add " : "Update "}Expense
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <Controller
                name="expenseName"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <FormGroup>
                    <Label for="expenseName">Expense Description *</Label>
                    <Input
                      name="expenseName"
                      placeholder="Enter..."
                      value={field.value}
                      invalid={!!errors.expenseName}
                      onChange={(e) => field.onChange(e)}
                    />
                    <ErrorElement message={errors.budgetName?.message} />
                  </FormGroup>
                )}
              />

              {matterList?.length > 0 && (
                <Controller
                  name="matterId"
                  control={control}
                  defaultValue={-1}
                  render={({ field }) => (
                    <FormGroup>
                      <Label for="matterId">Matter *</Label>
                      <CustomInput
                        type="select"
                        id="matterSelect"
                        value={field.value}
                        name="matterId"
                        invalid={!!errors.matterId}
                        onChange={(e) =>
                          field.onChange(parseInt(e.target.value))
                        }
                      >
                        <option key={-1} value={-1}>
                          Select Matter
                        </option>
                        {matterList.map((m) => {
                          return (
                            <option key={m.matterId} value={m.matterId}>
                              {m.matterName}
                            </option>
                          );
                        })}
                      </CustomInput>
                      <ErrorElement message={errors.matterId?.message} />
                    </FormGroup>
                  )}
                />
              )}

              {explanationCodes?.length > 0 && (
                <Controller
                  name="explanationCodeID"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <FormGroup>
                      <Label for="explanationCodeID">Explanation Code</Label>
                      <CustomInput
                        type="select"
                        id="explanationCodeID"
                        value={field.value}
                        invalid={!!errors.explanationCodeID}
                        onChange={(e) =>
                          field.onChange(parseInt(e.target.value))
                        }
                      >
                        <option value={0}>None</option>
                        {explanationCodes.map((ec) => {
                          return (
                            <option
                              key={ec.explanationCodeID}
                              value={ec.explanationCodeID}
                            >
                              {ec.code} - {ec.title}
                            </option>
                          );
                        })}
                      </CustomInput>
                      <ErrorElement
                        message={errors.explanationCodeID?.message}
                      />
                    </FormGroup>
                  )}
                />
              )}

              <FormGroup>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <label>Vendor</label>
                  <a
                    href="#"
                    onClick={(e) => {
                      e && e.preventDefault();
                      toggleVendorModal();
                    }}
                  >
                    Add new vendor
                  </a>
                </div>

                <Controller
                  name="vendorID"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <CustomInput
                      type="select"
                      id="vendorID"
                      value={field.value}
                      invalid={!!errors.vendorID}
                      onChange={(e) => field.onChange(parseInt(e.target.value))}
                    >
                      <option value="0">None</option>
                      {vendors.map((v) => {
                        return (
                          <option key={v.vendorId} value={v.vendorId}>
                            {v.vendorName}
                          </option>
                        );
                      })}
                    </CustomInput>
                  )}
                />
              </FormGroup>

              <Controller
                name="expenseDate"
                control={control}
                defaultValue={new Date()}
                render={({ field }) => (
                  <FormGroup>
                    <Label for="expenseDate">Date *</Label>
                    <DatePicker
                      name="expenseDate"
                      selected={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Date..."
                      isInvalid={!!errors.expenseDate}
                      className="form-control"
                    />
                    <ErrorElement message={errors.expenseDate?.message} />
                  </FormGroup>
                )}
              />

              <Controller
                name="expenseAmount"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <FormGroup>
                    <Label for="expenseAmount">Amount *</Label>
                    <Input
                      name="expenseAmount"
                      type="number"
                      value={field.value}
                      onChange={(e) =>
                        field.onChange(parseFloat(e.target.value))
                      }
                      placeholder="Amount..."
                      invalid={!!errors.expenseAmount}
                    />
                    <ErrorElement message={errors.expenseAmount?.message} />
                  </FormGroup>
                )}
              />

              <Controller
                name="notes"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <FormGroup>
                    <Label for="notes">Notes</Label>
                    <Input
                      name="notes"
                      type="textarea"
                      value={field.value}
                      onChange={(e) => field.onChange(e)}
                      placeholder="Notes..."
                      invalid={!!errors.notes}
                    />
                    <ErrorElement message={errors.notes?.message} />
                  </FormGroup>
                )}
              />

              {expenseDocuments.length > 0 && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {expenseDocuments.map((ed, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={ed.filePath}
                        download=""
                        target="_blank"
                        rel="noreferrer"
                      >
                        {ed.documentName}
                      </a>
                      <i
                        className="fa fa-trash"
                        style={{ cursor: "pointer" }}
                        onClick={() => removeExpenseDocument(ed)}
                      ></i>
                    </div>
                  ))}
                </div>
              )}

              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ borderRadius: "0px" }}
              />
            </Col>
          </Row>
          {isOnFinalInvoice && (
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <p>
                  This Expense has been invoiced on invoice{" "}
                  {expenseInvoice !== undefined && expenseInvoice !== null && expenseInvoice.invoiceNumber} and cannot be
                  modified
                </p>
              </Col>
            </Row>
          )}
          <div style={{ marginTop: "10px" }}>
            <Button color="link" onClick={toggleModal}>
              Cancel
            </Button>
            {expense.expenseId > 0 && (
              <Button
                color="danger"
                style={{ marginLeft: ".25rem" }}
                className="pull-right"
                disabled={isOnFinalInvoice}
                onClick={() => {
                  let exp = { ...expense };
                  exp.isDeleted = !exp.isDeleted;
                  saveExpense(exp, []);
                }}
              >
                Delete
              </Button>
            )}
            <Button
              color="primary"
              type="submit"
              disabled={isOnFinalInvoice}
              style={{ marginLeft: ".25rem" }}
            >
              Save
            </Button>
          </div>
        </Form>
      </ModalBody>

      <VendorModal
        showModal={showVendorModal}
        toggleModal={toggleVendorModal}
        reload={loadVendors}
      />
    </Modal>
  );
};

export default ExpenseModal;
