import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import { toast } from "react-toastify";
import { Button, Col, Input, Label, Modal, Row } from "reactstrap";
import { api, userService } from "../../../../_services";

const UploadExpensesModal = ({
  isOpen,
  toggleModal,
  reloadData,
  entityId,
  yearId,
}) => {
  const currentUser = userService.getLoggedInUser();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setFile(null);
      setUploadResult({
        successMessages: [],
        errorMessages: [],
      });
    }
  }, [isOpen]);

  const saveDocument = async () => {
    if (file == null) {
      toast.error("You can not save expenses without a file");
    } else {
      setIsLoading(true);
      api.uploadPapercutExpenses(currentUser.userId, file).then(
        (resp) => {
          setUploadResult(resp);
          setFile(null);
          setIsLoading(false);
        },
        (err) => {
          setUploadResult({
            successMessages: [],
            errorMessages: ["There was a problem while uploading the file"],
          });
          setIsLoading(false);
        }
      );
      //   await api.createEntityDocument(currentUser.userID, file, {
      //     ...document,
      //     entityID: entityId,
      //     yearID: yearId,
      //   })
      //   toggleModal()
      //   reloadData()
    }
  };

  const onFileChange = (event) => {
    let f = event.target.files[0];
    setFile(f);
  };

  return (
    <Modal
      isOpen={isOpen}
      size="md"
      toggle={() => {
        toggleModal();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Add Expenses
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label
            className="btn btn-primary"
            style={{ marginBottom: "0px", fontWeight: "bold" }}
          >
            <input
              type="file"
              name="myFile"
              onChange={onFileChange}
              style={{ display: "none" }}
              disabled={isLoading}
            />
            Upload File
          </Label>
          {file != null && (
            <span style={{ marginLeft: "10px" }}>{file.name}</span>
          )}
        </div>
        {uploadResult != null &&
          uploadResult.errorMessages.map((m, idx) => (
            <p
              key={idx}
              style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
            >
              {m}
            </p>
          ))}
        {uploadResult != null &&
          uploadResult.successMessages.map((m, idx) => (
            <p
              key={idx}
              style={{ color: "green", marginBottom: 0, paddingBottom: 0 }}
            >
              {m}
            </p>
          ))}
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        {/* <Button color="link" onClick={toggleModal}>
          Cancel
        </Button> */}
        {isLoading && <Loader style={{}} type="ball-pulse" active />}
        <Button
          color="primary"
          type="button"
          className="pull-right"
          onClick={file == null ? toggleModal : saveDocument}
          disabled={isLoading}
        >
          {file != null ? "Process" : "Close"}
        </Button>
      </div>
    </Modal>
  );
};

export default UploadExpensesModal;
