import React, { Fragment, useState } from 'react';
import {
    Row, Col
} from 'reactstrap';
import { userService } from '../../_services';
import _ from 'lodash';

const Help = () => {
    let currentUser = userService.getLoggedInUser();
    let [selectedVideo, setSelectedVideo] = useState('');

    let videoList = [
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return currentUser.highestRole == 2;
            },
            title: 'System Overview',
            videoUrl: 'https://vimeo.com/394685369',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394685369" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 1,
            groupTitle: 'Videos for your billers (of which you should be aware)',
            isVisible: () => {
                return currentUser.highestRole == 3 || currentUser.entityRoleId == 2;
            },
            title: 'Creating and Revising Activities',
            videoUrl: 'https://vimeo.com/394685810',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394685810" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 1,
            groupTitle: 'Videos for your billers (of which you should be aware)',
            isVisible: () => {
                return currentUser.highestRole == 3 || currentUser.entityRoleId == 2;
            },
            title: 'Suspended Activities (very important)',
            videoUrl: 'https://vimeo.com/394686187',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394686187" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return currentUser.highestRole == 3 || currentUser.systemRoleId == 2 || currentUser.entityRoleId == 2;
            },
            title: 'Reports',
            videoUrl: 'https://vimeo.com/394686366',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394686366" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 1,
            groupTitle: 'Videos for your billers (of which you should be aware)',
            isVisible: () => {
                return currentUser.entityRoleId == 2;
            },
            title: 'Client Dashboard',
            videoUrl: 'https://vimeo.com/394686641',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394686641" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return currentUser.highestRole == 3 || (currentUser.highestRole == 2 && currentUser.systemRoleId == 2)
            },
            title: 'System Overview',
            videoUrl: 'https://vimeo.com/394686993',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394686993" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return currentUser.highestRole == 3 || (currentUser.highestRole == 2 && currentUser.systemRoleId == 2)
            },
            title: 'Dashboard',
            videoUrl: 'https://vimeo.com/394687415',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394687415" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return (currentUser.highestRole == 3 || currentUser.highestRole == 2) && currentUser.systemRoleId == 2
            },
            title: 'Manage Clients',
            videoUrl: 'https://vimeo.com/394687415',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394687415" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2
            },
            title: 'Admin - Manage Users',
            videoUrl: 'https://vimeo.com/394687917',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394687917" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2
            },
            title: 'Admin - Manage Rates',
            videoUrl: 'https://vimeo.com/394688234',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394688234" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 1,
            groupTitle: 'Videos for your billers (of which you should be aware)',
            isVisible: () => {
                return true;
            },
            title: 'Manage Alerts',
            videoUrl: 'https://vimeo.com/394820969',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394820969" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 3,
            groupTitle: 'Videos for more advanced administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2 && currentUser.highestRole == 3;
            },
            title: 'Admin - Invoices',
            videoUrl: 'https://vimeo.com/394821359',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394821359" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 3,
            groupTitle: 'Videos for more advanced administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2;
            },
            title: 'Admin - Company Structure',
            videoUrl: 'https://vimeo.com/394821490',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394821490" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 3,
            groupTitle: 'Videos for more advanced administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2;
            },
            title: 'Admin - Activity Packages',
            videoUrl: 'https://vimeo.com/394821708',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394821708" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 3,
            groupTitle: 'Videos for more advanced administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2;
            },
            title: 'Admin - Category Lists',
            videoUrl: 'https://vimeo.com/394821870',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394821870" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return currentUser.highestRole == 1;
            },
            title: 'System Overview',
            videoUrl: 'https://vimeo.com/394822033',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394822033" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return currentUser.highestRole == 1;
            },
            title: 'Dashboard',
            videoUrl: 'https://vimeo.com/394822269',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394822269" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return currentUser.highestRole == 1;
            },
            title: 'Charts',
            videoUrl: 'https://vimeo.com/394822429',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394822429" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return currentUser.highestRole == 1;
            },
            title: 'Reports',
            videoUrl: 'https://vimeo.com/394822655',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394822655" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return currentUser.highestRole == 1 && currentUser.systemRoleId == 2;
            },
            title: 'Manage Law firms',
            videoUrl: 'https://vimeo.com/394823013',
            videoIFrame: <iframe src="https://player.vimeo.com/video/394823013" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return currentUser.highestRole !== 2;
            },
            title: 'Manage Matters',
            videoUrl: 'https://vimeo.com/395185025',
            videoIFrame: <iframe src="https://player.vimeo.com/video/395185025" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        }
    ]

    let groups = [
        {
            groupId: 0,
            groupTitle: 'General',
            isVisible: () => {
                return true;
            }
        },
        {
            groupId: 1,
            groupTitle: 'Videos for your billers (of which you should be aware)',
            isVisible: () => {
                return true;
            }
        },
        {
            groupId: 2,
            groupTitle: 'Videos for basic administrative tasks',
            isVisible: () => {
                return true;
            }
        },
        {
            groupId: 3,
            groupTitle: 'Videos for more advanced administrative tasks',
            isVisible: () => {
                return currentUser.systemRoleId == 2;
            }
        }
    ]

    return (
        <Fragment>

            <Row>
                <Col md='5'>
                    {_.map(_.groupBy(videoList, 'groupTitle'), function (gr, key) {
                        if (groups.find(x => x.groupTitle == key).isVisible())
                            return (
                                <div>
                                    <div style={{ fontSize: 'large', fontWeight: 'bold', paddingTop: 20, paddingBottom: 20, color: '#8b0000' }}>{key}</div>
                                    <div>{gr.map(x => {
                                        if (x.isVisible())
                                            return <div key={x.title} className='help-button-div' style={{ background: selectedVideo == x.title ? 'white' : 'lightgray' }} onClick={e => setSelectedVideo(x.title)}>{x.title}</div>
                                    })}</div>
                                </div>
                            )
                    })
                    }
                </Col>

                <Col md='6'>
                    <Row>
                        <Col md='12'>
                            {videoList.find(x => x.title == selectedVideo && x.isVisible()) != undefined ? videoList.find(x => x.title == selectedVideo && x.isVisible()).videoIFrame : ''}
                        </Col>
                        <Col md='12'>
                            <h5 style={{ color: 'red' }}><b>The password for all videos is "refresh".</b><br/><br/> If you still have questions, contact us at <a href="mailto:info@leguard.com">info@leguard.com</a></h5>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Help;