import React, { Component, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
  Label,
} from "reactstrap";

import { AvForm } from "availity-reactstrap-validation";

import PageTitle from "../../../Layout/AppMain/PageTitle";
import { userService, api } from "../../../_services";
import ButtonPackagesGrid from "./ButtonPackagesGrid";
import AddEditUserModal from "./AddEditUserModal";
import { InviteCompanyModal } from "./InviteCompanyModal";
import SearchUsersModal from "./SearchUsersModal";
import SearchCompaniesModal from "./SearchCompaniesModal";
import UsersGrid from "./UsersGrid";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { currencyFormatter } from "../../../_services/currencyFormatter";
import SearchUserModal from "../../../Components/SearchUserModal";
import { TextareaAutosize } from "@material-ui/core";

class PartnershipSettings extends Component {
  constructor(props) {
    super(props);
    this.currentUser = userService.getLoggedInUser();

    this.state = {
      entity: null,
      showCompaniesModal: false,
      showSearchUserModal: false,
      showSearchUserOldModal: false,
      showAddEditUserModal: false,
      showInviteCompanyModal: false,
      searchCompaniesSearchText: "",
      selectedEntity: { entityId: -1 },
      isResendingInvitation: false,
      partnership: {
        partnershipId: -1,
        partnershipName: "",
        clientEntityId: -1,
        clientEntityName: "",
        backBillingDays: 7,
        backBillingDaysText: "",
        billerEntityId: -1,
        billerEntityName: "Not selected",
        managingUserName: "Not selected",
        managingUserId: -1,
        partnershipTypeId: 0,
        currencyId: this.currentUser.currencyId,
        updateUserId: this.currentUser.userId,
        isDeleted: 0,
        clientNumber: "",
        ledesClientNumber: "",
      },
      user: {
        userId: -1,
        name: "",
        email: "",
        roleId: "",
        rate: "",
        currencyId: this.currentUser.currencyId,
        sendEmail: false,
        isDeleted: false,
      },
      currencyList: [],
      entityList: [],
      billerList: [],
      authViewerList: [],
      showFormErrors: false,
      searchUsersModalRoleId: -1,
    };
    this.partnershipId = parseInt(props.match.params.partnershipId);
    this.isInternal = props.match.params.isInternal == "true";
    this.newUser = {
      userId: -1,
      name: "",
      email: "",
      roleId: "",
      rate: "",
      currencyId: this.currentUser.currencyId,
      sendEmail: false,
      isDeleted: false,
    };
    this.successToastOpts = {
      autoClose: 3000,
      type: toast.TYPE.SUCCESS,
      hideProgressBar: false,
    };
  }

  componentDidMount() {
    if (this.partnershipId > -1) {
      this.loadPartnership(this.partnershipId).then((x) => {
        this.loadBillers();
        this.loadAuthViewers();
      });
    } else {
      this.toggleCompaniesModal(null, true);
    }
    api.getEntityById(this.currentUser.entityId).then((entity) => {
      if (
        this.currentUser.entityRoleId == 2 &&
        this.currentUser.systemRoleId == 2
      ) {
        this.setState((prevState) => ({
          ...prevState,
          entity: entity,
          partnership: {
            ...prevState.partnership,
            billerEntityId: entity.entityId,
            billerEntityName: entity.name,
            backBillingDaysText:
              this.state.partnership.backBillingDays + " days",
          },
        }));
      } else if (this.currentUser.entityRoleId == 1) {
        //client entity
        this.setState((prevState) => ({
          ...prevState,
          entity: entity,
          partnership: {
            ...prevState.partnership,
            clientEntityId: entity.entityId,
            clientEntityName: entity.name,
            backBillingDaysText:
              this.state.partnership.backBillingDays + " days",
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          entity: entity,
          partnership: {
            ...prevState.partnership,
            billerEntityId: entity.entityId,
            billerEntityName: entity.name,
            backBillingDaysText:
              this.state.partnership.backBillingDays + " days",
          },
        }));
      }
    });
    api.getCurrencyList().then((data) => {
      this.setState({ currencyList: data });
    });
    this.loadEntities();
    this.loadUsers();
  }
  loadPartnership(partnershipId, callback) {
    return api.getPartnershipById(partnershipId).then(async (partnership) => {
      let clientEntity = await api.getEntityById(partnership.clientEntityId);
      this.setState(
        { partnership: partnership, clientEntity: clientEntity },
        () => callback && callback()
      );
    });
  }
  loadEntities() {
    if (this.currentUser.entityRoleId == 1) {
      if (this.isInternal) {
        api
          .getInternalLawfirmListByEntityId(this.currentUser.entityId)
          .then((data) => {
            if (data == null || data.length == 0) {
              swal(
                "You have no internal billing companies. Please setup an internal legal group for your company first. Go to Administration > Company Structure."
              );
            } else {
              this.setState({ entityList: data });
            }
          });
      } else {
        api.getAllLawfirmEntityList("").then((data) => {
          this.setState({ entityList: data });
        });
      }
    } else {
      if (this.isInternal) {
        api
          .getInternalClientListByEntityId(this.currentUser.entityId)
          .then((data) => {
            this.setState({ entityList: data });
          });
      } else {
        api
          .getAllClientEntityListByBillerEntityId(this.currentUser.entityId)
          .then((data) => {
            this.setState({ entityList: data });
          });
      }
      // api.getAllClientEntityList('').then(data => {
      //     this.setState({ entityList: data });
      // })
    }
  }
  loadUsers() {
    api
      .getUserListByUserId(this.currentUser.userId, this.currentUser.entityId)
      .then((data) => {
        this.setState({ userList: data });
      });
  }
  loadBillers(callback) {
    api
      .getBillerListByPartnershipId(this.state.partnership.partnershipId)
      .then((data) => {
        this.setState({ billerList: data });
        api
          .getRespLawyerListByPartnershipId(
            this.state.partnership.partnershipId
          )
          .then((data) => {
            this.setState(
              (prevState) => ({
                ...prevState,
                billerList: [...prevState.billerList, ...data],
              }),
              () => callback && callback()
            );
          });
      })
      .catch(this.handleError);
  }
  reloadUsers() {
    this.loadBillers(() => {
      this.setState({ showAddEditUserModal: false });
      if (this.state.user.userId !== -1) {
        let user = this.state.billerList.find(
          (x) => x.userId == this.state.user.userId
        );
        if (user !== undefined && user !== null) this.setState({ user: user });
      }
    });
  }
  handleError = (error) => {
    //console.log(error);
  };
  loadAuthViewers() {
    api
      .getAuthViewerListByPartnershipId(this.state.partnership.partnershipId)
      .then((data) => {
        this.setState({
          authViewerList: data.filter(
            (x) => x.entityId == this.currentUser.entityId
          ),
        });
      });
  }
  reloadGrid(roleId) {
    if (roleId == 1) {
      //auth viewers
      this.loadAuthViewers();
    } else {
      this.loadBillers();
    }
  }

  handleChange(prop, e) {
    let val = e.target.value;
    this.setState((prevstate) => ({
      ...prevstate,
      partnership: {
        ...prevstate.partnership,
        [prop]: val,
      },
    }));
  }

  //param usually undefined
  handleValidSubmit(newCompanySendEmailFlag) {
    this.setState({ showFormErrors: true });
    if (
      this.state.partnership.billerEntityId == -1 ||
      this.state.partnership.managingUserId == -1
    ) {
    } else {
      if (this.state.partnership.partnershipId == -1) {
        this.createPartnership(newCompanySendEmailFlag);
      } else {
        this.updatePartnership(false);
      }
    }
  }

  //param usually undefined
  createPartnership(newCompanySendEmailFlag) {
    api
      .createPartnership(this.currentUser.userId, this.state.partnership)
      .then((partnershipId) => {
        if (this.state.entity != null && this.state.entity.isDirty) {
          api.updateEntity({ ...this.state.entity });
        }
        if (newCompanySendEmailFlag) {
          swal(
            "The Law firm relationship has been created and the invitation email was sent",
            {
              buttons: {
                ok: {
                  text: "Ok",
                  value: "ok",
                  className: "btn-success",
                },
              },
              icon: "success",
            }
          );
        } else {
          swal("The Law firm relationship has been created", {
            buttons: {
              ok: {
                text: "Ok",
                value: "ok",
                className: "btn-success",
              },
            },
            icon: "success",
          });
        }

        this.props.history.replace({
          pathname: `/partnershipsettings/${partnershipId}`,
        });

        this.loadPartnership(partnershipId, () => {
          if (this.currentUser.entityRoleId == 1) {
            api
              .getUserByUserId(this.state.partnership.managingUserId)
              .then((user) => {
                var up = {
                  userId: user.userId,
                  name: user.name,
                  email: user.email,
                  roleId: 1,
                  timezoneId: user.timezoneId,
                  partnershipId: this.state.partnership.partnershipId,
                  updateUserId: this.currentUser.userId,
                };
                api.createUserPartnership(up).then((data) => {
                  //console.log(data);
                });
              });
          }
        });
      });
  }

  updatePartnership(goBack) {
    if (this.state.entity != null && this.state.entity.isDirty) {
      api.updateEntity({ ...this.state.entity });
    }
    if (this.state.clientEntity != null && this.state.clientEntity.isDirty) {
      api.updateEntity({ ...this.state.clientEntity });
    }
    if (this.state.partnership.isClientEntityNameDirty) {
      api
        .updateEntity({
          entityId: this.state.partnership.clientEntityId,
          name: this.state.partnership.clientEntityName,
        })
        .then((r) => {
          api
            .updatePartnership(this.currentUser.userId, this.state.partnership)
            .then((data) => {
              toast("Partnership saved successfuly", this.successToastOpts);
              if (goBack) this.props.history.goBack();
              else this.loadPartnership(this.state.partnership.partnershipId);
            });
        });
    } else {
      api
        .updatePartnership(this.currentUser.userId, this.state.partnership)
        .then((data) => {
          toast("Partnership saved successfuly", this.successToastOpts);
          if (goBack) this.props.history.goBack();
          else this.loadPartnership(this.state.partnership.partnershipId);
        });
    }
  }

  changeDeletion() {
    this.setState(
      (prevState) => ({
        ...prevState,
        partnership: {
          ...prevState.partnership,
          isDeleted: !this.state.partnership.isDeleted,
        },
      }),
      () => {
        this.updatePartnership(true);
      }
    );
  }

  dummyAnchorClick(e) {
    e && e.preventDefault();
  }

  toggleCompaniesModal(e, show) {
    this.dummyAnchorClick(e);
    if (this.state.partnership.partnershipId == -1) {
      this.setState({
        showCompaniesModal:
          show !== undefined ? show : !this.state.showCompaniesModal,
      });
    }
  }

  toggleAddEditUserModal = (roleId, user, e) => {
    this.dummyAnchorClick(e);
    if (user !== undefined) {
      user.partnershipId = this.state.partnership.partnershipId;
      user.isSaved = true;
      this.setState({
        user: user,
        showAddEditUserModal: !this.state.showAddEditUserModal,
        searchUsersModalRoleId: user.roleId,
      });
    } else {
      this.newUser.roleId = roleId;
      this.newUser.partnershipId = this.state.partnership.partnershipId;
      this.setState({
        user: this.newUser,
        showAddEditUserModal: !this.state.showAddEditUserModal,
        searchUsersModalRoleId: roleId,
      });
    }
  };

  toggleInviteCompanyModal(refreshEntities, show) {
    if (refreshEntities != undefined && refreshEntities) {
      this.loadEntities();
    }
    this.setState({
      showInviteCompanyModal:
        show !== undefined ? show : !this.state.showInviteCompanyModal,
    });
  }

  toggleSearchUserModal(e, loadLawfirmUsers) {
    this.dummyAnchorClick(e);
    if (loadLawfirmUsers) {
      if (this.state.partnership.billerEntityId == -1) {
        swal("Please select a lawfirm first.");
      } else {
        this.setState({
          showSearchUserOldModal: !this.state.showSearchUserOldModal,
          searchUsersModalRoleId: 2,
        });
      }
    } else {
      this.setState({
        showSearchUserModal: !this.state.showSearchUserModal,
      });
    }
  }

  onPropertyChange(prop, val) {
    this.setState((prevstate) => ({
      ...prevstate,
      partnership: {
        ...prevstate.partnership,
        [prop]: val,
      },
    }));
  }

  onAddEditUserSave = (user) => {
    if (user.isDeleted == false) {
      user.timezoneId = this.currentUser.timezoneId;
      user.currencyId = this.currentUser.currencyId;
      api.createUserPartnership(user).then((data) => {
        if (data !== "") {
          swal(data, {
            buttons: {
              ok: {
                text: "Ok",
                value: "ok",
                className: "btn-success",
              },
            },
            icon: "warning",
          });
        } else {
          this.reloadGrid(user.roleId);
          this.toggleAddEditUserModal(user.roleId);
          this.toggleSearchUserModal();
        }
      });
    } else {
      api.deleteUserPartnership(user).then((data) => {
        this.reloadGrid(user.roleId);
        this.toggleAddEditUserModal(user.roleId);
        this.toggleSearchUserModal();
      });
    }
  };

  onEntitySelect(entity) {
    if (this.currentUser.entityRoleId == 1) {
      //client entity
      this.setState((prevState) => ({
        ...prevState,
        selectedEntity: entity,
        partnership: {
          ...prevState.partnership,
          billerEntityId: entity.entityId,
          billerEntityName: entity.name,
          managingUserId: entity.contactId,
          managingUserName: entity.contactName,
        },
      }));
      this.toggleInviteCompanyModal();
    } else {
      this.setState((prevState) => ({
        ...prevState,
        partnership: {
          ...prevState.partnership,
          clientEntityId: entity.entityId,
          clientEntityName: entity.name,
        },
      }));

      this.toggleCompaniesModal();
    }
    // this.setState(prevState => ({
    //     ...prevState,
    //     partnership: {
    //         ...prevState.partnership,
    //         billerEntityId: entity.entityId,
    //         billerEntityName: entity.name,
    //         managingUserId: entity.contactId,
    //         managingUserName: entity.contactName
    //     }
    // }));
  }

  onUserSelect(user) {
    user.partnershipId = this.state.partnership.partnershipId;
    // if (this.state.showAddEditUserModal) {
    //     user.roleId = this.state.searchUsersModalRoleId;
    //     this.setState({ user: user });
    // } else {
    this.setState((prevState) => ({
      ...prevState,
      showSearchUserOldModal: !this.state.showSearchUserOldModal,
      partnership: {
        ...prevState.partnership,
        managingUserName: user.name,
        managingUserId: user.userId,
      },
    }));
    // }
  }

  onUsersSave = (userList) => {
    let list = userList.filter((x) => x.roleId !== -1);
    api
      .saveUserPartnershipAssociationList(
        this.state.partnership.partnershipId,
        this.currentUser.userId,
        list
      )
      .then((data) => {
        this.loadBillers();
        this.loadAuthViewers();
        this.toggleSearchUserModal();
      });
  };

  onUsersSaveSilent = (userList) => {
    let list = userList.filter((x) => x.roleId !== -1);
    api
      .saveUserPartnershipAssociationList(
        this.state.partnership.partnershipId,
        this.currentUser.userId,
        list
      )
      .then((data) => {
        this.loadBillers();
        this.loadAuthViewers();
      });
  };

  saveCompany = (company) => {
    //console.log(company);
    api.inviteEntity(company).then((data) => {
      let contactUser = data.contact;
      this.onEntitySelect(data.entity);
      this.toggleInviteCompanyModal(false, false);
      this.toggleCompaniesModal(undefined, false);
      if (this.currentUser.entityRoleId == 2) {
        this.setState(
          (prevState) => ({
            ...prevState,
            partnership: {
              ...prevState.partnership,
              managingUserName: this.currentUser.name,
              managingUserId: this.currentUser.userId,
            },
          }),
          () => this.handleValidSubmit(company.sendEmail)
        );
      } else {
        this.setState(
          (prevState) => ({
            ...prevState,
            partnership: {
              ...prevState.partnership,
              managingUserName: contactUser.name,
              managingUserId: contactUser.userId,
            },
          }),
          () => this.handleValidSubmit(company.sendEmail)
        );
        //this.handleValidSubmit(company.sendEmail);
      }
    });
  };

  setSearchCompaniesSearchText = (val) => {
    this.setState({ searchCompaniesSearchText: val });
  };

  resendInvitation = () => {
    this.setState({ isResendingInvitation: true });

    let entityId = this.state.partnership.clientEntityId;
    if (this.currentUser.entityRoleId == 1) {
      entityId = this.state.partnership.billerEntityId;
    }

    api.getUserListByEntityId(entityId).then((data) => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.sendInvitation(this.currentUser.userId, data[0].userId);
      }
    });
  };

  sendInvitation = (fromUserId, toUserId) => {
    api.resendInvitationEmail(fromUserId, toUserId).then((data) => {
      this.setState({ isResendingInvitation: false });

      if (data == "")
        swal("Invitation successfuly sent.", {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "success",
        });
      else
        swal(data, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
    });
  };

  render() {
    const billerColumns = [
      {
        dataField: "name",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { width: "30%" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              key={row.userId}
              href="#"
              onClick={(e) => this.toggleAddEditUserModal(row.roleId, row, e)}
            >
              {cellContent}
            </a>
          );
        },
      },
      {
        dataField: "rate",
        text: "Billing Rate",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
        formatter: (cell, row) => {
          return `${currencyFormatter(row.currencySymbol, cell)} / hr`;
        },
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        headerStyle: () => {
          return { width: "35%" };
        },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cell, row) => {
          let bg = "",
            text = "";
          if (row.status == 0) {
            bg = "orange";
            text = "Invited";
          } else {
            bg = "green";
            text = "Registered";
          }
          return (
            <span>
              <label
                style={{
                  backgroundColor: bg,
                  padding: "2px",
                  borderRadius: "3px",
                  color: "white",
                }}
              >
                {text}
              </label>
            </span>
          );
        },
      },
    ];

    const authViewerColumns = [
      {
        dataField: "name",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { width: "45%" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              key={row.userId}
              href="#"
              onClick={(e) => this.toggleAddEditUserModal(row.roleId, row, e)}
            >
              {cellContent}
            </a>
          );
        },
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        headerStyle: () => {
          return { width: "45%" };
        },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cell, row) => {
          let bg = "",
            text = "";
          if (row.status == 0) {
            bg = "orange";
            text = "Invited";
          } else {
            bg = "green";
            text = "Registered";
          }
          return (
            <label
              style={{
                backgroundColor: bg,
                padding: "2px",
                borderRadius: "3px",
                color: "white",
              }}
            >
              {text}
            </label>
          );
        },
      },
    ];

    return (
      <Fragment>
        <PageTitle heading={this.state.partnership.partnershipName} />
        <Row style={{ marginTop: "30px" }}>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <AvForm onValidSubmit={() => this.handleValidSubmit()}>
                  <Row>
                    <Col md="6">
                      {/* <AvField name="name" label="Name" value={this.state.partnership.partnershipName}
                                                onChange={(e) => this.handleChange('partnershipName', e)} placeholder="Name..." required /> */}
                      <div className="form-group">
                        <label>Client</label>
                        <br />
                        {this.currentUser.entityRoleId == 1 && (
                          <label>
                            {this.state.partnership.clientEntityName}
                          </label>
                        )}
                        {this.currentUser.entityRoleId == 2 &&
                          this.state.partnership.partnershipId == -1 && (
                            <a
                              href="#"
                              onClick={(e) => this.toggleCompaniesModal(e)}
                            >
                              {this.state.partnership.clientEntityName == ""
                                ? "Not selected"
                                : this.state.partnership.clientEntityName}
                            </a>
                          )}
                        {this.currentUser.entityRoleId == 2 &&
                          this.state.partnership.partnershipId != -1 && (
                            <CustomInput
                              type="text"
                              value={this.state.partnership.clientEntityName}
                              onChange={(e) => {
                                let val = e.target.value;
                                this.setState((prev) => ({
                                  ...prev,
                                  partnership: {
                                    ...prev.partnership,
                                    clientEntityName: val,
                                    isClientEntityNameDirty: true,
                                  },
                                }));
                              }}
                              className="form-control"
                            />
                          )}
                      </div>
                      <div className="form-group">
                        <label>Allowable Back Billing</label>
                        <CustomInput
                          type="select"
                          id="backbilling"
                          value={this.state.partnership.backBillingDays}
                          // disabled={this.currentUser.entityRoleId == 2}
                          name="backbilling"
                          onChange={(e) =>
                            this.onPropertyChange(
                              "backBillingDays",
                              e.target.value
                            )
                          }
                        >
                          {[...Array(90).keys()].map((x) => {
                            return (
                              <option key={x + 1} value={x + 1}>
                                {x + 1}
                              </option>
                            );
                          })}
                        </CustomInput>
                      </div>

                      {this.currentUser.entityRoleId == "2" && (
                        <Row>
                          {this.currentUser.systemRoleId == "2" && (
                            <Col md="6">
                              <div className="form-group">
                                <Label>Invoice number prefix</Label>
                                <CustomInput
                                  type="text"
                                  value={this.state.partnership.invoicePrefix}
                                  onChange={(e) => {
                                    this.handleChange("invoicePrefix", e);
                                  }}
                                  placeholder="Prefix..."
                                  className="form-control"
                                />
                              </div>
                            </Col>
                          )}
                          {this.state.partnership.partnershipId > -1 && (
                            <Col md="6">
                              <div className="form-group">
                                <Label>Client number</Label>
                                <CustomInput
                                  type="text"
                                  value={this.state.partnership.clientNumber}
                                  onChange={(e) => {
                                    this.handleChange("clientNumber", e);
                                  }}
                                  placeholder="Client number..."
                                  className="form-control"
                                />
                              </div>
                            </Col>
                          )}
                          {this.state.entity != null && (
                            <>
                              <Col md="6">
                                <div className="form-group">
                                  <Label>Task Code</Label>
                                  <CustomInput
                                    type="text"
                                    value={
                                      this.state.partnership.ledesTaskNumber
                                    }
                                    onChange={(e) => {
                                      this.handleChange("ledesTaskNumber", e);
                                    }}
                                    placeholder="Empty..."
                                    className="form-control"
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <Label>Law Firm EIN</Label>
                                  <CustomInput
                                    type="text"
                                    value={this.state.partnership.invoiceEIN}
                                    onChange={(e) => {
                                      this.handleChange("invoiceEIN", e);
                                    }}
                                    placeholder="Empty..."
                                    className="form-control"
                                  />
                                </div>
                              </Col>
                            </>
                          )}
                          <Col md="6">
                            <div className="form-group">
                              <Label>Ledes Client Number</Label>
                              <CustomInput
                                type="text"
                                value={this.state.partnership.ledesClientNumber}
                                onChange={(e) => {
                                  let val = e.target.value;
                                  this.setState((prev) => ({
                                    ...prev,
                                    partnership: {
                                      ...prev.partnership,
                                      ledesClientNumber: val,
                                    },
                                  }));
                                }}
                                className="form-control"
                                placeholder="Empty..."
                              />
                            </div>
                          </Col>
                          {this.currentUser.systemRoleId == 2 &&
                            this.state.clientEntity != undefined && (
                              <Col md="12">
                                <div className="form-group">
                                  <label>Client Notes</label>
                                  <TextareaAutosize
                                    className="form-control"
                                    minRows={2}
                                    value={this.state.clientEntity.partnerNotes}
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      this.setState((prev) => ({
                                        ...prev,
                                        clientEntity: {
                                          ...prev.clientEntity,
                                          partnerNotes: val,
                                          isDirty: true,
                                        },
                                      }));
                                    }}
                                  />
                                </div>
                              </Col>
                            )}
                        </Row>
                      )}
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Lawfirm</label>
                        <br />
                        {this.state.partnership.partnershipId == -1 &&
                          this.currentUser.entityRoleId !== 2 && (
                            <a
                              href="#"
                              onClick={(e) => this.toggleCompaniesModal(e)}
                            >
                              {this.state.partnership.billerEntityName}
                            </a>
                          )}
                        {(this.state.partnership.partnershipId !== -1 ||
                          this.currentUser.entityRoleId == 2) && (
                          <label>
                            {this.state.partnership.billerEntityName}
                          </label>
                        )}
                        {this.state.showFormErrors &&
                          this.state.partnership.billerEntityId == -1 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              Select a lawfirm
                            </div>
                          )}
                      </div>
                      <div className="form-group">
                        <label>Managing User</label>
                        <br />
                        {this.currentUser.entityRoleId == 1 && (
                          <label>
                            {" "}
                            {this.state.partnership.managingUserName ==
                            undefined
                              ? "Not selected"
                              : this.state.partnership.managingUserName}
                          </label>
                        )}
                        {this.currentUser.entityRoleId == 2 && (
                          <a
                            href="#"
                            onClick={(e) => this.toggleSearchUserModal(e, true)}
                          >
                            {this.state.partnership.managingUserName ==
                            undefined
                              ? "Not selected"
                              : this.state.partnership.managingUserName}
                          </a>
                        )}
                        {this.state.partnership.partnershipId > 0 &&
                          ((this.currentUser.entityRoleId == 1 &&
                            !this.state.partnership.hasActiveBillers) ||
                            (this.currentUser.entityRoleId == 2 &&
                              !this.state.partnership.hasActiveClients)) && (
                            <Button
                              color="warning"
                              style={{ color: "white" }}
                              className="pull-right"
                              disabled={this.state.isResendingInvitation}
                              onClick={() => this.resendInvitation()}
                            >
                              Resend Invitation
                            </Button>
                          )}
                        {this.state.showFormErrors &&
                          this.state.partnership.managingUserId == -1 && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              Select a manager
                            </div>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Button
                    color="link"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    color="primary"
                    type="submit"
                    style={{ marginLeft: ".25rem" }}
                  >
                    Save
                  </Button>{" "}
                  {this.state.partnership.partnershipId > -1 && (
                    <Button
                      color={
                        this.state.partnership.isDeleted ? "success" : "danger"
                      }
                      style={{ marginLeft: ".25rem" }}
                      className="pull-right"
                      onClick={() => {
                        if (!this.state.partnership.isDeleted) {
                          swal({
                            title: "Are you sure?",
                            text: "Performing this action will also inactivate all associated matters.  No billing can be performed on inactivated matters.",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              this.changeDeletion();
                            }
                          });
                        } else {
                          this.changeDeletion();
                        }
                      }}
                    >
                      {this.state.partnership.isDeleted
                        ? "Activate"
                        : "Inactivate"}
                    </Button>
                  )}
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {this.state.partnership.partnershipId > 0 && (
          <Row style={{ marginTop: "30px" }}>
            {this.currentUser.entityRoleId == 2 && (
              <UsersGrid
                colWidth={6}
                title="Default Rates"
                buttonText="Add Biller"
                roleId={2}
                columns={billerColumns}
                partnership={this.state.partnership}
                toggleAddEditUserModal={(e) => this.toggleSearchUserModal()}
                userList={this.state.billerList}
              />
            )}
            {/* <Col md="6"> */}
            <UsersGrid
              colWidth={6}
              title="Viewers"
              subtitle="Viewers for all firm/client matters except discretionary"
              buttonText="Add Viewer"
              roleId={1}
              columns={authViewerColumns}
              partnership={this.state.partnership}
              toggleAddEditUserModal={(e) => this.toggleSearchUserModal()}
              userList={this.state.authViewerList}
            />
            <ButtonPackagesGrid
              colWidth={6}
              partnershipId={this.state.partnership?.partnershipId}
            />
            {/* </Col> */}
          </Row>
        )}

        <AddEditUserModal
          partnership={this.state.partnership}
          currencyList={this.state.currencyList}
          showModal={this.state.showAddEditUserModal}
          toggleModal={this.toggleAddEditUserModal}
          showSearchUsersModal={() => this.toggleSearchUserModal()}
          user={this.state.user}
          onSave={(user) => this.onAddEditUserSave(user)}
          rateLabel="Rate"
          roleId={this.state.searchUsersModalRoleId}
          refreshUsers={() => this.reloadUsers()}
        />

        <SearchCompaniesModal
          size="lg"
          showModal={this.state.showCompaniesModal}
          toggleModal={() => this.toggleCompaniesModal()}
          entityList={this.state.entityList}
          setSearchCompaniesSearchText={this.setSearchCompaniesSearchText}
          toggleInviteCompanyModal={() => this.toggleInviteCompanyModal()}
          onEntitySelect={(entity) => this.onEntitySelect(entity)}
        />

        <InviteCompanyModal
          showModal={this.state.showInviteCompanyModal}
          toggleModal={(refreshEntities) =>
            this.toggleInviteCompanyModal(refreshEntities)
          }
          saveCompany={(company) => this.saveCompany(company)}
          selectedEntity={this.state.selectedEntity}
        />

        <SearchUsersModal
          size="lg"
          showModal={this.state.showSearchUserOldModal}
          toggleModal={() =>
            this.setState({
              showSearchUserOldModal: !this.state.showSearchUserOldModal,
            })
          }
          partnershipId={this.state.partnership.partnershipId}
          roleId={this.state.searchUsersModalRoleId}
          onUserSelect={(user) => this.onUserSelect(user)}
        />

        <SearchUserModal
          showModal={this.state.showSearchUserModal}
          toggleModal={() => this.toggleSearchUserModal()}
          partnershipId={this.state.partnership.partnershipId}
          onUsersSave={this.onUsersSave}
          onUsersSaveSilent={this.onUsersSaveSilent}
          addNewUser={() =>
            this.toggleAddEditUserModal(
              this.currentUser.entityRoleId == 1 ? 1 : 2
            )
          }
          isOnPartnership={true}
        />
      </Fragment>
    );
  }
}

export default PartnershipSettings;
