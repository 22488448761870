import React, { Component, Fragment } from 'react';

import {
    Button, Modal, ModalHeader, Card, CardBody,
    ModalBody, Row, Col, CustomInput, CardTitle
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { api, userService } from '../../../_services';
class SearchCompaniesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: props.showModal,
            entityList: props.entityList
        };
        this.toggleModal = props.toggleModal;
        this.currentUser = userService.getLoggedInUser();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showModal != nextProps.showModal)
            this.setState({ showModal: nextProps.showModal });
        if (this.props.entityList != nextProps.entityList)
            this.setState({ entityList: nextProps.entityList });
    }

    toggleInviteCompanyModal() {
        // if (this.props.setSearchCompaniesSearchText)
        //     this.props.setSearchCompaniesSearchText(document.getElementById("text-filter-column-Company").value);
        this.props.toggleInviteCompanyModal();
    }

    onEntitySelect(entity, e) {
        e && e.preventDefault();
        this.props.onEntitySelect(entity);
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: 'Company',
                sort: true,
                // filter: textFilter(),
                headerStyle: () => {
                    return { width: "100%" };
                },
                formatter: (cellContent, row) => {
                    return (
                        <a href='#' onClick={(e) => this.onEntitySelect(row, e)}>{cellContent}</a>
                    );
                }
            },
            // {
            //     dataField: 'contactEmail',
            //     text: 'Email',
            //     sort: true,
            //     headerStyle: () => {
            //         return { width: "50%" };
            //     }
            // }

        ]

        const defaultSorted = [{
            dataField: 'name',
            order: 'asc'
        }];

        const options = {
            sizePerPageList: [
                {
                    text: '20',
                    value: 20
                }
            ],
            hideSizePerPage: true
        }
        const { size } = this.props;
        return (
            <Modal size={size} isOpen={this.state.showModal} toggle={() => this.toggleModal()} className={this.props.className} backdrop="static">
                <ModalHeader toggle={() => this.toggleModal()}>{this.currentUser.entityRoleId == 1 ? 'Law Firm ' : 'Client '} List</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md='12'>
                            <p>Select the company you wish to connect to from the list of firms that currently use LeGuard. Use the search box below to search the list. If the company you are looking for is not listed, click the "Invite Company" button.</p>
                        </Col>
                        <Col md='12'>
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <div className="table-responsive">
                                        {this.props.entityList != undefined && this.props.entityList != null && this.props.entityList.length > 0 &&
                                        <BootstrapTable
                                            striped
                                            bordered={false}
                                            bootstrap4
                                            keyField="entityId"
                                            data={this.props.entityList}
                                            columns={columns}
                                            defaultSorted={defaultSorted}
                                            // filter={filterFactory()}
                                            pagination={paginationFactory(options)}
                                        />
    }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Button color="link" onClick={() => this.toggleModal()}>Cancel</Button>
                            <Button color="primary" className="pull-right" onClick={() => this.toggleInviteCompanyModal()}>Invite Company</Button>{' '}
                        </Col>

                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

export default SearchCompaniesModal;