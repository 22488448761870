import React, { Fragment } from "react";
import { api, userService } from "../../_services";
import { currencyFormatter } from "../../_services/currencyFormatter";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CustomInput,
  CardTitle,
} from "reactstrap";
import TwoLevelsGrid from "./Components/TwoLevelsGrid";
import DatePicker from "react-datepicker";
import RunningActivityHelper from "../../_helpers/runningActivityHelper";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.currentUser = userService.getLoggedInUser();
    this.newDate = new Date();

    this.state = {
      isResponsibleGridLoading: true,
      isMasterMattersGridLoading: true,
      isBillerGridLoading: true,
      partnershipList: [],
      responsibleMatterList: [],
      masterMatterGridList: [],
      billerMatterList: [],
      responsibleGridList: [],
      billerGridList: [],
      selectedPartnershipId: -1,
      currencyList: [],
      selectedCurrencyId: this.currentUser.currencyId,
      selectedCurrencySymbol: "$",
      selectedDate: new Date(this.newDate.getFullYear(), 0, 1),
      selectedInterval: -1,
      selectedMatterTypeId: -1,
      selectedGeoCategoryId: -1,
      geoCategoryList: [],
      matterTypeList: [],
      matterStatus: 1,
    };
  }

  componentDidMount() {
    this.initialActivity = RunningActivityHelper.runningActivity;
    this.loadPartnershipList(this.currentUser.entityId);
    api.getCurrencyList().then((data) => {
      this.setState({
        currencyList: data,
        selectedCurrencySymbol: data.find(
          (x) => x.currencyId == this.state.selectedCurrencyId
        ).symbol,
      });
    });

    api
      .getItemListByEntityId("GeoCategory", this.currentUser.entityId)
      .then((list) => {
        this.setState({ geoCategoryList: list });
      });
    api
      .getItemListByEntityId("MatterType", this.currentUser.entityId)
      .then((list) => {
        this.setState({ matterTypeList: list });
      });
    this.activityObservable =
      RunningActivityHelper.runningActivityObservable.subscribe((val) => {
        if (
          (this.initialActivity == null && val !== null) ||
          (val == null && this.initialActivity !== null) ||
          (this.initialActivity !== null &&
            val !== null &&
            this.initialActivity.activityId !== val.activityId)
        ) {
          this.initialActivity = val;
          this.loadGridsData(this.currentUser.entityId);
        }
      });
  }

  componentWillUnmount() {
    if (this.interval !== undefined) {
      clearInterval(this.interval);
    }
    this.activityObservable.unsubscribe();
  }

  loadPartnershipList(entityId) {
    api
      .getPartnershipListByEntityId(entityId, this.currentUser.userId)
      .then((data) => {
        this.setState({ partnershipList: data ?? [] });
        this.loadGridsData(entityId);
      });
  }

  loadGridsData(entityId) {
    if (this.currentUser.entityRoleId == 2) {
      this.loadBillerMatterList(entityId).then((data) => {});
    }

    this.loadResponsibleMatterList(entityId).then((data) => {});
  }

  loadResponsibleMatterList(entityId) {
    this.setState({ isResponsibleGridLoading: true });
    return api
      .getMatterListByEntityIdIncludeTotals(
        entityId,
        this.state.selectedPartnershipId,
        this.state.selectedCurrencyId,
        this.state.selectedDate,
        this.state.selectedGeoCategoryId,
        this.state.selectedMatterTypeId,
        this.state.matterStatus
      )
      .then((data) => {
        let matterList =
          data?.map((x) => {
            x.remainingBudget = x.activeBudgetAmount - x.currentSpend;
            return x;
          }) ?? [];
        this.populateResponsibleGridDataSource(matterList);

        if (this.currentUser.entityRoleId == 1) {
          this.populateMasterMatterGridDataSource(matterList);
        }
      });
  }

  loadBillerMatterList(entityId) {
    this.setState({ isBillerGridLoading: true });
    return api
      .getBillerMatterListByEntityIdIncludeTimes(
        entityId,
        this.state.selectedPartnershipId,
        this.state.selectedCurrencyId,
        this.state.selectedDate,
        this.state.selectedGeoCategoryId,
        this.state.selectedMatterTypeId,
        this.state.matterStatus
      )
      .then((data) => {
        let matterList =
          data?.map((x) => {
            x.remainingBudget = x.activeBudgetAmount - x.currentSpend;
            return x;
          }) ?? [];
        this.populateBillerGridDataSource(matterList);
      });
  }

  populateMasterMatterGridDataSource(matterList) {
    let data = [];
    let masterMatters = matterList.filter((x) => x.isMasterMatter);
    masterMatters.forEach((mm) => {
      let matList = matterList.filter((x) => x.masterMatterId == mm.matterId);
      if (matList && matList.length > 0) {
        let mClone = JSON.parse(JSON.stringify(mm));
        mClone.isExpanded = false;
        mClone.todayTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.todayTotal, 0);
        mClone.monthTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.monthTotal, 0);
        mClone.quarterTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.quarterTotal, 0);
        mClone.yearTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.yearTotal, 0);

        mClone.todayTotal += mm.todayTotal;
        mClone.monthTotal += mm.monthTotal;
        mClone.quarterTotal += mm.quarterTotal;
        mClone.yearTotal += mm.yearTotal;

        mClone.matterList = matList;
        mClone.matterList.unshift(mm);
        data.push(mClone);
      }
    });

    this.setState({
      masterMatterGridList: data,
      isMasterMattersGridLoading: false,
    });
  }

  populateResponsibleGridDataSource(matterList) {
    let data = [];
    this.state.partnershipList.forEach((p) => {
      let matList = matterList.filter(
        (m) => m.partnershipId == p.partnershipId
      );
      if (matList && matList.length > 0) {
        let pClone = JSON.parse(JSON.stringify(p));
        pClone.isExpanded = false;
        pClone.todayTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.todayTotal, 0);
        pClone.monthTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.monthTotal, 0);
        pClone.quarterTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.quarterTotal, 0);
        pClone.yearTotal =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.yearTotal, 0);
        pClone.matterList = matList;
        data.push(pClone);
      }
    });

    this.setState({
      responsibleGridList: data,
      isResponsibleGridLoading: false,
    });
  }

  populateBillerGridDataSource(matterList) {
    let data = [];
    this.state.partnershipList.forEach((p) => {
      let matList = matterList.filter(
        (m) => m.partnershipId == p.partnershipId
      );
      if (matList && matList.length > 0) {
        let pClone = JSON.parse(JSON.stringify(p));
        pClone.isExpanded = false;
        pClone.todayTime =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.todayTime, 0);
        pClone.monthTime =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.monthTime, 0);
        pClone.quarterTime =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.quarterTime, 0);
        pClone.yearTime =
          matList == undefined || matList.length == 0
            ? 0
            : matList.reduce((acc, item) => acc + item.yearTime, 0);
        pClone.matterList = matList;
        data.push(pClone);
      }
    });

    this.setState({
      billerGridList: data,
      isBillerGridLoading: false,
    });
  }

  onPartnershipSelectionChange = (e) => {
    let partnershipId = e.target.value;
    this.setState({ selectedPartnershipId: partnershipId });
  };

  getTimeInHoursMinutes = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    return hours > 0 ? `${hours} hours ${minutes} min` : `${minutes} min`;
  };

  filterGrid() {
    this.loadGridsData(this.currentUser.entityId);
    if (this.state.selectedInterval > -1) {
      this.interval = setInterval(() => {
        this.loadGridsData(this.currentUser.entityId);
      }, parseInt(this.state.selectedInterval) * 1000 * 60);
    }
    this.setState({
      selectedCurrencySymbol: this.state.currencyList.find(
        (x) => x.currencyId == this.state.selectedCurrencyId
      ).symbol,
    });
  }

  render() {
    const responsibleGridColumns = [
      {
        header: "Matter",
        width: "33%",
        sortableProperty: "matterName",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`matterName-${firstLevelObject.matterId}`}>
              {firstLevelObject.partnershipName}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`matterName2-${secondLevelObject.matterId}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: `/matterActivities/${secondLevelObject.matterId}`,
                  });
                }}
              >
                {secondLevelObject.matterName}{" "}
                {this.currentUser.entityRoleId == 2 &&
                  secondLevelObject.eBillerId_Lawfirm != null &&
                  secondLevelObject.eBillerId_Lawfirm != "" &&
                  `(${secondLevelObject.eBillerId_Lawfirm})`}
                {this.currentUser.entityRoleId == 1 &&
                  secondLevelObject.eBillerId != null &&
                  secondLevelObject.eBillerId != "" &&
                  `(${secondLevelObject.eBillerId})`}
              </a>
            </td>
          );
        },
      },
      {
        header: "Today's Activity",
        width: "14%",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`act-${firstLevelObject.matterId}`}>
              {firstLevelObject.matterList !== undefined && (
                <Fragment>
                  {firstLevelObject.matterList.find(
                    (x) => x.runningActivitiesCount > 0
                  ) !== undefined && (
                    <span className="dashboard-running-activities-indicator">
                      {this.getTimeInHoursMinutes(
                        firstLevelObject.matterList.reduce(
                          (acc, item) => acc + item.todayTime,
                          0
                        )
                      )}
                    </span>
                  )}
                  {firstLevelObject.matterList.find(
                    (x) => x.runningActivitiesCount > 0
                  ) == undefined && (
                    <span>
                      {this.getTimeInHoursMinutes(
                        firstLevelObject.matterList.reduce(
                          (acc, item) => acc + item.todayTime,
                          0
                        )
                      )}
                    </span>
                  )}
                </Fragment>
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`act2-${secondLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(secondLevelObject.todayTime)}{" "}
              {secondLevelObject.runningActivitiesCount > 0 ? (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push({
                      pathname: `/matterActivities/${secondLevelObject.matterId}`,
                    });
                  }}
                  className="dashboard-running-activities-indicator"
                >
                  {secondLevelObject.runningActivitiesCount} in progress
                </span>
              ) : (
                ""
              )}
            </td>
          );
        },
      },
      {
        header: "Today",
        width: "10%",
        sortableProperty: "todayTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`todayTotal-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.todayTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`todayTotal2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.todayTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Month",
        width: "10%",
        sortableProperty: "monthTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`monthTotal-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.monthTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`monthTotal2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.monthTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Quarter",
        width: "10%",
        sortableProperty: "quarterTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`quarter-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.quarterTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`quarter2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.quarterTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Year",
        width: "10%",
        sortableProperty: "yearTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`yearTotal-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.yearTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`yearTotal2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.yearTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Remaining Budget",
        width: "10%",
        firstLevelFormatter: (firstLevelObject) => {
          return <td key={`remaining-${firstLevelObject.matterId}`}></td>;
        },
        secondLevelFormatter: (secondLevelObject) => {
          return secondLevelObject.activeBudgetAmount == 0 ? (
            <td key={`remaining2-${secondLevelObject.matterId}`}>-</td>
          ) : secondLevelObject.remainingBudget < 0 ? (
            <td
              key={`remaining2-${secondLevelObject.matterId}`}
              style={{ color: "red", fontWeight: "bold" }}
            >
              (
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.remainingBudget * -1
              )}
              )
            </td>
          ) : (
            <td key={`remaining2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.remainingBudget
              )}
            </td>
          );
        },
      },
    ];

    const masterGridColumns = [
      {
        header: "Matter",
        width: "33%",
        sortableProperty: "matterName",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`0-matterName-${firstLevelObject.matterId}`}>
              {firstLevelObject.matterName}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`0-matterName2-${secondLevelObject.matterId}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: `/matterActivities/${secondLevelObject.matterId}`,
                  });
                }}
              >
                {secondLevelObject.partnershipName} -{" "}
                {secondLevelObject.matterName}{" "}
                {this.currentUser.entityRoleId == 2 &&
                  secondLevelObject.eBillerId_Lawfirm != null &&
                  secondLevelObject.eBillerId_Lawfirm != "" &&
                  `(${secondLevelObject.eBillerId_Lawfirm})`}
                {this.currentUser.entityRoleId == 1 &&
                  secondLevelObject.eBillerId != null &&
                  secondLevelObject.eBillerId != "" &&
                  `(${secondLevelObject.eBillerId})`}
              </a>
            </td>
          );
        },
      },
      {
        header: "Today's Activity",
        width: "14%",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`0-act-${firstLevelObject.matterId}`}>
              {firstLevelObject.matterList !== undefined && (
                <Fragment>
                  {firstLevelObject.matterList.find(
                    (x) => x.runningActivitiesCount > 0
                  ) !== undefined && (
                    <span className="dashboard-running-activities-indicator">
                      {this.getTimeInHoursMinutes(
                        firstLevelObject.matterList.reduce(
                          (acc, item) => acc + item.todayTime,
                          0
                        )
                      )}
                    </span>
                  )}
                  {firstLevelObject.matterList.find(
                    (x) => x.runningActivitiesCount > 0
                  ) == undefined && (
                    <span>
                      {this.getTimeInHoursMinutes(
                        firstLevelObject.matterList.reduce(
                          (acc, item) => acc + item.todayTime,
                          0
                        )
                      )}
                    </span>
                  )}
                </Fragment>
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`0-act2-${secondLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(secondLevelObject.todayTime)}{" "}
              {secondLevelObject.runningActivitiesCount > 0 ? (
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push({
                      pathname: `/matterActivities/${secondLevelObject.matterId}`,
                    });
                  }}
                  className="dashboard-running-activities-indicator"
                >
                  {secondLevelObject.runningActivitiesCount} in progress
                </span>
              ) : (
                ""
              )}
            </td>
          );
        },
      },
      {
        header: "Today",
        width: "10%",
        sortableProperty: "todayTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`0-todayTotal-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.todayTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`0-todayTotal2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.todayTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Month",
        width: "10%",
        sortableProperty: "monthTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`0-monthTotal-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.monthTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`0-monthTotal2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.monthTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Quarter",
        width: "10%",
        sortableProperty: "quarterTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`0-quarter-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.quarterTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`0-quarter2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.quarterTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Year",
        width: "10%",
        sortableProperty: "yearTotal",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`0-yearTotal-${firstLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                firstLevelObject.yearTotal
              )}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`0-yearTotal2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.yearTotal
              )}
            </td>
          );
        },
      },
      {
        header: "Remaining Budget",
        width: "10%",
        firstLevelFormatter: (firstLevelObject) => {
          return <td key={`0-remaining-${firstLevelObject.matterId}`}></td>;
        },
        secondLevelFormatter: (secondLevelObject) => {
          return secondLevelObject.activeBudgetAmount == 0 ? (
            <td key={`0-remaining2-${secondLevelObject.matterId}`}>-</td>
          ) : secondLevelObject.remainingBudget < 0 ? (
            <td
              key={`0-remaining2-${secondLevelObject.matterId}`}
              style={{ color: "red", fontWeight: "bold" }}
            >
              (
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.remainingBudget * -1
              )}
              )
            </td>
          ) : (
            <td key={`0-remaining2-${secondLevelObject.matterId}`}>
              {currencyFormatter(
                this.state.selectedCurrencySymbol,
                secondLevelObject.remainingBudget
              )}
            </td>
          );
        },
      },
    ];

    const billerGridColumns = [
      {
        header: "Matter",
        width: "37%",
        sortableProperty: "matterName",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`mattername222-${firstLevelObject.matterId}`}>
              {firstLevelObject.partnershipName}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`mattername223-${secondLevelObject.matterId}`}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push({
                    pathname: `/matterActivities/${secondLevelObject.matterId}`,
                  });
                }}
              >
                {secondLevelObject.matterName}{" "}
                {this.currentUser.entityRoleId == 2 &&
                  secondLevelObject.eBillerId_Lawfirm != null &&
                  secondLevelObject.eBillerId_Lawfirm != "" &&
                  `(${secondLevelObject.eBillerId_Lawfirm})`}
                {this.currentUser.entityRoleId == 1 &&
                  secondLevelObject.eBillerId != null &&
                  secondLevelObject.eBillerId != "" &&
                  `(${secondLevelObject.eBillerId})`}
              </a>
            </td>
          );
        },
      },
      {
        header: "Time Today",
        width: "15%",
        sortableProperty: "todayTime",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`todayTime-${firstLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(firstLevelObject.todayTime)}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`todayTime2-${secondLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(secondLevelObject.todayTime)}
            </td>
          );
        },
      },
      {
        header: "Time Month",
        width: "15%",
        sortableProperty: "monthTime",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`monthTime-${firstLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(firstLevelObject.monthTime)}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`monthTime-${secondLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(secondLevelObject.monthTime)}
            </td>
          );
        },
      },
      {
        header: "Time Quarter",
        width: "15%",
        sortableProperty: "quarterTime",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`quarterTime-${firstLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(firstLevelObject.quarterTime)}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`quartertime2-${secondLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(secondLevelObject.quarterTime)}
            </td>
          );
        },
      },
      {
        header: "Time Year",
        width: "15%",
        sortableProperty: "yearTime",
        firstLevelFormatter: (firstLevelObject) => {
          return (
            <td key={`yeartime-${firstLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(firstLevelObject.yearTime)}
            </td>
          );
        },
        secondLevelFormatter: (secondLevelObject) => {
          return (
            <td key={`yeartime2-${secondLevelObject.matterId}`}>
              {this.getTimeInHoursMinutes(secondLevelObject.yearTime)}
            </td>
          );
        },
      },
    ];
    return (
      <Fragment>
        <Row>
          <Col md="12">
            <Card className="mb-3" style={{ backgroundColor: "transparent" }}>
              <CardBody>
                <Row>
                  {this.state.partnershipList.length > 0 && (
                    <div className="form-group col-md-4">
                      <label>
                        {this.currentUser.entityRoleId == 1
                          ? "Law Firm"
                          : "Client"}
                      </label>
                      <CustomInput
                        type="select"
                        id="partnershipSelect"
                        className="form-control"
                        name="partnershipSelect"
                        onChange={this.onPartnershipSelectionChange}
                      >
                        <option key={0} value={-1}>
                          {this.currentUser.entityRoleId == 1
                            ? "All Law Firms"
                            : "All Clients"}
                        </option>
                        {this.state.partnershipList?.map((partnership) => {
                          return (
                            <option
                              key={partnership.partnershipId}
                              value={partnership.partnershipId}
                            >
                              {partnership.partnershipName}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </div>
                  )}
                  <div className="form-group col-md-4">
                    <label htmlFor="matterTypeId">Matter Type</label>
                    <CustomInput
                      type="select"
                      id="matterTypeId"
                      name="matterTypeId"
                      value={this.state.selectedMatterTypeId}
                      onChange={(e) =>
                        this.setState({ selectedMatterTypeId: e.target.value })
                      }
                    >
                      <option key={-1} value={-1}>
                        All Matter Types
                      </option>
                      {this.state.matterTypeList.map((gc) => {
                        if (gc.isDeleted == false)
                          return (
                            <option key={gc.id} value={gc.id}>
                              {gc.name}
                            </option>
                          );
                      })}
                    </CustomInput>
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="geoCategoryId">Geographic Location</label>
                    <CustomInput
                      type="select"
                      id="geoCategoryId"
                      name="geoCategoryId"
                      value={this.state.selectedGeoCategoryId}
                      onChange={(e) =>
                        this.setState({ selectedGeoCategoryId: e.target.value })
                      }
                    >
                      <option key={-1} value={-1}>
                        All Geographic Locations
                      </option>
                      {this.state.geoCategoryList.map((gc) => {
                        if (gc.isDeleted == false)
                          return (
                            <option key={gc.id} value={gc.id}>
                              {gc.name}
                            </option>
                          );
                      })}
                    </CustomInput>
                  </div>
                </Row>
                <Row>
                  <div className="form-group col-md-3">
                    <Row>
                      <Col md="3">
                        <label htmlFor="refreshnow">Refresh</label>
                        <br />
                        <Button
                          color="primary"
                          onClick={() => this.filterGrid()}
                        >
                          Now
                        </Button>
                      </Col>
                      <Col md="2">
                        <br />
                        <CustomInput
                          id={`1min`}
                          type="radio"
                          value="1"
                          label="1 min"
                          checked={this.state.selectedInterval == 1}
                          onChange={(e) =>
                            this.setState({ selectedInterval: 1 })
                          }
                        ></CustomInput>
                      </Col>
                      <Col md="2">
                        <br />
                        <CustomInput
                          id={`5min`}
                          type="radio"
                          value="5"
                          label="5 min"
                          checked={this.state.selectedInterval == 5}
                          onChange={(e) =>
                            this.setState({ selectedInterval: 5 })
                          }
                        ></CustomInput>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="matterTypeId">Matter Status</label>
                    <CustomInput
                      type="select"
                      id="activeSelect"
                      value={this.state.matterStatus}
                      name="activeSelect"
                      onChange={(e) =>
                        this.setState({ matterStatus: e.target.value })
                      }
                    >
                      <option key={0} value={0}>
                        All
                      </option>
                      <option key={1} value={1}>
                        Active
                      </option>
                      <option key={2} value={2}>
                        Inactive
                      </option>
                    </CustomInput>
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="currency">Year Start Date</label>
                    {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker value={this.state.selectedDate}
                                                format="MM/DD/YYYY"
                                                disableFuture
                                                onChange={(date) => this.setState({ selectedDate: new Date(date) })}
                                                InputProps={{ className: 'form-control' }}
                                            />
                                        </MuiPickersUtilsProvider> */}
                    <DatePicker
                      className="form-control"
                      selected={this.state.selectedDate}
                      onChange={(date) => this.setState({ selectedDate: date })}
                      placeholderText="Start..."
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="currency">Currency</label>
                    <CustomInput
                      type="select"
                      id="currencySelect"
                      name="currencySelect"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ selectedCurrencyId: e.target.value });
                      }}
                      value={this.state.selectedCurrencyId}
                    >
                      {this.state.currencyList.map((currency) => {
                        return (
                          <option
                            onClick={() =>
                              this.setState({
                                selectedCurrencyId: currency.currencyId,
                              })
                            }
                            key={currency.currencyId}
                            value={currency.currencyId}
                          >
                            {currency.displayValue}
                          </option>
                        );
                      })}
                    </CustomInput>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {(this.state.isResponsibleGridLoading ||
          this.state.responsibleGridList.length > 0) && (
          <TwoLevelsGrid
            key={1}
            list={this.state.responsibleGridList}
            isLoading={this.state.isResponsibleGridLoading}
            gridColumns={responsibleGridColumns}
            secondLevelListPropertyName={"matterList"}
            secondLevelSearchFilterPropertyList={["matterName"]}
            showTotals={true}
            selectedCurrencySymbol={this.state.selectedCurrencySymbol}
            title={
              this.currentUser.systemRoleId == 2
                ? ""
                : this.currentUser.highestRole == 3
                ? "Matters Responsible Lawyer For"
                : ""
            }
            subtitle="TOTALS"
          />
        )}

        {this.currentUser.entityRoleId == 1 &&
          (this.state.isMasterMattersGridLoading ||
            this.state.masterMatterGridList.length > 0) && (
            <TwoLevelsGrid
              key={3}
              list={this.state.masterMatterGridList}
              isLoading={this.state.isMasterMattersGridLoading}
              gridColumns={masterGridColumns}
              secondLevelListPropertyName={"matterList"}
              secondLevelSearchFilterPropertyList={["matterName"]}
              showTotals={true}
              selectedCurrencySymbol={this.state.selectedCurrencySymbol}
              subtitle="Total for Master Matters"
              title="Master Matters"
            />
          )}

        {this.currentUser.entityRoleId == 2 &&
          (this.state.isBillerGridLoading ||
            this.state.billerGridList.length > 0) && (
            <TwoLevelsGrid
              key={2}
              list={this.state.billerGridList}
              isLoading={this.state.isBillerGridLoading}
              gridColumns={billerGridColumns}
              secondLevelListPropertyName={"matterList"}
              secondLevelSearchFilterPropertyList={["matterName"]}
              showTimeTotals={true}
              title="Matters Billing To"
            />
          )}
      </Fragment>
    );
  }
}

export default Dashboard;
