import React, { Fragment } from "react";
import PageTitle from "../../Layout/AppMain/PageTitle";
import { api } from "../../_services";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Table,
  UncontrolledAlert,
} from "reactstrap";
import GeneralGrid from "../../Components/GeneralGrid";
import * as Icons from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencyFormatter } from "../../_services/currencyFormatter";
import DatePicker from "react-datepicker";
import Loader from "react-loaders";
import swal from "sweetalert";
class UpdateRates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userList: [],
      partnership: {},
      matter: {},
      billerList: [],
      startDate: null,
      endDate: null,
      startDateError: false,
    };
    this.partnershipId = props.match.params.partnershipId;
    this.matterId = props.match.params.matterId;
  }

  componentDidMount() {
    this.initialLoad();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.partnershipId !==
        this.props.match.params.partnershipId ||
      nextProps.match.params.matterId !== this.props.match.params.matterId
    ) {
      this.partnershipId = nextProps.match.params.partnershipId;
      this.matterId = nextProps.match.params.matterId;
      this.initialLoad();
    }
  }

  initialLoad = () => {
    api.getPartnershipById(parseInt(this.partnershipId)).then((data) => {
      this.setState({ partnership: data });
    }, this.handleError);
    if (parseInt(this.matterId) > -1) {
      api.getMatterById(parseInt(this.matterId)).then((data) => {
        this.setState({ matter: data });
      }, this.handleError);
    }
    this.loadBillers();
  };

  loadBillers = () => {
    this.setState({ isLoading: true });
    if (parseInt(this.matterId) == -1) {
      api.getBillerListByPartnershipId(this.partnershipId).then((lawyers) => {
        api
          .getRespLawyerListByPartnershipId(this.partnershipId)
          .then((data) => {
            let list = [...lawyers, ...data]
              .map((x) => {
                x.updatedRate = "";
                return x;
              })
              .sort(function(a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
            this.setState({
              isLoading: false,
              billerList: list,
            });
          }, this.handleError);
      }, this.handleError);
    } else {
      api.getUserListByMatterId(this.matterId).then((data) => {
        this.setState({
          isLoading: false,
          billerList: data.map((u) => {
            u.rate = u.matterRate;
            u.updatedRate = "";
            return u;
          }),
        });
      }, this.handleError);
    }
  };

  handleError = () => {
    this.setState({ isLoading: false });
  };

  saveRates = () => {
    if (this.state.startDate == null || this.state.startDate == "") {
      this.setState({ startDateError: true });
    } else {
      if (parseInt(this.matterId) == -1) {
        swal({
          buttons: {
            saveAll: {
              text:
                "Update Partnership Rate and all Matters related to this Partnership",
              value: "saveAll",
            },
            saveOnly: {
              text:
                "Update the Partnership Rate only - don’t change existing Matters and Activities",
              value: "saveOnly",
            },
          },
        }).then((value) => {
          if (value == "saveAll") {
            let list = this.state.billerList
              .filter((x) => x.updatedRate !== "")
              .map((x) => {
                return {
                  userId: x.userId,
                  userPartnershipId: x.userPartnershipId,
                  partnershipId: x.partnershipId,
                  startDate: this.state.startDate,
                  endDate: this.state.endDate,
                  currencyId: x.currencyId,
                  rate: x.updatedRate,
                };
              });
            api.updateRatesBatch(list, 0, this.matterId).then((data) => {
              this.loadBillers();
            });
          } else if (value == "saveOnly") {
            let list = this.state.billerList
              .filter((x) => x.updatedRate !== "")
              .map((x) => {
                return {
                  userId: x.userId,
                  partnershipId: x.partnershipId,
                  startDate: this.state.startDate,
                  rate: x.updatedRate,
                };
              });
            api.updateRatesBatch(list, 2, this.matterId).then((data) => {
              this.loadBillers();
            });
          }
        });
      } else {
        swal({
          buttons: {
            saveAll: {
              text:
                "Update the Rate for this Matter, the Partnership and any other Matters related to the Partnership",
              value: "saveAll",
            },
            savePart: {
              text:
                "Update the Rate for this Matter AND the Partnership but don’t change other Matters",
              value: "savePart",
            },
            saveOnly: {
              text: "Update the rate for this Matter only",
              value: "saveOnly",
            },
          },
        }).then((value) => {
          if (value == "saveAll") {
            let list = this.state.billerList
              .filter((x) => x.updatedRate !== "")
              .map((x) => {
                return {
                  userId: x.userId,
                  userPartnershipId: x.userPartnershipId,
                  partnershipId: x.partnershipId,
                  startDate: this.state.startDate,
                  endDate: this.state.endDate,
                  currencyId: x.currencyId,
                  rate: x.updatedRate,
                };
              });
            api.updateRatesBatch(list, 0, this.matterId).then((data) => {
              this.loadBillers();
            });
          } else if (value == "savePart") {
            let list = this.state.billerList
              .filter((x) => x.updatedRate !== "")
              .map((x) => {
                return {
                  userId: x.userId,
                  matterId: x.matterId,
                  partnershipId: x.partnershipId,
                  startDate: this.state.startDate,
                  rate: x.updatedRate,
                };
              });
            api.updateRatesBatch(list, 1, this.matterId).then((data) => {
              this.loadBillers();
            });
          } else if (value == "saveOnly") {
            let list = this.state.billerList
              .filter((x) => x.updatedRate !== "")
              .map((x) => {
                return {
                  userId: x.userId,
                  matterId: x.matterId,
                  startDate: this.state.startDate,
                  rate: x.updatedRate,
                };
              });
            api.updateRatesBatch(list, 2, this.matterId).then((data) => {
              this.loadBillers();
            });
          }
        });
      }
    }
  };

  render() {
    return (
      <Fragment>
        <style>{`
                    .swal-button-container{
                        width:100%;
                    }
                    .swal-button-container button{
                        width:100%;
                    }
                `}</style>
        <PageTitle heading="Update Rates" />
        <Row>
          <Col md="12">
            <UncontrolledAlert color="warning">
              <h4>
                <FontAwesomeIcon icon={Icons.faInfo} /> Alert
              </h4>
              This is an advanced administration area. Please contact LeGuard if
              you have any questions using this feature.
            </UncontrolledAlert>
          </Col>
        </Row>
        {this.state.partnership && this.state.partnership.partnershipName && (
          <Row>
            <Col md="12">
              <h4>Partnership: {this.state.partnership.partnershipName}</h4>
            </Col>
          </Row>
        )}
        {this.state.matter && this.state.matter.matterName && (
          <Row>
            <Col md="12">
              <h4>Matter: {this.state.matter.matterName}</h4>
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: "30px" }}>
          <div className="form-group col-md-6">
            <label htmlFor="specificRateStartDate">Start Date</label>
            <DatePicker
              className="form-control"
              selected={this.state.startDate}
              onChange={(date) =>
                this.setState({ startDate: date, startDateError: false })
              }
              placeholderText="Start date..."
            />
            {this.state.startDateError && (
              <div className="invalid-feedback" style={{ display: "block" }}>
                A Start Date is required
              </div>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="specificRateEndDate">End Date (not required)</label>
            <DatePicker
              className="form-control"
              selected={this.state.endDate}
              onChange={(date) => this.setState({ endDate: date })}
              placeholderText="End date..."
            />
          </div>
        </Row>
        {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!this.state.isLoading && (
          <Row style={{ marginTop: "30px" }}>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th width="30%">Biller / Lawyer</th>
                        <th width="25%">Current Rate</th>
                        <th width="20%">Future rates</th>
                        <th width="25%">New Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.billerList.length > 0 &&
                        this.state.billerList.map((p) => {
                          return (
                            <tr key={p.userId}>
                              <td>{p.name}</td>
                              <td>
                                {currencyFormatter(
                                  p.currencySymbol,
                                  p.rate,
                                  true
                                )}
                              </td>
                              <td>
                                {p.futureRatesDisplayString == null
                                  ? ""
                                  : p.futureRatesDisplayString.substring(
                                      0,
                                      p.futureRatesDisplayString.length - 2
                                    )}
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Amount..."
                                  value={p.updatedRate}
                                  onChange={(e) => {
                                    let list = [...this.state.billerList];
                                    let idx = list.find(
                                      (x) => x.userId == p.userId
                                    );
                                    idx.updatedRate = e.target.value;
                                    this.setState({ billerList: list });
                                  }}
                                  id={`newRate-${p.userId}`}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      {this.state.billerList.length == 0 && (
                        <tr>
                          <td>No items to display</td>
                          <td />
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              <Button
                className="mb-2 mr-2"
                color="primary"
                onClick={() => this.saveRates()}
              >
                Update Rates
              </Button>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

export default UpdateRates;
