import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AppHeader from '../Layout/AppHeader/';
import AppSidebar from '../Layout/AppSidebar/';
import AppFooter from '../Layout/AppFooter/';
import ThemeOptions from '../Layout/ThemeOptions/';
import ExpenseList from '../Views/Admin/ExpenseList';
import ManageLists from '../Views/Admin/ManageLists';
import Users from '../Views/Admin/Users';
import ManageCompany from '../Views/Admin/ManageCompany';
import { PrivateRoute } from '../Components/PrivateRoute';
import CategoryPackages from '../Views/Admin/CategoryPackages';
import PartnershipList from '../Views/Partnership/PartnershipList';
import PartnershipSettings from '../Views/Partnership/PatrnershipSettings/PartnershipSettings';
import MatterList from '../Views/Matter/MatterList';
import MatterSettings from '../Views/Matter/MatterSettings/MatterSettings';
import Logout from '../Views/Logout';
import ActivityList from '../Views/Activity/ActivityList';
import Dashboard from '../Views/Home/Dashboard';
import { ToastContainer } from 'react-toastify';
import MatterDetails from '../Views/Matter/MatterDetails/MatterDetails';
import RegistrationComplete from '../Views/User/RegistrationComplete';
import UserProfile from '../Views/User/UserProfile';
import ChartList from '../Views/Chart/ChartList';
import ReportList from '../Views/Report/ReportList';
import UpdateRates from '../Views/Admin/UpdateRates';
import Alerts from '../Views/User/Alerts';
import ChangePassword from '../Views/User/ChangePassword';
import Help from '../Views/User/Help';
import InvoiceList from '../Views/Invoice/InvoiceList';
import InvoiceSettings from '../Views/Invoice/InvoiceSettings/InvoiceSettings';
import AdminNotice from '../Views/Admin/AdminNotice';
import MatterActivityList from '../Views/Matter/MatterDetails/MatterActivityList';
import PaymentList from '../Views/Payment/PaymentList';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';
class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            closeButtonRef: undefined
        }
        // this.closeButtonRef = undefined;
    }

    setStopButtonRef = (ref) => {
        //this.closeButtonRef = ref;
        this.setState({closeButtonRef: ref});
    }

    render() {
        return (
            <Fragment>
                <ThemeOptions />
                <AppHeader closeButtonRef={this.state.closeButtonRef} />
                <div className="app-main">
                    <AppSidebar setStopButtonRef={this.setStopButtonRef} />
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                    <div className="app-main__outer">
                        <div className="app-main__inner">
                            <PrivateRoute exact path='/' component={Dashboard} />
                            <PrivateRoute path='/dashboard' component={Dashboard} />
                            <PrivateRoute path='/admin/managelists' component={ManageLists} />
                            <PrivateRoute path='/admin/notice' component={AdminNotice} />
                            <PrivateRoute path='/admin/users' component={Users} />
                            <PrivateRoute path='/admin/managecompanies' component={ManageCompany} />
                            <PrivateRoute path='/admin/categorypackages' component={CategoryPackages} />
                            <PrivateRoute path='/partnership' component={PartnershipList} />
                            <PrivateRoute path='/partnershipsettings/:partnershipId/:isInternal?' component={PartnershipSettings} />
                            <PrivateRoute path='/matter' component={MatterList} />
                            <PrivateRoute path='/matterSettings/:matterId' component={MatterSettings} />
                            <PrivateRoute path='/matterDetails/:matterId/:userId?' component={MatterDetails} />
                            <PrivateRoute path='/matterActivities/:matterId' component={MatterActivityList} />
                            <PrivateRoute path='/activity' component={ActivityList} />
                            <PrivateRoute path='/registrationComplete' component={RegistrationComplete} />
                            <PrivateRoute path='/profile' component={UserProfile} />
                            <PrivateRoute path='/charts' component={ChartList} />
                            <PrivateRoute path='/reports' component={ReportList} />
                            <PrivateRoute path='/updaterates/:partnershipId/:matterId' component={UpdateRates} />
                            <PrivateRoute path='/alerts' component={Alerts}/>
                            <PrivateRoute path='/changePassword' component={ChangePassword} />
                            <PrivateRoute path='/invoices' component={InvoiceList} />
                            <PrivateRoute path='/expenses' component={ExpenseList} />
                            <PrivateRoute path='/invoice/:invoiceId' component={InvoiceSettings} />
                            <PrivateRoute path='/help' component={Help} />
                            <PrivateRoute path='/payments' component={PaymentList} />
                            <Route path='/logout' component={Logout}></Route>
                        </div>
                        <AppFooter />
                    </div>
                    {/* </MuiPickersUtilsProvider> */}
                </div>
                <ToastContainer />
            </Fragment>
        );
    }
}

export default Main;