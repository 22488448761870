import moment from 'moment';
import 'moment-timezone';
import { userService } from '../_services';

export const DateTimeHelper = {
    getUsersDateTimeStringFromUtc,
    getUtcDateTimeStringFromUser,
    getUtcNow,
    getUsersNow,
    showDurationFromSeconds,
    getDurationInHours
}

const getDurationInHours = (startDate, endDate) => {
    if (startDate == null) {
      return 0;
    }
    let start = moment(startDate);
    let end = moment(
      endDate == null || endDate == undefined
        ? getUsersNow("MM/DD/YYYY hh:mm A")
        : endDate
    );
    const duration = moment.duration(end.diff(start));
    const durationInHours = duration.asHours();
    return durationInHours.toFixed(2);
  };

function showDurationFromSeconds(seconds){
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);

    let formattedTime = '';
    
    if (hours > 0) {
        formattedTime += hours + ' hours ';
    }
    if (minutes > 0) {
        formattedTime += minutes + ' minutes ';
    }
    
    return formattedTime.trim();
}

function getUsersDateTimeStringFromUtc(date, format) {
    moment.locale("en");
    let currentUser = userService.getLoggedInUser();
    if (currentUser == null || currentUser.timezoneIanaName == undefined)
        return moment(date).format();
    if (format !== undefined)
        return moment.tz(date, 'UTC').tz(currentUser.timezoneIanaName).format(format);
    return date == undefined ? null : moment.tz(date, 'UTC').tz(currentUser.timezoneIanaName).format();
}

function getUtcDateTimeStringFromUser(date, format) {
    moment.locale("en");
    let currentUser = userService.getLoggedInUser();
    if (currentUser == null || currentUser.timezoneIanaName == undefined)
        return moment(date).format();
    if (format !== undefined)
        return moment.tz(date, currentUser.timezoneIanaName).tz('UTC').format(format);
    return date !== null ? (typeof date === 'string' ? moment.tz(date, currentUser.timezoneIanaName).tz('UTC').format() : date.tz('UTC').format()) : null;
}

function getUtcNow(format) {
    moment.locale("en");
    if (format !== undefined)
        return moment.tz('UTC').format(format);
    return moment.tz('UTC').format();
}

function getUsersNow(format) {
    moment.locale("en");
    let currentUser = userService.getLoggedInUser();
    if (currentUser == null || currentUser.timezoneIanaName == undefined)
        return moment().format();
    if (format !== undefined)
        return moment.tz('UTC').tz(currentUser.timezoneIanaName).format(format);
    return moment.tz('UTC').tz(currentUser.timezoneIanaName).format();
}


function getUtcDateFromUser(date) {
    let currentUser = userService.getLoggedInUser();
    return typeof date === 'string' ? moment.tz(date, currentUser.timezoneIanaName).tz('UTC').format() : date.tz('UTC').format();
}