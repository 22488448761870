import React, { Fragment } from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import HeaderLogo from '../AppLogo';

import { Button } from 'reactstrap';

import SearchBox from './Components/SearchBox';
import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import HeaderRightDrawer from "./Components/HeaderRightDrawer";

import HeaderDots from "./Components/HeaderDots";
import RunningActivityHelper from '../../_helpers/runningActivityHelper';
import { DateTimeHelper } from '../../_helpers/dateTimeHelper';
import moment from 'moment';
import MobileHelper from '../../_helpers/mobileHelper';
import { userService } from '../../_services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

class Header extends React.Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     runningActivity: null
        // }
        this.currentUser = userService.getLoggedInUser();
    }

    componentDidMount() {
        // this.runningActivityObservable = RunningActivityHelper.runningActivityObservable.subscribe((val) => {
        //     this.setState({ runningActivity: val == null ? null : (val.isPaused ? null : val) });
        // });
    }

    // componentWillUnmount() {
    //     this.runningActivityObservable.unsubscribe();
    // }

    // getTimeInHoursMinutes = (seconds) => {
    //     let hours = Math.floor(seconds / 3600);
    //     let minutes = Math.floor((seconds - hours * 3600) / 60);
    //     return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
    // }

    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}
                    style={{ backgroundColor: 'darkred', color: 'white' }}>

                    <HeaderLogo hideMenu={this.props.hideMenu} />

                    <div className={cx(
                        "app-header__content",
                        { 'header-mobile-open': enableMobileMenuSmall },
                    )}>
                        <div className="app-header-left">
                            {!MobileHelper.isMobile() &&
                                'Tracking Legal Fees in Real Time® for Legal Spend Integrity®'
                            }
                        </div>
                        <div className="app-header-right"
                        // onClick={() => this.props.closeButtonRef !== null && this.props.closeButtonRef.current !== null && this.props.closeButtonRef.current.click()}
                        >
                            {/* {this.state.runningActivity !== null &&
                                <p style={{color: MobileHelper.isMobile() ? '#3c8dbc' : 'white'}}>In Progress: {this.state.runningActivity.activityCodeTitle} - {this.state.runningActivity.matterName} - {this.getTimeInHoursMinutes(this.state.runningActivity.duration)}</p>
                            } */}
                            {this.currentUser !== undefined && this.currentUser != null &&
                                <span style={{ color: MobileHelper.isMobile() ? '#3c8dbc' : 'white' }}><b>{this.currentUser.name}</b>, {this.currentUser.entityName} <FontAwesomeIcon icon={faUser}></FontAwesomeIcon></span>
                            }
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);