import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/fontawesome-free-solid";
import { userService, api } from "../../../_services";
import Loader from "react-loaders";
const CategoryModal = ({
  showModal,
  toggleModal,
  matterId,
  selectedCodes,
  reloadCodes,
}) => {
  let [selectedPackageFilter, setSelectedPackageFilter] = useState({
    packageId: 0,
  });
  const currentUser = userService.getLoggedInUser();
  let [isLoading, setIsLoading] = useState(false);
  let [categoryPackageGridList, setCategoryPackageGridList] = useState([]);
  let [categoryPackageOriginalList, setCategoryPackageOriginalList] = useState(
    []
  );
  let [selectionActivityCodeList, setSelectionActivityCodeList] = useState([]);
  let [selectedActivityCodeList, setSelectedActivityCodeList] = useState([]);
  let [selectAllOnSelectionCodes, setSelectAllOnSelectionCodes] = useState([]);
  let [selectAllOnSelectedCodes, setSelectAllOnSelectedCodes] = useState([]);

  useEffect(() => {
    if (showModal) {
      setIsLoading(true);
      api
        .getCategoryPackageListByEntityId(currentUser.entityId)
        .then((data) => {
          setDefaultValues(data, selectedCodes);
          setIsLoading(false);
        });
    }
  }, [showModal]);

  const setDefaultValues = (data, selectedCodes) => {
    let allActivityCodes = [];
    // let selected = data.filter(x => {
    //     return selectedCodes.find(c => c.activityCodeId == x.activityCodeId);
    // })
    let list = data
      .map((p, i) => {
        let ret = p;
        ret.codes =
          p.activityCodes == undefined || p.activityCodes == null
            ? []
            : p.activityCodes
                .map((ac) => {
                  allActivityCodes.push({ ...ac, isSelected: false });
                  return ac.title;
                })
                .join(", ");
        return ret;
      })
      .filter((el) => {
        return el !== undefined; //&& el.entityId !== 0;
      });
    let selected = [];
    for (var i = 0; i < selectedCodes.length; i++) {
      let s = allActivityCodes.find(
        (x) => x.activityCodeId == selectedCodes[i].activityCodeId
      );
      if (s !== undefined) selected.push(s);
    }
    selected = selected.map((x) => {
      x.isSelected = false;
      x.isVisible = true;
      return x;
    });
    allActivityCodes = allActivityCodes.filter((x) => {
      return (
        selectedCodes.find((c) => c.activityCodeId == x.activityCodeId) ==
        undefined
      );
    });

    let filteredCodes = allActivityCodes
      .reduce((acc, current) => {
        let x = acc.find(
          (item) => item.activityCodeId === current.activityCodeId
        );
        current.isVisible = true;
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, [])
      .filter(
        (x) =>
          selected.find((z) => z.activityCodeId == x.activityCodeId) ==
          undefined
      );
    // setCategoryPackageGridList(list);
    setCategoryPackageOriginalList(data);
    setSelectionActivityCodeList(filteredCodes);
    setSelectedActivityCodeList(selected);
    setSelectedPackageFilter({ packageId: 0 });
    setSelectAllOnSelectionCodes({
      id: 0,
      isSelected: false,
    });
    setSelectAllOnSelectedCodes({
      id: 1,
      isSelected: false,
    });
  };

  const onPackageSelectionChange = (packageId) => {
    let actCodeList = [];
    if (packageId == 0) {
      actCodeList = selectionActivityCodeList.map((ac) => {
        ac.isVisible = true;
        return ac;
      });
    } else {
      let actCodesToShow = categoryPackageOriginalList
        .map((p) => {
          if (p.packageId == packageId) {
            return p.activityCodes;
          }
        })
        .filter((el) => {
          return el !== undefined;
        })[0];
      actCodeList = selectionActivityCodeList.map((ac) => {
        let found = actCodesToShow.find(
          (a) => a.activityCodeId == ac.activityCodeId
        );
        if (found !== undefined) {
          ac.isVisible = true;
        } else {
          ac.isVisible = false;
        }
        return ac;
      });
    }
    setSelectionActivityCodeList(actCodeList);
    setSelectedPackageFilter({ packageId: packageId });
  };

  const orderCodes = (list, type) => {
    let sortedList = _.sortBy(list, (el) => el.title);
    if (type == 0) setSelectionActivityCodeList(sortedList);
    else setSelectedActivityCodeList(sortedList);
  };

  const onSelectAllSelectionChange = (type) => {
    if (type == 0) {
      //select all in selectionActivityCodeList

      let list = selectionActivityCodeList.map((ac) => {
        ac.isSelected = !selectAllOnSelectionCodes.isSelected;
        return ac;
      });
      setSelectionActivityCodeList(list);
      setSelectAllOnSelectionCodes({
        id: selectAllOnSelectionCodes.id,
        isSelected: !selectAllOnSelectionCodes.isSelected,
      });
    } else {
      //selectedActivityCodeList
      let list = selectedActivityCodeList.map((ac) => {
        ac.isSelected = !selectAllOnSelectedCodes.isSelected;
        return ac;
      });
      setSelectedActivityCodeList(list);
      setSelectAllOnSelectedCodes({
        id: selectAllOnSelectedCodes.id,
        isSelected: !selectAllOnSelectedCodes.isSelected,
      });
    }
  };

  const onSelectionChangeSelection = (actCode, e) => {
    let list = selectionActivityCodeList.map((item) => {
      if (item.activityCodeId == actCode.activityCodeId) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setSelectionActivityCodeList(list);
  };
  const onSelectionChangeSelected = (actCode, e) => {
    let list = selectedActivityCodeList.map((item) => {
      if (item.activityCodeId == actCode.activityCodeId) {
        item.isSelected = !item.isSelected;
      }
      return item;
    });
    setSelectedActivityCodeList(list);
  };

  const addSelectedCodes = () => {
    let selected = selectionActivityCodeList.filter((ac) => {
      return ac.isSelected && ac.isVisible;
    });
    let selectionList = selectionActivityCodeList.filter((ac) => {
      if (!selected.includes(ac)) return ac;
    });
    let lst = selectedActivityCodeList.concat(
      selected.map((ac) => {
        ac.isSelected = false;
        return ac;
      })
    );
    setSelectedActivityCodeList(lst);
    setSelectionActivityCodeList(selectionList);
    setSelectAllOnSelectedCodes({
      id: selectAllOnSelectedCodes.id,
      isSelected: false,
    });
  };

  const removeSelectedCodes = () => {
    let selected = selectedActivityCodeList.filter((ac) => {
      return ac.isSelected && ac.isVisible;
    });
    let selectedList = selectedActivityCodeList.filter((ac) => {
      if (!selected.includes(ac)) return ac;
    });
    let lst = selectionActivityCodeList.concat(
      selected.map((ac) => {
        ac.isSelected = false;
        return ac;
      })
    );

    setSelectionActivityCodeList(lst);
    setSelectedActivityCodeList(selectedList);
    setSelectAllOnSelectionCodes({
      id: selectAllOnSelectionCodes.id,
      isSelected: false,
    });
  };

  const saveActivityCodes = () => {
    api
      .updateMatterActivityCodes(
        matterId,
        currentUser.userId,
        selectedActivityCodeList
      )
      .then((data) => {
        reloadCodes();
        toggleModal();
      });
  };

  return (
    <Modal
      size="lg"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Add Codes</ModalHeader>
      <ModalBody>
        <div className="container">
          {isLoading && <Loader style={{}} type="ball-pulse" active />}
          {!isLoading && (
            <div className="row">
              <div className="col-5">
                <label htmlFor="packageSelect">Select from</label>
                <CustomInput
                  type="select"
                  id="packageSelect"
                  value={selectedPackageFilter.packageId}
                  name="packageSelect"
                  onChange={(e) => onPackageSelectionChange(e.target.value)}
                >
                  <option key={0} value={0}>
                    All Codes
                  </option>
                  {categoryPackageOriginalList.map((cp) => {
                    return (
                      <option key={cp.packageId} value={cp.packageId}>
                        {cp.name}
                      </option>
                    );
                  })}
                </CustomInput>
                <Card style={{ marginTop: "20px" }}>
                  <CardBody style={{ height: "300px", overflowY: "auto" }}>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th width="30%">Select</th>
                          <th
                            width="70%"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              orderCodes(selectionActivityCodeList, 0)
                            }
                          >
                            Activity Code{" "}
                            <FontAwesomeIcon icon={Icons.faChevronUp} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <CustomInput
                              type="checkbox"
                              id={"selectAll0"}
                              style={{ cursor: "pointer" }}
                              value={selectAllOnSelectionCodes.isSelected}
                              checked={selectAllOnSelectionCodes.isSelected}
                              onChange={() =>
                                onSelectAllSelectionChange(
                                  selectAllOnSelectionCodes.id
                                )
                              }
                            />
                          </td>
                          <td>Select all</td>
                        </tr>
                        {selectionActivityCodeList.map((ac) => {
                          return (
                            ac.isVisible && (
                              <tr key={ac.activityCodeId}>
                                <td>
                                  <CustomInput
                                    type="checkbox"
                                    id={ac.activityCodeId}
                                    style={{ cursor: "pointer" }}
                                    value={ac.isSelected}
                                    checked={ac.isSelected}
                                    onChange={(e) =>
                                      onSelectionChangeSelection(ac, e)
                                    }
                                  />
                                </td>
                                <td>{ac.title}</td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
              <div
                className="col-2"
                style={{
                  display: "grid",
                  alignItems: "center",
                  paddingTop: "15%",
                }}
              >
                <Button color="primary" onClick={() => addSelectedCodes()}>
                  Add {">>"}
                </Button>
                <Button color="primary" onClick={() => removeSelectedCodes()}>
                  {"<<"} Remove
                </Button>
              </div>
              <div className="col-5">
                <Card style={{ marginTop: "20px" }}>
                  <CardBody style={{ height: "360px", overflowY: "auto" }}>
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th width="30%">Select</th>
                          <th
                            width="70%"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              orderCodes(selectedActivityCodeList, 1)
                            }
                          >
                            Activity Code{" "}
                            <FontAwesomeIcon icon={Icons.faChevronUp} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <CustomInput
                              type="checkbox"
                              id={"selectAll1"}
                              style={{ cursor: "pointer" }}
                              value={selectAllOnSelectedCodes.isSelected}
                              checked={selectAllOnSelectedCodes.isSelected}
                              onChange={() =>
                                onSelectAllSelectionChange(
                                  selectAllOnSelectedCodes.id
                                )
                              }
                            />
                          </td>
                          <td>Select all</td>
                        </tr>
                        {selectedActivityCodeList.map((ac) => {
                          return (
                            ac.isVisible && (
                              <tr key={"s" + ac.activityCodeId}>
                                <td>
                                  <CustomInput
                                    type="checkbox"
                                    id={"s" + ac.activityCodeId}
                                    value={ac.isSelected}
                                    style={{ cursor: "pointer" }}
                                    checked={ac.isSelected}
                                    onChange={(e) =>
                                      onSelectionChangeSelected(ac, e)
                                    }
                                  />
                                </td>
                                <td>{ac.title}</td>
                              </tr>
                            )
                          );
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
          <div className="row" style={{ marginTop: "10px" }}>
            <Button color="link" onClick={() => toggleModal()}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => saveActivityCodes()}
              style={{ marginLeft: ".25rem" }}
            >
              Save
            </Button>{" "}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CategoryModal;
