import React, { Fragment, useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader, ModalFooter, CardBody,
    ModalBody, Row, Col, CustomInput, CardTitle
} from 'reactstrap';
import GeneralGrid from '../../../Components/GeneralGrid'
import { api, userService, userMatterRoles } from '../../../_services';
import Loader from 'react-loaders';

const SelectActivityCodeModal = ({ showModal, toggleModal, onActivityCodeSelect, matter }) => {

    let [activityCodeList, setActivityCodeList] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const currentUser = userService.getLoggedInUser();
    const gridOptions = {
        sizePerPageList: [
            {
                text: '100',
                value: 100
            }
        ],
        hideSizePerPage: false
    }
    useEffect(() => {
        if (showModal) {
            setIsLoading(true);
            api.getActivityCodeListByMatterId(matter.matterId).then(data => {
                setIsLoading(false);
                setActivityCodeList(data);
            })

        }
    }, [showModal]);

    const onMatterClick = e => {
        e && e.preventDefault();
        toggleModal();
    }

    return (
        <Modal size="md" isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>
                <a href='#' onClick={onMatterClick}><h4>{'Matter: ' + matter.matterName}</h4></a>
            </ModalHeader>
            <ModalBody>
                {isLoading && <Loader style={{}} type="ball-pulse" active />}
                {!isLoading &&
                    <GeneralGrid colWidth={12}
                        title="Select Activity"
                        keyField="activityCodeId"
                        defaultSortedColumn="title"
                        dataSource={activityCodeList}
                        options={gridOptions}
                        columns={[
                            {
                                dataField: 'title',
                                text: 'Activity',
                                sort: true,
                                formatter: (cellContent, row) => {
                                    return (
                                        <a key={row.activityCodeId} href="#" onClick={(e) => { e.preventDefault(); onActivityCodeSelect(row); }}>{cellContent}</a>
                                    );
                                }
                            }
                        ]}
                    />
                }
                <Row style={{ marginTop: '30px' }}>
                    <Col md="12">
                        <Button color="link" onClick={() => toggleModal()}>Cancel</Button>
                    </Col>
                </Row>
            </ModalBody>

        </Modal>
    );
}

export default SelectActivityCodeModal;