import "@fortawesome/fontawesome-free/css/all.min.css";
import moment from "moment";
import React, { Component, Fragment } from "react";
import MetisMenu from "react-metismenu";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import swal from "sweetalert";
import EditActivityModal from "../../Views/Activity/Components/EditActivityModal";
import SelectActivityCodeModal from "../../Views/Activity/Components/SelectActivityCodeModal";
import SelectMatterModal from "../../Views/Activity/Components/SelectMatterModal";
import StartActivityModal from "../../Views/Activity/Components/StartActivityModal";
import ReportModal from "../../Views/Report/ReportModal";
import { DateTimeHelper } from "../../_helpers/dateTimeHelper";
import MobileHelper from "../../_helpers/mobileHelper";
import { navBarHelper } from "../../_helpers/navBarHelper";
import RunningActivityHelper from "../../_helpers/runningActivityHelper";
import SuspendedActivitiesHelper from "../../_helpers/suspendedActivitiesHelper";
import { api, userService } from "../../_services";
import UploadExpensesModal from "../../Views/Invoice/InvoiceSettings/Components/UploadExpensesModal";
import CopyActivitiesModal from "../../Components/CopyActivitiesModal";

class MyNav extends Component {
  constructor(props) {
    super(props);
    this.newActivity = {
      activityId: -1,
      matterName: "Not selected",
      revisionReason: "forgot",
      description: "",
    };
    this.state = {
      showUpdateRatesModal: false,
      showMatterModal: false,
      showActivityCodeModal: false,
      showStartActivityModal: false,
      showStopActivityModal: false,
      showAddActivityModal: false,
      activityToEdit: this.newActivity,
      showReportModal: false,
      selectedReportId: 1,
      activity: {},
      showCopyActivitiesModal: false,
      matter: {
        matterId: -1,
      },
      activityCode: {
        activityCodeId: -1,
      },
      leguardNav: [],
      partnershipList: [],
      selectedPartnershipId: 0,
      matterList: [],
      selectedMatterId: -1,
      suspendedActivitiesCount: 0,
      isStopModal: true,
      showUploadExpensesModal: false,
      currentDate: DateTimeHelper.getUsersNow(),
    };
    this.currentUser = userService.getLoggedInUser();
    this.stopButtonRef = React.createRef();
    this.canUserBill = false;
  }

  componentDidMount() {
    api.canUserBill(this.currentUser.userId).then((flag) => {
      if (flag || this.currentUser.entityRoleId == 2) {
        this.canUserBill = flag;
        this.getRunningActivity();
        this.getSuspendedActivities();
        this.props.setStopButtonRef(this.stopButtonRef);
        this.suspendedActivitiesObservable =
          SuspendedActivitiesHelper.suspendedActivitiesObservable.subscribe(
            (val) => {
              this.getSuspendedActivities();
            }
          );
      } else {
        RunningActivityHelper.setRunningActivity(null);
        this.setState((prevState) => ({
          ...prevState,
          activity: null,
        }));
      }
    });
    api
      .getPartnershipListByEntityId(
        this.currentUser.entityId,
        this.currentUser.userId
      )
      .then((data) => {
        this.setState({
          partnershipList: data ?? [],
          selectedPartnershipId: data?.length > 0 ? data[0].partnershipId : 0,
        });
      });
    this.populateNavigationItems();
    this.startStopActivityListener =
      MobileHelper.startStopActivityObservable.subscribe((val) => {
        if (val !== "undefined" && val !== null) {
          if (val) this.toggleMatterModal();
          else this.toggleStopActivityModal();
        }
      });
  }

  componentWillUnmount() {
    if (this.interval !== undefined) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    if (this.suspendedActivitiesObservable !== undefined)
      this.suspendedActivitiesObservable.unsubscribe();
    if (this.startStopActivityListener !== undefined)
      this.startStopActivityListener.unsubscribe();
  }

  getSuspendedActivities() {
    api
      .getSuspendedActivityListByBillerId(this.currentUser.userId, 1)
      .then((data) => {
        if (data.length > 0)
          this.setState({ suspendedActivitiesCount: data.length });
        else {
          this.setState({ suspendedActivitiesCount: 0 });
          let count = document.getElementById("suspended-activities-count");
          if (count !== undefined && count !== null)
            count.style.display = "none";
        }
      });
  }

  populateNavigationItems() {
    let nav = navBarHelper.getNavigationBar(this.currentUser);
    this.setState({ leguardNav: nav });
  }

  getRunningActivity() {
    api.getRunningActivity(this.currentUser.userId).then((data) => {
      if (this.interval == undefined) {
        this.interval = setInterval(() => {
          this.getRunningActivity();
        }, 60000);
      }

      RunningActivityHelper.setRunningActivity(data);
      this.setState({ activity: data });
      this.checkLongRunningActivity(data);
      this.hideShowStartStopButton(data);
      if (!this.currentUser.initialActivityCheckMade) {
        userService.setInitialActivityCheck();
        this.currentUser = userService.getLoggedInUser();
        if (data !== null && data.endTime == null) {
          swal(
            `Activity "${data.activityCodeTitle}" is running. Would you like to stop it?`,
            {
              buttons: {
                cancel: "Cancel",
                stop: {
                  text: "Stop",
                  value: "stop",
                  className: "btn-danger",
                },
              },
              icon: "warning",
            }
          ).then((value) => {
            switch (value) {
              case "cancel":
                break;
              case "stop":
                this.toggleStopActivityModal();
                break;
              default:
                break;
            }
          });
        }
      }
    });
  }

  checkLongRunningActivity = (act) => {
    //let start, end;
    if (act !== undefined && act !== null && act.endTime == null) {
      let ret = "";
      var diffMs = moment(moment().utc().format()).diff(
        moment(DateTimeHelper.getUsersDateTimeStringFromUtc(act.startTime))
      );
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000 + diffDays * 24); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      // if (diffDays > 0)
      //     ret += diffDays + ' days ';
      if (diffHrs > 0) ret += diffHrs + " hours ";
      if (diffMins > 0) ret += diffMins + " minutes";
      if (diffHrs > 6 && !act.alreadyReminded) {
        //alert(`Activity ${act.activityCodeTitle} is running for more than 6 hours. ${diffHrs}`);
        swal(
          `Activity "${act.activityCodeTitle}" is running for more than 6 hours (${ret}). What would you like to do?`,
          {
            buttons: {
              cancel: "Do nothing",
              stop: {
                text: "Stop Activity",
                value: "stop",
              },
              mark: {
                text: "Don't ask again",
                value: "mark",
              },
            },
          }
        ).then((value) => {
          switch (value) {
            case "cancel":
              break;

            case "stop":
              this.toggleStopActivityModal();
              break;

            case "mark":
              api
                .changeAlreadyRemindedFlagOnActivity(act.activityId)
                .then((data) => {});
              break;
            default:
              break;
          }
        });
      }
    }
  };

  hideShowStartStopButton = (runningActivity) => {
    this.setState((prevState) => ({
      ...prevState,
      activity: runningActivity,
    }));
  };

  onClick = (e, props) => {
    if (props.hasSubMenu) props.toggleSubMenu(e);
    else {
      //handle redirect
      // if (props.label == 'Activity') {
      //     e && e.preventDefault();
      // }

      if (props.to == "addNewActivity") {
        e && e.preventDefault();
        this.toggleAddNewActivityModal();
      }

      if (props.to == "#/activity") {
        e && e.preventDefault();
        this.props.history.push({
          pathname: "/activity",
        });
      }

      if (props.to.indexOf("report") > -1) {
        e && e.preventDefault();
        this.showReportModal(parseInt(props.to.split("_")[1]));
      }

      if (props.to == "uploadExpenses") {
        e && e.preventDefault();
        this.toggleUploadExpensesModal();
      }

      if (props.to == "updaterates") {
        e && e.preventDefault();
        this.toggleUpdateRatesModal();
      }

      if (props.to == "copyactivities") {
        e && e.preventDefault();
        this.toggleCopyActivitiesModal();
      }

      if (props.to == "#/logout") {
        e && e.preventDefault();
        api.getRunningActivity(this.currentUser.userId).then((data) => {
          if (data !== undefined && data !== null && data.endTime == null) {
            swal(
              `Activity "${data.activityCodeTitle}" is still running. Would you like to stop it?`,
              {
                buttons: {
                  logout: {
                    text: "Logout",
                    value: "logout",
                  },
                  stop: {
                    text: "Stop",
                    value: "stop",
                    className: "btn-danger",
                  },
                },
                icon: "warning",
              }
            ).then((value) => {
              switch (value) {
                case "logout":
                  this.props.history.push({
                    pathname: `/logout`,
                  });
                  break;
                case "stop":
                  this.toggleStopActivityModal();
                  break;
                default:
                  break;
              }
            });
          } else {
            this.props.history.push({
              pathname: `/logout`,
            });
          }
        });
      }

      MobileHelper.hideMenu();

      props.activateMe({
        newLocation: props.to,
        selectedMenuLabel: props.label,
      });
    }
  };
  showReportModal = (reportId) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        selectedReportId: reportId,
      }),
      () => this.toggleReportModal()
    );
  };

  toggleReportModal = () => {
    this.setState({ showReportModal: !this.state.showReportModal });
  };

  toggleMatterModal = () => {
    this.setState({ showMatterModal: !this.state.showMatterModal });
  };

  onMatterSelect = (matter) => {
    this.setState({ matter: matter });
    this.toggleActivityCodeModal();
  };

  toggleActivityCodeModal = () => {
    this.setState({ showActivityCodeModal: !this.state.showActivityCodeModal });
  };

  onActivityCodeSelect = (actCode) => {
    this.setState({ activityCode: actCode });
    this.toggleStartActivityModal();
  };

  toggleStartActivityModal = () => {
    if (!this.state.showStartActivityModal) {
      this.currentUser = userService.getLoggedInUser();
    }
    this.setState({
      showStartActivityModal: !this.state.showStartActivityModal,
    });
  };

  toggleStopActivityModal = () => {
    if (!this.state.showStopActivityModal) {
      this.currentUser = userService.getLoggedInUser();
    }
    this.setState({
      showStopActivityModal: !this.state.showStopActivityModal,
      isStopModal: true,
    });
  };

  toggleAddActivityModal = () => {
    this.setState({
      showAddActivityModal: !this.state.showAddActivityModal,
    });
  };

  toggleAddNewActivityModal = () => {
    if (!this.state.showStopActivityModal) {
      this.currentUser = userService.getLoggedInUser();
    }
    this.setState({
      showStopActivityModal: !this.state.showStopActivityModal,
      isStopModal: false,
    });
  };

  toggleUpdateRatesModal = () => {
    this.setState({
      showUpdateRatesModal: !this.state.showUpdateRatesModal,
      matterList: [],
      selectedMatterId: -1,
    });
  };

  toggleCopyActivitiesModal = () => {
    this.setState({
      showCopyActivitiesModal: !this.state.showCopyActivitiesModal,
    });
  };

  toggleUploadExpensesModal = () => {
    this.setState({
      showUploadExpensesModal: !this.state.showUploadExpensesModal,
    });
  };

  startFixedPriceActivity = (startDate, fixedPrice) => {
    let activity = {
      startTime: startDate,
      endTime: null,
      authBillerId: this.currentUser.userId,
      activityCodeId: this.state.activityCode.activityCodeId,
      matterId: this.state.matter.matterId,
      cost: 0,
      activityFixedPrice: fixedPrice,
      encryptedNotes: this.currentUser.encryptNotes,
    };
    api.startActivity(activity).then((data) => {
      if (data !== "")
        swal(data, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
      else this.postActivitySave(activity);
    });
  };

  startRateBasedActivity = (startDate, endTime) => {
    if (endTime == undefined) endTime = null;
    let activity = {
      startTime: startDate,
      endTime: endTime,
      authBillerId: this.currentUser.userId,
      activityCodeId: this.state.activityCode.activityCodeId,
      matterId: this.state.matter.matterId,
      cost: 0,
      activityFixedPrice: 0,
      encryptedNotes: this.currentUser.encryptNotes,
    };
    api.startActivity(activity).then((data) => {
      if (data !== "")
        swal(data, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
      else this.postActivitySave(activity);
    });
  };

  postActivitySave = (activity) => {
    if (this.state.showStartActivityModal) this.toggleStartActivityModal();
    if (this.state.showActivityCodeModal) this.toggleActivityCodeModal();
    if (this.state.showMatterModal) this.toggleMatterModal();
    this.hideShowStartStopButton(activity);
    //window.location.href = '/#/activity';
    //RunningActivityHelper.setRunningActivity(activity);
    this.getRunningActivity();
  };

  pauseActivity = (activity, callback) => {
    activity.isPaused = true;

    api.updateActivity(activity).then((data) => {
      if (data !== "") callback(data);
      else {
        this.toggleStopActivityModal();
        this.hideShowStartStopButton(activity);
        this.getRunningActivity();
        callback("");
      }
    });
  };

  stopActivity = (activity, callback) => {
    // if (activity.endTime == null) {
    //     activity.endTime = new Date();
    // }
    if (this.interval !== undefined) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    api.updateActivity(activity).then((data) => {
      if (data !== "") callback(data);
      else {
        this.toggleStopActivityModal();
        this.hideShowStartStopButton(null);
        RunningActivityHelper.setRunningActivity(null);
        this.setState({ activity: null });
        callback("");
      }
    });
  };

  createActivity = (activity, callback) => {
    api.startActivity(activity).then((data) => {
      if (data !== "") callback(data);
      else this.toggleStopActivityModal();
    });
  };

  saveActivity = (activity, callback, addAnother) => {
    if (activity.activityId > -1) {
      api.updateActivity(activity).then((data) => {
        if (data !== undefined && data !== null && data !== "") {
          callback && callback(data);
        } else {
          if (!addAnother) {
            this.toggleAddActivityModal();
          }
          callback && callback("");
        }
      });
    } else {
      let act = {
        ...activity,
        startTime: activity.startTime,
        endTime: activity.endTime,
        authBillerId:
          activity.authBillerId != undefined && activity.authBillerId > 0
            ? activity.authBillerId
            : this.currentUser.userId,
        activityCodeId: activity.activityCodeId,
        matterId: activity.matterId,
        cost: 0,
        description: activity.description,
        password: activity.password,
        encryptedNotes: this.currentUser.encryptNotes,
        timePeriod: activity.timePeriod,
        isTimePeriodSet: activity.isTimePeriodSet,
        date: activity.date,
        explanationCodeID: activity.explanationCodeID,
      };
      api.startActivity(act).then((data) => {
        if (data !== undefined && data !== null && data !== "") {
          callback && callback(data);
        } else {
          if (!addAnother) {
            this.toggleAddActivityModal();
          }
          callback && callback("");
        }
      });
    }
  };

  stopStartActivity = (activity, callback) => {
    // if (activity.endTime == null) {
    //     activity.endTime = new Date();
    // }
    api.updateActivity(activity).then((data) => {
      if (data !== "") callback(data);
      else {
        this.toggleStopActivityModal();
        // this.toggleStartActivityModal();
        this.toggleMatterModal();
        this.getRunningActivity();
        callback("");
      }
    });
  };

  restartActivity = () => {
    api
      .getMatterActivityCodeByMatterIdActivityCodeId(
        this.state.activity.matterId,
        this.state.activity.activityCodeId
      )
      .then((data) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            activityCode: data,
            matter: {
              matterId: this.state.activity.matterId,
              matterName: this.state.activity.matterName,
            },
          }),
          () => this.toggleStartActivityModal()
        );
      });
  };

  loadMatters = () => {
    api
      .getMatterListByPartnershipIdEntityId(
        this.state.selectedPartnershipId,
        this.currentUser.entityId,
        this.currentUser.userId
      )
      .then((data) => {
        this.setState({ matterList: data });
      });
  };

  goToUpdateRates = () => {
    this.props.history.push({
      pathname: `/updaterates/${this.state.selectedPartnershipId}/${
        this.state.matterList.length > 0 ? this.state.selectedMatterId : -1
      }`,
    });
    this.toggleUpdateRatesModal();
  };

  getTimeInHoursMinutes = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  render() {
    return (
      <Fragment>
        {/* <h5 style={{ marginTop: '5px' }} className="leguard-menu-heading">{this.state.activity == null ? 'Press to Start' : 'In Progress'}</h5>
                <p className="leguard-menu-heading">{this.state.activity == null ? 'an Activity' : `${this.state.activity.activityCodeTitle} - ${this.state.activity.matterName} - ${this.getTimeInHoursMinutes(this.state.activity.duration)}`}</p> */}

        {this.currentUser.entityRoleId == 2 && (
          <Fragment>
            {(this.state.activity == undefined ||
              this.state.activity == null ||
              (this.state.activity !== undefined &&
                (this.state.activity.activityId == undefined ||
                  this.state.activity.isPaused))) && (
              <Fragment>
                {/* <h5
                  style={{ marginTop: "5px" }}
                  className="leguard-menu-heading"
                >
                  Press to Start
                </h5>
                <p className="leguard-menu-heading2">an Activity</p> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    className="activity-action-menu-button btn-primary"
                    onClick={() => {
                      this.setState(
                        { activityToEdit: this.newActivity },
                        this.toggleAddActivityModal()
                      );
                    }}
                    style={{ marginTop: "5px" }}
                  >
                    Create New Activity
                  </Button>
                  <Button
                    className="activity-action-menu-button btn-success"
                    onClick={this.toggleMatterModal}
                    style={{ marginTop: "5px" }}
                  >
                    Start Activity
                  </Button>
                  {/* <Button className='leguard-btn-start' onClick={this.restartActivity}>Re-Start</Button> */}
                </div>
              </Fragment>
            )}
            {this.state.activity !== undefined &&
              this.state.activity !== null &&
              this.state.activity.activityId !== undefined &&
              !this.state.activity.isPaused && (
                <Fragment>
                  <h5
                    style={{ marginTop: "5px" }}
                    className="leguard-menu-heading"
                  >
                    In Progress
                  </h5>
                  <p className="leguard-menu-heading2">
                    {this.state.activity.activityCodeTitle} -{" "}
                    {this.state.activity.matterName} -{" "}
                    {this.getTimeInHoursMinutes(this.state.activity.duration)}
                  </p>

                  <Row
                    style={{
                      display: "block",
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    <Button
                      className="activity-action-menu-button btn-danger"
                      onClick={this.toggleStopActivityModal}
                    >
                      Stop / Pause
                    </Button>
                  </Row>
                </Fragment>
              )}
            {this.state.activity !== undefined &&
              this.state.activity !== null &&
              this.state.activity.activityId !== undefined &&
              this.state.activity.isPaused && (
                <Row
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <Button
                    className="activity-action-menu-button btn-success"
                    onClick={this.restartActivity}
                  >
                    Re-Start
                  </Button>
                </Row>
              )}
            {this.state.suspendedActivitiesCount > 0 && (
              <Fragment>
                <Row
                  style={{
                    display: "block",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  <Button
                    className="activity-action-menu-button btn-danger"
                    onClick={(e) => {
                      e && e.preventDefault();
                      this.props.history.push({
                        pathname: "/activity",
                      });
                    }}
                  >
                    {" "}
                    {this.state.suspendedActivitiesCount} suspended
                  </Button>
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}

        <MetisMenu
          content={this.state.leguardNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
          LinkComponent={(props) => {
            // if (props.to !== 'start' && props.to !== 'stop' && props.to !== 'restart' && props.to !== '#/activity') {
            return (
              <a
                className="metismenu-link"
                href={props.to}
                onClick={(e) => this.onClick(e, props)}
              >
                {props.children}
              </a>
            );
          }}
        />

        <div style={{ marginTop: "50px", textAlign: "center" }}>
          <div
            style={{ height: "2px", width: "100%", background: "darkred" }}
          />
          <p style={{ marginTop: "10px", color: "darkred" }}>
            <i>Legal Spend Integrity®</i>
          </p>
        </div>

        <SelectMatterModal
          showModal={this.state.showMatterModal}
          toggleModal={this.toggleMatterModal}
          onMatterSelect={this.onMatterSelect}
        />

        <SelectActivityCodeModal
          showModal={this.state.showActivityCodeModal}
          toggleModal={this.toggleActivityCodeModal}
          matter={this.state.matter}
          onActivityCodeSelect={this.onActivityCodeSelect}
        />

        <StartActivityModal
          showModal={this.state.showStartActivityModal}
          toggleModal={this.toggleStartActivityModal}
          activityCode={this.state.activityCode}
          matter={this.state.matter}
          toggleActivityCodeModal={this.toggleActivityCodeModal}
          startRateBasedActivity={this.startRateBasedActivity}
          startFixedPriceActivity={this.startFixedPriceActivity}
        />

        <EditActivityModal
          showModal={this.state.showStopActivityModal}
          toggleModal={this.toggleStopActivityModal}
          isStopModal={this.state.isStopModal}
          onSave={this.createActivity}
          onPause={this.pauseActivity}
          onStop={this.stopActivity}
          onStopStart={this.stopStartActivity}
          showSaveAndAddAnother={true}
        />

        <EditActivityModal
          showModal={this.state.showAddActivityModal}
          toggleModal={this.toggleAddActivityModal}
          activityId={this.state.activityToEdit.activityId}
          currentDate={this.state.currentDate}
          onSave={this.saveActivity}
          showSaveAndAddAnother={true}
        />

        <ReportModal
          toggleModal={this.toggleReportModal}
          showModal={this.state.showReportModal}
          selectedReportId={this.state.selectedReportId}
        />

        <UploadExpensesModal
          isOpen={this.state.showUploadExpensesModal}
          toggleModal={this.toggleUploadExpensesModal}
        />

        <Modal
          size="md"
          isOpen={this.state.showUpdateRatesModal}
          toggle={() => this.toggleUpdateRatesModal()}
          backdrop="static"
        >
          <ModalHeader toggle={() => this.toggleUpdateRatesModal()}>
            Update Rates
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                {this.state.partnershipList.length > 0 && (
                  <CustomInput
                    type="select"
                    id="partnershipSelect"
                    value={this.state.selectedPartnershipId}
                    name="partnershipSelect"
                    onChange={(e) => {
                      this.setState({ selectedPartnershipId: e.target.value });
                    }}
                  >
                    {this.state.partnershipList.map((partnership) => {
                      return (
                        <option
                          key={partnership.partnershipId}
                          value={partnership.partnershipId}
                        >
                          {partnership.partnershipName}
                        </option>
                      );
                    })}
                  </CustomInput>
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Col md="6" style={{ display: "flex" }}>
                <Button color="primary" onClick={() => this.goToUpdateRates()}>
                  Update Client Rates
                </Button>
              </Col>
              <Col md="6">
                <Button color="primary" onClick={() => this.loadMatters()}>
                  Update rates for a specific matter
                </Button>
              </Col>
            </Row>
            {this.state.matterList.length > 0 && (
              <Row>
                <Col md="12">
                  <CustomInput
                    type="select"
                    id="matterSelect"
                    value={this.state.selectedMatterId}
                    name="matterSelect"
                    onChange={(e) => {
                      this.setState({ selectedMatterId: e.target.value }, () =>
                        this.goToUpdateRates()
                      );
                    }}
                  >
                    <option key={0} value={-1}>
                      Select Matter
                    </option>
                    {this.state.matterList.map((matter) => {
                      return (
                        <option key={matter.matterId} value={matter.matterId}>
                          {matter.matterName}
                        </option>
                      );
                    })}
                  </CustomInput>
                </Col>
              </Row>
            )}
          </ModalBody>
        </Modal>

        <CopyActivitiesModal
          showModal={this.state.showCopyActivitiesModal}
          toggleModal={this.toggleCopyActivitiesModal}
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

export default withRouter(MyNav);
