import React, { Component, Fragment } from "react";
import PageTitle from "../../Layout/AppMain/PageTitle";
import Loader from "react-loaders";
import { api, userService } from "../../_services";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
  Input,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from "react-moment";
import GeneralGrid from "../../Components/GeneralGrid";

class PartnershipList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      entityList: [],
      partnershipList: [],
      originalPartnershipList: [],
      partnershipStatus: 1,
    };
    this.newPartnership = {
      partnershipId: -1,
    };
    this.selectedEntityId = -1;
    this.currentUser = userService.getLoggedInUser();
  }

  componentDidMount() {
    // this.loadPartnerships(this.selectedEntityId);
    api
      .getPartnershipListByManager(
        this.currentUser.entityId,
        this.currentUser.userId
      )
      .then((data) => {
        this.setState({
          partnershipList: data,
          originalPartnershipList: data,
          isLoading: false,
        });
      });
    this.loadEntities();
  }

  loadEntities() {
    api
      .getEntityListByEntityId(this.currentUser.entityId)
      .then((data) => {
        this.setState({ entityList: data });
      })
      .catch(this.handleError);
  }

  handleError() {}

  filterPartnerships(entityId) {
    if (this.currentUser.entityRoleId == 1) {
      //CLIENT
      if (entityId == -1) {
        this.setState({
          partnershipList: [...this.state.originalPartnershipList],
        });
      } else {
        this.setState({
          partnershipList: [
            ...this.state.originalPartnershipList.filter(
              (x) => x.clientEntityId == entityId
            ),
          ],
        });
      }
    } else {
      if (entityId == -1) {
        this.setState({
          partnershipList: [...this.state.originalPartnershipList],
        });
      } else {
        this.setState({
          partnershipList: [
            ...this.state.originalPartnershipList.filter(
              (x) => x.billerEntityId == entityId
            ),
          ],
        });
      }
    }

    // if (entityId == -1)
    //     entityId = this.currentUser.entityId;
    // api.getPartnershipListByManager(entityId, this.currentUser.userId).then(data => {
    //     this.setState({ partnershipList: data, isLoading: false });
    // })
  }

  onEntitySelectionChange(e) {
    this.selectedEntityId = parseInt(e.target.value);
    this.filterPartnerships(this.selectedEntityId);
  }

  goToSettings(partnership, isInternal) {
    this.props.history.push({
      pathname: `/partnershipsettings/${partnership.partnershipId}/${isInternal}`,
    });
  }

  render() {
    let columns = [
      {
        dataField: "partnershipName",
        text:
          this.currentUser.entityRoleId == 2 ? "Client Name" : "Law Firm Name",
        headerStyle: () => {
          return { width: this.currentUser.entityRoleId == 2 ? "20%" : "40%" };
        },
        sort: true,
        formatter: (cellContent, row) => {
          if (row.canEdit) {
            return (
              <a
                key={row.id}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.goToSettings(row, false);
                }}
              >
                {cellContent}
              </a>
            );
          } else {
            return <span key={row.id}>{cellContent}</span>;
          }
        },
      },
      // {
      //     dataField: 'clientEntityName',
      //     text: 'Partner',
      //     sort: true,
      //     headerStyle: () => {
      //         return { width: "25%" };
      //     },
      //     formatter: (cellContent, row) => {
      //         let entityId = this.currentUser.entityId;
      //         return (
      //             row.billerEntityId == entityId ? row.clientEntityName : row.billerEntityName
      //         )
      //     }
      // },
      {
        dataField: "managingUserName",
        text: "Managing User",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      {
        dataField: "effectiveDate",
        text: "Start Date",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cellContent, row) => {
          return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
        },
      },
      {
        dataField: "isDeleted",
        headerStyle: () => {
          return { width: "10%" };
        },
        text: "Active",
        sort: true,
        formatter: (cellContent, row) => {
          return (
            <CustomInput
              readOnly={true}
              type="checkbox"
              id={row.partnershipId}
              checked={!row.isDeleted}
            />
          );
        },
      },
      {
        dataField: "pending",
        headerStyle: () => {
          return { width: "10%" };
        },
        text: "Pending",
        sort: true,
        formatter: (cellContent, row) => {
          if (row.pending) {
            return (
              <label
                style={{
                  backgroundColor: "orange",
                  padding: "2px",
                  borderRadius: "3px",
                  color: "white",
                }}
              >
                Invited
              </label>
            );
          } else {
            return "";
          }
        },
      },
    ];

    if (this.currentUser.entityRoleId == 2) {
      columns.splice(1, 0, {
        dataField: "clientNumber",
        text: "Client Number",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
      });
    }

    const defaultSorted = [
      {
        dataField: "partnershipName",
        order: "asc",
      },
    ];

    const options = {
      sizePerPageList: [
        {
          text: "50",
          value: 50,
        },
      ],
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
    };
    return (
      <Fragment>
        <PageTitle
          heading={
            this.currentUser.entityRoleId == 2
              ? "Manage Clients"
              : "Manage Law Firms"
          }
        />
        {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!this.state.isLoading && (
          <Fragment>
            <Row>
              <Col md="12">
                <Button
                  className="mb-2 mr-2"
                  color="primary"
                  onClick={() => this.goToSettings(this.newPartnership, false)}
                >
                  Link to new{" "}
                  {this.currentUser.entityRoleId == 2 ? "Client" : "Law Firm"}
                </Button>
                <Button
                  className="mb-2 mr-2"
                  color="primary"
                  onClick={() => this.goToSettings(this.newPartnership, true)}
                >
                  Link to Internal{" "}
                  {this.currentUser.entityRoleId == 2 ? "Firm" : "Legal"}
                </Button>
              </Col>
              <Col md="9">
                {this.state.entityList.length > 0 && (
                  <CustomInput
                    type="select"
                    id="entitySelect"
                    name="entitySelect"
                    onChange={(e) => this.onEntitySelectionChange(e)}
                  >
                    <option key={0} value={-1}>
                      All Entities
                    </option>
                    {this.state.entityList.map((entity) => {
                      return (
                        <option key={entity.entityId} value={entity.entityId}>
                          {entity.name}
                        </option>
                      );
                    })}
                  </CustomInput>
                )}
              </Col>
              <Col md="3">
                <CustomInput
                  type="select"
                  id="activeSelect"
                  value={this.state.partnershipStatus}
                  name="activeSelect"
                  onChange={(e) =>
                    this.setState({
                      partnershipStatus: e.target.value,
                      partnershipList:
                        this.state.originalPartnershipList.filter((x) =>
                          e.target.value == "0"
                            ? true
                            : e.target.value == "1"
                            ? !x.isDeleted
                            : x.isDeleted
                        ),
                    })
                  }
                >
                  <option key={0} value={0}>
                    All
                  </option>
                  <option key={1} value={1}>
                    Active
                  </option>
                  <option key={2} value={2}>
                    Inactive
                  </option>
                </CustomInput>
              </Col>
            </Row>

            <Row style={{ marginTop: "30px" }}>
              <GeneralGrid
                title=""
                keyField="partnershipId"
                defaultSortedColumn="name"
                dataSource={this.state.partnershipList}
                filterColumns={[
                  "managingUserName",
                  "partnershipName",
                  "clientEntityName",
                ]}
                columns={columns}
                defaultSorted={defaultSorted}
                options={options}
              />
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default PartnershipList;
