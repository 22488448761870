import React, { Fragment } from 'react';
import {
    Card, CardBody, Button, Row, Col, CustomInput, CardTitle,  Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { api, userService } from '../../_services';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Chart from 'react-apexcharts'
import * as Yup from 'yup';
import moment from 'moment';
import { currencyFormatter } from '../../_services/currencyFormatter';
import Loader from 'react-loaders';

class ChartList extends React.Component {
    constructor(props) {
        super(props);
        this.currentUser = userService.getLoggedInUser();
        this.state = {
            showModal: false,
            chartHistory: { name: '' },
            startDate: new Date(),
            endDate: new Date(),
            showSpendToBudgetChart: false,
            isSpendToBudgetChartLoading: false,
            isPieChartLoading: false,
            isBarChartLoading: false,
            pieChartTitle: 'Pie Chart',
            barChartTitle: 'Bar Chart',
            selectedDatesInterval: '',
            geoCategoryList: [],
            matterTypeList: [],
            matterList: [],
            matterId: 0,
            businessTypeList: [],
            currencyList: [],
            partnershipList: [],
            selectedCurrencyId: this.currentUser.currencyId,
            selectedCurrencySymbol: '$',
            selectedGeoCategoryId: -1,
            selectedMatterTypeId: -1,
            selectedBusinessActivityId: -1,
            selectedDateRange: 0,
            barChartHeight: 500,
            startDate: new Date(),
            endDate: new Date(),
            allPartnersSelected: false,
            selectedChartId: 1,
            chartTypes: [],
            pieChartOptions: {
                chart: {
                    id: 'pie'
                },
                labels: [],
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            width: 350
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            pieChartSeries: [],
            barChartOptions: {
                chart: {
                    id: 'bar'
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                xaxis: {
                    categories: []
                }
            },
            barChartSeries: [{ data: [] }],
            budgetChartSeries: [
                {
                    name: 'Spent',
                    data: []
                }, {
                    name: 'Budget',
                    data: []
                }
                // , {
                //     name: 'Free Cash Flow',
                //     data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
                // }
            ],
            budgetChartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'flat'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Amount'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return '$'
                        }
                    }
                }
            }
        };
        this.formRef = React.createRef();
        this.chartTypes = [
            {
                id: 4,
                name: 'Spend by Activity'
            },
            {
                id: 3,
                name: 'Spend by Biller'
            },
            {
                id: 5,
                name: 'Spend by Client'
            },
            {
                id: 1,
                name: 'Spend by Law Firm'
            },
            {
                id: 2,
                name: 'Spend by Matter'
            },
            {
                id: 6,
                name: 'Spend to Budget'
            }
        ];
        this.monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

    }


    componentDidMount() {
        api.getItemListByEntityId("GeoCategory", this.currentUser.entityId).then(list => {
            this.setState({ geoCategoryList: list });
        });
        api.getItemListByEntityId("MatterType", this.currentUser.entityId).then(list => {
            this.setState({ matterTypeList: list });
        });
        // api.getItemListByEntityId("BusinessActivity", this.currentUser.entityId).then(list => {
        //     this.setState({ businessTypeList: list });
        // });
        api.getCurrencyList().then(list => {
            this.setState({ currencyList: list });
        })
        api.getPartnershipListByEntityId(this.currentUser.entityId, this.currentUser.userId).then(data => {
            this.setState({ partnershipList: data.map(x => { x.isSelected = false; return x; }) });
        })
        if (this.currentUser.entityRoleId == 1) {
            this.setState({ chartTypes: this.chartTypes.filter(x => x.id !== 5) });
        } else {
            this.setState({ chartTypes: this.chartTypes.filter(x => x.id !== 1), selectedChartId: 5 });
        }
        api.getMatterListByUserIdWithBudget(this.currentUser.userId, 0).then(data => {
            this.setState({ matterList: data, matterId: data.length == 0 ? 0 : data[0].matterId });
        })
    }

    generateChart = () => {
        if (this.state.selectedChartId == 6) {
            let dates = this.getDates();
            this.setState({ startDate: dates.startDate, endDate: dates.endDate, isSpendToBudgetChartLoading: true, showSpendToBudgetChart: true }, () => {
                if (this.state.matterId > 0) {
                    api.getSpendToBudgetChartData(this.currentUser.userId, this.state.matterId, this.state.startDate, this.state.endDate, this.state.selectedCurrencyId, this.currentUser.timezoneId).then(data => {
                        let opt = JSON.parse(JSON.stringify(this.state.budgetChartOptions));
                        let ser = [...this.state.budgetChartSeries];
                        ser.find(x => x.name == 'Spent').data = [];
                        ser.find(x => x.name == 'Budget').data = [];
                        opt.xaxis.categories = [];
                        opt.yaxis.labels = {
                            show: true,
                            formatter: (value) => {
                                if (!isNaN(value)) {
                                    let symbol = document.getElementById("hiddenCurrencySymbolBudget").innerText;
                                    return currencyFormatter(symbol, value);
                                }
                                return value;
                            }
                        }
                        opt.tooltip.y = {
                            formatter: (val, otherData) => {
                                if (val !== undefined)
                                    return currencyFormatter(otherData.w.globals.dom.baseEl.parentElement.children[1].innerText, val);
                                return `$${val}`;
                            },
                            title: {
                                formatter: function (seriesName) {
                                    return `${seriesName}: `
                                }
                            }
                        }
                        opt.xaxis.categories = [];

                        data.forEach(element => {
                            opt.xaxis.categories.push(`${this.monthNames[new Date(element.startDate).getMonth()]} ${new Date(element.startDate).getFullYear()}`);

                            ser.find(x => x.name == 'Spent').data.push(element.cost);
                            ser.find(x => x.name == 'Budget').data.push(element.budget);
                        });
                        this.setState({ budgetChartOptions: opt, budgetChartSeries: ser, isSpendToBudgetChartLoading: false });
                    })
                } else {
                    this.setState({ isSpendToBudgetChartLoading: false });

                }
            })

        } else {
            this.setState({ isPieChartLoading: true, isBarChartLoading: true, showSpendToBudgetChart: false });
            this.setDates();
        }
    }

    getData = (startDate, endDate) => {
        switch (parseInt(this.state.selectedChartId)) {
            case 1:
                return api.getSpendByLawfirmChartData(this.currentUser.userId, this.currentUser.entityId, startDate, endDate, this.state.selectedCurrencyId, this.currentUser.timezoneId,
                    this.state.selectedGeoCategoryId, this.state.selectedMatterTypeId, this.state.selectedBusinessActivityId);
                break;
            case 2:
                return api.getSpendByMatterChartData(this.currentUser.userId, this.state.partnershipList.filter(x => x.isSelected).map(x => x.partnershipId), this.currentUser.entityId, startDate, endDate,
                    this.state.selectedCurrencyId, this.currentUser.timezoneId, this.state.selectedGeoCategoryId, this.state.selectedMatterTypeId, this.state.selectedBusinessActivityId);
                break;
            case 3:
                return api.getSpendByBillerChartData(this.currentUser.userId, this.state.partnershipList.filter(x => x.isSelected).map(x => x.partnershipId), this.currentUser.entityId, startDate, endDate,
                    this.state.selectedCurrencyId, this.currentUser.timezoneId, this.state.selectedGeoCategoryId, this.state.selectedMatterTypeId, this.state.selectedBusinessActivityId);
                break;
            case 4:
                return api.getSpendByActivityChartData(this.currentUser.userId, this.state.partnershipList.filter(x => x.isSelected).map(x => x.partnershipId), this.currentUser.entityId, startDate, endDate,
                    this.state.selectedCurrencyId, this.currentUser.timezoneId, this.state.selectedGeoCategoryId, this.state.selectedMatterTypeId, this.state.selectedBusinessActivityId);
                break;
            case 5:
                return api.getSpendByClientChartData(this.currentUser.userId, this.currentUser.entityId, startDate, endDate, this.state.selectedCurrencyId, this.currentUser.timezoneId,
                    this.state.selectedGeoCategoryId, this.state.selectedMatterTypeId, this.state.selectedBusinessActivityId);
                break;
                break;
        }
    }

    populatePieChart = (data) => {
        let options = {
            chart: {
                id: 'pie'
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: (val, otherData) => {

                        if (val !== undefined)
                            return currencyFormatter(otherData.globals.dom.baseEl.parentElement.children[1].innerText, val);
                        return `$${val}`;
                    },
                    title: {
                        formatter: function (seriesName) {
                            return `${seriesName}: `
                        }
                    }
                }
            },
            labels: [],
            responsive: [{
                breakpoint: 600,
                options: {
                    chart: {
                        width: 350
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        };
        let series = [];
        let other = {
            label: 'Other',
            percentage: 0
        };
        let total = data.reduce((acc, item) => acc + item.cost, 0);
        let otherTotal = 0;
        for (var i = 0; i < data.length; i++) {
            if (i < 10) {
                options.labels.push(data[i].name);
                series.push(data[i].cost);
            } else {
                otherTotal += data[i].cost;
            }
        }
        if (otherTotal > 0) {
            options.labels.push('Other');
            series.push(otherTotal);
        }
        this.setState({
            pieChartOptions: options,
            pieChartSeries: series,
            isPieChartLoading: false
        });
    }

    populateBarChart = (list) => {
        let options = {
            chart: {
                id: 'bar'
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    formatter: (value) => {
                        if (!isNaN(value)) {
                            let symbol = document.getElementById("hiddenCurrencySymbol").innerText;
                            return currencyFormatter(symbol, value);
                        }
                        return value;
                    }
                }
            },
            yaxis: {
                labels: {
                    show: true,
                    align: 'left',
                    minWidth: 300,
                    maxWidth: 300,
                    style: {
                        color: undefined,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        cssClass: 'apexcharts-yaxis-label',
                    },
                    offsetX: 0,
                    offsetY: 0,
                    rotate: 0,
                    formatter: (value) => {
                        return value;
                    },
                }
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: (val, otherData) => {
                        if (val !== undefined)
                            return currencyFormatter(otherData.w.globals.dom.baseEl.parentElement.children[1].innerText, val);
                        return `$${val}`;
                    },
                    title: {
                        formatter: function (seriesName) {
                            return `${seriesName}: `
                        }
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 600,
                    options: {
                        chart: {
                            width: 350
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false
                            }
                        },
                        legend: {
                            position: "bottom"
                        },
                        yaxis: {
                            labels: {
                                show: false,
                                // align: 'left',
                                // minWidth: 300,
                                // maxWidth: 300,
                                // style: {
                                //     color: undefined,
                                //     fontSize: '12px',
                                //     fontFamily: 'Helvetica, Arial, sans-serif',
                                //     cssClass: 'apexcharts-yaxis-label',
                                // },
                                // offsetX: 0,
                                // offsetY: 0,
                                // rotate: 0,
                                // formatter: (value) => {
                                //     return value;
                                // },
                            }
                        },
                    }
                }
            ]
        };
        let series = [];
        let data = { name: 'Spend', data: [] };

        list.map(x => {
            options.xaxis.categories.push(x.name);
            //series.push({ name: x.name, data: [x.cost] });
            data.data.push(x.cost);
        });
        series.push(data);
        this.setState({
            barChartOptions: options,
            barChartSeries: series,
            isBarChartLoading: false,
            barChartHeight: data.data.length * 30 < 600 ? 600 : data.data.length * 30
        });
    }

    setDates = () => {
        let dates = this.getDates();
        this.getData(dates.startDate, dates.endDate).then(data => {
            this.populatePieChart(data);
            this.populateBarChart(data);
            let chartsTitle = this.state.chartTypes.find(x => x.id == this.state.selectedChartId).name
            this.setState({
                pieChartTitle: `Top 10 ${chartsTitle}`,
                barChartTitle: chartsTitle,
                selectedDatesInterval: `(${dates.startDate} - ${dates.endDate})`
            });
        });
    }


    getDates = () => {
        let startDate, endDate;
        let userNow = moment.tz('UTC').tz(this.currentUser.timezoneIanaName);
        switch (parseInt(this.state.selectedDateRange)) {
            case 0: //from today
                startDate = userNow.format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 1: //from yesterday
                startDate = userNow.clone().subtract(1, 'days').format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 2: //from last 7 days
                startDate = userNow.clone().subtract(7, 'days').format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 3: //from last 30 days
                startDate = userNow.clone().subtract(30, 'days').format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 4: //this month
                startDate = userNow.clone().startOf('month').format("YYYY-MM-DD");
                endDate = userNow.clone().endOf('month').format("YYYY-MM-DD");
                break;
            case 5: //last month
                startDate = userNow.clone().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                endDate = userNow.clone().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                break;
            case 6: //current quarter
                startDate = moment().quarter(moment().quarter()).startOf('quarter').format("YYYY-MM-DD");
                endDate = moment().quarter(moment().quarter()).endOf('quarter').format("YYYY-MM-DD");
                break;
            case 7: //current year
                startDate = moment().startOf('year').format("YYYY-MM-DD");
                endDate = moment().endOf('year').format("YYYY-MM-DD");
                break;
            case 8: //custom
                startDate = moment(this.state.startDate).format("YYYY-MM-DD");
                endDate = moment(this.state.endDate).format("YYYY-MM-DD");
                break;
        }
        return { startDate, endDate };
    }

    showModal(e) {
        e && e.preventDefault();
        this.setState({ showModal: !this.state.showModal, chartHistory: { name: '' } });
    }

    handleSubmit() {
        this.formRef.current.submitForm();
    }

    render() {
        return (
            <Fragment>
                <Row>
                    <Col md="12">
                        <Card className='mb-3'>
                            <CardBody>
                                {/* <Row>
                                    <div className='col-md-12'>
                                        <Button className="pull-right btn-sm" style={{ backgroundColor: 'green', background: 'green' }}
                                            onClick={() => this.generateChart()}>Save criteria</Button>
                                    </div>
                                </Row> */}
                                <Row>

                                    <div className='form-group col-md-4'>
                                        <label>Chart</label>

                                        <CustomInput type="select" id="chartSelect" value={this.state.selectedChartId}
                                            name="chartSelect" onChange={(e) => { this.setState({ selectedChartId: parseInt(e.target.value) }) }}>
                                            {this.state.chartTypes.map(c => {
                                                return <option key={c.id} value={c.id}>{c.name}</option>
                                            })}
                                        </CustomInput>
                                    </div>
                                    {(this.state.selectedChartId !== 1 && this.state.selectedChartId !== 5 && this.state.selectedChartId !== 6) &&
                                        <div className='form-group col-md-4'>
                                            <Card style={{ width: '100%' }}>
                                                <CardBody style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                    <table className="table-responsive" style={{ width: '100%' }}>
                                                        <thead>
                                                            <tr>
                                                                <th width="10%">Select</th>
                                                                <th width="90%" >{this.currentUser.entityRoleId == 1 ? 'Law Firm' : 'Client'} (s) </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <CustomInput type="checkbox" id={'selectAll0'} style={{ marginLeft: '3px', cursor: 'pointer' }}
                                                                        value={this.state.allPartnersSelected}
                                                                        checked={this.state.allPartnersSelected}
                                                                        onChange={() => {
                                                                            let partnershipList = [...this.state.partnershipList];
                                                                            this.setState({ allPartnersSelected: !this.state.allPartnersSelected, partnershipList: partnershipList.map(x => { x.isSelected = !this.state.allPartnersSelected; return x; }) });
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        let partnershipList = [...this.state.partnershipList];
                                                                        this.setState({ allPartnersSelected: !this.state.allPartnersSelected, partnershipList: partnershipList.map(x => { x.isSelected = !this.state.allPartnersSelected; return x; }) });
                                                                    }}
                                                                >Select all</td>
                                                            </tr>
                                                            {this.state.partnershipList.map(e => {
                                                                return (
                                                                    <tr key={e.partnershipId} style={{ cursor: 'pointer' }} >
                                                                        <td>
                                                                            <CustomInput type="checkbox" id={e.partnershipId} style={{ cursor: 'pointer' }}
                                                                                value={e.isSelected} checked={e.isSelected}
                                                                                onChange={(event) => {
                                                                                    let partnershipList = [...this.state.partnershipList];
                                                                                    let partner = partnershipList.find(x => x.partnershipId == e.partnershipId);
                                                                                    partner.isSelected = !partner.isSelected;
                                                                                    let index = partnershipList.findIndex(x => x.partnershipId == partner.partnershipId);
                                                                                    partnershipList[index] = partner;
                                                                                    this.setState({ partnershipList: partnershipList });
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td style={{ cursor: 'pointer' }}
                                                                            onClick={(event) => {
                                                                                let partnershipList = [...this.state.partnershipList];
                                                                                let partner = partnershipList.find(x => x.partnershipId == e.partnershipId);
                                                                                partner.isSelected = !partner.isSelected;
                                                                                let index = partnershipList.findIndex(x => x.partnershipId == partner.partnershipId);
                                                                                partnershipList[index] = partner;
                                                                                this.setState({ partnershipList: partnershipList });
                                                                            }}
                                                                        >{e.partnershipName}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    }

                                    <div className='form-group col-md-4'>
                                        <label htmlFor="currency">Currency</label>
                                        <CustomInput type="select" id="currencySelect" name="currencySelect" className='form-control'
                                            onChange={(e) => { this.setState({ selectedCurrencyId: e.target.value, selectedCurrencySymbol: this.state.currencyList.find(x => x.currencyId == e.target.value).symbol }) }}
                                            value={this.state.selectedCurrencyId}>
                                            {this.state.currencyList.map(currency => {
                                                return <option key={currency.currencyId} value={currency.currencyId}>{currency.displayValue}</option>
                                            })}
                                        </CustomInput>
                                    </div>
                                    {this.state.selectedChartId !== 6 &&
                                        <Fragment>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="geoCategoryId">Geographic Location</label>
                                                <CustomInput type="select" id="geoCategoryId"
                                                    name="geoCategoryId" value={this.state.selectedGeoCategoryId}
                                                    onChange={(e) => this.setState({ selectedGeoCategoryId: e.target.value })}>
                                                    <option key={-1} value={-1}>All Geographic Locations</option>
                                                    {this.state.geoCategoryList.map(gc => {
                                                        if (gc.isDeleted == false)
                                                            return <option key={gc.id} value={gc.id}>{gc.name}</option>
                                                    })}
                                                </CustomInput>
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label htmlFor="matterTypeId">Matter Types</label>
                                                <CustomInput type="select" id="matterTypeId"
                                                    name="matterTypeId" value={this.state.selectedMatterTypeId}
                                                    onChange={(e) => this.setState({ selectedMatterTypeId: e.target.value })}>
                                                    <option key={-1} value={-1}>All Matter Types</option>
                                                    {this.state.matterTypeList.map(gc => {
                                                        if (gc.isDeleted == false)
                                                            return <option key={gc.id} value={gc.id}>{gc.name}</option>
                                                    })}
                                                </CustomInput>
                                            </div>
                                        </Fragment>
                                    }
                                    {/* <div className="form-group col-md-6">
                                        <Row>
                                            <Col md='3'>
                                                <label htmlFor="businessActivityId">Business Type</label>
                                            </Col>
                                            <Col md='9'>
                                                <CustomInput type="select" id="businessActivityId"
                                                    name="businessActivityId" value={this.state.selectedBusinessActivityId}
                                                    onChange={(e) => this.setState({ selectedBusinessActivityId: e.target.value })}>
                                                    <option key={-1} value={-1}>All Business Types</option>
                                                    {this.state.businessTypeList.map(gc => {
                                                        if (gc.isDeleted == false)
                                                            return <option key={gc.id} value={gc.id}>{gc.name}</option>
                                                    })}
                                                </CustomInput>
                                            </Col>
                                        </Row>
                                    </div> */}

                                    {this.state.selectedChartId == 6 &&

                                        <div className='form-group col-md-4'>
                                            <label htmlFor="matter">Matter</label>
                                            <CustomInput type="select" id="matterSelect" name="matter" className='form-control'
                                                onChange={(e) => { this.setState({ matterId: parseInt(e.target.value) }) }}
                                                value={this.state.matterId}>
                                                {this.state.matterList.map(m => {
                                                    return <option key={m.matterId} value={m.matterId}>{m.matterName}</option>
                                                })}
                                            </CustomInput>
                                        </div>
                                    }

                                    <div className="form-group col-md-4">
                                        <label htmlFor="dateRange">Date Range</label>
                                        <CustomInput type="select" id="dateRange"
                                            name="dateRange" value={this.state.selectedDateRange}
                                            onChange={(e) => this.setState({ selectedDateRange: e.target.value })}>
                                            <option key={`dr-${0}`} value={0}>Today</option>
                                            <option key={`dr-${1}`} value={1}>Yesterday</option>
                                            <option key={`dr-${2}`} value={2}>Last 7 Days</option>
                                            <option key={`dr-${3}`} value={3}>Last 30 Days</option>
                                            <option key={`dr-${4}`} value={4}>This Month</option>
                                            <option key={`dr-${5}`} value={5}>Last Month</option>
                                            <option key={`dr-${6}`} value={6}>Quarter to Date</option>
                                            <option key={`dr-${7}`} value={7}>Year to Date</option>
                                            <option key={`dr-${8}`} value={8}>Custom Range</option>
                                        </CustomInput>
                                        {this.state.selectedDateRange == 8 &&
                                            <Row>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="startDate">Start Date</label>
                                                    {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <DatePicker value={this.state.startDate}
                                                            format="MM/DD/YYYY"
                                                            disableFuture
                                                            onChange={(date) => this.setState({ startDate: date })}
                                                            InputProps={{ className: 'form-control' }}
                                                        />
                                                    </MuiPickersUtilsProvider> */}
                                                    <DatePicker className="form-control"
                                                        selected={this.state.startDate}
                                                        onChange={(date) => this.setState({ startDate: date })}
                                                        placeholderText="Start date..."
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="endDate">End Date</label>
                                                    <DatePicker className="form-control"
                                                        selected={this.state.endDate}
                                                        onChange={(date) => this.setState({ endDate: date })}
                                                        placeholderText="Start date..."
                                                    />
                                                </div>
                                            </Row>
                                        }
                                    </div>

                                    <div className="form-group col-md-4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <Button color="primary" onClick={() => this.generateChart()}>Generate Chart</Button>
                                    </div>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row >

                <Row>
                    {!this.state.showSpendToBudgetChart &&
                        <Col md="12">
                            <Card className="mb-3">
                                <CardBody>
                                    <CardTitle>
                                        {this.state.pieChartTitle}           {this.state.selectedDatesInterval}
                                    </CardTitle>
                                    <div style={{ display: 'none' }}>{this.state.selectedCurrencySymbol}</div>
                                    {this.state.isPieChartLoading && <Loader style={{}} type="ball-pulse" active />}
                                    {!this.state.isPieChartLoading && this.state.pieChartSeries.length > 0 &&
                                        <Chart
                                            options={this.state.pieChartOptions}
                                            series={this.state.pieChartSeries}
                                            type="pie" width="70%" />
                                    }
                                    {!this.state.isPieChartLoading && this.state.pieChartSeries.length == 0 &&
                                        "No data for the selected filters"
                                    }
                                </CardBody>
                            </Card>
                            <Card className="mb-3">
                                <CardBody>
                                    <CardTitle>
                                        {this.state.barChartTitle}           {this.state.selectedDatesInterval}
                                    </CardTitle>
                                    <div id="hiddenCurrencySymbol" style={{ display: 'none' }}>{this.state.selectedCurrencySymbol}</div>
                                    {this.state.isBarChartLoading && <Loader style={{}} type="ball-pulse" active />}
                                    {!this.state.isBarChartLoading && this.state.barChartSeries[0].data.length > 0 &&
                                        <Chart
                                            options={this.state.barChartOptions}
                                            series={this.state.barChartSeries}
                                            type="bar" width="80%"
                                            height={this.state.barChartHeight} />
                                    }
                                    {!this.state.isBarChartLoading && this.state.barChartSeries[0].data.length == 0 &&
                                        "No data for the selected filters"
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    }
                    {this.state.showSpendToBudgetChart &&
                        <Col md='12'>
                            <Card className="mb-3">
                                <CardBody>
                                    <CardTitle>
                                        Spend to Budget - {this.state.matterList.find(x => x.matterId == this.state.matterId) !== undefined && this.state.matterList.find(x => x.matterId == this.state.matterId).matterName}
                                    </CardTitle>
                                    <div id="hiddenCurrencySymbolBudget" style={{ display: 'none' }}>{this.state.selectedCurrencySymbol}</div>
                                    {this.state.isSpendToBudgetChartLoading && <Loader style={{}} type="ball-pulse" active />}
                                    {!this.state.isSpendToBudgetChartLoading &&
                                        <Fragment>
                                            {this.state.budgetChartOptions.xaxis.categories.length > 0 &&
                                                <Chart
                                                    options={this.state.budgetChartOptions}
                                                    series={this.state.budgetChartSeries}
                                                    type="bar" width="80%"
                                                    height='350'
                                                />
                                            }
                                            {this.state.budgetChartOptions.xaxis.categories.length == 0 &&
                                                'No data to display'
                                            }
                                        </Fragment>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    }
                </Row>

                {/* <Modal isOpen={this.state.showModal} toggle={() => this.showModal()} className={this.props.className}>
                    <ModalHeader toggle={() => this.showModal()}>Chart History</ModalHeader>
                    <ModalBody>
                        <Formik ref={this.formRef}
                            initialValues={{ name: this.state.chartHistory.name }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Name is required')
                            })}
                            onSubmit={({ name }, { setStatus, setSubmitting }) => {
                                setStatus();
                                this.setState({
                                    chartHistory: {
                                        name: name
                                    }
                                }, () => this.saveChartHistory());

                            }}
                            render={({ errors, status, touched, isSubmitting, submitForm }) => {

                                return (
                                    <Form >
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>

                                        <div className="form-footer">
                                            <Button color="link" onClick={() => this.showModal()}>Cancel</Button>
                                            <Button color="primary" onClick={() => this.handleSubmit()} style={{ marginLeft: '.25rem' }}>Save</Button>{' '}
                                        </div>

                                        {status &&
                                            <div className={'alert alert-danger'}>{status}</div>
                                        }
                                    </Form>
                                );
                            }}
                        />

                    </ModalBody>
                </Modal> */}

            </Fragment >
        );
    }
}

export default ChartList;