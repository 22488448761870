import React, { Fragment, useState, useEffect } from "react";
import { api } from "../_services";
import { GeneralForm } from "./GeneralForm";
import swal from "sweetalert";
import { userService } from "../_services/user.service";
import { toast } from "react-toastify";

const UserForm = ({
  user,
  roleList,
  timezoneList,
  currencyList,
  userTypeList,
  entityList,
  closeModal,
  reloadUsers,
  setUserId,
}) => {
  const currentUser = userService.getLoggedInUser();
  let [userToEdit, setUserToEdit] = useState({});
  let [propTypes, setPropTypes] = useState([]);

  const successToastOpts = {
    autoClose: 3000,
    type: toast.TYPE.SUCCESS,
    hideProgressBar: false,
  };
  const errorToastOpts = {
    autoClose: 3000,
    type: toast.TYPE.ERROR,
    hideProgressBar: false,
  };

  useEffect(() => {
    if (user.userId <= 0) {
      user.sendInvitation = true;
    }

    if (
      user.secondaryEmails != undefined &&
      user.secondaryEmails !== null &&
      user.secondaryEmails !== ""
    ) {
      let emails = user.secondaryEmails.split(",");
      emails.forEach((e, i) => {
        user[`secondaryEmail${i + 1}`] = e;
      });
    }

    setUserToEdit(user);

    setPropTypes([
      {
        type: "input",
        property: "name",
        label: "Name *",
        width: 12,
        isRequired: true,
      },
      {
        type: "input",
        property: "initials",
        fieldType: "text",
        label: "Initials",
        isRequired: false,
      },
      {
        type: "input",
        fieldType: "email",
        property: "email",
        label: "Email *",
        isRequired: true,
      },
      {
        type: "input",
        fieldType: "email",
        property: "secondaryEmail1",
        label: "Secondary Email 1",
        isRequired: false,
        isVisibleFunc: (obj) => {
          return (
            obj.secondaryEmail1 !== undefined && obj.secondaryEmail1 !== ""
          );
        },
      },
      {
        type: "input",
        fieldType: "email",
        property: "secondaryEmail2",
        label: "Secondary Email 2",
        isRequired: false,
        isVisibleFunc: (obj) => {
          return (
            obj.secondaryEmail2 !== undefined && obj.secondaryEmail2 !== ""
          );
        },
      },
      {
        type: "input",
        fieldType: "email",
        property: "secondaryEmail3",
        label: "Secondary Email 3",
        isRequired: false,
        isVisibleFunc: (obj) => {
          return (
            obj.secondaryEmail3 !== undefined && obj.secondaryEmail3 !== ""
          );
        },
      },
      {
        type: "dropdown",
        property: "systemRoleId",
        label: "System Role *",
        dropdownData: roleList.map((x) => {
          x.id = x.systemRoleId;
          x.name = x.systemRole;
          return x;
        }),
        positioning: "left",
        onChange: (event) => {
          if (
            currentUser.entityRoleId == 2 &&
            parseInt(event.target.value) == 2
          ) {
            swal(
              "If you are making someone an Administrator, please ensure they were given  viewer or biller rights at the Client level below for the clients they will Administer",
              {
                buttons: {
                  ok: {
                    text: "Ok",
                    value: "ok",
                    className: "btn-success",
                  },
                },
                icon: "warning",
              }
            );
          }
        },
      },
      {
        type: "dropdown",
        property: "canManageMatters",
        label: "User can Manage Matters (create and update) *",
        dropdownData: [
          {
            id: true,
            name: "Yes",
          },
          {
            id: false,
            name: "No",
          },
        ],
        positioning: "right",
      },
      {
        type: "dropdown",
        property: "entityId",
        label: "Company *",
        dropdownData: entityList.map((x) => {
          x.id = x.entityId;
          x.name = x.name;
          return x;
        }),
        positioning: "left",
      },
      {
        type: "dropdown",
        property: "currencyId",
        label: "Default Currency *",
        dropdownData: currencyList.map((x) => {
          x.id = x.currencyId;
          x.name = x.displayValue;
          return x;
        }),
        positioning: "right",
      },
      {
        type: "dropdown",
        property: "timezoneId",
        label: "Default Timezone *",
        dropdownData: timezoneList.map((x) => {
          x.id = x.timezoneId;
          x.name = x.winDisplayName;
          return x;
        }),
        positioning: "left",
      },
      {
        type: "input",
        property: "postal",
        label: "Zip / Postal code",
        positioning: "right",
      },
      {
        type: "dropdown",
        property: "userTypeId",
        label: "Biller Type",
        dropdownData: userTypeList,
        positioning: "left",
        isVisibleFunc: (obj) => {
          if (
            obj.entityId !== undefined &&
            entityList.find((x) => x.entityId == obj.entityId).entityRoleId == 2
          )
            return true;
          return false;
        },
      },
      {
        type: "dropdown",
        property: "encryptNotes",
        label: "Encrypt Notes",
        dropdownData: [{ id: false, name: "No" }, { id: true, name: "Yes" }],
        positioning: "right",
        isVisibleFunc: (obj) => {
          return currentUser.entityRoleId == 2;
        },
      },
      {
        type: "checkbox",
        property: "sendInvitation",
        label: "Send Invitation Email",
        positioning: "left",
        isVisibleFunc: (obj) => {
          return obj.userId == -1;
        },
      },
    ]);
  }, [user]);

  const updateUser = (user) => {
    api.updateUser(user).then((msg) => {
      if (msg !== "") {
        swal(msg, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
      } else {
        toast("User saved successfuly.", successToastOpts);
        reloadUsers();
      }
    });
  };
  const createUser = (user) => {
    api.createUser(user).then((msg) => {
      if (msg !== "") {
        swal(msg, {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        });
      } else {
        let txt = `User saved successfuly${
          user.sendInvitation ? ", an invitation email has been sent." : ""
        }`;
        toast(txt, successToastOpts);
        api.getUserByEmail(user.email).then((data) => {
          setUserId(data.userId);
          reloadUsers();
        });
      }
    });
  };

  const saveUser = (user) => {
    if (user.initials && user.initials.length > 10) {
      toast("User initials can not be longer than 10 letters.", errorToastOpts);
    } else {
      var secondaryEmails = [];
      for (var i = 1; i <= 3; i++) {
        let email = user[`secondaryEmail${i}`];
        if (email !== undefined && email !== null && email !== "") {
          secondaryEmails.push(email);
        }
      }

      if (secondaryEmails.length > 0) {
        user.secondaryEmails = secondaryEmails.join(",");
      } else {
        user.secondaryEmails = "";
      }

      if (
        entityList.find((x) => x.entityId == user.entityId).entityRoleId !== 2
      ) {
        user.userTypeId = -1;
      }

      if (user.userId == -1) {
        createUser(user);
      } else {
        updateUser(user);
      }
    }
  };

  const showAnotherSecondaryEmail = () => {
    let props = [...propTypes];
    let secondaryEmails = props.filter(function(el) {
      return el.property.indexOf("secondaryEmail") > -1;
    });
    for (var i = 0; i < secondaryEmails.length; i++) {
      if (!secondaryEmails[i].isVisibleFunc(userToEdit)) {
        secondaryEmails[i].isVisibleFunc = (user) => true;
        break;
      }
    }
    setPropTypes(props);
  };

  return (
    <GeneralForm
      data={userToEdit}
      propertyTypes={propTypes}
      onCancel={closeModal}
      onDelete={userToEdit.userId == -1 ? undefined : saveUser}
      onSave={saveUser}
      showSave={true}
      showAnotherSecondaryEmail={() => showAnotherSecondaryEmail()}
    />
  );
};

export default UserForm;
