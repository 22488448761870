import React, { Fragment, useState, useEffect } from 'react';
import GeneralGrid from '../../../../Components/GeneralGrid';
import {
    Button, Modal, ModalHeader, Card, CardBody,
    ModalBody, Row, Col, CustomInput, CardTitle
} from 'reactstrap';
import Loader from 'react-loaders';
import { currencyFormatter } from '../../../../_services/currencyFormatter';

const SelectActivityCodesModal = ({ isLoading, showModal, toggleModal, matter, activityCodeList, packageList, selectedPackageId, onPackageSelectionChange, toggleEditActivityCodeModal }) => {

    const [packageId, setPackageId] = useState(0);

    useEffect(() => {
        setPackageId(selectedPackageId);
    }, [selectedPackageId]);

    const onPackageChange = (e) => {
        setPackageId(e.target.value);
        onPackageSelectionChange(e);
    }

    return (
        <Modal size="md" isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>Select Activity Codes</ModalHeader>
            <ModalBody>
                <Row style={{ marginBottom: '10px' }}>
                    <Col md="12">
                        {packageList.length > 0 &&
                            <CustomInput type="select" id="packageSelect" value={packageId}
                                name="packageSelect" onChange={onPackageChange}>
                                <option key={0} value={0}>Show All</option>
                                {packageList.map(p => {
                                    return <option key={p.packageId} value={p.packageId}>{p.name}</option>
                                })}
                            </CustomInput>}
                    </Col>
                </Row>
                {isLoading && <Loader style={{}} type="ball-pulse" active />}
                {!isLoading &&
                    <Row>
                        <GeneralGrid colWidth={12}
                            title=""
                            keyField="activityCodeId"
                            defaultSortedColumn="title"
                            dataSource={activityCodeList}
                            columns={[
                                {
                                    dataField: 'selected',
                                    text: 'Selected',
                                    sort: true,
                                    headerStyle: () => {
                                        return { width: "20%" };
                                    },
                                    formatter: (cellContent, row) => {
                                        return (
                                            <CustomInput readOnly={true} type="checkbox" id={row.activityCodeId} checked={row.selected} />
                                        );
                                    }
                                },
                                {
                                    dataField: 'title',
                                    text: 'Activity Code',
                                    sort: true,
                                    headerStyle: () => {
                                        return { width: "80%" };
                                    },
                                    formatter: (cellContent, row) => {
                                        return (
                                            matter.matterId > -1 ?
                                                <a key={row.matterActivityCodeId}
                                                    href="#"
                                                    onClick={(e) => { e.preventDefault(); toggleEditActivityCodeModal(row, packageId); }}>
                                                    {cellContent} {(row.fixedPrice !== null && row.fixedPrice > 0) ? `(${currencyFormatter(row.currencySymbol, row.fixedPrice)}) Fixed` : ''}
                                                </a>
                                                : cellContent

                                        );
                                    }
                                }
                            ]}
                        />
                    </Row>
                }
                <Row>
                    <Col md="12">
                        <Button color="link" onClick={() => toggleModal()}>Cancel</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default SelectActivityCodesModal;