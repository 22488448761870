import React, { Fragment } from 'react';
import {
    Button, Container, Modal, ModalHeader, Card, CardBody,
    ModalBody, ModalFooter, Row, Col, CustomInput, UncontrolledAlert
} from 'reactstrap';
import { Table } from 'reactstrap';
import Loader from 'react-loaders'
import { userService } from '../../_services/user.service';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/fontawesome-free-solid";
import * as Yup from 'yup';
import 'react-dropdown/style.css';
import { api } from '../../_services/api.service';
import PageTitle from '../../Layout/AppMain/PageTitle';

class ManageLists extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            submitFunction: null,
            isLoading: false,
            selectedCategory: {
                id: 1,
                displayName: 'Geo Category',
                apiCategoryRouteName: 'GeoCategory'
            },
            itemToEdit: {
                id: -1,
                name: '',
                isDeleted: false,
                entityId: -1
            },
            items: []
        };
        this.newItemToEdit = {
            id: -1,
            name: '',
            isDeleted: false,
            entityId: -1
        };
        this.categories = [
            {
                id: 1,
                displayName: 'Geo Category',
                apiCategoryRouteName: 'GeoCategory'
            },
            {
                id: 2,
                displayName: 'Matter Type',
                apiCategoryRouteName: 'MatterType'
            },
            // {
            //     id: 3,
            //     displayName: 'Business Activity',
            //     apiCategoryRouteName: 'BusinessActivity'
            // }
        ];
        this.currentUser = userService.getLoggedInUser();
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.loadItems(this.state.selectedCategory.apiCategoryRouteName, this.currentUser.entityId);
    }

    loadItems(apiCategoryRouteName, entityId) {
        api.getItemListByEntityId(apiCategoryRouteName, entityId).then(list => {
            this.setState({ items: list, isLoading: false });
        })
    }

    showModal(itemToEdit, e) {
        e && e.preventDefault();
        this.setState({ showModal: !this.state.showModal, itemToEdit: { id: itemToEdit.id, name: itemToEdit.name, isDeleted: itemToEdit.isDeleted, entityId: itemToEdit.entityId } });
    }

    onInputChange(text) {
        this.setState({
            itemToEdit: {
                id: this.state.itemToEdit.id,
                name: text,
                isDeleted: this.state.itemToEdit.isDeleted,
                entityId: this.state.itemToEdit.entityId
            }
        });
    }

    onCategorySelectionChange(e) {
        let selectedId = e.target.value;
        let selectedCategory = this.categories.filter(cat => {
            return cat.id == parseInt(selectedId);
        })[0];
        var stateCategory = this.state.selectedCategory;
        stateCategory.id = selectedCategory.id;
        stateCategory.displayName = selectedCategory.displayName;
        stateCategory.apiCategoryRouteName = selectedCategory.apiCategoryRouteName;
        this.setState({ stateCategory });
        this.loadItems(selectedCategory.apiCategoryRouteName, this.currentUser.entityId);
    }

    save() {
        if (this.state.itemToEdit.id != -1) {
            this.updateItem();
        } else {
            this.createItem();
        }
    }

    createItem() {
        api.createItem(this.state.selectedCategory.apiCategoryRouteName, this.currentUser.entityId, this.state.itemToEdit.name).then(ret => {
            this.showModal(this.newItemToEdit);
            this.loadItems(this.state.selectedCategory.apiCategoryRouteName, this.currentUser.entityId);
        });
    }

    updateItem() {
        api.updateItem(this.state.selectedCategory.apiCategoryRouteName, this.state.itemToEdit).then(ret => {
            this.showModal(this.newItemToEdit);
            this.loadItems(this.state.selectedCategory.apiCategoryRouteName, this.currentUser.entityId);
        })
    }

    changeDeletion() {
        this.setState({
            itemToEdit: {
                id: this.state.itemToEdit.id,
                name: this.state.itemToEdit.name,
                isDeleted: !this.state.itemToEdit.isDeleted,
                entityId: this.state.itemToEdit.entityId
            }
        }, () => {
            this.updateItem();
        });
    }

    handleSubmit() {
        this.formRef.current.submitForm();
    }



    render() {

        return (
            <Fragment>
                <PageTitle
                    heading="Manage Lists"
                />
                {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
                {!this.state.isLoading &&
                    <Container fluid>
                        <Row>
                            <Col md='12'>
                                <UncontrolledAlert color="warning">
                                    <h4><FontAwesomeIcon icon={Icons.faInfo} /> Alert</h4>
                                    This is an advanced administration area. Please contact LeGuard if you have any questions using this feature.
                                    </UncontrolledAlert>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button className="mb-2 mr-2" color="primary" onClick={() => this.showModal(this.newItemToEdit)}>Create New Item</Button>
                            </Col>
                            <Col md="12">
                                <CustomInput type="select" id="categorySelect"
                                    name="categorySelect" onChange={(e) => this.onCategorySelectionChange(e)}>
                                    {this.categories.map(cat => {
                                        return <option key={cat.id} value={cat.id}>{cat.displayName}</option>
                                    })}
                                </CustomInput>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '30px' }}>

                            {this.state.items && <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th width='50%'>Item</th>
                                                    <th>Active</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {this.state.items.length > 0 && this.state.items.map(p => {
                                                    return (
                                                        <tr key={p.id}>
                                                            <td><a href='#' onClick={(e) => this.showModal(p, e)}>{p.name}</a></td>
                                                            <td>
                                                                <CustomInput type="checkbox" id={p.id} checked={!p.isDeleted} readOnly={true} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                {this.state.items.length == 0 &&
                                                    <tr>
                                                        <td>No items to display</td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>}
                        </Row>

                    </Container>
                }

                <Modal isOpen={this.state.showModal} toggle={() => this.showModal(this.newItemToEdit)} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={() => this.showModal(this.newItemToEdit)}>{this.state.selectedCategory.displayName}</ModalHeader>
                    <ModalBody>
                        <Formik ref={this.formRef}
                            initialValues={{ name: this.state.itemToEdit.name }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Name is required')
                            })}
                            onSubmit={({ name }, { setStatus, setSubmitting }) => {
                                setStatus();
                                this.setState({
                                    itemToEdit: {
                                        id: this.state.itemToEdit.id,
                                        name: name,
                                        isDeleted: this.state.itemToEdit.isDeleted,
                                        entityId: this.state.itemToEdit.entityId
                                    }
                                }, () => this.save());

                            }}
                            render={({ errors, status, touched, isSubmitting, submitForm }) => {

                                return (
                                    <Form >
                                        <div className="form-group">
                                            <label htmlFor="name">Name *</label>
                                            <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>

                                        <div className="form-footer">
                                            <Button color="link" onClick={() => this.showModal(this.newItemToEdit)}>Cancel</Button>
                                            {this.state.itemToEdit.id > -1 &&
                                                <Button color={this.state.itemToEdit.isDeleted ? 'success' : 'danger'} style={{ marginLeft: '.25rem' }} className="pull-right"
                                                    onClick={() => this.changeDeletion()}>{this.state.itemToEdit.isDeleted ? 'Activate' : 'Inactivate'}</Button>
                                            }
                                            <Button color="primary" onClick={() => this.handleSubmit()} style={{ marginLeft: '.25rem' }}>Save</Button>{' '}
                                        </div>

                                        {status &&
                                            <div className={'alert alert-danger'}>{status}</div>
                                        }
                                    </Form>
                                );
                            }}
                        />

                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="link" onClick={() => this.showModal(this.newItemToEdit)}>Cancel</Button>
                        <Button color={!this.state.itemToEdit.isDeleted ? 'success' : 'danger'} onClick={() => this.changeDeletion()}>{!this.state.itemToEdit.isDeleted ? 'Activate' : 'Inactivate'}</Button>
                        <Button color="primary" onClick={() => this.handleSubmit()}>Save</Button>{' '}
                    </ModalFooter> */}
                </Modal>
            </Fragment>
        )
    }
}

export default ManageLists;



