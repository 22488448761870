import React, { Fragment, useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useResizeDetector } from 'react-resize-detector';

import App from '../App';

const Main = () => {
  const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
  const colorScheme = useSelector((state) => state.ThemeOptions.colorScheme);
  const enableFixedHeader = useSelector((state) => state.ThemeOptions.enableFixedHeader);
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);
  const enableFixedFooter = useSelector((state) => state.ThemeOptions.enableFixedFooter);
  const enableFixedSidebar = useSelector((state) => state.ThemeOptions.enableFixedSidebar);
  const enableClosedSidebar = useSelector((state) => state.ThemeOptions.enableClosedSidebar);
  const enablePageTabsAlt = useSelector((state) => state.ThemeOptions.enablePageTabsAlt);
  const { width } = useResizeDetector();

  return (
    <Fragment>
      <link rel="apple-touch-icon-precomposed" sizes="57x57" href="assets/utils/images/icons/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon-precomposed" sizes="114x114" href="assets/utils/images/icons/apple-touch-icon-114x114.png" />
      <link rel="apple-touch-icon-precomposed" sizes="72x72" href="assets/utils/images/icons/apple-touch-icon-72x72.png" />
      <link rel="apple-touch-icon-precomposed" sizes="144x144" href="assets/utils/images/icons/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon-precomposed" sizes="60x60" href="assets/utils/images/icons/apple-touch-icon-60x60.png" />
      <link rel="apple-touch-icon-precomposed" sizes="120x120" href="assets/utils/images/icons/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon-precomposed" sizes="76x76" href="assets/utils/images/icons/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon-precomposed" sizes="152x152" href="assets/utils/images/icons/apple-touch-icon-152x152.png" />

      <div className={cx(
        "app-container app-theme-" + colorScheme,
        { 'fixed-header': enableFixedHeader },
        { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
        { 'fixed-footer': enableFixedFooter },
        { 'closed-sidebar': enableClosedSidebar || width < 1250 },
        { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
        { 'sidebar-mobile-open': enableMobileMenu },
        { 'body-tabs-shadow-btn': enablePageTabsAlt },
      )}>
        <App />
      </div>
    </Fragment>
  )
}

export default withRouter(Main);