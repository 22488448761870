import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../Layout/AppMain/PageTitle";
import {
  Button,
  Col,
  CustomInput,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import GeneralGrid from "../../Components/GeneralGrid";
import Moment from "react-moment";
import { currencyFormatter } from "../../_services/currencyFormatter";
import { api, userService } from "../../_services";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import InvoicePaymentModal from "./Components/InvoicePaymentModal";
import moment from "moment";
import swal from "sweetalert";
import CurrencyInput from "react-currency-input-field";

const PaymentList = (props) => {
  const currentUser = userService.getLoggedInUser();
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [partnershipList, setPartnershipList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceNumberSearch, setInvoiceNumberSearch] = useState("");

  const [paymentToEdit, setPaymentToEdit] = useState(null);
  const [invoicePaymentToEdit, setInvoicePaymentToEdit] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const [showInvoicePaymentModal, setShowInvoicePaymentModal] = useState(false);
  const toggleInvoicePaymentModal = () =>
    setShowInvoicePaymentModal(!showInvoicePaymentModal);

  const [selectedPartnershipIdFilter, setSelectedPartnershipIdFilter] =
    useState(-1);
  const [startDate, setStartDate] = useState(
    moment().add("-30", "day").toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());

  useEffect(() => {
    api
      .getPartnershipListByEntityId(currentUser.entityId, currentUser.userId)
      .then((data) => {
        setPartnershipList(data);
      });
    loadData();
  }, []);

  const loadData = () => {
    api.getPaymentsByBillingEntityId(currentUser.entityId).then((data) => {
      setPayments(data);
    });
  };

  const loadInvoices = async (partnershipId) => {
    let data = await api.getInvoiceListByPartnershipsEntity(
      partnershipId,
      currentUser.entityId,
      currentUser.entityRoleId,
      -1,
      -1,
      null,
      null,
      currentUser.currencyId,
      false
    );
    if (
      invoiceNumberSearch !== undefined &&
      invoiceNumberSearch !== null &&
      invoiceNumberSearch !== ""
    ) {
      let searchedInvoice = await api.getInvoiceByInvoiceNumber(
        invoiceNumberSearch
      );
      if (searchedInvoice !== null) {
        let found = data.find((x) => x.invoiceId == searchedInvoice.invoiceId);
        if (found == undefined) {
          data.push(searchedInvoice);
        }
      }
    }
    setInvoiceList(data);
    return data;
  };

  const addPayment = () => {
    setPaymentToEdit({
      paymentDate: new Date(),
      partnershipId: -1,
      paymentId: -1,
      paymentAmount: 0,
      checkNumber: "",
      paymentType: "",
      invoiceListString: "",
      invoicePayments: [],
    });
    setInvoiceNumberSearch("");
    setShowEditModal(true);
  };

  const editPayment = (payment) => {
    api.getPayment(payment.paymentId).then((data) => {
      setPaymentToEdit({
        ...data,
        paymentDate: moment(data.paymentDate).toDate(),
        invoicePayments: data.invoicePayments.map((ip) => {
          ip.id = generateGuid();
          return ip;
        }),
      });
      loadInvoices(data.partnershipId);
      setInvoiceNumberSearch("");
      setShowEditModal(true);
    });
  };

  const savePayment = () => {
    console.log(paymentToEdit);

    if (paymentToEdit.paymentId == -1) {
      api.createPayment(paymentToEdit).then((id) => {
        let dirtyInvPayments = paymentToEdit.invoicePayments.filter(
          (x) => x.isDirty
        );
        if (dirtyInvPayments.length > 0) {
          let requests = dirtyInvPayments.map((ip) => {
            return api.createInvoicePayment({ ...ip, paymentId: id });
          });
          Promise.all(requests).then((r) => {
            toggleEditModal();
            loadData();
          });
        } else {
          toggleEditModal();
          loadData();
        }
      });
    } else {
      api.updatePayment(paymentToEdit).then(() => {
        let dirtyInvPayments = paymentToEdit.invoicePayments.filter(
          (x) => x.isDirty
        );
        if (dirtyInvPayments.length > 0) {
          let requests = dirtyInvPayments.map((ip) => {
            if (ip.isDeleted) {
              if (ip.invoicePaymentId > -1) {
                return api.deleteInvoicePayment(ip.invoicePaymentId);
              } else {
                return null;
              }
            }
            if (ip.invoicePaymentId > 0) {
              return api.updateInvoicePayment(ip);
            }

            return api.createInvoicePayment({
              ...ip,
              paymentId: paymentToEdit.paymentId,
            });
          });
          Promise.all(requests.filter((x) => x !== null)).then((r) => {
            toggleEditModal();
            loadData();
          });
        } else {
          toggleEditModal();
          loadData();
        }
      });
    }
  };

  const removePayment = () => {
    swal({
      text: "Are you sure you want to remove this payment?",
      type: "warning",
      buttons: {
        confirm: {
          text: "Confirm",
          value: "Confirm",
        },
        return: {
          text: "Cancel",
          value: "Cancel",
        },
      },
    }).then((value) => {
      if (value === "Confirm") {
        api.deletePayment(paymentToEdit.paymentId).then((d) => {
          setShowEditModal(false);
          loadData();
        });
      }
    });
  };

  const saveInvoicePayment = () => {
    var copy = { ...paymentToEdit };
    var existingInvPayment = copy.invoicePayments.find(
      (x) => x.id == invoicePaymentToEdit.id
    );

    if (existingInvPayment != undefined) {
      copy.invoicePayments[copy.invoicePayments.indexOf(existingInvPayment)] = {
        ...invoicePaymentToEdit,
        isDirty: true,
      };
    } else {
      copy.invoicePayments.push({
        ...invoicePaymentToEdit,
        isDirty: true,
      });
    }

    let client = partnershipList.find(
      (x) => x.partnershipId == invoicePaymentToEdit.partnershipId
    )

    setPaymentToEdit({
      ...copy,
      partnershipId:
        paymentToEdit.partnershipId <= 0 &&
        invoicePaymentToEdit.partnershipId !== undefined
          ? invoicePaymentToEdit.partnershipId
          : copy.partnershipId,
      clientName: client !== undefined ? client.partnershipName : copy.partnershipName
    });
    setShowInvoicePaymentModal(false);
  };

  const removeInvoicePayment = () => {
    var copy = { ...paymentToEdit };
    var existingInvPayment = copy.invoicePayments.find(
      (x) => x.id == invoicePaymentToEdit.id
    );
    if (existingInvPayment != undefined) {
      copy.invoicePayments[copy.invoicePayments.indexOf(existingInvPayment)] = {
        ...invoicePaymentToEdit,
        isDirty: true,
        isDeleted: true,
      };
      setPaymentToEdit(copy);
    }

    setShowInvoicePaymentModal(false);
  };

  const addInvoicePayment = async () => {
    try {
      setIsLoading(true);
      let invoices = await loadInvoices(paymentToEdit.partnershipId);
      let preselectedInvoiceId = -1;
      let preselectedInvoice = invoices.find(
        (x) => x.invoiceNumber == invoiceNumberSearch
      );
      if (preselectedInvoice !== undefined) {
        preselectedInvoiceId = preselectedInvoice.invoiceId;
      }

      if (invoices.length == 0) {
        swal("No invoices found.");
      } else {
        setInvoicePaymentToEdit({
          id: generateGuid(),
          invoicePaymentId: -1,
          invoiceId: preselectedInvoiceId,
          invoiceNumber:
            preselectedInvoice !== undefined
              ? preselectedInvoice.invoiceNumber
              : "",
          partnershipId:
            preselectedInvoice !== undefined
              ? preselectedInvoice.partnershipId
              : undefined,
          amountApplied: 0,
          writeOff: false,
          comments: "",
        });
        setShowInvoicePaymentModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const editInvoicePayment = (invPayment) => {
    setInvoicePaymentToEdit(invPayment);
    setShowInvoicePaymentModal(true);
  };

  const changePaymentProp = (prop, val) => {
    setPaymentToEdit({ ...paymentToEdit, [prop]: val, isDirty: true });
  };

  const changeInvoicePaymentProp = (prop, val) => {
    setInvoicePaymentToEdit({
      ...invoicePaymentToEdit,
      [prop]: val,
      isDirty: true,
    });
  };

  const generateGuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const defaultSorted = [
    {
      dataField: "clientName",
      order: "asc",
    },
  ];
  const options = {
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
    ],
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };
  const columns = [
    {
      dataField: "clientName",
      text: "Client",
      sort: true,
      headerStyle: () => {
        return { width: "15%" };
      },
    },
    {
      dataField: "paymentDate",
      text: "Payment Date",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cellContent, row) => {
        return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
      },
    },
    {
      dataField: "paymentAmount",
      text: "Amount",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: (cell, row) => {
        return currencyFormatter("$", cell, true);
      },
    },
    {
      dataField: "checkNumber",
      text: "Check Number",
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "paymentType",
      sort: true,
      text: "Payment Type",
      headerStyle: () => {
        return { width: "20%" };
      },
    },
    {
      dataField: "invoiceListString",
      sort: true,
      text: "Invoices",
      headerStyle: () => {
        return { width: "20%" };
      },
      formatter: (cell, row) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e && e.preventDefault();
              editPayment(row);
            }}
          >
            {cell == null || cell == "" ? "Apply" : cell}
          </a>
        );
      },
    },
  ];

  const remainingAmount =
    paymentToEdit == null
      ? 0
      : parseFloat(parseFloat(paymentToEdit.paymentAmount) -
        paymentToEdit.invoicePayments.reduce(
          (acc, item) =>
            acc +
            (!isNaN(item.amountApplied) ? parseFloat(item.amountApplied) : 0),
          0
        )).toFixed(2);

  return (
    <Fragment>
      <PageTitle heading="Manage Payments" />
      <Row>
        <Col md="12">
          <Button
            className="mb-2 mr-2"
            color="primary"
            onClick={() => addPayment()}
          >
            Create New Payment
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <div className="form-group">
            <label>Client</label>
            <CustomInput
              type="select"
              value={selectedPartnershipIdFilter}
              onChange={(e) => {
                setSelectedPartnershipIdFilter(e.target.value);
              }}
            >
              <option key={0} value={-1}>
                All
              </option>
              {partnershipList.map((partnership) => {
                return (
                  <option
                    key={partnership.partnershipId}
                    value={partnership.partnershipId}
                  >
                    {partnership.partnershipName}
                  </option>
                );
              })}
            </CustomInput>
          </div>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Label>Start Date</Label>
            <DatePicker
              selected={startDate}
              onChange={(e) => setStartDate(e)}
              className="form-control"
            />
          </div>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Label>End Date</Label>
            <DatePicker
              selected={endDate}
              onChange={(e) => setEndDate(e)}
              className="form-control"
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <GeneralGrid
          title=""
          keyField="paymentId"
          defaultSortedColumn="clientName"
          dataSource={payments.filter(
            (x) =>
              (selectedPartnershipIdFilter == -1 ||
                x.partnershipId == selectedPartnershipIdFilter) &&
              moment(startDate).startOf("day") <=
                moment(x.paymentDate).startOf("day") &&
              moment(x.paymentDate).startOf("day") <=
                moment(endDate).startOf("day")
          )}
          columns={columns}
          defaultSorted={defaultSorted}
          options={options}
        />
      </Row>

      <Modal
        size="md"
        isOpen={showEditModal}
        toggle={() => toggleEditModal()}
        backdrop="static"
      >
        <ModalHeader toggle={() => toggleEditModal()}>Payment</ModalHeader>
        <ModalBody>
          {paymentToEdit != null && (
            <Row>
              <Col md="12">
                <div className="form-group">
                  <label>Client *</label>
                  <CustomInput
                    type="select"
                    value={paymentToEdit.partnershipId}
                    onChange={(e) => {
                      setPaymentToEdit({
                        ...paymentToEdit,
                        partnershipId: e.target.value,
                        clientName:
                          e.target.value == -1
                            ? ""
                            : partnershipList.find(
                                (x) => x.partnershipId == e.target.value
                              ).partnershipName,
                        invoiceListString: "",
                      });
                      //loadInvoices(e.target.value);
                    }}
                  >
                    <option key={0} value={-1}>
                      Select Client
                    </option>
                    {partnershipList.map((partnership) => {
                      return (
                        <option
                          key={partnership.partnershipId}
                          value={partnership.partnershipId}
                        >
                          {partnership.partnershipName}
                        </option>
                      );
                    })}
                  </CustomInput>
                </div>
                <div className="form-group">
                  <Label>Date</Label>
                  <DatePicker
                    selected={paymentToEdit.paymentDate}
                    onChange={(e) => changePaymentProp("paymentDate", e)}
                    placeholder="Payment Date"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <Label>Amount</Label>
                  <CurrencyInput
                    className="form-control"
                    value={paymentToEdit.paymentAmount}
                    onValueChange={(value, name, values) => {
                      changePaymentProp("paymentAmount", values.value);
                    }}
                    prefix={"$"}
                  />
                </div>
                <div className="form-group">
                  <Label>Check Number</Label>
                  <CustomInput
                    type="text"
                    value={paymentToEdit.checkNumber}
                    onChange={(e) =>
                      changePaymentProp("checkNumber", e.target.value)
                    }
                    placeholder="Check Number"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <Label>Payment Type</Label>
                  <CustomInput
                    type="text"
                    value={paymentToEdit.paymentType}
                    onChange={(e) =>
                      changePaymentProp("paymentType", e.target.value)
                    }
                    placeholder="Payment Type"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Notes</label>
                  <Input
                    name="notess"
                    type="textarea"
                    value={paymentToEdit.notes}
                    onChange={(e) => changePaymentProp("notes", e.target.value)}
                    placeholder="Notes..."
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  {/* {paymentToEdit?.partnershipId > 0 && ( */}
                  {/* <> */}
                  <Button
                    color="primary"
                    onClick={addInvoicePayment}
                    disabled={isLoading}
                  >
                    Apply to Invoice
                  </Button>
                  <div className="form-group">
                    <label>Invoice Number</label>
                    <Input
                      type="text"
                      value={invoiceNumberSearch}
                      onChange={(e) => setInvoiceNumberSearch(e.target.value)}
                    />
                  </div>
                  {/* </> */}
                  {/* )} */}
                </div>

                <div style={{ marginTop: "30px" }}>
                  <table
                    className="table table-striped"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th width="40%">Invoice Number</th>
                        <th width="40%" style={{ textAlign: "right" }}>
                          Amount Applied
                        </th>
                        <th width="20%" style={{ textAlign: "right" }}>
                          Write Off
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentToEdit.invoicePayments.length > 0 &&
                        paymentToEdit.invoicePayments
                          .filter((x) => !x.isDeleted)
                          .map((x, idx) => (
                            <tr key={idx}>
                              <td>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e && e.preventDefault();
                                    editInvoicePayment(x);
                                  }}
                                >
                                  {x.invoiceNumber}
                                </a>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {currencyFormatter("$", x.amountApplied, true)}
                              </td>
                              <td style={{ textAlign: "right" }}>
                                {x.writeOff ? "Yes" : "No"}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
                {!isNaN(paymentToEdit.paymentAmount) && (
                  <p>
                    Remaining amount:{" "}
                    {isNaN(remainingAmount)
                      ? "$0"
                      : currencyFormatter("$", remainingAmount)}
                  </p>
                )}
              </Col>
            </Row>
          )}
        </ModalBody>
        <div style={{ margin: "10px" }}>
          <Button color="link" onClick={() => toggleEditModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={savePayment}
            style={{ marginLeft: ".25rem" }}
          >
            Save
          </Button>
          {paymentToEdit != null && paymentToEdit.paymentId > -1 && (
            <Button
              color="danger"
              style={{ marginLeft: ".25rem" }}
              className="pull-right"
              onClick={removePayment}
            >
              Remove
            </Button>
          )}
        </div>
      </Modal>
      <InvoicePaymentModal
        showModal={showInvoicePaymentModal}
        toggleModal={toggleInvoicePaymentModal}
        invoiceList={invoiceList}
        invoicePaymentToEdit={invoicePaymentToEdit}
        changeInvoicePaymentProp={changeInvoicePaymentProp}
        setInvoicePaymentToEdit={setInvoicePaymentToEdit}
        onSave={saveInvoicePayment}
        removeInvoicePayment={removeInvoicePayment}
      />
    </Fragment>
  );
};

export default PaymentList;
