import React, { Fragment } from "react";
import { connect } from "react-redux";

import Hamburger from "react-hamburgers";

import AppMobileMenu from "../AppMobileMenu";

import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

import logo from "../../assets/utils/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/fontawesome-free-solid";
import MobileHelper from "../../_helpers/mobileHelper";

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    };
    this.isMobile = MobileHelper.isMobile();
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    let { enableClosedSidebar } = this.props;

    const {} = this.state;

    return (
      <Fragment>
        <div className="app-header__logo">
          {/* <div> */}
          <h4 className="logo" style={{ fontWeight: "bold" }}>
            LeGuard
          </h4>
          {/* <p style={{ margin: 0 }}>sadasd sad dsa dsa</p> */}
          {/* </div> */}
          {!this.isMobile && <img className="logo" src={logo} />}
          {/* <div className="logo-src"/> */}
          <div className="header__pane ml-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              {this.props.hideMenu == undefined && (
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "white", cursor: "pointer" }}
                />
              )}
              {/* <Hamburger
                                style={{color: 'white'}}
                                active={false}
                                type="elastic"
                                onClick={() => this.setState({active: !this.state.active})}
                            /> */}
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderLogo);
