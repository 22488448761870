import { BehaviorSubject } from 'rxjs';

import { api } from './api.service';
import { handleResponse } from '../_helpers';

const currentDataSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentData')));
const tokenInfoSubject = new BehaviorSubject(currentDataSubject.value ? currentDataSubject.value.access_token : currentDataSubject.value);
export const authenticationService = {
    login,
    logout,
    resetPassword,
    currentData: currentDataSubject.asObservable(),
    tokenInfo: tokenInfoSubject.asObservable(),
    get currentDataValue() { return currentDataSubject.value },
    get tokenInfoValue() { return tokenInfoSubject.value }
};

function login(username, password) {
    let data = {
        'username': username,
        'password': password,
        'grant_type': 'password'
    }
    let formBody = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: formBody
    };

    return fetch(`${api.authApiUrl}/token`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (data.user) {
                let u = JSON.parse(data.user);
                let user =
                {
                    userId: u.UserId,
                    name: u.Name,
                    address: u.Address,
                    city: u.City,
                    phoneNumber: u.PhoneNumber,
                    stateProvince: u.StateProvince,
                    country: u.Country,
                    postal: u.Postal,
                    email: u.Email,
                    username: u.UserName,
                    status: u.Status,
                    balanceStartDate: u.BalanceStartDate,
                    timezoneId: u.TimeZoneId,
                    currencyId: u.CurrencyId,
                    currencySymbol: u.CurrencySymbol,
                    entityId: u.EntityId,
                    entityName: u.EntityName,
                    entityTypeId: u.EntityTypeId,
                    entityRoleId: u.EntityRoleId,
                    billerTypeId: u.BillerTypeId,
                    systemRoleId: u.SystemRoleId,
                    systemRole: u.SystemRole,
                    tempPasswordSetDate: u.TempPasswordSetDate,
                    canManageMatters: u.CanManageMatters,
                    timezoneIanaName: u.TimezoneIanaName,
                    isManagingUser: u.IsManagingUser,
                    highestRole: u.HighestRole,
                    parentId: u.ParentId,
                    encryptNotes: u.EncryptNotes
                };
                data.user = JSON.stringify(user);
            }
            localStorage.setItem('currentData', JSON.stringify(data));
            currentDataSubject.next(data);
            // tokenInfoSubject.next(user);
            return data;
        }, error => {
            return error('Username or password incorrect');
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentData');
    currentDataSubject.next(null);
    // tokenInfoSubject.next(null);
}

function resetPassword(email){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${api.authApiUrl}/api/Home/ResetPassword?email=${email}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        }, error => {
            return error('Something went wrong while trying to reset your password.');
        });
}
