import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  CustomInput,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { userService } from "../../../../_services/user.service";
import moment from "moment";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorElement } from "../../../../Components/ErrorElement";

const BudgetModal = ({
  budget,
  budgetList,
  showModal,
  toggleModal,
  currencyList,
  saveBudget,
}) => {
  const currentUser = userService.getLoggedInUser();
  const defaultBudget = {
    budgetId: -1,
    budgetName: "",
    startDate: undefined,
    endDate: undefined,
    amount: undefined,
    currencyId: currentUser.currencyId,
  };

  let [currentBudget, setCurrentBudget] = useState(defaultBudget);
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: currentBudget,
    resolver: yupResolver(
      Yup.object().shape({
        budgetName: Yup.string().required("Name is required"),
        startDate: Yup.date()
          .required("Start Date is required")
          .test("is-valid-date", "Invalid date", function (value) {
            return value instanceof Date && !isNaN(value.getTime());
          }),
        amount: Yup.number()
          .nullable()
          .transform((value, originalValue) => {
            if (originalValue === "") {
              return null;
            }
            return value;
          })
          .required("Amount is required"),
        currencyId: Yup.number().required("Currency is required"),
        endDate: Yup.date()
          .nullable()
          .test("is-valid-date", "Invalid date", function (value) {
            if (value === undefined) return true;
            if (value === null) return true;
            if (value === "") return true;
            return value instanceof Date && !isNaN(value.getTime());
          })
          .test(
            "is-valid-end-date",
            "End Date cannot be before Start Date",
            function (value, options) {
              const { startDate } = options.parent;
              if (startDate === undefined || value === undefined) return true;
              if (startDate === null || value === undefined) return true;
              if (startDate === "" || value === undefined) return true;
              if (value instanceof Date && !isNaN(value.getTime())) {
                return value >= startDate;
              }

              return true;
            }
          ),
      })
    ),
  });

  useEffect(() => {
    if (budget.budgetId !== -1 && currentBudget !== budget && showModal) {
      const startDate = new Date(budget.startDate);
      const endDate = new Date(budget.endDate);
      const b = {
        ...budget,
        startDate: isNaN(startDate.getTime()) ? undefined : startDate,
        endDate: isNaN(endDate.getTime()) ? undefined : endDate,
      };
      setCurrentBudget(b);
      reset(b);
    } else {
      setCurrentBudget(defaultBudget);
      reset(defaultBudget);
    }
  }, [budget]);

  useEffect(() => {
    // Retrieve the entire form data object
    const subscription = watch((value) => setCurrentBudget(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (data) => {
    var b = { ...data };
    b.startDate = moment(data.startDate).add(1, "minutes");
    let isOverlapping = isBudgetOverlapping(b);
    if (!isOverlapping) {
      saveBudget(b);
    } else {
      swal(
        "This budget overlaps with other budgets. Please solve the overlap in order to save.",
        {
          buttons: {
            ok: {
              text: "Ok",
              value: "ok",
              className: "btn-success",
            },
          },
          icon: "warning",
        }
      );
    }
  };

  const isBudgetOverlapping = (budget) => {
    let overlap = budgetList.filter((b) => {
      const start1 = new Date(budget.startDate).getTime();
      const end1 = new Date(budget.endDate).getTime();
      const start2 = new Date(b.startDate).getTime();
      const end2 = new Date(b.endDate).getTime();
      return start1 <= end2 && end1 >= start2;
    });
    return overlap.length > 0 ? true : false;
  };

  return (
    <Modal isOpen={showModal} toggle={toggleModal} backdrop="static">
      <ModalHeader toggle={toggleModal}>
        {currentBudget.budgetId == -1 ? "Add" : "Update"} Budget Item
      </ModalHeader>
      <ModalBody>
        <Row style={{ marginTop: "30px" }}>
          <Col md="12">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md="12">
                  <Controller
                    name="budgetName"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="budgetName">Name *</Label>
                        <Input
                          type="text"
                          id="budgetName"
                          placeholder="Name..."
                          value={field.value}
                          invalid={!!errors.budgetName}
                          onChange={(e) => field.onChange(e)}
                        />
                        <ErrorElement message={errors.budgetName?.message} />
                      </FormGroup>
                    )}
                  />

                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="startDate">Start Date *</Label>
                        <DatePicker
                          className="form-control"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          placeholderText="Start date..."
                        />
                        <ErrorElement message={errors.startDate?.message} />
                      </FormGroup>
                    )}
                  />

                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="endDate">End Date</Label>
                        <DatePicker
                          className="form-control"
                          selected={field.value}
                          onChange={(e) => field.onChange(e)}
                          placeholderText="End date..."
                        />
                        <ErrorElement message={errors.endDate?.message} />
                      </FormGroup>
                    )}
                  />

                  <Controller
                    name="amount"
                    control={control}
                    defaultValue={undefined}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="amount">Amount *</Label>
                        <Input
                          type="number"
                          id="amount"
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          min={0}
                          placeholder="Amount..."
                          invalid={!!errors.amount}
                        />
                        <ErrorElement message={errors.amount?.message} />
                      </FormGroup>
                    )}
                  />

                  <Controller
                    name="currencyId"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <FormGroup>
                        <Label for="currencyId">Default Currency *</Label>
                        <CustomInput
                          type="select"
                          id="currencyId"
                          name="currencyId"
                          className="form-control"
                          value={field.value}
                          onChange={(e) => field.onChange(e)}
                          placeholder="Select currency..."
                        >
                          {currencyList.map((currency) => {
                            return (
                              <option
                                key={currency.currencyId}
                                value={currency.currencyId}
                              >
                                {currency.displayValue}
                              </option>
                            );
                          })}
                        </CustomInput>
                      </FormGroup>
                    )}
                  />
                </Col>
              </Row>
              <Button color="link" onClick={toggleModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                style={{ marginLeft: ".25rem" }}
              >
                Save
              </Button>{" "}
              {currentBudget.budgetId > -1 && (
                <Button
                  color={"danger"}
                  style={{ marginLeft: ".25rem" }}
                  className="pull-right"
                  onClick={() => {
                    let budget = currentBudget;
                    budget.isDeleted = true;
                    saveBudget(budget);
                  }}
                >
                  Remove
                </Button>
              )}
            </Form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

BudgetModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  budget: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
  currencyList: PropTypes.array.isRequired,
  saveBudget: PropTypes.func,
  budgetList: PropTypes.array,
};

export { BudgetModal };

// class BudgetModal extends Component {

//     constructor(props) {
//         super(props);
//         this.currentUser = userService.getLoggedInUser();
//         this.state = {
//             //showModal: props.showModal,
//             budget: props.budget,
//             isStartDateValid: true,
//             isEndDateValid: true
//         };
//         //this.toggleModal = props.toggleModal;
//     }

//     componentWillReceiveProps(nextProps) {
//         if (nextProps.budget !== null && nextProps.budget !== undefined && nextProps.budget != this.state.budget)
//             this.setState({ budget: nextProps.budget });
//     }

//     handleSubmit = (e, errors, values) => {
//         this.setState(prev =>({
//             ...prev,
//             budget:{
//                 ...prev.budget,
//                 startDate: moment(this.state.budget.startDate).add(1, 'minutes')
//             }
//         }));
//         let isBudgetValid = this.validateBudget();
//         if (errors.length == 0 && isBudgetValid) {
//             let isOverlapping = this.isBudgetOverlapping();
//             if (!isOverlapping)
//                 this.props.saveBudget(this.state.budget);
//             else
//                 alert("This budget overlaps with other budgets. Please solve the overlap in order to save.");
//         }
//     }

//     isBudgetOverlapping() {
//         let overlap = this.props.budgetList.filter(b => {
//             return this.state.budget.budgetId !== b.budgetId && (new Date(this.state.budget.startDate) <= new Date(b.endDate)) && (new Date(this.state.budget.endDate) >= new Date(b.endDate));
//         });
//         return overlap.length > 0 ? true : false;
//     }

//     validateBudget() {
//         let errorCount = 0;
//         if (this.state.budget.startDate == '' || this.state.budget.startDate == null) {
//             errorCount++;
//             this.setState({ isStartDateValid: false });
//         }
//         if (this.state.budget.endDate !== undefined && this.state.budget.endDate !== null && this.state.budget.endDate !== '' && this.state.budget.startDate !== '' && this.state.budget.startDate !== null && new Date(this.state.budget.endDate) < new Date(this.state.budget.startDate)) {
//             errorCount ++;
//             this.setState({ isEndDateValid: false });
//         }
//         return errorCount == 0;
//     }

//     handleChange(prop, val) {
//         let sdValid = this.state.isStartDateValid;
//         if (prop == 'startDate')
//             sdValid = true;
//         this.setState(prevState => ({
//             ...prevState,
//             budget: {
//                 ...prevState.budget,
//                 [prop]: val
//             },
//             isStartDateValid: sdValid
//         }));

//     }

//     render() {

//         return (
//             <Modal isOpen={this.props.showModal} toggle={() => this.props.toggleModal()} className={this.props.className}>
//                 <ModalHeader toggle={() => this.props.toggleModal()}>{this.state.budget.budgetId == -1 ? 'Add' : 'Update'} Budget Item</ModalHeader>
//                 <ModalBody>
//                     <Row style={{ marginTop: '30px' }}>
//                         <Col md="12">

//                             <AvForm onSubmit={this.handleSubmit}>
//                                 <Row>
//                                     <Col md="12">
//                                         <AvField name="budgetName" label="Name" value={this.state.budget.budgetName} placeholder="Name..."
//                                             onChange={(e) => this.handleChange('budgetName', e.target.value)} required />

//                                         <div className="form-group">
//                                             <label>Start Date</label>
//                                             <DatePicker className="form-control"
//                                                 selected={this.state.budget.startDate == '' ? null : this.state.budget.startDate}
//                                                 onChange={(date) => this.handleChange('startDate', date)}
//                                                 placeholderText="Start date..."
//                                             />
//                                             {!this.state.isStartDateValid &&
//                                                 <div className="invalid-feedback" style={{ display: 'block' }}>Start Date is required</div>
//                                             }

//                                         </div>

//                                         <div className="form-group">
//                                             <label>End Date</label>
//                                             <DatePicker className="form-control"
//                                                 selected={this.state.budget.endDate == '' ? null : this.state.budget.endDate}
//                                                 onChange={(date) => this.handleChange('endDate', date)}
//                                                 placeholderText="End date..."
//                                             />
//                                             {!this.state.isEndDateValid &&
//                                                 <div className="invalid-feedback" style={{ display: 'block' }}>End Date cannot be before Start Date</div>
//                                             }
//                                         </div>

//                                         <AvField name="amount" type="number" label="Amount" value={this.state.budget.amount} placeholder="Amount..."
//                                             onChange={(e) => this.handleChange('amount', e.target.value)} required />

//                                         <div className='form-group'>
//                                             <label htmlFor="currency">Default Currency</label>
//                                             <CustomInput type="select" id="currencySelect" name="currencySelect" className='form-control'
//                                                 onChange={(e) => this.handleChange('currencyId', e.target.value)}
//                                                 value={this.currentUser.currencyId}>
//                                                 {this.props.currencyList.map(currency => {
//                                                     return <option key={currency.currencyId} value={currency.currencyId}>{currency.displayValue}</option>
//                                                 })}
//                                             </CustomInput>
//                                         </div>

//                                     </Col>
//                                 </Row>

//                                 {/* <Row style={{ marginTop: '30px' }}> */}
//                                 <Button color="link" onClick={() => this.props.toggleModal()}>Cancel</Button>

//                                 <Button color="primary" type="submit" style={{ marginLeft: '.25rem' }}>Save</Button>{' '}

//                                 {this.state.budget.budgetId > -1 &&
//                                     <Button color={'danger'} style={{ marginLeft: '.25rem' }} className='pull-right'
//                                         onClick={() => { let budget = this.state.budget; budget.isDeleted = true; this.props.saveBudget(budget); }}>Remove
//                                     </Button>
//                                 }

//                                 {/* </Row> */}
//                             </AvForm>

//                         </Col>
//                     </Row>
//                 </ModalBody>
//             </Modal>
//         );
//     }
// }

// BudgetModal.propTypes = {
//     showModal: PropTypes.bool.isRequired,
//     budget: PropTypes.object.isRequired,
//     toggleModal: PropTypes.func.isRequired,
//     currencyList: PropTypes.array.isRequired,
//     saveBudget: PropTypes.func,
//     budgetList: PropTypes.array
// }

// export default BudgetModal;
