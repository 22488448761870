
import { authHeader, handleResponse } from '../_helpers';

export const userService = {
    getLoggedInUser,
    setInitialActivityCheck,
    setUserEntityRole,
    updateUser
};

function updateUser(user){
    let data = JSON.parse(localStorage.getItem('currentData'));
    let userString = data.user;
    let userJson = JSON.parse(userString);
    userJson.encryptNotes = user.encryptNotes;
    data.user = JSON.stringify(userJson);
    localStorage.setItem('currentData', JSON.stringify(data));
}

function getLoggedInUser(){
   
    let data = JSON.parse(localStorage.getItem('currentData'))
    if(data === 'undefined' || data == null)
        return null;
    let user = data.user;
    if(user === 'undefined' || user == null)
        return null;
    return JSON.parse(user);
}

function setInitialActivityCheck(){
    let user = getLoggedInUser();
    if(user !== undefined && user !== null){
        var cd = JSON.parse(localStorage.getItem('currentData'));
        var u = JSON.parse(cd.user);
        u.initialActivityCheckMade = true;
        cd.user = JSON.stringify(u);
        localStorage.setItem('currentData', JSON.stringify(cd));
    }
}

function setUserEntityRole(entityRoleId){
    let user = getLoggedInUser();
    if(user !== undefined && user !== null){
        var cd = JSON.parse(localStorage.getItem('currentData'));
        var u = JSON.parse(cd.user);
        u.entityRoleId = entityRoleId;
        cd.user = JSON.stringify(u);
        localStorage.setItem('currentData', JSON.stringify(cd));
    }
}