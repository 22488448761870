import React, { useState, useEffect, Fragment } from 'react';
import {
    Button, Container, Modal, ModalHeader, Card, CardBody,
    ModalBody, ModalFooter, Row, Col, CustomInput, Table
} from 'reactstrap';
import PageTitle from '../../Layout/AppMain/PageTitle';
import Loader from 'react-loaders';
import { userService, api } from '../../_services';


const AdminNotice = () => {

    const currentUser = userService.getLoggedInUser();
    const types = [
        { id: 0, displayName: 'All', isVisible: true },
        { id: 1, displayName: 'Matters', isVisible: true },
        { id: 2, displayName: 'Users', isVisible: true }
    ];

    let [isLoading, setIsLoading] = useState(false);
    let [notices, setNotices] = useState([]);
    let [selectedType, setSelectedType] = useState({ id: 0, displayName: 'All' });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = (type) => {
        setIsLoading(true);
        api.getAdminNotices(currentUser.userId, type == undefined ? selectedType.id : type).then(data => {
            setIsLoading(false);
            setNotices(data);
        })
    }

    const onTypeChanged = (e) => {
        let type = types.find(x => x.id == e.target.value);
        if (type !== undefined && type !== null) {
            setSelectedType(type);
            loadData(type.id);
        }
    }

    return (
        <Fragment>
            <PageTitle
                heading="Administrative Notices"
            />
            {isLoading && <Loader style={{}} type="ball-pulse" active />}
            {!isLoading &&
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <CustomInput type="select" id="categorySelect" value={selectedType.id}
                                name="categorySelect" onChange={(e) => onTypeChanged(e)}>
                                {types.filter(x => x.isVisible).map(cat => {
                                    return <option key={cat.id} value={cat.id}>{cat.displayName}</option>
                                })}
                            </CustomInput>
                        </Col>
                    </Row>
                    {notices &&
                        <Row style={{ marginTop: '30px' }}>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th width='50%'>Notice</th>
                                                    <th>Action</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {notices.length > 0 &&
                                                    notices
                                                        .map((n, idx) => {
                                                            return (
                                                                <tr key={idx}>
                                                                    <td>{n.notice}</td>
                                                                    <td dangerouslySetInnerHTML={{ __html: n.action }}></td>
                                                                </tr>
                                                            )
                                                        })}
                                                {notices.length == 0 &&
                                                    <tr>
                                                        <td>No items to display</td>
                                                        <td></td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            }
        </Fragment >
    )
}

export default AdminNotice;