import React, { Component, Fragment, useEffect, useState } from "react";
import { api, userService } from "../../../_services";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Card,
  CardBody,
  ModalBody,
  Row,
  Col,
  CustomInput,
  CardTitle,
} from "reactstrap";
import Loader from "react-loader-advanced";
import swal from "sweetalert";

const ButtonPackagesGrid = ({ partnershipId, colWidth }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonPackageList, setButtonPackageList] = useState(false);
  const [companyButtonPackageList, setCompanyButtonPackageList] =
    useState(false);
  const currentUser = userService.getLoggedInUser();

  useEffect(() => {
    if (partnershipId > -1) {
      loadPackages();
    }
  }, [partnershipId]);

  const loadPackages = () => {
    api.getPackageListByPartnershipId(partnershipId).then((data) => {
      setButtonPackageList(data);
      setIsLoading(false);
    });
    api
      .getPackageListByEntityIdPartnershipId(
        currentUser.entityId,
        partnershipId
      )
      .then((data) => {
        setCompanyButtonPackageList(data);
      });
  };

  const toggleModal = () => setShowModal(!setShowModal);

  const addButtonPackage = () => {
    if (this.state.partnership.partnershipId == -1) {
      swal("Please save the partnership first.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    } else {
      this.toggleModal();
    }
  };

  const onPackageSelectionChange = (packageId) => {
    let list = this.state.companyButtonPackageList.map((p) => {
      if (p.packageId == packageId) p.selected = !p.selected;
      return p;
    });
    this.setState((prevState) => ({
      ...prevState,
      companyButtonPackageList: list,
    }));
  };

  const save = () => {
    api
      .updatePartnershipPackages(this.state.companyButtonPackageList)
      .then((data) => {
        this.toggleModal();
        this.loadPackages();
      });
  };

  const columns = [
    {
      dataField: "entityName",
      text: "Company",
      sort: true,
      headerStyle: () => {
        return { width: "40%" };
      },
    },
    {
      dataField: "name",
      text: "Package",
      sort: true,
      headerStyle: () => {
        return { width: "60%" };
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const companyButtonPackageListColumns = [
    {
      dataField: "selected",
      text: "Selected",
      headerStyle: () => {
        return { width: "30%" };
      },
      formatter: (cellContent, row) => {
        return (
          <CustomInput
            type="checkbox"
            id={row.packageId}
            checked={row.selected}
            value={row.selected}
            onChange={() => this.onPackageSelectionChange(row.packageId)}
          />
        );
      },
    },
    {
      dataField: "packageName",
      text: "Package Name",
      sort: true,
      headerStyle: () => {
        return { width: "70%" };
      },
    },
  ];

  const defaultSortedCompanyButtonPackageList = [
    {
      dataField: "packageName",
      order: "asc",
    },
  ];
  return (
    <Fragment>
      {isLoading && <Loader style={{}} type="ball-pulse" active />}
      {!isLoading && (
        <Col md={colWidth}>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>
                Button Packages for this{" "}
                {currentUser.entityRoleId == 1 ? "Law Firm" : "Client"}
              </CardTitle>
              <div className="table-responsive">
                <BootstrapTable
                  bootstrap4
                  keyField="packageId"
                  data={buttonPackageList}
                  columns={columns}
                  defaultSorted={defaultSorted}
                />
              </div>
              <Button color="primary" onClick={() => addButtonPackage()}>
                Select Packages
              </Button>
            </CardBody>
          </Card>
        </Col>
      )}

      <Modal isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
        <ModalHeader toggle={() => toggleModal()}>Select Packages</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <div className="table-responsive">
                <BootstrapTable
                  striped
                  bordered={false}
                  bootstrap4
                  keyField="packageId"
                  pagination={null}
                  data={companyButtonPackageList}
                  columns={companyButtonPackageListColumns}
                  defaultSorted={defaultSortedCompanyButtonPackageList}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Button color="link" onClick={() => toggleModal()}>
                Cancel
              </Button>

              <Button
                color="primary"
                style={{ marginLeft: ".25rem" }}
                onClick={() => save()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ButtonPackagesGrid;
