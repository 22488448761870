import React, { Fragment, useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader, ModalFooter, CardBody,
    ModalBody, Row, Col, CustomInput, CardTitle
} from 'reactstrap';
import GeneralGrid from '../../../Components/GeneralGrid'
import { api, userService, userMatterRoles } from '../../../_services';
import Loader from 'react-loaders';
import * as Datetime from 'react-datetime';

import { DateTimeHelper } from '../../../_helpers/dateTimeHelper';
import moment from 'moment-timezone';

const StartActivityModal = ({ showModal, toggleModal, toggleActivityCodeModal, startRateBasedActivity, startFixedPriceActivity, activityCode, matter }) => {

    let [selectedDateTime, setSelectedDateTime] = useState(DateTimeHelper.getUsersNow());
    let [fixedPrice, setFixedPrice] = useState('');
    let [startSeconds, setStartSeconds] = useState(0);
    const currentUser = userService.getLoggedInUser();

    useEffect(() => {
        if (activityCode !== undefined && activityCode.fixedPrice !== undefined)
            setFixedPrice(activityCode.fixedPrice);
    }, [activityCode]);

    useEffect(() => {
        if (showModal) {
            let date = DateTimeHelper.getUsersNow('MM/DD/YYYY hh:mm A');
            setSelectedDateTime(date);
            setStartSeconds(parseInt(moment(new Date()).format('ss')));
        }
    }, [showModal])

    const onMatterClick = e => {
        e && e.preventDefault();
        toggleModal();
        toggleActivityCodeModal();
    }

    const onActivityCodeClick = e => {
        e && e.preventDefault();
        toggleModal();
    }

    return (
        <Modal size="md" isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>
                <Fragment>
                    <h4>Start Activity</h4>
                    <a href='#' onClick={onMatterClick}><h4>{'Matter: ' + matter.matterName}</h4></a>
                    <a href='#' onClick={onActivityCodeClick}><h4>{'Activity: ' + activityCode.title}</h4></a>
                </Fragment>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="12">
                        <div className="form-group">
                            <label>Start Time</label><br />
                            <Datetime
                                value={selectedDateTime}
                                onChange={date => {
                                    setSelectedDateTime(date);
                                }}
                                className='react-datetime'
                            />
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '30px' }}>
                    <Col md="12">
                        <Button color="primary" onClick={() => startRateBasedActivity(moment.tz(selectedDateTime, 'MM/DD/YYYY hh:mm:ss A', currentUser.timezoneIanaName).tz('utc').add(startSeconds, 'seconds').format())}>Start Rate based Activity</Button>
                    </Col>
                </Row>
                {activityCode.fixedPrice !== 0 &&
                    <Fragment >
                        {activityCode.fpHidden &&
                            <Row >
                                <Col md="12">
                                    <div className="form-group">
                                        <label>If this is a fixed fee Activity enter the Amount below</label><br />
                                        <input type="number" className="form-control" value={fixedPrice} readOnly={!activityCode.fpEditable}
                                            onChange={(e) => setFixedPrice(e.target.value)} />
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md="12">
                                <Button color="primary" onClick={() => startFixedPriceActivity(moment.tz(selectedDateTime, 'MM/DD/YYYY hh:mm:ss A', currentUser.timezoneIanaName).tz('utc').add(startSeconds, 'seconds').format(), fixedPrice)}>Record Fixed Fee Activity</Button>
                            </Col>
                        </Row>
                    </Fragment>
                }
                <Row style={{ marginTop: '30px' }}>
                    <Col md="12">
                        <Button color="link" onClick={() => toggleModal()}>Cancel</Button>
                    </Col>
                </Row>
            </ModalBody>

        </Modal>
    );
}

export default StartActivityModal;