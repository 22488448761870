import React, { Fragment } from 'react';
import { api, authenticationService } from '../../_services';
import { GeneralForm } from '../../Components/GeneralForm';
import AppFooter from '../../Layout/AppFooter/';
import AppHeader from '../../Layout/AppHeader/';
import ThemeOptions from '../../Layout/ThemeOptions/';
import swal from 'sweetalert';
import { Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';

class UserRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showTermsAndConditionsModal: false,
            user: {
                username: '',
                // email: '',
                // postalCode: '',
                password: '',
                confirmpassword: '',
                billerTypeId: '',
                currencyId: 3,
                timezoneId: 114
            },
            currencyList: [],
            timezoneList: []
        }
        this.userId = parseInt(props.match.params.userId);
    }

    componentDidMount() {
        api.getUserByUserId(this.userId).then(data => {
            var user = {};
            if (data !== null) {
                user.username = data.email;
                user.email = data.email;
                user.timeZoneId = data.timezoneId;
                user.password = '';
                user.confirmpassword = '';
                user.billerTypeId = '';
                this.setState({ user });
            }


        })
        api.getCurrencyList().then(data => {
            this.setState({ currencyList: data });
        });
        api.getTimezoneList().then(data => {
            this.setState({ timezoneList: data });
        })
    }

    register = (user) => {
        user.username = user.email;
        if (user.status !== undefined && user.status == -1) {
            api.unregisterUser(user).then(data => {
                if (data !== null && data.length > 0) {
                    swal({
                        title: data,
                        text: "",
                        icon: "warning",
                        buttons: {
                            cancel: "Close"
                        },
                        dangerMode: true,
                    })
                } else {
                    swal({
                        title: "",
                        text: "You have unregistered from LeGuard",
                        icon: "success",
                        buttons: {
                            cancel: "Close"
                        }
                    })
                }
            })
        } else {
            api.registerUser(user).then(data => {
                if (data !== null && data.length > 0) {
                    swal({
                        title: data,
                        text: "",
                        icon: "warning",
                        buttons: {
                            cancel: "Close"
                        },
                        dangerMode: true,
                    })
                } else {
                    authenticationService.login(user.username, user.password).then(user => {
                        this.props.history.push({ pathname: "/registrationComplete" });
                    })
                }
            })
        }
    }

    showTermsAndConditions = () => {
        this.setState({ showTermsAndConditionsModal: !this.state.showTermsAndConditionsModal })
    }

    render() {
        const formProps = [

            {
                type: 'input',
                fieldType: 'email',
                property: 'email',
                label: 'Email',
                isRequired: true,
                positioning: 'left'
            },
            {
                type: 'input',
                property: 'postal',
                label: 'Zip / Postal code',
                positioning: 'right'
            },
            {
                type: 'dropdown',
                property: 'timezoneId',
                label: 'Default Timezone',
                dropdownData: this.state.timezoneList.map(x => {
                    x.id = x.timezoneId;
                    x.name = x.winDisplayName;
                    return x;
                }),
                positioning: 'right'
            },
            {
                type: 'dropdown',
                property: 'currencyId',
                label: 'Default Currency',
                dropdownData: this.state.currencyList.map(x => {
                    x.id = x.currencyId;
                    x.name = x.displayValue;
                    return x;
                }),
                positioning: 'right'
            },
            {
                type: 'input',
                fieldType: 'password',
                property: 'password',
                label: 'Password',
                isRequired: true,
                positioning: 'left'
            },
            {
                type: 'input',
                fieldType: 'password',
                property: 'confirmPassword',
                label: 'Confirm Password',
                positioning: 'left'
            }

        ]

        return (
            <Fragment>
                <ThemeOptions />
                <AppHeader hideMenu={true} />
                <div className="app-main" style={{ overflow: 'hidden' }}>
                    <div style={{ width: '100%' }}>
                        {/* <PageTitle heading="Register User" style={{ marginTop: '20px' }} /> */}
                        <div style={{ width: '100%', padding: '30px', fontSize: 'large', textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.55)', fontWeight: 'bold' }}>
                            User Registration
                        </div>
                        <Row>
                            <Col md="2"></Col>
                            <Col md="8">
                                <GeneralForm
                                    data={this.state.user}
                                    propertyTypes={formProps}
                                    onCancel={() => this.props.history.push({ pathname: '/login' })}
                                    //onDelete={userToEdit.userId == -1 ? undefined : saveUser}
                                    showSave={true}
                                    onSave={(user) => this.register(user)}
                                    saveButtonText="Register"
                                    showTermsAndConditions={this.showTermsAndConditions}
                                    customValidatorRules={
                                        [
                                            {
                                                property: 'confirmPassword',
                                                validate: (user) => {
                                                    if (user.password === user.confirmPassword)
                                                        return true;
                                                    return false;
                                                },
                                                errorMessage: "Password and Confirm Password don't match"
                                            }
                                        ]
                                    }

                                />
                            </Col>
                        </Row>
                        <AppFooter margin='0px' />
                    </div>
                </div>

                <Modal size="lg" isOpen={this.state.showTermsAndConditionsModal} toggle={() => this.showTermsAndConditions()} className={this.props.className} backdrop="static">

                    <ModalHeader toggle={() => this.showTermsAndConditions()}>LeGuard's Terms and Conditions
                    </ModalHeader>
                    <ModalBody>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center' }}><strong><u><span style={{ fontSize: '16px', lineHeight: '106%' }}>Terms and Conditions for Users</span></u></strong></p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><span style={{ fontSize: '16px', lineHeight: '106%' }}>Welcome to LeGuard. &nbsp;We are glad that you are using our service and hope that you enjoy all the benefits that it provides. &nbsp;Please read these terms carefully since by registering with and using LeGuard, you are accepting and agreeing to the following Terms and Conditions, which have legal effect, including but not limited to, a notice provision, limitations of liability, and time limitations on claims. Your acceptance of these Terms and Conditions constitutes an acknowledgment that you have read and understand all the provisions herein.</span></p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'upper-roman', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Definitions Used in these Terms and Conditions</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“Client” is the purchaser of LeGuard Inc.’s products and/or services.</p>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“Law Firm” is the entity or group providing substantive legal or other services to a Client, and whose users will use or administer the LeGuard software as a service.</p>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“LeGuard” is the software as a service provided by LeGuard, Inc.</p>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“LeGuard, Inc.” or “we” is the corporation providing a limited right for use of the LeGuard software as a service by Client, Law Firm and You.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“Matter” is a Client’s specific legal case or project for which a Law Firm’s users perform substantive legal or other services and track their time via LeGuard.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“Order Form” is the contract formed between Client and LeGuard, Inc.&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“Party” or “party” is You or LeGuard, Inc. “Parties” or “parties” is both You and LeGuard, Inc.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>“You” or “Your” is anyone registering in or using LeGuard including, without limitation, any Client or Law Firm user or administrator.</p>
                        <br/>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Limited Right to Access; Maintenance Support; Ownership</span></u></li>
                            </ol>
                        </div>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>As stated in the Client’s Order Form, Client’s purchase of products or services from LeGuard, Inc. grants Client a limited right (which is non-transferable and non-exclusive) to access and use LeGuard, and to direct and designate Law Firm and Client users or administrators such as You to access and use LeGuard. So long as Client’s account is current and Order Form is in effect, LeGuard, Inc. shall exercise commercially reasonable efforts to provide maintenance and support of LeGuard for Your use. In our constant effort to improve our service, LeGuard, Inc. reserves the right at any time, with or without notice to You, to modify or discontinue, temporarily or permanently, LeGuard services or features. LeGuard, Inc. shall not be liable to You or any third party for any modification, suspension, or discontinuance of LeGuard services or any feature. To protect the integrity of the LeGuard Inc. product and services, including, without limitation, from denial of service attacks, or illegal or inappropriate usage, LeGuard, Inc. reserves the right at any time in its sole discretion to block users from certain IP addresses to access LeGuard.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>LeGuard, Inc. owns all rights, title, and interest, including all related intellectual and proprietary property rights, in and to LeGuard’s technology, content, and services. LeGuard, Inc. is, and shall be, the sole owner of all copyrights, trademarks, trade names, service marks, patents, inventions, discoveries, updates, improvements, modifications, enhancements, and other intellectual property comprising or relating to LeGuard and LeGuard, Inc.’s product and services (collectively hereinafter “Intellectual Property”) , and retains the exclusive right to reproduce, publish, patent, copyright, trademark, sell, license, or otherwise make use of such Intellectual Property. The LeGuard name, logo, and the product names associated with LeGuard’s product and services are trademarks of LeGuard, Inc., and no right or license is granted to You to use them. You agree not to copy, modify, transmit, create any derivative works from, make use of, or reproduce in any way any copyrighted material, trademarks, trade names, service marks, or other intellectual property or proprietary information accessible through, relating to, or associated with LeGuard, or to facilitate anyone else doing the same. You agree not to remove, obscure, or otherwise alter any proprietary notices appearing on any content, including copyright, trademark, and other intellectual property notices. By these Terms and Conditions, You grant LeGuard, Inc. a world-wide, perpetual, irrevocable, royalty-free license to use and incorporate into LeGuard any suggestion, enhancement request, recommendation, correction or other feedback provided by You relating to LeGuard and its operation.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Disclaimer of Warranty&nbsp;</span></u></li>
                            </ol>
                        </div>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>We provide LeGuard using a commercially reasonable level of care and promise to try our best to make sure you enjoy the use of LeGuard. But there are certain things that we do not promise about LeGuard.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>OTHER THAN AS&nbsp;EXPRESSLY&nbsp;SET OUT IN THESE TERMS&nbsp;AND CONDITIONS, NEITHER LEGUARD, INC. NOR ITS AUTHORIZED REPRESENTATIVES MAKE ANY SPECIFIC PROMISES ABOUT LEGUARD. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE SPECIFIC FUNCTION&nbsp;OF LEGUARD, OR&nbsp;ITS RELIABILITY, AVAILABILITY,&nbsp;OR&nbsp;ABILITY&nbsp;TO&nbsp;MEET YOUR NEEDS. WE PROVIDE LEGUARD “AS IS”. SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED&nbsp;WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, OR&nbsp;ACCURACY. LEGUARD, INC. DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN LEGUARD WILL MEET YOUR REQUIREMENTS, THAT THE OPERATION OF LEGUARD WILL BE UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN LEGUARD WILL BE CORRECTED.&nbsp;TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.&nbsp;NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LEGUARD, INC. OR A LEGUARD, INC. AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY.&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>ANY MATERIAL OR INFORMATION OBTAINED BY YOU THROUGH THE USE OF LEGUARD IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR DEVICE, COMPUTER SYSTEM, INTERNET ACCESS, DISPLAY DEVICE, OR LOSS OR CORRUPTION OF DATA THAT RESULTS OR MAY RESULT FROM THE USE OF LEGUARD AND/OR OBTAINING OF ANY SUCH MATERIAL OR INFORMATION. IF YOU DO NOT ACCEPT THIS LIMITATION OF LIABILITY, YOU ARE NOT AUTHORIZED TO USE OR OBTAIN ANY MATERIAL OR INFORMATION THROUGH LEGUARD.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
                        <br/>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Authorized Usage</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>Please do not use LeGuard in a way that violates any laws, infringes on anyone’s rights, is offensive, or interferes with LeGuard or any features of LeGuard and its use. &nbsp;By Your registration, LeGuard’s use is only for You, and you shall not, directly or indirectly, let or facilitate anyone in using LeGuard with your credentials besides You. &nbsp;And we need you to&nbsp;bear sole responsibility for ensuring that You restrict your usage of LeGuard as stated herein and to its authorized purpose of tracking fees and time on a live basis and viewing and administering the same.&nbsp;If we determine (in our sole discretion) that You have acted inappropriately, we reserve the right to prohibit You from using LeGuard and to take appropriate legal actions.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol start={22} style={{ marginBottom: '0in', listStyleType: 'upper-alpha', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Accuracy of Account Information</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>You are responsible for maintaining up-to-date, accurate, and truthful account information, including, but not limited to, names of Law Firm or Client user or administrator names, rates, email addresses and other such information (as applicable and hereinafter “Account Information”), and shall update such Account Information to be current and accurate on a regular basis. If the Account Information provided by You is false or fraudulent, LeGuard, Inc. reserves the right to suspend or terminate Your access to LeGuard, in addition to seeking any other legal or equitable remedies.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Internet Connection and Security</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>An Internet connection is required to access certain features of LeGuard. &nbsp;You will need to provide your own Internet access at your expense to use LeGuard. &nbsp;The LeGuard product and services may be subject to, and LeGuard, Inc. cannot be responsible for, any&nbsp;losses, claims, costs, penalties, fees, expenses, damages (including but not limited to special, consequential and punitive damages), fines, charges, liens, injuries, debts, liabilities, and expenses of every kind, including but not limited to reasonable attorneys’ fees and courts costs,&nbsp;and/or similar charges (collectively, “Costs”) of any limitations, delays, and other problems inherent in the use of the Internet and electronic communications.</p>
                        <br/>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>No Warranty of Fee-Tracking Accuracy</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>You bear sole responsibility for the accuracy of the fee and time tracking information inputted by You as a Law Firm user via LeGuard (i.e., Client Matters, start and stop times, activity descriptions, rates, etc.). &nbsp;You agree that LeGuard, Inc. is not responsible for any failure by You to utilize LeGuard and/or input information in a diligent, timely and accurate manner to track fees live.&nbsp;</p>
                        <br/>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Governing Law</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>These Terms and Conditions shall be construed and interpreted under, and governed by, the substantive laws of the State of New York without regard to the choice or conflicts of law provisions of any jurisdiction which would apply the laws of another jurisdiction.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Exclusive Choice of Forum</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>Any claims, disputes, lawsuits, demands, administrative proceedings or causes of action (“Action”) arising from or relating to You or&nbsp;anyone who You, directly or indirectly, let or facilitate using LeGuard including, without limitation, any other user or administrator&nbsp;(collectively, “Your Indemnitors”),&nbsp;and LeGuard and/or LeGuard, Inc. can only and exclusively be brought in a court of competent jurisdiction located in the Commonwealth of Virginia (which is LeGuard, Inc.’s principal place of business and state of incorporation, and the locale where the services are rendered). &nbsp; You and Your Indemnitors hereby consent to jurisdiction of the courts within the Commonwealth of Virginia AS THE SOLE AND EXCLUSIVE FORUM for any Action, and agree that any process, notice, motion, or other application to the courts within the Commonwealth of Virginia may be served outside the Commonwealth of Virginia by registered mail or by personal service, so long as the method used provides a reasonable time for appearance.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol start={24} style={{ marginBottom: '0in', listStyleType: 'upper-alpha', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>LIMITATION OF ACTIONS</span></u></li>
                            </ol>
                        </div>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>YOU AGREE THAT REGARDLESS OF ANY STATUTE OR LAW TO THE CONTRARY, ANY ACTION MUST BE FILED WITHIN ONE YEAR AFTER SUCH ACTION ACCRUED, OR SUCH ACTION IS FOREVER BARRED. &nbsp;FOR CLARIFICATION, ACTION IS A DEFINED TERM ABOVE.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Attorneys’ Fees and Costs; Prevailing Party Provision</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>In the event that an Action or other legal matter between LeGuard, Inc. and You is instituted, the substantially prevailing party in such dispute shall be entitled to recover any and all Costs, from the non-prevailing party including, without limitation, all Costs of appeals.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Force Majeure</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>If LeGuard Inc.’s performance of any part of these Terms and Conditions, or the performance of LeGuard, or the provision of services hereunder is prevented, hindered, delayed, or otherwise made impracticable due to anything outside LeGuard, Inc.’s reasonable control including, without limitation, any strike or other labor problem,&nbsp;flood, fire, explosion, war,&nbsp;civil unrest, act of God, act of terrorism, act of civil authority, act of vandalism, or technology failure (including network intrusions/failures/delays, denial of service attacks, and internet connectivity issues,&nbsp;computer or other technological failures involving hardware or software not within LeGuard, Inc.’s possession or reasonable control),&nbsp;LeGuard,&nbsp;Inc. shall be excused from performance to the extent that it is prevented, hindered or delayed. Provided LeGuard, Inc. takes reasonable steps to rectify the same, LeGuard, Inc. shall continue to be excused from performance and these Terms and Conditions shall be deemed suspended so long as and to the extent that any such cause shall operate to prevent, hinder, or delay the performance by LeGuard, Inc. of its obligations.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Indemnification</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>You agree to indemnify, defend, protect and hold LeGuard, Inc. and its&nbsp;officers, directors, employees, agents, assigns, and successors in interest (collectively the “Indemnitees”) harmless from and against any and all Costs, arising from, relating to, or resulting from, in whole or in part: (1) the use of LeGuard by You or Your Indemnitors, (2) any breach, default, or violation of these Terms and Conditions by You or Your Indemnitors; and/or (3) violation of local, state, federal, or international rules, laws, codes, ordinances, regulations or treaties by You or Your Indemnitors.&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Additional Disclaimers; Limitation of Liability; Waiver of Consequential Damages</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>Law Firm and You acknowledge and agree that neither LeGuard, Inc. nor its Indemnitees are responsible for, and such entities and individuals shall have no liability, directly or indirectly, for any Action or Cost arising from, relating to, or resulting from: (1) any incorrect or inaccurate content, information, or data inputted via LeGuard by Client, Law Firm, You or Your Indemnitors; (2) any error, omission or defect in, interruption, deletion, alteration, delay in operation or transmission, theft or destruction of, or unauthorized access to, any content inputted by a LeGuard user or administrator or such user or administrator communications; or (3) any problems, failure, or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, or LeGuard services on account of technical problems or traffic congestion on the Internet.&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>YOU AGREE AND ACKNOWLEDGE THAT LEGUARD, INC.’S AND ITS INDEMNITEES LIABILITY WITH RESPECT TO ANY ACTION, OR OTHER LEGAL MATTER THAT IS BROUGHT AGAINST LEGUARD, INC. BY YOU SHALL BE LIMITED TO $10,000.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>IN NO EVENT SHALL LEGUARD, INC. OR THE INDEMNITEES BE LIABLE TO YOU OR YOUR INDEMNITORS FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF SIMILAR TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE).</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>LeGuard’s Suspension or Termination for Breach or Default</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>If You or Your Indemnitors breach or default upon any of the obligations set forth in these Terms and Conditions, LeGuard, Inc. has the exclusive and sole right to suspend or terminate Your access to LeGuard products and services. In the event of such suspension or termination, LeGuard, Inc. shall not be responsible for any Costs related to suspension, interruption, termination or cessation of Law Firm’s or Your fee-tracking. These Terms and Conditions survive such suspension or termination, and continue in full force and effect, except for any terms that, by their nature, expire or are fully satisfied.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Severability</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>If any clause or provision of these Terms and Conditions shall be held invalid or unenforceable, in whole or in part, in any jurisdiction, such invalidity or unenforceability shall attach only to such clause or provision, or part thereof, and shall not in any manner affect any other clause or provision in any jurisdiction.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Notice</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>All notices under these Terms and Conditions shall be in writing and shall be deemed to be delivered five (5) business days after being deposited in the United States Postal Service, postage prepaid, the next business day if sent by recognized overnight courier, or when sent by e-mail (with proof of electronic delivery). Notices to LeGuard, Inc. shall be addressed to: LeGuard, Inc., 1751 Pinnacle Drive, Suite 1100, McLean, VA 22102. Notices to You shall be sent to the email address listed for You in LeGuard.&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Complete Agreement; Waiver</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>These Terms and Conditions constitutes the complete and exclusive agreement between You and LeGuard, Inc. and supersedes all prior express or implied agreements or understandings between the Parties concerning LeGuard and its use.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>Any waiver by LeGuard, Inc. of any performance by You of any of LeGuard, Inc.’s rights under these Terms and Conditions, shall not be, and shall not be deemed to be, a waiver of any subsequent obligation of performance, default, or right of the same or similar kind.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Non-Assignment</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>You may not assign any of your rights or obligations under these Terms and Conditions to any third party or entity, and any rights or obligations hereunder may not be involuntarily assigned or assigned by operation of law, without the prior written consent of LeGuard, Inc.</p>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '.0001pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>&nbsp;</p>
                        <div style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>
                            <ol style={{ marginBottom: '0in', listStyleType: 'undefined', marginLeft: '-0.25in' }}>
                                <li style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}><u><span style={{ fontFamily: 'Calibri' }}>Authority</span></u></li>
                            </ol>
                        </div>
                        <br/>
                        <p style={{ marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '.25in', lineHeight: '106%', fontSize: '15px', fontFamily: '"Calibri",sans-serif' }}>You represent and warrant that you have the legal power and authority to accept these Terms and Conditions.</p>                    </ModalBody>
                </Modal>

            </Fragment >
        );
    }
}

export default UserRegistration;