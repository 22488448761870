import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader,
    ModalBody, Row, Col
} from 'reactstrap';
import { api, userService } from '../../../_services';
import GeneralGrid from '../../../Components/GeneralGrid';

const SearchUsersModal = ({ showModal, toggleModal, roleId, partnershipId, onUserSelect, size }) => {

    let [userList, setUserList] = useState([]);
    let currentUser = userService.getLoggedInUser();
    useEffect(() => {
        if (showModal) {
            api.getUserListByUserIdIncludePartnership(currentUser.userId, partnershipId).then(data =>{
                setUserList(data);
            })
        }
    }, [showModal]);

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            // filter: textFilter(),
            headerStyle: () => {
                return { width: "25%" };
            },
            formatter: (cellContent, row) => {
                return (
                    <a href='#' onClick={(e) => selectUser(row, e)}>{cellContent}</a>
                );
            }
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            headerStyle: () => {
                return { width: "35%" };
            }
        },
        {
            dataField: 'entityName',
            text: 'Entity',
            sort: true,
            headerStyle: () => {
                return { width: "25%" };
            }
        },
        {
            dataField: 'isPartnershipMember',
            text: 'In Partnership',
            sort: true,
            headerStyle: () => {
                return { width: "15%" };
            },
            formatter: (cellContent, row) =>{
                return row.isPartnershipMember ? 'Yes' : 'No';
            }
        }

    ];

    const rowStyleFormatter = (row, rowIndex) => {
        const style = {};

        if (row.isPartnershipMember){
            style.backgroundColor = 'lightgreen';
        }
        return style;
    };

    const defaultSorted = [{
        dataField: 'isPartnershipMember',
        order: 'desc'
    }];

    const options = {
        sizePerPageList: [
            {
                text: '10',
                value: 10
            }
        ],
        hideSizePerPage: false
    }

    const selectUser = (user, e) => {
        e && e.preventDefault();
        onUserSelect(user);
    }


    return (
        <Modal size={size} isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>Search Users</ModalHeader>
            <ModalBody>
                <Row>
                    <GeneralGrid colWidth={12}
                        striped
                        title="Search Users"
                        keyField="userId"
                        dataSource={userList}
                        columns={columns}
                        onAdd={(e) => selectUser(undefined, e)}
                        defaultSorted={defaultSorted}
                        options={options}
                        rowStyle={rowStyleFormatter}
                    />
                   
                </Row>
                <Row>
                    <Col md="12">
                        <Button color="link" onClick={() => toggleModal()}>Cancel</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default SearchUsersModal;