import React, { Fragment } from 'react';
import {
    Button, Card, CardBody, Modal, ModalHeader, ModalBody,
    Row, Col, CustomInput, Table
} from 'reactstrap';
import PageTitle from '../../Layout/AppMain/PageTitle';
import Switch from "react-switch";
import { api, userService } from '../../_services';
import GeneralCheckboxList from '../../Components/GeneralCheckboxList';
class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMattersModal: false,
            showPartnershipsModal: false,
            alertList: [],
            matterList: [],
            partnershipList: [],
            selectedAlert: {
                allPartnerships: false
            }
        };
        this.currentUser = userService.getLoggedInUser();
    }

    componentDidMount() {
        this.loadAlerts();
    }

    loadAlerts = () => {
        api.getAlertList(this.currentUser.userId).then(data => {
            let list = this.currentUser.highestRole == 2 ? data.filter(x => x.notificationTypeId == 1 || x.notificationTypeId == 2) : data;
            this.setState({ alertList: list });
        })
    }

    handleSwitchChange = (alert) => {
        let alerts = [...this.state.alertList];
        let a = alerts.find(x => x.notificationTypeId == alert.notificationTypeId);
        a.isOn = !a.isOn;
        api.updateAlertStatus(a.notificationTypeId, this.currentUser.userId, a.isOn, this.currentUser.userId).then(data => {
            this.loadAlerts();
        })
        this.setState({ alertList: alerts, selectedAlert: alert });
    }

    toggleMattersModal = (alert) => {
        this.setState({ showMattersModal: !this.state.showMattersModal, matterList: [] });
        api.getMatterListForAlerts(this.currentUser.userId, this.currentUser.entityId).then(data => {
            this.setState({ matterList: data });
        })
    }

    togglePartnershipsModal = (alert) => {
        this.setState({ showPartnershipsModal: !this.state.showPartnershipsModal, partnershipList: [] });
        if (alert !== undefined) {
            this.setState({ selectedAlert: alert });
            api.getPartnershipListForAlerts(this.currentUser.userId, this.currentUser.entityId, alert.notificationTypeId).then(data => {
                if (alert.allPartnerships)
                    data = data.map(x => { x.isSelected = true; return x; });
                this.setState({ partnershipList: data });
            })
        }
    }

    onPartnershipsSave = (partnershipList) => {
        api.updateAlertPartnerships(this.currentUser.userId, this.currentUser.userId, this.state.selectedAlert, partnershipList.filter(x => x.isSelected)).then(data => {
            this.loadAlerts();
            this.togglePartnershipsModal();
        })
    }

    toggleMattersModal = (alert) => {
        this.setState({ showMattersModal: !this.state.showMattersModal, matterList: [] });
        if (alert !== undefined) {
            this.setState({ selectedAlert: alert });
            api.getMatterListForAlerts(this.currentUser.userId, this.currentUser.entityId, alert.notificationTypeId).then(data => {
                this.setState({ matterList: data });
            })
        }
    }

    onMattersSave = (matterList) => {
        api.updateAlertMatters(this.currentUser.userId, this.currentUser.userId, this.state.selectedAlert, matterList.filter(x => x.isSelected)).then(data => {
            this.loadAlerts();
            this.toggleMattersModal();
        })
    }

    render() {
        return (
            <Fragment>
                <PageTitle heading="Manage Alerts" />
                <Row>
                    <Col md="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th width="40%">Alert</th>
                                            <th width="20%">Status</th>
                                            <th width="20%">Partners</th>
                                            <th width="20%">Matters</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.alertList.length > 0 &&
                                            this.state.alertList.map(x => {
                                                return <tr key={x.notificationTypeId}>
                                                    <td>{x.name}</td>
                                                    <td>
                                                        <Switch
                                                            checked={x.isOn}
                                                            onChange={(e) => this.handleSwitchChange(x)}
                                                            className="mr-2 mb-2"
                                                        />
                                                    </td>
                                                    <td>
                                                        {x.selectPartnerships ?
                                                            (x.isOn ? <a href='#' onClick={(e) => { e.preventDefault(); this.togglePartnershipsModal(x); }}>{x.allPartnerships ? 'All' : 'Multiple'}</a> : x.allPartnerships ? 'All' : 'Multiple')
                                                            : "N/A"
                                                        }
                                                    </td>
                                                    <td>
                                                        {x.selectMatters ?
                                                            (x.isOn ? <a href='#' onClick={e => { e.preventDefault(); this.toggleMattersModal(x); }}>{x.allMatters ? 'All' : 'Multiple'}</a> : x.allMatters ? 'All' : 'Multiple')
                                                            : "N/A"
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={this.state.showPartnershipsModal} toggle={() => this.togglePartnershipsModal()} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={() => this.togglePartnershipsModal()}>Select Partners</ModalHeader>
                    <ModalBody>
                        <GeneralCheckboxList
                            list={this.state.partnershipList}
                            headers={[
                                {
                                    label: "Partner",
                                    width: "70%"
                                },
                                {
                                    label: 'Agreement',
                                    width: '20%'
                                }
                            ]}
                            props={["partnershipName", (obj) => {
                                return obj.isSelected ? 'All Matters' : 'No Matters';
                            }]}
                            keyProperty="partnershipId"
                            postSelectAllAction={(flag) => this.setState(prevState => ({
                                ...prevState,
                                selectedAlert: {
                                    ...prevState.selectedAlert,
                                    allPartnerships: flag
                                }
                            }))}
                            onSave={(list) => this.onPartnershipsSave(list)}
                            selected={this.state.selectedAlert.allPartnerships}
                        />
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.showMattersModal} toggle={() => this.toggleMattersModal()} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={() => this.toggleMattersModal()}>Select Matters</ModalHeader>
                    <ModalBody>
                        <GeneralCheckboxList
                            list={this.state.matterList}
                            headers={[
                                {
                                    label: "Partner",
                                    width: "50%"
                                },
                                {
                                    label: 'Matter',
                                    width: '40%'
                                }
                            ]}
                            props={["partnershipName", "matterName"]}
                            keyProperty="matterId"
                            postSelectAllAction={(flag) => this.setState(prevState => ({
                                ...prevState,
                                selectedAlert: {
                                    ...prevState.selectedAlert,
                                    allMatters: flag
                                }
                            }))}
                            onSave={(list) => this.onMattersSave(list)}
                            selected={this.state.selectedAlert.allMatters}
                        />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default Alerts;

