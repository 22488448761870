
import React, { Fragment, useState, useEffect } from 'react';
import { api, userService } from '../../../_services';
import GeneralGrid from '../../../Components/GeneralGrid';
import { CustomInput, Card, CardBody, Table, Input, Row, Col, Button, UncontrolledTooltip } from 'reactstrap';
import {
    toast
} from 'react-toastify';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfoCircle } from '@fortawesome/fontawesome-free-solid';
const UserPartnership = ({ userId, currencyList }) => {


    let [partnershipMatterRoleList, setPartnershipMatterRoleList] = useState([]);
    const currentUser = userService.getLoggedInUser();

    const successToastOpts = {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: false
    };

    useEffect(() => {
        if (userId > -1)
            getList();
    }, [userId])

    const getList = () => {
        api.getPartnershipMatterRoleList(userId, currentUser.entityId).then(data => {
            setPartnershipMatterRoleList(
                data.map(x => {
                    x.currencyId = x.currencyId == null ? 3 : x.currencyId;
                    if (x.partnershipRate !== null)
                        x.partnershipRate = x.partnershipRate.toString().indexOf('.') > -1 ? x.partnershipRate.toString() : x.partnershipRate.toString() + '.00';
                    return x;
                })
            );
        })
    }

    const save = () => {
        var found = partnershipMatterRoleList.find(x => (x.roleId == 2 || x.roleId == 3) && (x.partnershipRate == null || x.partnershipRate == ''));
        if (found)
            swal("Cannot save a user without a default rate. Please provide a rate for each relation.");
        else
            api.savePartnershipMatterRoleList(userId, partnershipMatterRoleList).then(data => {
                let msg = `User ${currentUser.entityRoleId == 1 ? 'Law Firm' : 'Client'} relations saved successfuly`;
                toast(msg, successToastOpts);
            })
    }

    return (
        <Fragment>
            <h5>User Roles at the {currentUser.entityRoleId == 2 ? 'Client' : 'Law Firm'} Level {' '}
                <span id="userpartnershiptooltip" style={{ cursor: 'pointer' }}><FontAwesomeIcon style={{ color: 'darkred' }} icon={faInfoCircle} /></span>
                <UncontrolledTooltip target="userpartnershiptooltip">
                    •	Here you select the User’s role at the Client/Law firm Level (Viewer only or Biller). User’s with view access at this level can see all matters for this Client/ Law Firm unless the matter is marked discretionary.
                <br />
                •	If the User is a Biller, you can set the default rate that will be entered whenever you create a new matter for that Client and put the Biller on that matter.  It saves time so you do not have to keep putting the biller’s rate in for each new matter.
                <br />
                Remember to Save any changes before moving on to other areas within this page or LeGuard.
                </UncontrolledTooltip>
            </h5>
            <Card className="main-card mb-3">
                <CardBody>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th width='35%'>{currentUser.entityRoleId == 1 ? 'Law Firm' : 'Client'}</th>
                                <th width='10%'>Viewer</th>
                                {currentUser.entityRoleId !== 1 &&
                                    <Fragment>
                                        <th width='10%'>Biller</th>
                                        <th width='15%'>Rate / h</th>
                                        <th width='30%'>Currency</th>
                                    </Fragment>
                                }
                            </tr>

                        </thead>
                        <tbody>
                            {partnershipMatterRoleList.length > 0 && partnershipMatterRoleList.map(p => {
                                return (
                                    <tr key={`p-${p.partnershipId}`}>
                                        <td>{p.partnershipName}</td>
                                        <td>
                                            <CustomInput type="checkbox" id={`v-${p.partnershipId}`} checked={p.roleId !== 0 && p.roleId == 1}
                                                onChange={(e) => {
                                                    let partnership = partnershipMatterRoleList.find(x => x.partnershipId == p.partnershipId);
                                                    partnership.roleId = partnership.roleId == 1 ? 0 : 1;
                                                    let lst = [...partnershipMatterRoleList];
                                                    let index = lst.findIndex(x => x.partnershipId == partnership.partnershipId);
                                                    lst[index] = partnership;
                                                    setPartnershipMatterRoleList(lst);
                                                }}
                                            />
                                        </td>
                                        {currentUser.entityRoleId !== 1 &&
                                            <Fragment>
                                                <td>
                                                    <CustomInput type="checkbox" id={`b-${p.partnershipId}`} checked={p.roleId !== 0 && (p.roleId == 2 || p.roleId == 3)}
                                                        onChange={(e) => {
                                                            let partnership = partnershipMatterRoleList.find(x => x.partnershipId == p.partnershipId);
                                                            partnership.roleId = partnership.roleId == 2 ? 0 : 2;
                                                            let lst = [...partnershipMatterRoleList];
                                                            let index = lst.findIndex(x => x.partnershipId == partnership.partnershipId);
                                                            lst[index] = partnership;
                                                            setPartnershipMatterRoleList(lst);
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    {(p.roleId == 2 || p.roleId == 3) &&
                                                        <Input value={p.partnershipRate} readOnly={(p.userPartnershipId > 0 && p.partnershipRate && p.partnershipRate > 0) ? true : false}
                                                            onChange={(e) => {
                                                                let partnership = partnershipMatterRoleList.find(x => x.partnershipId == p.partnershipId);
                                                                partnership.partnershipRate = e.target.value;
                                                                let lst = [...partnershipMatterRoleList];
                                                                let index = lst.findIndex(x => x.partnershipId == partnership.partnershipId);
                                                                lst[index] = partnership;
                                                                setPartnershipMatterRoleList(lst);
                                                            }}
                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    {(p.roleId == 2 || p.roleId == 3) &&
                                                        <CustomInput type="select" id={`${p.partnershipId}-currencySelect`}
                                                            name={`${p.partnershipId}-currencySelect`} className='form-control'
                                                            onChange={(e) => {
                                                                let partnership = partnershipMatterRoleList.find(x => x.partnershipId == p.partnershipId);
                                                                partnership.currencyId = e.target.value;
                                                                let lst = [...partnershipMatterRoleList];
                                                                let index = lst.findIndex(x => x.partnershipId == partnership.partnershipId);
                                                                lst[index] = partnership;
                                                                setPartnershipMatterRoleList(lst);
                                                            }}
                                                            disabled={(p.userPartnershipId > 0 && p.partnershipRate && p.partnershipRate > 0) ? true : false}
                                                            value={p.currencyId > 0 ? p.currencyId : 2}>
                                                            {currencyList.map(currency => {
                                                                return <option key={`currency${p.partnershipId}-${currency.currencyId}`} value={currency.currencyId}>{currency.displayValue}</option>
                                                            })}
                                                        </CustomInput>
                                                    }
                                                </td>
                                            </Fragment>
                                        }
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>
                    <Row>
                        <Col md="12">
                            <Button color="primary" onClick={() => save()}>Save</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </Fragment>
    );

}

export default UserPartnership;