import React, { Fragment } from 'react';
import Loader from 'react-loaders'
import TreeView from '../../Components/TreeView';
import { api, userService } from '../../_services';
import {Row, Col, UncontrolledAlert} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/fontawesome-free-solid";
import _ from 'lodash';
import PageTitle from '../../Layout/AppMain/PageTitle';
export default class ManageCompany extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            groupedList: [],
            entityList: []
        }

        this.currentUser = userService.getLoggedInUser();
    }

    componentDidMount() {
        this.loadEntities();
    }

    loadEntities() {
        api.getEntityListByEntityId(this.currentUser.entityId).then(data => {
            this.setState({ entityList: data });
            let gg = _.forEach(data, f => {
                f.nodes = _.filter(data, g => g.parentId == f.entityId);
            })
            let groupedList = _.filter(data, f => f.parentId == 0);
            this.setState({ groupedList: groupedList, isLoading: false });
        })
    }

    render() {
        return (
            <Fragment>
                <PageTitle heading="Company Structure" />
                <Row>
                    <Col md='12'>
                        <UncontrolledAlert color="warning">
                            <h4><FontAwesomeIcon icon={Icons.faInfo} /> Alert</h4>
                            This is an advanced administration area. Please contact LeGuard if you have any questions using this feature.
                                    </UncontrolledAlert>
                    </Col>
                </Row>
                {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
                {
                    !this.state.isLoading &&
                    <TreeView
                        list={this.state.groupedList}
                        entityList={this.state.entityList}
                        reloadEntities={() => this.loadEntities()}
                    />
                }
            </Fragment>
        );
    }
}
