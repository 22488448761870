import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Card, CardBody, UncontrolledAlert, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faSave } from '@fortawesome/free-solid-svg-icons';
import { api, userService, authenticationService } from '../../_services';
import { toast } from 'react-toastify';
import PageTitle from '../../Layout/AppMain/PageTitle';
import swal from 'sweetalert';

const ChangePassword = ({ }) => {

    let [oldPwd, setOldPwd] = useState('');
    let [oldPwdErrorMessage, setOldPwdErrorMessage] = useState('Password does not match');
    let [newPwd, setNewPwd] = useState('');
    let [confirmNewPwd, setConfirmNewPwd] = useState('');
    let [confirmPwdError, setConfirmPwdError] = useState(false);
    let [oldPwdError, setOldPwdError] = useState(false);
    let currentUser = userService.getLoggedInUser();
    let [submitting, setSubmitting] = useState(false);
    const save = () => {
        if (newPwd === confirmNewPwd) {
            setSubmitting(true);
            if (currentUser.tempPasswordSetDate !== undefined && currentUser.tempPasswordSetDate !== null) {
                api.resetPassword(currentUser.userId, newPwd).then(data => {
                    if (data !== '') {
                        swal(data, {
                            buttons: {
                                ok: {
                                    text: "Ok",
                                    value: "ok",
                                    className: "btn-success"
                                }
                            },
                            icon: 'warning'
                        });
                    } else {
                        authenticationService.login(currentUser.email, newPwd).then(data => window.location.reload());
                    }
                })
            } else {
                api.changePassword(currentUser.entityId, currentUser.email, oldPwd, newPwd).then(data => {
                    if (data !== '') {
                        setOldPwdErrorMessage(data);
                        setOldPwdError(true);
                    } else {
                        toast("Password changed successfuly", { autoClose: 3000, type: toast.TYPE.SUCCESS, hideProgressBar: false });
                    }
                    setSubmitting(false);
                }, error => setSubmitting(false));
            }
        } else {
            setConfirmPwdError(true);
        }
    }
    const cancel = () => {
        if (currentUser.tempPasswordSetDate !== undefined && currentUser.tempPasswordSetDate !== null) {
            this.props.history.push({
                pathname: `/logout`
            });
        }
    }
    return (
        <Fragment>
            <PageTitle heading="Change Password" />
            <Row>
                <Col md="8">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <Col md="12">
                                {currentUser.tempPasswordSetDate !== undefined && currentUser.tempPasswordSetDate !== null &&
                                    <UncontrolledAlert color="danger">
                                        <h4><FontAwesomeIcon icon={faInfo} /> Alert</h4>
                                        If you have saved any activity notes they were encrypted using your password and this new password will
                                        not be able to decrypt those notes, so they will be lost. Please make every effort to find or remember
                                        your password if you don’t want to lose those notes. Click cancel to go back to the login screen.
                                    </UncontrolledAlert>
                                }
                                <UncontrolledAlert color="warning">
                                    <h4><FontAwesomeIcon icon={faInfo} /> Alert</h4>
                                    Changing your password will update all your existing notes to encrypt them with the new password.
                                </UncontrolledAlert>
                            </Col>
                            {(currentUser.tempPasswordSetDate == undefined || currentUser.tempPasswordSetDate == null) &&
                                <div className="form-group">
                                    <label htmlFor="oldPassword">Old Password *</label>
                                    <input className='form-control' type="password" value={oldPwd} onChange={(e) => { setOldPwd(e.target.value); setOldPwdError(false); }} />
                                    {oldPwdError &&
                                        <div className="invalid-feedback"
                                            style={{ display: 'block' }}>
                                            {oldPwdErrorMessage}
                                        </div>
                                    }
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor="newPassword">New Password *</label>
                                <input className='form-control' type="password" value={newPwd} onChange={(e) => setNewPwd(e.target.value)} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="confirm">Confirm Password *</label>
                                <input className='form-control' type="password" value={confirmNewPwd} onChange={(e) => { setConfirmNewPwd(e.target.value); setConfirmPwdError(false); }} />
                                {confirmPwdError &&
                                    <div className="invalid-feedback"
                                        style={{ display: 'block' }}>
                                        New Password and Confirm Password do not match
                                    </div>
                                }
                            </div>

                            <Button color="link" onClick={() => cancel()}>Cancel</Button>
                            <Button color="primary" style={{ marginLeft: '.25rem' }} onClick={() => save()} disabled={submitting}>Save</Button>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default ChangePassword;