import React, { PureComponent, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    Button, Modal, ModalHeader, Card, CardBody,
    ModalBody, Row, Col, CustomInput, CardTitle
} from 'reactstrap';
import swal from 'sweetalert';

const UsersGrid = (props) => {

    //let [loading, setLoading] = useState(false);

    let addBiller = (e) => {
        //setLoading(true);
        e && e.preventDefault();
        if (props.partnership.partnershipId == -1) {
            swal('Please save the partnership first.', {
                buttons: {
                    ok: {
                        text: "Ok",
                        value: "ok",
                        className: "btn-success"
                    }
                },
                icon: 'warning'
            });
        } else {

            
            props.toggleAddEditUserModal(props.roleId);

        }
    }

    const defaultSorted = [{
        dataField: 'name',
        order: 'asc'
    }];

    const options = {
        sizePerPageList: [
            {
                text: '50',
                value: 50
            }
        ],
        hideSizePerPage: true
    }
    return (
        <Fragment>
            {/* {loading && <Loader style={{}} type="ball-pulse" active />}
            {!loading && */}
                <Col md={props.colWidth}>
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle>{props.title}</CardTitle>
                            {props.subtitle && <p>{props.subtitle}</p>}
                            <div className="table-responsive">
                                <BootstrapTable
                                    bootstrap4
                                    striped
                                    keyField="userId"
                                    data={props.userList}
                                    columns={props.columns}
                                    defaultSorted={defaultSorted}
                                />
                            </div>
                            <Button color="primary" onClick={addBiller}>{props.buttonText}</Button>
                        </CardBody>
                    </Card>
                </Col>
            {/* } */}
        </Fragment>
    );
}

UsersGrid.propTypes = {
    title: PropTypes.string.isRequired,
    userList: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    colWidth: PropTypes.number.isRequired,
    roleId: PropTypes.number.isRequired,
    toggleAddEditUserModal: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired
}
export default UsersGrid;