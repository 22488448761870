import React, { Fragment } from 'react';
import { api, authenticationService } from '../../_services';
import { GeneralForm } from '../../Components/GeneralForm';
import AppFooter from '../../Layout/AppFooter/';
import AppHeader from '../../Layout/AppHeader/';
import ThemeOptions from '../../Layout/ThemeOptions/';
import swal from 'sweetalert';
import { Col, Row } from 'reactstrap';

class GeneralRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {
                username: '',
                email: '',
                timezoneId: 114,
                password: '',
                confirmpassword: '',
                billerTypeId: '',
                currencyId: 3
            },
            currencyList: [],
            timezoneList: []
        }
    }

    componentDidMount() {
        api.getCurrencyList().then(data => {
            this.setState({ currencyList: data });
        });
        api.getTimezoneList().then(data => {
            this.setState({ timezoneList: data });
        })
    }

    register = (user) => {
        user.username = user.email;
        if (user.status !== undefined && user.status == -1) {
            api.unregisterUser(user).then(data => {
                if (data !== null && data.length > 0) {
                    swal({
                        title: data,
                        text: "",
                        icon: "warning",
                        buttons: {
                            cancel: "Close"
                        },
                        dangerMode: true,
                    })
                } else {
                    swal({
                        title: "",
                        text: "You have unregistered from LeGuard",
                        icon: "success",
                        buttons: {
                            cancel: "Close"
                        }
                    })
                }
            })
        } else {
            api.registerUser(user).then(data => {
                if (data !== null && data.length > 0) {
                    swal({
                        title: data,
                        text: "",
                        icon: "warning",
                        buttons: {
                            cancel: "Close"
                        },
                        dangerMode: true,
                    })
                } else {
                    authenticationService.login(user.username, user.password).then(user => {
                        this.props.history.push({ pathname: "/registrationComplete" });
                    })
                }
            })
        }
    }

    render() {
        const formProps = [

            {
                type: 'input',
                fieldType: 'email',
                property: 'email',
                label: 'Email',
                isRequired: true,
                positioning: 'left'
            },
            {
                type: 'input',
                property: 'postal',
                label: 'Zip / Postal code',
                positioning: 'right'
            },
            {
                type: 'dropdown',
                property: 'timezoneId',
                label: 'Default Timezone',
                dropdownData: this.state.timezoneList.map(x => {
                    x.id = x.timezoneId;
                    x.name = x.winDisplayName;
                    return x;
                }),
                positioning: 'right'
            },
            {
                type: 'dropdown',
                property: 'currencyId',
                label: 'Default Currency',
                dropdownData: this.state.currencyList.map(x => {
                    x.id = x.currencyId;
                    x.name = x.displayValue;
                    return x;
                }),
                positioning: 'right'
            },
            {
                type: 'input',
                fieldType: 'password',
                property: 'password',
                label: 'Password',
                isRequired: true,
                positioning: 'left'
            },
            {
                type: 'input',
                fieldType: 'password',
                property: 'confirmPassword',
                label: 'Confirm Password',
                positioning: 'left'
            }

        ]

        return (
            <Fragment>
                <ThemeOptions />
                <AppHeader hideMenu={true} />
                <div className="app-main" style={{ overflow: 'hidden' }}>
                    <div style={{ width: '100%' }}>
                        {/* <PageTitle heading="Register User" style={{ marginTop: '20px' }} /> */}
                        <div style={{ width: '100%', padding: '30px', fontSize: 'large', textAlign: 'center', backgroundColor: 'rgba(255, 255, 255, 0.55)', fontWeight: 'bold' }}>
                            User Registration
                        </div>
                        <Row>
                            <Col md="2"></Col>
                            <Col md="8">
                                <GeneralForm
                                    data={this.state.user}
                                    propertyTypes={formProps}
                                    onCancel={() => this.props.history.push({ pathname: '/login' })}
                                    //onDelete={userToEdit.userId == -1 ? undefined : saveUser}
                                    showSave={true}
                                    onSave={(user) => this.register(user)}
                                    saveButtonText="Register"
                                    customButton={true}
                                    customValidatorRules={
                                        [
                                            {
                                                property: 'confirmPassword',
                                                validate: (user) => {
                                                    if (user.password === user.confirmPassword)
                                                        return true;
                                                    return false;
                                                },
                                                errorMessage: "Password and Confirm Password don't match"
                                            }
                                        ]
                                    }

                                />
                            </Col>
                        </Row>
                        <AppFooter margin='0px' />
                    </div>
                </div>
            </Fragment >
        );
    }
}

export default GeneralRegistration;