import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Loader from "react-loaders";
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import GeneralGrid from "../../../Components/GeneralGrid";
import { DateTimeHelper } from "../../../_helpers/dateTimeHelper";
import { api, userService } from "../../../_services";
import { currencyFormatter } from "../../../_services/currencyFormatter";
import EditActivityModal, {
  getDurationInHours,
} from "../../Activity/Components/EditActivityModal";

const MatterActivityList = (props) => {
  const matterId = props.match.params.matterId;
  const currentUser = userService.getLoggedInUser();
  const [activityList, setActivityList] = useState([]);
  const [billerList, setBillerList] = useState([]);
  const [matter, setMatter] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBillerId, setSelectedBillerId] = useState(-1);
  const [gridColumns, setGridColumns] = useState([]);
  const [userMatter, setUserMatter] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState({ activityId: -1 });
  const [showActivityDetailsModal, setShowActivityDetailsModal] =
    useState(false);
  const [showEditActivityModal, setShowEditActivityModal] = useState(false);

  useEffect(() => {
    api.getMatterById(matterId).then((m) => {
      setMatter(m);
    });
    api.getBillerListByMatterId(matterId).then((b) => {
      setBillerList(b);
    });
    api.getUserMatter(matterId, currentUser.userId).then((um) => {
      setUserMatter(um);
      loadGridColumns(um);
    });
  }, []);

  useEffect(() => {
    if (userMatter !== null) {
      loadActivities();
    }
  }, [userMatter]);

  const loadActivities = () => {
    setIsLoading(true);

    let dates = getDates();
    api
      .getActivitiesByMatterIdDateRange(
        matterId,
        dates.startDate,
        dates.endDate,
        userMatter.roleId == 2 && currentUser.systemRoleId == 1
          ? currentUser.userId
          : selectedBillerId
      )
      .then(
        (activities) => {
          setIsLoading(false);
          setActivityList(activities);
        },
        (err) => setIsLoading(false)
      );
  };

  const saveAndClose = () => {
    toggleActivityDetailsModal();

    api.updateActivity(selectedActivity).then(
      (data) => {
        loadActivities();
      },
      (err) => {}
    );
  };

  const removeActivity = (activity, callback) => {
    saveActivity({ ...activity, isDeleted: true }, callback);
  };

  const hideActivity = (activity, callback) => {
    // activity.isHidden = !activity.isHidden;
    // this.saveActivity(activity, callback);
  };

  const saveActivity = (activity, callback, addAnother) => {
    api.updateActivity(activity).then((data) => {
      if (data !== undefined && data !== null && data !== "") {
        callback && callback(data);
      } else {
        loadActivities();
        if (!addAnother) {
          toggleEditActivityModal();
        }
        callback && callback("");
      }
    });
  };

  const loadGridColumns = (um) => {
    let columns = [];

    columns = [
      {
        dataField: "activityId",
        text: "",
        sort: true,
        headerStyle: () => {
          return { width: "5%" };
        },
        formatter: (cell, row) => {
          if (
            row.authBillerId == currentUser.userId ||
            currentUser.systemRoleId == 2
          ) {
            return (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedActivity(row);
                  toggleEditActivityModal();
                }}
              >
                Edit
              </a>
            );
          }
        },
      },
      {
        dataField: "startTime",
        text: "Date",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cell, row) => {
          return DateTimeHelper.getUsersDateTimeStringFromUtc(cell, "L");
        },
      },
      {
        dataField: "authBillerName",
        text: "Attorney",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "activityCodeTitle",
        text: "Activity",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "startTime",
        text: "Duration",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cell, row) => {
          return getDurationInHours(row.startTime, row.endTime) + " hours";
        },
      },
      {
        dataField: "rate",
        text: "Rate",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cellContent, row) => {
          return currencyFormatter("$", cellContent);
        },
      },
      {
        dataField: "effectiveCost",
        text: "Amount",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cellContent, row) => {
          return currencyFormatter("$", cellContent);
        },
      },
      {
        dataField: "description",
        text: "Description",
        sort: true,
        headerStyle: () => {
          return { width: "43%" };
        },
        formatter: (cell, row) => {
          return row.encryptedNotes ? "" : cell;
        },
      },
    ];

    setGridColumns(
      currentUser.systemRoleId != 2 && um.roleId == 2
        ? columns
            .filter((x) => x.text !== "Rate" && x.text !== "Amount")
            .map((c) => {
              if (c.text == "Description") {
                c.headerStyle = () => {
                  return { width: "57%" };
                };
              }
              return c;
            })
        : columns
    );
  };

  const toggleActivityDetailsModal = () => {
    setShowActivityDetailsModal(!showActivityDetailsModal);
  };

  const toggleEditActivityModal = () => {
    setShowEditActivityModal(!showEditActivityModal);
  };

  const applyFilters = () => {
    loadActivities();
  };

  const getTimeInHoursMinutes = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  const getDates = () => {
    let start, end;
    let userNow = moment.tz("UTC").tz(currentUser.timezoneIanaName);
    switch (parseInt(selectedDateRange)) {
      case 0: //from today
        start = userNow.format("YYYY-MM-DD");
        end = userNow.format("YYYY-MM-DD");
        break;
      case 1: //from yesterday
        start = userNow.clone().subtract(1, "days").format("YYYY-MM-DD");
        end = userNow.clone().subtract(1, "days").format("YYYY-MM-DD");
        // end = userNow.format("YYYY-MM-DD");
        break;
      case 2: //from last 7 days
        start = userNow.clone().subtract(7, "days").format("YYYY-MM-DD");
        end = userNow.format("YYYY-MM-DD");
        break;
      case 3: //from last 30 days
        start = userNow.clone().subtract(30, "days").format("YYYY-MM-DD");
        end = userNow.format("YYYY-MM-DD");
        break;
      case 4: //this month
        start = userNow.clone().startOf("month").format("YYYY-MM-DD");
        end = userNow.clone().endOf("month").format("YYYY-MM-DD");
        break;
      case 5: //last month
        start = userNow
          .clone()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        end = userNow
          .clone()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case 6: //custom
        start = moment(startDate).format("YYYY-MM-DD");
        end = moment(endDate).format("YYYY-MM-DD");
        break;
    }
    return {
      startDate: start,
      endDate: end,
    };
  };

  const gridOptions = {
    sizePerPageList: [
      {
        text: "40",
        value: 40,
      },
    ],
    hideSizePerPage: false,
  };

  return (
    <Fragment>
      {matter !== null && (
        <h2>
          <b>{matter.matterName}</b>
        </h2>
      )}
      <div style={{ display: "inline-block", marginBottom: "20px" }}>
        <Button
          color="primary"
          onClick={() => {
            props.history.push({
              pathname: "/dashboard",
            });
          }}
        >
          &lt;&lt; Dashboard
        </Button>

        <Button
          color="primary"
          onClick={() => {
            props.history.push({
              pathname: `/matterDetails/${matterId}`,
            });
          }}
          style={{ marginLeft: "10px" }}
        >
          Daily View
        </Button>
      </div>

      <Row>
        <div className="form-group col-md-4">
          <label htmlFor="dateRange">Date Range</label>
          <CustomInput
            type="select"
            id="dateRange"
            name="dateRange"
            value={selectedDateRange}
            onChange={(e) => setSelectedDateRange(e.target.value)}
          >
            <option key={`dr-${0}`} value={0}>
              Today
            </option>
            <option key={`dr-${1}`} value={1}>
              Yesterday
            </option>
            <option key={`dr-${2}`} value={2}>
              Last 7 Days
            </option>
            <option key={`dr-${3}`} value={3}>
              Last 30 Days
            </option>
            <option key={`dr-${4}`} value={4}>
              This Month
            </option>
            <option key={`dr-${5}`} value={5}>
              Last Month
            </option>
            <option key={`dr-${6}`} value={6}>
              Custom Range
            </option>
          </CustomInput>
          {selectedDateRange == 6 && (
            <Row>
              <div className="form-group col-md-6">
                <label htmlFor="startDate">Start Date</label>

                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  placeholderText="Start date..."
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  className="form-control"
                  selected={endDate}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  placeholderText="End date..."
                />
              </div>
            </Row>
          )}
        </div>
        {((userMatter != null && userMatter.roleId !== 2) ||
          currentUser.systemRoleId == 2) && (
          <div className="form-group col-md-4">
            <label htmlFor="billerFilter">Biller</label>
            <CustomInput
              type="select"
              id="billerFilter"
              name="billerFilter"
              value={selectedBillerId}
              onChange={(e) => setSelectedBillerId(e.target.value)}
            >
              <option key={-1} value={-1}>
                All Billers
              </option>
              {billerList.length > 0 &&
                billerList.map((x) => (
                  <option key={x.userId} value={x.userId}>
                    {x.name}
                  </option>
                ))}
            </CustomInput>
          </div>
        )}
        <Col md="4">
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <Button
              color="primary"
              onClick={() => applyFilters()}
              style={{ position: "absolute", bottom: "16px" }}
            >
              Apply
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        {isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!isLoading && activityList.length > 0 && gridColumns.length > 0 && (
          <Fragment>
            <div
              style={{ display: "flex", flexDirection: "row", padding: "20px" }}
            >
              <h5 style={{ marginRight: "50px" }}>
                Total cost:{" "}
                {currencyFormatter(
                  "$",
                  activityList.reduce((a, item) => a + item.effectiveCost, 0)
                )}
              </h5>
              <h5>
                Total time:{" "}
                {getTimeInHoursMinutes(
                  activityList.reduce(
                    (a, item) => a + item.effectiveDuration,
                    0
                  )
                )}
              </h5>
            </div>
            <GeneralGrid
              colWidth={12}
              title=""
              keyField="activityId"
              defaultSorted={[
                {
                  dataField: "startTime",
                  order: "desc",
                },
              ]}
              dataSource={activityList}
              options={gridOptions}
              columns={gridColumns}
            />
          </Fragment>
        )}
        {!isLoading && activityList.length == 0 && (
          <Col md="12">
            <p>No activities available</p>
          </Col>
        )}
      </Row>

      <Modal
        size="md"
        isOpen={showActivityDetailsModal}
        toggle={() => toggleActivityDetailsModal()}
        backdrop="static"
      >
        <ModalHeader toggle={() => toggleActivityDetailsModal()}>
          <Fragment>
            <h4>Activity Details</h4>
          </Fragment>
        </ModalHeader>
        <ModalBody>
          <Row>
            {selectedActivity.activityId > 0 && (
              <Col>
                <div style={{ textAlign: "center", fontSize: "16px" }}>
                  {selectedActivity.clientFlag}
                  <div>Matter: {selectedActivity.matterName}</div>
                  <div>Activity: {selectedActivity.activityCodeTitle}</div>
                  <div>
                    Start Time:{" "}
                    {DateTimeHelper.getUsersDateTimeStringFromUtc(
                      selectedActivity.startTime,
                      "MM/DD/YYYY hh:mm A"
                    )}
                  </div>
                  <div>
                    End Time:{" "}
                    {selectedActivity.endTime == null
                      ? "-"
                      : DateTimeHelper.getUsersDateTimeStringFromUtc(
                          selectedActivity.endTime,
                          "MM/DD/YYYY hh:mm A"
                        )}
                  </div>

                  {currentUser.entityRoleId == 1 && (
                    <Fragment>
                      {!selectedActivity.clientFlag && (
                        <Row
                          style={{
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              setSelectedActivity({
                                ...selectedActivity,
                                clientFlag: true,
                              });
                            }}
                          >
                            Flag Activity
                          </Button>
                        </Row>
                      )}

                      {selectedActivity.clientFlag && (
                        <Fragment>
                          <Row
                            style={{
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ width: "80%" }}
                            >
                              <label htmlFor="clientNotes">Flag Notes</label>
                              <textarea
                                className="form-control"
                                type="text"
                                value={selectedActivity.clientFlagNotes}
                                onChange={(e) => {
                                  let { value } = e.target;

                                  setSelectedActivity({
                                    ...selectedActivity,
                                    clientFlagNotes: value,
                                  });
                                }}
                              />
                            </div>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                color="primary"
                                onClick={() => {
                                  setSelectedActivity({
                                    ...selectedActivity,
                                    clientFlag: false,
                                  });
                                }}
                              >
                                Remove Flag
                              </Button>
                            </Col>
                          </Row>
                        </Fragment>
                      )}

                      <Row>
                        <Col />
                        <Col>
                          {/* <Loading isLoading={this.state.isActivitySaving} /> */}
                          <Button
                            color="link"
                            className="pull-right"
                            onClick={saveAndClose}
                          >
                            Save and close
                          </Button>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </ModalBody>
      </Modal>

      <EditActivityModal
        showModal={showEditActivityModal}
        toggleModal={toggleEditActivityModal}
        activityId={selectedActivity.activityId}
        onRemove={removeActivity}
        onHide={hideActivity}
        onSave={saveActivity}
        showSaveAndAddAnother={true}
      />
    </Fragment>
  );
};

export default MatterActivityList;
