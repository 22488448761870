import { BehaviorSubject } from 'rxjs';


const suspendedActivitiesSubject = new BehaviorSubject(0);

const SuspendedActivitiesHelper = {
    suspendedActivitiesObservable: suspendedActivitiesSubject.asObservable(),
    suspendedActivities: suspendedActivitiesSubject.value,
    setSuspendedActivities
}

function setSuspendedActivities(count){
    suspendedActivitiesSubject.next(count);
}

export default SuspendedActivitiesHelper;