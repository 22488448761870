import React, { Fragment, ReactDOM } from 'react';
import {
    Card, CardBody, Button, Row, Col, CustomInput, CardTitle
} from 'reactstrap';
import { api, userService } from '../../_services';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { currencyFormatter } from '../../_services/currencyFormatter';
import Loader from 'react-loaders';

class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.currentUser = userService.getLoggedInUser();
        this.state = {
            selectedReportId: 1,
            selectedCurrencyId: this.currentUser.currencyId,
            allPartnersSelected: false,
            partnershipList: [],
            currencyList: [],
            startDate: new Date(),
            endDate: new Date(),
            isSubmitting: false
        };

        this.reportTypes = [
            {
                id: 1,
                name: 'Activities By Duration'
            }
        ]
    }

    componentDidMount() {
        api.getCurrencyList().then(list => {
            this.setState({ currencyList: list });
        })
        api.getPartnershipListByEntityId(this.currentUser.entityId, this.currentUser.userId).then(data => {
            this.setState({ partnershipList: data.map(x => { x.isSelected = false; return x; }) });
        })
    }

    downloadReport = () => {
        let lawfirmIds = this.state.partnershipList.filter(x=>x.isSelected).map(x=>x.partnershipId).join(',');
        let url = api.getReportUrl(this.state.selectedReportId, moment(this.state.startDate).format("YYYY-MM-DDTHH:mm:ss"), moment(this.state.endDate).format("YYYY-MM-DDTHH:mm:ss"), this.state.selectedCurrencyId,lawfirmIds,'PDF');
        setTimeout(() => {
            
            window.open(url);
          
          }, 100);
    }


    render() {
        return (
            <Fragment>
                <Row>
                    <Col md="12">
                        <Card className='mb-3'>
                            <CardBody>
                                <Row>
                                    <div className='form-group col-md-6'>
                                        <label>Report</label>
                                        <CustomInput type="select" id="reportSelect" value={this.state.selectedReportId}
                                            name="reportSelect" onChange={(e) => { this.setState({ selectedReportId: e.target.value }) }}>
                                            {this.reportTypes.map(c => {
                                                return <option key={c.id} value={c.id}>{c.name}</option>
                                            })}
                                        </CustomInput>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <label htmlFor="currency">Currency</label>
                                        <CustomInput type="select" id="currencySelect" name="currencySelect" className='form-control'
                                            onChange={(e) => { this.setState({ selectedCurrencyId: e.target.value }) }}
                                            value={this.state.selectedCurrencyId}>
                                            {this.state.currencyList.map(currency => {
                                                return <option key={currency.currencyId} value={currency.currencyId}>{currency.displayValue}</option>
                                            })}
                                        </CustomInput>
                                    </div>
                                    <div className='form-group col-md-6'>
                                        <Card style={{ width: '100%' }}>
                                            <CardBody style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <table className="table-responsive" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th width="10%">Select</th>
                                                            <th width="90%" >Partner (s) </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <CustomInput type="checkbox" id={'selectAll0'} style={{ marginLeft: '3px', cursor: 'pointer' }}
                                                                    value={this.state.allPartnersSelected}
                                                                    checked={this.state.allPartnersSelected}
                                                                    onChange={() => {
                                                                        let partnershipList = [...this.state.partnershipList];
                                                                        this.setState({ allPartnersSelected: !this.state.allPartnersSelected, partnershipList: partnershipList.map(x => { x.isSelected = !this.state.allPartnersSelected; return x; }) });
                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    let partnershipList = [...this.state.partnershipList];
                                                                    this.setState({ allPartnersSelected: !this.state.allPartnersSelected, partnershipList: partnershipList.map(x => { x.isSelected = !this.state.allPartnersSelected; return x; }) });
                                                                }}
                                                            >Select all</td>
                                                        </tr>
                                                        {this.state.partnershipList.map(e => {
                                                            return (
                                                                <tr key={e.partnershipId} style={{ cursor: 'pointer' }} >
                                                                    <td>
                                                                        <CustomInput type="checkbox" id={e.partnershipId} style={{ cursor: 'pointer' }}
                                                                            value={e.isSelected} checked={e.isSelected}
                                                                            onChange={(event) => {
                                                                                let partnershipList = [...this.state.partnershipList];
                                                                                let partner = partnershipList.find(x => x.partnershipId == e.partnershipId);
                                                                                partner.isSelected = !partner.isSelected;
                                                                                let index = partnershipList.findIndex(x => x.partnershipId == partner.partnershipId);
                                                                                partnershipList[index] = partner;
                                                                                this.setState({ partnershipList: partnershipList });
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ cursor: 'pointer' }}
                                                                        onClick={(event) => {
                                                                            let partnershipList = [...this.state.partnershipList];
                                                                            let partner = partnershipList.find(x => x.partnershipId == e.partnershipId);
                                                                            partner.isSelected = !partner.isSelected;
                                                                            let index = partnershipList.findIndex(x => x.partnershipId == partner.partnershipId);
                                                                            partnershipList[index] = partner;
                                                                            this.setState({ partnershipList: partnershipList });
                                                                        }}
                                                                    >{e.partnershipName}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className='form-group col-md-6'>
                                        <Row>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="startDate">Start Date</label>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker value={this.state.startDate}
                                                        format="MM/DD/YYYY"
                                                        disableFuture
                                                        onChange={(date) => this.setState({ startDate: date })}
                                                        InputProps={{ className: 'form-control' }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="endDate">End Date</label>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker value={this.state.endDate}
                                                        format="MM/DD/YYYY"
                                                        disableFuture
                                                        onChange={(date) => this.setState({ endDate: date })}
                                                        InputProps={{ className: 'form-control' }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </Row>
                                    </div>

                                    <div className='form-group col-md-6'>
                                        {/* <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button>
                                            {isSubmitting &&
                                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            } */}
                                        <a href='#' onClick={e => {e.preventDefault(); this.downloadReport()}}>download</a>
                                    </div>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default ReportList;