import React, { useState, useEffect } from 'react';
import {
  Button, Modal, ModalHeader,
  ModalBody, Row, Col, CustomInput, Input, Form
} from 'reactstrap';
import { api, userService } from '../../../_services';
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ErrorElement } from '../../../Components/ErrorElement';

export const InviteCompanyModal = ({ showModal, toggleModal, saveCompany, selectedEntity }) => {
  const currentUser = userService.getLoggedInUser();
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [defaultEmailText, setDefaultEmailText] = useState('');
  const [entity, setEntity] = useState({
    entityId: -1,
    parentId: 0,
    name: '',
    contactName: '',
    contactEmail: '',
    sendEmail: true,
    emailMessage: ''
  });

  const { handleSubmit, register, reset, watch, formState: { errors } } = useForm({
    defaultValues: selectedEntity ?? entity,
    resolver: yupResolver(Yup.object().shape({
      name: Yup.string().required('Name is required'),
      contactName: Yup.string().required('Contact name is required'),
      contactEmail: Yup.string().required('Contact email is required'),
    })),
  });

  useEffect(() => {
    api.getDefaultEmailText(currentUser.entityId).then(txt => {
      setDefaultEmailText(txt);
    });
  }, [currentUser.entityId]);

  useEffect(() => {
    setEntity(selectedEntity);
    reset(selectedEntity);
  }, [reset, selectedEntity])

  useEffect(() => {
    // Retrieve the entire form data object
    const subscription = watch((value) => setEntity(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setEntity(prevState => ({ ...prevState, emailMessage: defaultEmailText }));
    reset({ ...entity, emailMessage: defaultEmailText });
  }, [reset, defaultEmailText]);

  const handleValidSubmit = () => {
    let updatedEntity = entity;
    updatedEntity.invitingEntityId = currentUser.entityId;
    updatedEntity.parentId = 0;
    updatedEntity.emailMessage = defaultEmailText;
    setSaveButtonDisabled(true);
    saveCompany(updatedEntity);
  };

  const getInputField = (propName, label, type = 'text', placeholder = '', required = false) => {
    const { ref, ...rest } = register(propName);
    return (
      <div className="form-group col-md-12">
        <label htmlFor={propName}>{label}{required ? '*' : ''}</label>
        <Input
          innerRef={ref}
          name={propName}
          placeholder={placeholder}
          type={type}
          {...rest}
        />
        <ErrorElement message={errors[propName]?.message} />
      </div>)
  }

  const getSendEmailCheckbox = () => {
    const { ref, ...rest } = register('sendEmail');
    return (
      <div className="form-group col-md-12">
        <CustomInput
          innerRef={ref}
          name={'sendEmail'}
          type="checkbox"
          id="sendEmail"
          label="Send Email"
          {...rest}
        />
      </div>)
  }

  return (
    <Modal isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
      <ModalHeader toggle={() => toggleModal()}>Invite Company</ModalHeader>
      <ModalBody>
        <Row style={{ marginTop: '30px' }}>
          <Col md="12">
            <Form onSubmit={handleSubmit(handleValidSubmit)}>
              <Row>
                <Col md="12">
                  {getInputField('name', 'Name', 'text', 'Name...', true)}
                  {getInputField('contactName', 'Contact Name', 'text', 'Contact Name...', true)}
                  {getInputField('contactEmail', 'Contact Email', 'email', 'Contact Email...', true)}
                  {getSendEmailCheckbox()}
                  {entity.sendEmail &&
                    getInputField('emailMessage', 'Email message', 'textarea', 'Email message...')
                  }
                </Col>
              </Row>

              <Row style={{ marginTop: '30px' }}>
                <Button color="link" onClick={() => toggleModal()}>Cancel</Button>
                <Button color="primary" type="submit" style={{ marginLeft: '.25rem' }} disabled={saveButtonDisabled}>Save</Button>{' '}
              </Row>
            </Form>

          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
