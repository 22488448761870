import React, { Fragment, useState, useEffect } from "react";
import { api, userService } from "../../../_services";
import { currencyFormatter } from "../../../_services/currencyFormatter";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CardTitle,
  UncontrolledAlert,
  CustomInput,
  Table,
} from "reactstrap";
import Loader from "react-loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faSearch } from "@fortawesome/fontawesome-free-solid";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const TwoLevelsGrid = ({
  isLoading,
  list,
  gridColumns,
  secondLevelListPropertyName,
  secondLevelSearchFilterPropertyList,
  showTotals,
  showTimeTotals,
  selectedCurrencySymbol,
  title,
  style,
  expanded,
  subtitle,
}) => {
  let [isExpandedAll, setIsExpandedAll] = useState(false);
  let [gridList, setGridList] = useState(list);
  let [searchFilter, setSearchFilter] = useState("");
  let [sortedBy, setSortedBy] = useState("");
  let [sortedByCopy, setSortedByCopy] = useState("");
  let [selectedPartnershipId, setSelectedPartnershipId] = useState(-1);

  useEffect(() => {
    setGridList(list);
    if (list.length > 0) {
      let count = 0;
      for (var i = 0; i < list.length; i++) {
        count += list[i][secondLevelListPropertyName].length;
        if (count > 10) break;
      }
    }
  }, [list]);

  const changeExpansion = (partnership) => {
    let listCopy = [...gridList];
    setGridList(
      listCopy.map((x) => {
        if (x.partnershipId == partnership.partnershipId)
          x.isExpanded = !x.isExpanded;
        return x;
      })
    );
  };

  const changeExpansionAll = (flag) => {
    let listCopy = [...gridList];
    setGridList(
      listCopy.map((x) => {
        x.isExpanded = flag;
        return x;
      })
    );

    setIsExpandedAll(flag);
  };

  const sortGrid = (property) => {
    if (property !== undefined) {
      var descending = sortedBy == property ? true : false;
      let listCopy = [...list];
      if (listCopy.length > 0) {
        if (listCopy[0][property] !== undefined)
          listCopy.sort(sort_by(property, descending));
        listCopy.map((el) => {
          if (
            el[secondLevelListPropertyName] !== undefined &&
            el[secondLevelListPropertyName].length > 0
          ) {
            if (el[secondLevelListPropertyName][0][property] !== undefined) {
              el[secondLevelListPropertyName] = el[
                secondLevelListPropertyName
              ].sort(sort_by(property, descending));
            }
          }
          return el;
        });
      }
      setGridList(listCopy);
      setSortedBy(descending ? "" : property);
      setSortedByCopy(property);
    }
  };

  const sort_by = (field, reverse, primer) => {
    var key =
      field.toLowerCase().indexOf("total") > -1 ||
      field.toLowerCase().indexOf("time") > -1
        ? function (x) {
            return parseInt(x[field]);
          }
        : function (x) {
            return x[field].toLowerCase();
          };

    reverse = !reverse ? 1 : -1;

    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

  const getTimeInHoursMinutes = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - hours * 3600) / 60);
    return hours > 0 ? `${hours} hours ${minutes} min` : `${minutes} min`;
  };

  return (
    <Fragment>
      <Card className="mb-3" style={style}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <Row>
            <Col md="4">
              {/* <label>Search:</label> */}
              <input
                type="search"
                placeholder="Matter name..."
                className="form-control leguard-search-input"
                onChange={(e) => setSearchFilter(e.target.value)}
              />
            </Col>
          </Row>
          {!isLoading && (
            <Row>
              <Table responsive className="table-striped">
                <thead>
                  <tr>
                    <th width="5%">
                      <FontAwesomeIcon
                        icon={isExpandedAll ? faMinus : faPlus}
                        onClick={() => changeExpansionAll(!isExpandedAll)}
                        style={{ cursor: "pointer" }}
                      ></FontAwesomeIcon>
                    </th>
                    {gridColumns.map((col, i) => {
                      return (
                        <th
                          key={`gridColumns-${i}`}
                          width={col.width}
                          onClick={() => sortGrid(col.sortableProperty)}
                        >
                          {col.header}{" "}
                          {col.sortableProperty && (
                            <FontAwesomeIcon
                              icon={
                                sortedByCopy == col.sortableProperty &&
                                sortedBy == col.sortableProperty
                                  ? faChevronDown
                                  : faChevronUp
                              }
                            />
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {showTotals && gridList.length > 0 && (
                    <Fragment key="totals">
                      <tr className="totals_leguard_red" key="total">
                        <td></td>
                        <td>
                          <b>
                            {subtitle ? subtitle : "TOTALS FOR ALL MATTERS"}
                          </b>
                        </td>
                        <td></td>
                        <td>
                          <b>
                            {currencyFormatter(
                              selectedCurrencySymbol,
                              gridList.reduce(
                                (a, item) => a + item.todayTotal,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {currencyFormatter(
                              selectedCurrencySymbol,
                              gridList.reduce(
                                (a, item) => a + item.monthTotal,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {currencyFormatter(
                              selectedCurrencySymbol,
                              gridList.reduce(
                                (a, item) => a + item.quarterTotal,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {currencyFormatter(
                              selectedCurrencySymbol,
                              gridList.reduce(
                                (a, item) => a + item.yearTotal,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td></td>
                      </tr>
                    </Fragment>
                  )}
                  {showTimeTotals && (
                    <Fragment key="totals">
                      <tr className="totals_leguard_red" key="total">
                        <td></td>
                        <td>
                          <b>{subtitle ? subtitle : "TOTALS"}</b>
                        </td>
                        <td>
                          <b>
                            {getTimeInHoursMinutes(
                              gridList.reduce(
                                (a, item) => a + item.todayTime,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {getTimeInHoursMinutes(
                              gridList.reduce(
                                (a, item) => a + item.monthTime,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {getTimeInHoursMinutes(
                              gridList.reduce(
                                (a, item) => a + item.quarterTime,
                                0
                              )
                            )}
                          </b>
                        </td>
                        <td>
                          <b>
                            {getTimeInHoursMinutes(
                              gridList.reduce((a, item) => a + item.yearTime, 0)
                            )}
                          </b>
                        </td>
                        <td></td>
                      </tr>
                    </Fragment>
                  )}

                  {gridList.length > 0 &&
                    gridList
                      .filter((part) => {
                        if (
                          secondLevelSearchFilterPropertyList !== undefined &&
                          secondLevelSearchFilterPropertyList.length > 0
                        ) {
                          var ret = false;
                          secondLevelSearchFilterPropertyList.forEach(
                            (prop) => {
                              // if (m[prop].toLowerCase().indexOf(searchFilter.toLowerCase()) > -1)
                              //     ret = true;
                              if (
                                part[secondLevelListPropertyName].find(
                                  (x) =>
                                    x[prop]
                                      .toLowerCase()
                                      .indexOf(searchFilter.toLowerCase()) > -1
                                ) !== undefined
                              )
                                ret = true;
                            }
                          );
                          return ret;
                        } else {
                          return true;
                        }
                      })
                      .map((data) => {
                        return (
                          <Fragment key={`f-${data.partnershipId}`}>
                            <tr
                              key={`fl-${data.partnershipId}`}
                              className="leguard-main-tr"
                              style={{
                                color: data.isExpanded ? "darkred" : "black",
                              }}
                            >
                              <td>
                                <FontAwesomeIcon
                                  icon={data.isExpanded ? faMinus : faPlus}
                                  onClick={() => changeExpansion(data)}
                                  style={{ cursor: "pointer" }}
                                ></FontAwesomeIcon>
                              </td>
                              {gridColumns.map((col) => {
                                return col.firstLevelFormatter(data);
                              })}
                            </tr>
                            {data.isExpanded &&
                              data[secondLevelListPropertyName]
                                .filter((m) => {
                                  if (
                                    secondLevelSearchFilterPropertyList !==
                                      undefined &&
                                    secondLevelSearchFilterPropertyList.length >
                                      0
                                  ) {
                                    var ret = false;
                                    secondLevelSearchFilterPropertyList.forEach(
                                      (prop) => {
                                        if (
                                          m[prop]
                                            .toLowerCase()
                                            .indexOf(
                                              searchFilter.toLowerCase()
                                            ) > -1
                                        )
                                          ret = true;
                                      }
                                    );
                                    return ret;
                                  } else {
                                    return true;
                                  }
                                })
                                .filter((p) => {
                                  if (selectedPartnershipId !== -1) {
                                    return (
                                      parseInt(p.partnershipId) ==
                                      selectedPartnershipId
                                    );
                                  }
                                  return true;
                                })
                                .map((data) => {
                                  return (
                                    <tr key={data.matterId}>
                                      <td></td>
                                      {gridColumns.map((col) => {
                                        return col.secondLevelFormatter(data);
                                      })}
                                    </tr>
                                  );
                                })}
                          </Fragment>
                        );
                      })}

                  {gridList.length == 0 && (
                    <tr>
                      <td></td>
                      <td>No matters to display</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
          )}
          {isLoading && <Loader style={{}} type="ball-pulse" active />}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default TwoLevelsGrid;
