import React, {useEffect} from 'react';
import { authenticationService } from '../_services';
import { Redirect } from 'react-router-dom';

const Logout = (props) =>{
    useEffect(() =>{
        authenticationService.logout();
    },[]);

    return(
         <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )
}

export default Logout;