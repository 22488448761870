import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  CardBody,
  ModalBody,
  Row,
  Col,
  CustomInput,
  CardTitle,
  Input,
  Card,
  UncontrolledAlert,
} from "reactstrap";
import SelectMatterModal from "./SelectMatterModal";
import SelectActivityCodeModal from "./SelectActivityCodeModal";
import { api, userService } from "../../../_services";
import Loader from "react-loaders";
// import moment from 'moment';
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { differenceInHours } from "date-fns";
import { DateTimeHelper } from "../../../_helpers/dateTimeHelper";
import * as Datetime from "react-datetime";
import swal from "sweetalert";
import Switch from "react-switch";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
// import "react-datepicker/dist/react-datepicker.css";

export const getDurationInHours = (startDate, endDate) => {
  if (startDate == null) {
    return 0;
  }
  let start = moment(startDate);
  let end = moment(
    endDate == null || endDate == undefined
      ? DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A")
      : endDate
  );
  const duration = moment.duration(end.diff(start));
  const durationInHours = duration.asHours();
  return durationInHours.toFixed(2);
};

const EditActivityModal = ({
  showModal,
  toggleModal,
  isStopModal,
  activityId,
  onPause,
  onStop,
  onStopStart,
  onRemove,
  onHide,
  onSave,
  currentDate,
  showSaveAndAddAnother,
  preselectedData,
}) => {
  let [isLoading, setIsLoading] = useState(false);
  let [activity, setActivity] = useState({});
  let [activityCodeList, setActivityCodeList] = useState([]);
  let [showMatterModal, setShowMatterModal] = useState(false);
  let [showActivityCodeModal, setShowActivityCodeModal] = useState(false);
  let [activityCode, setActivityCode] = useState({});
  let [matter, setMatter] = useState({});
  let [timePeriod, setTimePeriod] = useState("");
  let [isPasswordRequired, setIsPasswordRequired] = useState(false);
  let [otherRevisionReason, setOtherRevisionReason] = useState(false);
  let [selectedRevisionReason, setSelectedRevisionReason] = useState("Forgot");
  let currentUser = userService.getLoggedInUser();
  let [timeTest, setTimeTest] = useState(new Date());
  let [startSeconds, setStartSeconds] = useState(0);
  let [endSeconds, setEndSeconds] = useState(0);
  let [activityNotes, setActivityNotes] = useState("");
  let [rememberMe, setRememberMe] = useState(false);
  const [explanationCodes, setExplanationCodes] = useState([]);
  const [timeAsDates, setTimeAsDates] = useState(false);
  const [billerList, setBillerList] = useState([]);
  const [selectedBillerId, setSelectedBillerId] = useState(currentUser.userId);
  const [isOnFinalInvoice, setIsOnFinalInvoice] = useState(false);
  const [invoice, setInvoice] = useState(null);

  // 0 - time as dates, 1 - time period, 2 - fixed price
  const [activityType, setActivityType] = useState(1);

  const revisionReasonList = [
    {
      id: 1,
      text: "Forgot",
    },
    {
      id: 2,
      text: "Resolved Suspended Activity",
    },
    {
      id: 3,
      text: "Prior To Training",
    },
    {
      id: 4,
      text: "Time put to wrong matter",
    },
    {
      id: -1,
      text: "Other",
    },
  ];

  let [startTimeError, setStartTimeError] = useState(false);
  let [endTimeError, setEndTimeError] = useState(false);

  useEffect(() => {
    if (activityCodeList && activityCodeList.length > 0) {
      let existingActCode = activityCodeList.find(
        (x) => x.activityCodeId == activity.activityCodeId
      );
      if (
        activity.activityCodeId !== undefined &&
        existingActCode !== undefined
      ) {
        setActivity({
          ...activity,
          activityCodeTitle: existingActCode.title,
          activityCodeId: existingActCode.activityCodeId,
        });
      } else {
        setActivity({
          ...activity,
          activityCodeTitle: activityCodeList[0].title,
          activityCodeId: activityCodeList[0].activityCodeId,
        });
      }
    }
  }, [activityCodeList]);

  useEffect(() => {
    if (showModal) {
      setActivityType(1);
      currentUser = userService.getLoggedInUser();
      //moment.locale('en');
      //moment.tz.setDefault(currentUser.timezoneIanaName);
      let rememberMe = localStorage.getItem("rememberMe") == "true";
      let pwd = "";
      if (rememberMe) {
        pwd = localStorage.getItem("password");
        setRememberMe(true);
      }

      setIsLoading(true);
      if (isStopModal) {
        setIsOnFinalInvoice(false);
        api.getRunningActivity(currentUser.userId).then((data) => {
          if (data == null) {
            setActivity({});
            setTimePeriod("");
          } else {
            calculateTimePeriod(
              moment(data.startTime).format("MM/DD/YYYY hh:mm A"),
              data.endTime == null
                ? moment.tz("UTC").format("MM/DD/YYYY hh:mm A")
                : moment(data.endTime).format("MM/DD/YYYY hh:mm A")
            );
            data.endTime = DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A"); //moment(DateTimeHelper.getUsersNow('MM/DD/YYYY hh:mm A')).format('MM/DD/YYYY hh:mm A');
            setStartSeconds(parseInt(moment(data.startTime).format("ss")));

            setEndSeconds(
              data.endTime == null
                ? moment.tz("UTC").format("ss")
                : DateTimeHelper.getUsersNow("ss")
            );
            setMatter({ matterId: data.matterId, matterName: data.matterName });
            loadBillers(data.matterId);
            setActivity({
              ...data,
              date: DateTimeHelper.getUsersNow("MM/DD/YYYY"),
              startTime: DateTimeHelper.getUsersDateTimeStringFromUtc(
                data.startTime,
                "MM/DD/YYYY hh:mm A"
              ), //moment(DateTimeHelper.getUsersDateTimeStringFromUtc(data.startTime, 'MM/DD/YYYY hh:mm A')).format('MM/DD/YYYY hh:mm A'),
              password: pwd,
              timePeriod: getDurationInHours(
                DateTimeHelper.getUsersDateTimeStringFromUtc(
                  data.startTime,
                  "MM/DD/YYYY hh:mm A"
                ),
                DateTimeHelper.getUsersNow()
              ),
            });
          }
          setIsLoading(false);
        });
      } else {
        //edit mode
        if (activityId > -1) {
          api.getActivity(activityId).then((data) => {
            if (data.invoiceId > 0) {
              api.getInvoiceById(data.invoiceId).then((inv) => {
                setInvoice(inv);
                if (
                  inv !== undefined &&
                  inv !== null &&
                  inv.invoiceStatusId == 120
                ) {
                  setIsOnFinalInvoice(true);
                } else {
                  setIsOnFinalInvoice(false);
                }
              });
            } else {
              setIsOnFinalInvoice(false);
            }
            if (data.activityFixedPrice > 0) {
              setActivityType(2);
            }
            if (data.revisionReason !== "") {
              let revReason = revisionReasonList.find(
                (x) => x.text == data.revisionReason
              );
              if (revReason == undefined) {
                setSelectedRevisionReason(-1);
                setOtherRevisionReason(true);
              } else {
                setSelectedRevisionReason(data.revisionReason);
              }
            } else {
              data.revisionReason = "Forgot";
              setSelectedRevisionReason("Forgot");
              setOtherRevisionReason(false);
            }
            setStartSeconds(parseInt(moment(data.startTime).format("ss")));
            setEndSeconds(
              data.endTime == null
                ? moment.tz("UTC").format("ss")
                : moment(data.endTime).format("ss")
            );
            loadBillers(data.matterId);
            setActivity({
              ...data,
              startTime: moment
                .utc(data.startTime)
                .tz(currentUser.timezoneIanaName)
                .format("MM/DD/YYYY hh:mm A"), //DateTimeHelper.getUsersDateTimeStringFromUtc(data.startTime, 'MM/DD/YYYY hh:mm A'),//moment(DateTimeHelper.getUsersDateTimeStringFromUtc(data.startTime, 'MM/DD/YYYY hh:mm A')).format('MM/DD/YYYY hh:mm A'),
              endTime:
                data.endTime == null
                  ? DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A")
                  : moment
                      .utc(data.endTime)
                      .tz(currentUser.timezoneIanaName)
                      .format("MM/DD/YYYY hh:mm A"), //DateTimeHelper.getUsersDateTimeStringFromUtc(data.endTime, 'MM/DD/YYYY hh:mm A'),//moment(DateTimeHelper.getUsersDateTimeStringFromUtc(data.endTime, 'MM/DD/YYYY hh:mm A')).format('MM/DD/YYYY hh:mm A'),
              password: pwd,
              date: moment
                .utc(data.startTime)
                .tz(currentUser.timezoneIanaName)
                .format("MM/DD/YYYY"),
              timePeriod: getDurationInHours(
                moment
                  .utc(data.startTime)
                  .tz(currentUser.timezoneIanaName)
                  .format("MM/DD/YYYY hh:mm A"),
                data.endTime == null
                  ? DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A")
                  : moment
                      .utc(data.endTime)
                      .tz(currentUser.timezoneIanaName)
                      .format("MM/DD/YYYY hh:mm A")
              ),
            });
            calculateTimePeriod(
              moment(data.startTime).format("MM/DD/YYYY hh:mm A"),
              data.endTime == null
                ? moment.tz("UTC").format("MM/DD/YYYY hh:mm A")
                : moment(data.endTime).format("MM/DD/YYYY hh:mm A")
            );
            loadActivityCodeList(data.matterId);
            setIsLoading(false);
          });
        } else {
          setIsOnFinalInvoice(false);
          let newAct =
            preselectedData == undefined
              ? {
                  activityId: -1,
                  matterName: "Not selected",
                  startTime: DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A"),
                  endTime: DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A"),
                  password: pwd,
                  encryptedNotes: currentUser.encryptNotes,
                  date:
                    activity != undefined && activity.date != undefined
                      ? moment(activity.date).format("MM/DD/YYYY")
                      : DateTimeHelper.getUsersNow("MM/DD/YYYY"),
                  timePeriod: 0,
                }
              : { ...preselectedData, password: pwd };
          setActivity(newAct);
          if (newAct.matterId > -1) {
            loadBillers(newAct.matterId);
            loadActivityCodeList(newAct.matterId);
          } else {
            setActivityCodeList([]);
          }
          setTimePeriod("");
          setIsLoading(false);
          setStartSeconds(moment.tz("UTC").format("ss"));
          setEndSeconds(moment.tz("UTC").format("ss"));
        }
      }
      setDatetimeNotClickable();
    } else {
      setActivityNotes("");
    }
  }, [showModal, activityId]);

  useEffect(() => {
    if (activityType == 1) {
      handleChange(
        "timePeriod",
        getDurationInHours(activity.startTime, activity.endTime)
      );
    }
  }, [activityType]);

  useEffect(() => {
    loadExplanationCodes();
  }, []);

  const loadExplanationCodes = () => {
    api.getExplanationCodes().then((expCodes) => {
      setExplanationCodes(expCodes);
    });
  };

  const setDatetimeNotClickable = () => {
    setTimeout(() => {
      let elements = document.getElementsByClassName("react-datetime");
      if (elements !== undefined && elements !== null && elements.length > 0) {
        for (var i = 0; i < elements.length; i++) {
          let input = elements[i].firstChild;
          input.setAttribute("disabled", "true");
          input.classList.add("clickable-input");
        }
      }
      //debugger;
      //let x = ((!activity.encryptedNotes && currentUser.encryptNotes) || (activity.encryptedNotes && currentUser.encryptNotes) || (activity.encriptedNotes && activity.description !== null && activity.description.length > 0))
      //console.log(activity);
    }, 4000);
  };

  const loadActivityCodeList = (matterId) => {
    api.getActivityCodeListByMatterId(matterId).then((data) => {
      setActivityCodeList(data);
    });
  };

  const toggleMatterModal = () => {
    setShowMatterModal(!showMatterModal);
  };

  const toggleActivityCodeModal = () => {
    setShowActivityCodeModal(!showActivityCodeModal);
  };

  const onMatterSelect = (matter) => {
    setActivity({
      ...activity,
      matterName: matter.matterName,
      matterId: matter.matterId,
    });
    setMatter(matter);
    loadActivityCodeList(matter.matterId);
    loadBillers(matter.matterId);
    toggleMatterModal();
  };

  const onActivityCodeSelect = (activityCode) => {
    setActivity({
      ...activity,
      activityCodeTitle: activityCode.title,
      activityCodeId: activityCode.activityCodeId,
      password: "",
    });
    setActivityCode(activityCode);
    toggleActivityCodeModal();
  };

  const loadBillers = (matterId) => {
    if (currentUser.entityRoleId == 2 && currentUser.systemRoleId == 2) {
      api.getUserListByMatterId(matterId, 2).then((billers) => {
        api.getUserListByMatterId(matterId, 3).then((rls) => {
          let list = [...billers, ...rls];
          setBillerList(list.filter((x) => x.userId !== currentUser.userId));
          // if (list.length > 0) {
          //   let found = list.find((x) => x.userId == currentUser.userId);
          //   if (found !== undefined) {
          //     setSelectedBillerId(currentUser.userId);
          //   } else {
          //     setSelectedBillerId(-1);
          //   }
          // } else {
          //   setSelectedBillerId(-1);
          // }
        });
      });
    }
  };

  const handleChange = (prop, val) => {
    if (prop == "password") setIsPasswordRequired(false);
    setActivity({
      ...activity,
      [prop]: val,
      isTimePeriodSet: activityType == 1,
    });
  };

  const calculateTimePeriod = (start, end) => {
    if (start == null) {
      setTimePeriod("");
      return;
    }
    let ret = "";
    let endTime = moment(
      end == null || end == undefined
        ? DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A")
        : end
    );
    let startTime = moment(start);
    var diffMs = endTime.diff(startTime);
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000 + diffDays * 24); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    // if (diffDays > 0)
    //     ret += diffDays + ' days ';
    if (diffHrs > 0) ret += diffHrs + " hours ";
    if (diffMins > 0) ret += diffMins + " minutes";

    setTimePeriod(ret);
  };

  const validateActivity = () => {
    if (
      activityNotes &&
      activityNotes.length > 0 &&
      (activity.password == undefined || activity.password == "")
    ) {
      setIsPasswordRequired(true);
      return false;
    }
    if (activity.matterId == undefined || activity.matterId <= 0) {
      swal("Please select a matter.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
      return false;
    }
    if (!isDate(activity.startTime)) {
      setStartTimeError(true);
      return false;
    }
    if (!isDate(activity.endTime)) {
      setEndTimeError(true);
      return false;
    }
    setIsPasswordRequired(false);
    return true;
  };

  let prevStartTime, prevEndTime;
  const save = (activity, action, callback, addAnother) => {
    var act = JSON.parse(JSON.stringify(activity));
    prevStartTime = activity.startTime;
    prevEndTime = activity.endTime;

    act.startTime = moment
      .tz(prevStartTime, "MM/DD/YYYY hh:mm:ss A", currentUser.timezoneIanaName)
      .tz("utc")
      .add(startSeconds, "seconds")
      .format("MM/DD/YYYY HH:mm:ss");
    act.endTime = moment
      .tz(prevEndTime, "MM/DD/YYYY hh:mm:ss A", currentUser.timezoneIanaName)
      .tz("utc")
      .add(endSeconds, "seconds")
      .format("MM/DD/YYYY HH:mm:ss");
    act.date = moment(act.date ?? new Date()).format("MM/DD/YYYY HH:mm:ss");
    act.encryptedNotes = currentUser.encryptNotes;
    act.isTimePeriodSet = activityType == 1 && act.timePeriod != null;

    if (currentUser.encryptNotes) act.description = activityNotes;

    if (rememberMe) {
      localStorage.setItem("password", activity.password);
    }

    if (currentUser.systemRoleId == 2 && currentUser.entityRoleId == 2) {
      if (selectedBillerId != -1 && selectedBillerId != currentUser.userId) {
        act.authBillerId = selectedBillerId;
      }
    }

    action(act, callback, addAnother);
  };

  const saveAndAddCallback = (errorMessage) => {
    if (errorMessage !== undefined && errorMessage !== "") {
      swal(errorMessage, {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    } else {
      toast("Activity saved successfuly", {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: false,
      });
      setActivity({
        ...activity,
        activityId: -1,
        //startTime: DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A"),
        //endTime: DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A"),
        encryptedNotes: currentUser.encryptNotes,
        //date: DateTimeHelper.getUsersNow("MM/DD/YYYY"),
        timePeriod: 0,
        description: "",
      });
    }
  };

  const saveCallback = (errorMessage) => {
    if (errorMessage !== undefined && errorMessage !== "") {
      setActivity({
        ...activity,
        startTime: prevStartTime,
        endTime: prevEndTime,
      });
      swal(errorMessage, {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    }
  };

  const onDateChange = (prop, dateString) => {
    if (isDate(dateString)) {
      let date = moment(dateString).format("MM/DD/YYYY hh:mm A");
      if (prop == "startTime") {
        calculateTimePeriod(
          date,
          activity.endTime == null ? null : activity.endTime
        );
        setActivity({ ...activity, startTime: date });
      } else if (prop == "endTime") {
        calculateTimePeriod(
          activity.startTime == null ? null : activity.startTime,
          date
        );
        setActivity({ ...activity, endTime: date });
      } else {
        setActivity({ ...activity, [prop]: date });
      }
    }
  };

  const isDate = function (date) {
    return moment(date).isValid(); //((new Date(date) !== "Invalid Date") && !isNaN(new Date(date)));
  };

  const getActivityNotes = () => {
    api
      .getActivityNotes(
        currentUser.email,
        activity.activityId,
        activity.password
      )
      .then((data) => {
        if (data == -1)
          swal("The password you provided is not correct", {
            buttons: {
              ok: {
                text: "Ok",
                value: "ok",
                className: "btn-success",
              },
            },
            icon: "warning",
          });
        else setActivityNotes(data);
      });
  };

  return (
    <Fragment>
      <Modal
        size="md"
        isOpen={showModal}
        toggle={() => toggleModal()}
        backdrop="static"
      >
        <ModalHeader toggle={() => toggleModal()}>
          {isStopModal && isStopModal == true
            ? "Stop / Pause Activity"
            : "Edit Activity"}
        </ModalHeader>
        <ModalBody>
          {isLoading && <Loader style={{}} type="ball-pulse" active />}
          {!isLoading && activity.activityId !== undefined && (
            <Fragment>
              {/* <Row>
                <Col md="12">
                  {!isStopModal && (
                    <UncontrolledAlert color="warning">
                      <h4>
                        <FontAwesomeIcon icon={faInfo} /> Alert
                      </h4>
                      Please take all efforts to track activities in real time.
                      Editing of activities should not be a common practice.
                    </UncontrolledAlert>
                  )}
                </Col>
              </Row> */}
              <Row style={{ marginTop: "10px" }}>
                <Col md="12">
                  <div className="form-group">
                    <label>Matter *</label>
                    <br />
                    <label>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleMatterModal();
                        }}
                        style={{
                          height: "calc(1.5em + 0.75rem + 2px)",
                          padding: "0.375rem 0.75rem",
                          fontSize: "1rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                        }}
                      >
                        {activity.matterName}
                      </a>
                    </label>
                  </div>
                  {currentUser.entityRoleId == 2 &&
                    currentUser.systemRoleId == 2 && (
                      <div className="form-group">
                        <label>Billed as</label>
                        <CustomInput
                          type="select"
                          value={activity.authBillerId}
                          onChange={(e) =>
                            handleChange("authBillerId", e.target.value)
                          }
                        >
                          <option value={currentUser.userId}>Self</option>
                          {billerList.map((b) => {
                            return (
                              <option key={b.userId} value={b.userId}>
                                {b.name}
                              </option>
                            );
                          })}
                        </CustomInput>
                      </div>
                    )}
                  {isStopModal && isStopModal == true && (
                    <div className="form-group">
                      <label>Activity *</label>
                      <br />
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleActivityCodeModal();
                        }}
                        style={{
                          height: "calc(1.5em + 0.75rem + 2px)",
                          padding: "0.375rem 0.75rem",
                          fontSize: "1rem",
                          fontWeight: "400",
                          lineHeight: "1.5",
                        }}
                      >
                        {activity.activityCodeTitle}
                      </a>
                    </div>
                  )}
                  {!isStopModal && (
                    <div className="form-group">
                      <label htmlFor="activity">Activity *</label>
                      <CustomInput
                        type="select"
                        id="activityCodeId"
                        value={activity.activityCodeId}
                        name="activityCodeId"
                        onChange={(e) =>
                          handleChange("activityCodeId", e.target.value)
                        }
                      >
                        {activityCodeList.map((ac) => {
                          return (
                            <option
                              key={ac.activityCodeId}
                              value={ac.activityCodeId}
                            >
                              {ac.title}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </div>
                  )}
                  {explanationCodes.length > 0 && (
                    <div className="form-group">
                      <label>Explanation Code</label>
                      <CustomInput
                        type="select"
                        value={activity.explanationCodeID}
                        onChange={(e) =>
                          handleChange("explanationCodeID", e.target.value)
                        }
                      >
                        <option value="0">None</option>
                        {explanationCodes.map((ec) => {
                          return (
                            <option
                              key={ec.explanationCodeID}
                              value={ec.explanationCodeID}
                            >
                              {ec.code} - {ec.title}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "1rem",
                      gap: "20px",
                    }}
                  >
                    <CustomInput
                      id="1"
                      type="radio"
                      value={activityType == 0}
                      label="Show as Times"
                      checked={activityType == 0}
                      onChange={(e) => setActivityType(0)}
                      disabled={
                        activity.activityId > 0 &&
                        activity.activityFixedPrice > 0
                      }
                    ></CustomInput>
                    <CustomInput
                      id="2"
                      type="radio"
                      value={activityType == 1}
                      label="Show as Period"
                      checked={activityType == 1}
                      onChange={(e) => setActivityType(1)}
                      disabled={
                        activity.activityId > 0 &&
                        activity.activityFixedPrice > 0
                      }
                    ></CustomInput>
                    {currentUser.systemRoleId == 2 && (
                      <CustomInput
                        id="3"
                        type="radio"
                        value={activityType == 2}
                        label="Fee Only"
                        checked={activityType == 2}
                        onChange={(e) => setActivityType(2)}
                        disabled={activity.activityId > 0}
                      ></CustomInput>
                    )}
                  </div>
                  {activityType == 1 && (
                    <>
                      <div className="form-group">
                        <label>Date *</label>
                        <Datetime
                          closeOnSelect={true}
                          dateFormat="MM/DD/YYYY"
                          timeFormat={false}
                          value={moment(activity.date).format("MM/DD/YYYY")}
                          onChange={(date) => {
                            onDateChange("date", date);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Time Period *</label>
                        <Input
                          type="number"
                          value={activity.timePeriod}
                          onChange={(e) =>
                            handleChange("timePeriod", e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}
                  {activityType == 0 && (
                    <>
                      <div className="form-group">
                        <label>Start Time *</label>
                        <br />
                        <Datetime
                          closeOnSelect={true}
                          value={activity.startTime}
                          onChange={(date) => {
                            // setActivity({ ...activity, startTime: date });
                            onDateChange("startTime", date);
                          }}
                          className="react-datetime-leguard"
                          inputProps={{ readOnly: true }}
                        />
                        {startTimeError && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Start Time is not valid
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>End Time</label>
                        <br />
                        <Datetime
                          closeOnSelect={true}
                          value={activity.endTime}
                          onChange={(date) => {
                            // setActivity({ ...activity, endTime: date });
                            onDateChange("endTime", date);
                          }}
                          className="react-datetime-leguard"
                          inputProps={{ readOnly: true }}
                        />
                        {endTimeError && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            End Time is not valid
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <h5 style={{ textAlign: "center" }}>{timePeriod} </h5>
                      </div>
                    </>
                  )}
                  {activityType == 2 && (
                    <>
                      <div className="form-group">
                        <label>Date *</label>
                        <Datetime
                          closeOnSelect={true}
                          dateFormat="MM/DD/YYYY"
                          timeFormat={false}
                          value={moment(activity.date).format("MM/DD/YYYY")}
                          onChange={(date) => {
                            onDateChange("date", date);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Amount</label>
                        <CurrencyInput
                          className="form-control"
                          value={activity.activityFixedPrice}
                          onValueChange={(value, name, values) => {
                            handleChange("activityFixedPrice", values.value);
                          }}
                          decimalScale={2}
                          decimalLimit={2}
                          prefix={""}
                        />
                      </div>
                    </>
                  )}
                  <div className="form-group">
                    <label>
                      Description
                      {activity.activityId > 0 &&
                        activity.description !== null &&
                        activity.description.length > 0 &&
                        activity.encryptedNotes && (
                          <b>
                            This activity has encrypted notes. Enter your
                            password to view / update them.
                          </b>
                        )}
                    </label>
                    {activity.encryptedNotes == true && (
                      <Input
                        type="textarea"
                        name="notes"
                        id="notes"
                        value={activityNotes}
                        onChange={(e) => {
                          setActivityNotes(e.target.value);
                        }}
                        disabled={
                          activity.activityId > 0 &&
                          activity.description !== null &&
                          activity.description.length > 0 &&
                          activityNotes == ""
                        }
                      />
                    )}
                    {activity.encryptedNotes == false && (
                      <Input
                        type="textarea"
                        name="notes2"
                        id="notes2"
                        value={activity.description}
                        onChange={(e) => {
                          setActivity({
                            ...activity,
                            description: e.target.value,
                          });
                        }}
                      />
                    )}
                  </div>
                  {((!activity.encryptedNotes && currentUser.encryptNotes) ||
                    (activity.encryptedNotes && currentUser.encryptNotes) ||
                    (activity.encryptedNotes &&
                      activity.description !== null &&
                      activity.description.length > 0)) && (
                    <div className="form-group">
                      <label>Password (required if notes are entered)</label>
                      <Input
                        type="password"
                        name="pass"
                        id="pass"
                        value={activity.password}
                        onChange={(e) =>
                          handleChange("password", e.target.value)
                        }
                      />
                      {activityNotes !== "" && isPasswordRequired && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          Password is needed for notes
                        </div>
                      )}
                      {activity.activityId > 0 &&
                        activity.description !== null &&
                        activity.description.length > 0 && (
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              getActivityNotes();
                            }}
                          >
                            View notes
                          </a>
                        )}
                      <CustomInput
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        label="Remember me"
                        onChange={(e) =>
                          setRememberMe(e.target.value == "true")
                        }
                      />
                    </div>
                  )}
                  {/* {!isStopModal && (
                    <Fragment>
                      <div className="form-group">
                        <label htmlFor="revisionReason">
                          Reason for Edited Activity *
                        </label>
                        <CustomInput
                          type="select"
                          id="revisionReason"
                          value={selectedRevisionReason}
                          name="revisionReason"
                          onChange={(e) => {
                            setSelectedRevisionReason(e.target.value);
                            if (e.target.value == -1) {
                              setOtherRevisionReason(true);
                              handleChange("revisionReason", "");
                            } else {
                              setOtherRevisionReason(false);
                              handleChange("revisionReason", e.target.value);
                            }
                          }}
                        >
                          <option key={1} value={"Forgot"}>
                            Forgot
                          </option>
                          <option key={2} value={"Resolved Suspended Activity"}>
                            Resolved Suspended Activity
                          </option>
                          <option key={3} value={"Prior To Training"}>
                            Prior To Training
                          </option>
                          <option key={4} value={"Time put to wrong matter"}>
                            Time put to wrong matter
                          </option>
                          <option key={-1} value={-1}>
                            Other
                          </option>
                        </CustomInput>
                      </div>
                      {otherRevisionReason && (
                        <div className="form-group">
                          <Input
                            type="textarea"
                            name="otherRevReason"
                            id="otherRevReason"
                            placeholder="Enter revision reason..."
                            value={activity.revisionReason}
                            onChange={(e) =>
                              handleChange("revisionReason", e.target.value)
                            }
                          />
                        </div>
                      )}
                    </Fragment>
                  )} */}
                </Col>
              </Row>
              {isOnFinalInvoice && (
                <Row style={{ marginTop: "10px" }}>
                  <Col>
                    <p>
                      This Activity has been invoiced on invoice{" "}
                      {invoice !== null && invoice.invoiceNumber} and cannot be
                      modified
                    </p>
                  </Col>
                </Row>
              )}
              <div style={{ marginTop: "10px" }}>
                {!isStopModal && (
                  <Fragment>
                    <Row>
                      <Col>
                        <Button color="link" onClick={toggleModal}>
                          Cancel
                        </Button>
                        <Button
                          color="primary"
                          style={{ marginLeft: ".25rem" }}
                          disabled={isOnFinalInvoice}
                          onClick={() => {
                            if (validateActivity())
                              save(activity, onSave, saveCallback);
                          }}
                        >
                          Save and Close
                        </Button>
                        {showSaveAndAddAnother && (
                          <Button
                            color="primary"
                            style={{ marginLeft: ".25rem" }}
                            disabled={isOnFinalInvoice}
                            onClick={() => {
                              if (validateActivity())
                                save(
                                  activity,
                                  onSave,
                                  saveAndAddCallback,
                                  true
                                );
                            }}
                          >
                            Save and add another
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {activity.activityId > 0 && (
                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <Button
                            color="danger"
                            style={{ marginLeft: ".25rem" }}
                            className="pull-right"
                            disabled={isOnFinalInvoice}
                            onClick={() => onRemove(activity)}
                          >
                            Remove Activity
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Fragment>
                )}
                {isStopModal && (
                  <Fragment>
                    <Button color="link" onClick={toggleModal}>
                      Cancel
                    </Button>
                    <Button
                      color="warning"
                      style={{ marginLeft: ".25rem" }}
                      onClick={() => save(activity, onPause, saveCallback)}
                    >
                      Pause
                    </Button>{" "}
                    <Button
                      color="danger"
                      style={{ marginLeft: ".25rem" }}
                      onClick={() => {
                        if (validateActivity())
                          save(activity, onStop, saveCallback);
                      }}
                    >
                      Stop
                    </Button>{" "}
                    <Button
                      color="danger"
                      style={{ marginLeft: ".25rem" }}
                      onClick={() => {
                        if (validateActivity())
                          save(activity, onStopStart, saveCallback);
                      }}
                    >
                      Stop and Start New
                    </Button>{" "}
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}

          {!isLoading && activity.activityId == undefined && (
            <Row>
              <Col md="12">
                <label>There is no running activity to stop.</label>
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>

      <SelectMatterModal
        showModal={showMatterModal}
        toggleModal={toggleMatterModal}
        onMatterSelect={onMatterSelect}
      />

      <SelectActivityCodeModal
        showModal={showActivityCodeModal}
        toggleModal={toggleActivityCodeModal}
        matter={matter}
        onActivityCodeSelect={onActivityCodeSelect}
      />
    </Fragment>
  );
};

export default EditActivityModal;
