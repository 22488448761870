import React, { Fragment } from "react";
import PageTitle from "../../Layout/AppMain/PageTitle";
import Loader from "react-loaders";
import { api, userService } from "../../_services";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
  Input,
  Label,
} from "reactstrap";
import Moment from "react-moment";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { currencyFormatter } from "../../_services/currencyFormatter";
import download from "downloadjs";
import moment from "moment";
import DatePicker from "react-datepicker";
import GenerateInvoicesModal from "./GenerateInvoicesModal";

export const PersistentFilters = () => {
  const getStartDate = () => {
    let sd = localStorage.getItem("invoiceStartDateFilter");
    if (sd == undefined || sd == null || sd == "") {
      return moment().add(-30, "day").toDate();
    } else {
      return moment(sd).toDate();
    }
  };
  const setStartDate = (date) => {
    localStorage.setItem("invoiceStartDateFilter", moment(date).format());
  };

  const getEndDate = () => {
    let sd = localStorage.getItem("invoiceEndDateFilter");
    if (sd == undefined || sd == null || sd == "") {
      return moment().toDate();
    } else {
      return moment(sd).toDate();
    }
  };
  const setEndDate = (date) => {
    localStorage.setItem("invoiceEndDateFilter", moment(date).format());
  };

  const getPartnershipId = () => {
    let sd = localStorage.getItem("invoicePartnershipFilter");
    if (sd == undefined || sd == null || sd == "") {
      return -1;
    } else {
      return sd;
    }
  };
  const setPartnershipId = (id) => {
    localStorage.setItem("invoicePartnershipFilter", id);
  };

  const getOutstandingAmount = () => {
    let sd = localStorage.getItem("invoiceOutstandingAmountFilter");
    if (sd == undefined || sd == null || sd == "") {
      return -1;
    } else {
      return sd;
    }
  };
  const setOutstandingAmount = (amount) => {
    localStorage.setItem("invoiceOutstandingAmountFilter", amount);
  };

  const getInvoiceStatus = () => {
    let sd = localStorage.getItem("invoiceInvoiceStatusFilter");
    if (sd == undefined || sd == null || sd == "") {
      return -1;
    } else {
      return sd;
    }
  };
  const setInvoiceStatus = (status) => {
    localStorage.setItem("invoiceInvoiceStatusFilter", status);
  };

  const getReviewer = () => {
    let sd = localStorage.getItem("invoiceReviewerFilter");
    if (sd == undefined || sd == null || sd == "") {
      return "All Reviewers";
    } else {
      return sd;
    }
  };
  const setReviewer = (reviewer) => {
    localStorage.setItem("invoiceReviewerFilter", reviewer);
  };

  const clearAll = () => {
    localStorage.removeItem("invoiceStartDateFilter");
    localStorage.removeItem("invoiceEndDateFilter");
    localStorage.removeItem("invoicePartnershipFilter");
    localStorage.removeItem("invoiceOutstandingAmountFilter");
    localStorage.removeItem("invoiceInvoiceStatusFilter");
    localStorage.removeItem("invoiceReviewerFilter");
  };

  return {
    getStartDate,
    setStartDate,
    getEndDate,
    setEndDate,
    getReviewer,
    setReviewer,
    getInvoiceStatus,
    setInvoiceStatus,
    getOutstandingAmount,
    setOutstandingAmount,
    getPartnershipId,
    setPartnershipId,
    clearAll,
  };
};
class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.persistentFilters = new PersistentFilters();

    this.state = {
      isLoading: true,
      isDownloading: false,
      entityList: [],
      partnershipList: [],
      selectedPartnershipId: this.persistentFilters.getPartnershipId(),
      invoiceList: [],
      selectedEntityId: -1,
      entityGridList: [],
      selectedInvoiceStatus: this.persistentFilters.getInvoiceStatus(),
      invoiceStatusList: [],
      outstandingAmountFilter: this.persistentFilters.getOutstandingAmount(),
      startDate: this.persistentFilters.getStartDate(),
      endDate: this.persistentFilters.getEndDate(),
      showGenerateInvoicesModal: false,
      reviewerList: [],
      selectedReviewer: this.persistentFilters.getReviewer(),
    };
    this.currentUser = userService.getLoggedInUser();
  }

  componentDidMount() {
    if (this.currentUser.systemRoleId == 2) {
      api
        .getPartnershipListByEntityId(
          this.currentUser.entityId,
          this.currentUser.userId
        )
        .then((data) => {
          this.setState({ partnershipList: data });
          api.getInvoiceStatuses().then((s) => {
            this.setState(
              {
                invoiceStatusList: [
                  { invoiceStatusId: -1, name: "All Statuses" },
                  ...s,
                ],
              },
              this.loadInvoices
            );
          });
        });
    } else {
      api
        .getPartnershipListByInvoiceReviewerId(this.currentUser.userId)
        .then((data) => {
          this.setState({ partnershipList: data }, () => {
            api.getInvoiceStatuses().then((s) => {
              this.setState(
                {
                  invoiceStatusList: [
                    { invoiceStatusId: -1, name: "All Statuses" },
                    ...s,
                  ],
                },
                this.loadInvoices
              );
            });
          });
        });
    }
  }

  loadInvoices = () => {
    if (this.currentUser.systemRoleId == 2) {
      let partnershipIds = this.getPartnershipIds();
      api
        .getInvoiceListByPartnershipsEntity(
          partnershipIds,
          this.currentUser.entityId,
          this.currentUser.entityRoleId,
          this.state.selectedInvoiceStatus,
          this.state.outstandingAmountFilter,
          this.state.startDate,
          this.state.endDate,
          this.currentUser.currencyId,
          true
        )
        .then(this.loadInvoicesCallback);
    } else {
      api
        .getInvoiceListByInvoiceReviewerId(
          this.currentUser.userId,
          this.state.outstandingAmountFilter,
          this.state.startDate,
          this.state.endDate
        )
        .then(this.loadInvoicesCallback);
    }
  };

  getDistinctReviewers = (invoices) => {
    let reviewers = ["All Reviewers"];
    invoices.forEach((inv) => {
      if (
        inv.reviewerList !== undefined &&
        inv.reviewerList != null &&
        inv.reviewerList.length > 0
      ) {
        let rs = inv.reviewerList.split(",").map((x) => x.trim());
        rs.forEach((r) => {
          if (reviewers.find((x) => x === r) == undefined) {
            reviewers.push(r);
          }
        });
      }
    });
    return reviewers.sort((a, b) => (a > b ? 1 : -1));
  };

  loadInvoicesCallback = (data) => {
    let selectedInvoiceStatus = this.state.invoiceStatusList.find(
      (x) => x.invoiceStatusId == this.state.selectedInvoiceStatus
    );
    let reviewers = this.getDistinctReviewers(data);
    let partners = [...this.state.partnershipList];
    partners = partners
      .map((p) => {
        p.invoiceList = data.filter(
          (m) =>
            m.partnershipId == p.partnershipId &&
            (this.state.selectedReviewer == "All Reviewers" ||
              m.reviewerList.indexOf(this.state.selectedReviewer) > -1)
        );
        return p;
      })
      .filter((x) => x.invoiceList !== undefined && x.invoiceList.length > 0);
    this.setState({
      reviewerList: reviewers,
      invoiceList: data.filter(
        (x) =>
          selectedInvoiceStatus.invoiceStatusId == -1 ||
          x.invoiceStatusId == selectedInvoiceStatus.invoiceStatusId
      ),
      invoiceGridList: partners,
      isLoading: false,
    });
  };

  getPartnershipIds() {
    return parseInt(this.state.selectedPartnershipId) > 0
      ? "" + this.state.selectedPartnershipId
      : this.state.partnershipList.map((x) => x.partnershipId).toString();
  }

  onPartnerSelectionChange(e) {
    let selectedPartnershipId = parseInt(e.target.value);
    this.persistentFilters.setPartnershipId(selectedPartnershipId);
    this.setState(
      { selectedPartnershipId: selectedPartnershipId },
      this.loadInvoices
    );
  }

  onOutstandingAmountFilterChange(e) {
    this.persistentFilters.setOutstandingAmount(e.target.value);
    this.setState(
      { outstandingAmountFilter: e.target.value },
      this.loadInvoices
    );
  }

  onSelectedInvoiceStatusChange(e) {
    this.persistentFilters.setInvoiceStatus(e.target.value);
    this.setState({ selectedInvoiceStatus: e.target.value }, this.loadInvoices);
  }

  onSelectedReviewerChange(e) {
    this.persistentFilters.setReviewer(e.target.value);
    this.setState({ selectedReviewer: e.target.value }, this.loadInvoices);
  }

  goToInvoice = (id) => {
    this.props.history.push({
      pathname: `/invoice/${id}`,
    });
  };

  toggleGenerateInvoicesModal = () => {
    this.setState({
      showGenerateInvoicesModal: !this.state.showGenerateInvoicesModal,
    });
  };

  downloadInvoice = (invoice) => {
    this.setState({ isDownloading: true });
    api.getInvoicePdf(invoice.invoiceId).then((blob) => {
      this.setState({ isDownloading: false });
      download(blob, "Invoice.pdf");
    });
  };

  downloadLedesInvoice = (e, invoice) => {
    e && e.preventDefault();
    this.setState({ isDownloading: true });
    api.getLedesInvoice(invoice.invoiceId).then((blob) => {
      this.setState({ isDownloading: false });
      download(blob, `LedesInvoice_${invoice.invoiceNumber}.txt`);
    });
  };

  render() {
    const columns = [
      {
        dataField: "partnershipName",
        text: this.currentUser.entityRoleId == 1 ? "Law Firm" : "Client",
        sort: true,
        headerStyle: () => {
          return { width: "100%" };
        },
        formatter: (cell, row) => {
          return cell;
        },
      },
    ];

    const invoiceColumns = [
      {
        dataField: "invoiceNumber",
        text: "Invoice",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cellContent, row, rowIndex, extraData) => {
          return this.currentUser.entityRoleId == 2 ? (
            <Link to={`/invoice/${row.invoiceId}`}>{cellContent}</Link>
          ) : extraData ? (
            <Fragment>
              {cellContent}{" "}
              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </Fragment>
          ) : (
            <a
              key={row.invoiceId}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.downloadInvoice(row);
              }}
            >
              {cellContent}
            </a>
          );
        },
        formatExtraData: this.state.isDownloading,
      },
      {
        dataField: "invoiceId",
        text: "",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cellContent, row, rowIndex, extraData) => {
          return this.currentUser.entityRoleId == 2 ? (
            <span>
              <a href="#" onClick={(e) => this.downloadLedesInvoice(e, row)}>
                Ledes Report
              </a>
              {extraData && (
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              )}
            </span>
          ) : (
            ""
          );
        },
        formatExtraData: this.state.isDownloading,
      },
      {
        dataField: "matterList",
        text: "Matter(s)",
        sort: true,
        headerStyle: () => {
          return { width: "15%" };
        },
      },
      {
        dataField: "reviewerList",
        text: "Reviewer(s)",
        sort: true,
        headerStyle: () => {
          return { width: "14%" };
        },
      },
      {
        dataField: "startDate",
        text: "Start Date",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cellContent, row) => {
          return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
        },
        // footer: columnData =>
        //     columnData.length > 0 ? new Date(columnData.reduce((prev, curr) => {
        //         return new Date(prev) > new Date(curr) ? curr : prev;
        //     })).toLocaleDateString() : ''
      },
      {
        dataField: "endDate",
        text: "End Date",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cellContent, row) => {
          return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
        },
        // footer: columnData =>
        //     columnData.length > 0 ? new Date(columnData.reduce((prev, curr) => {
        //         return new Date(prev) > new Date(curr) ? curr : prev;
        //     })).toLocaleDateString() : ''
      },
      {
        dataField: "invoiceTotal",
        sort: true,
        text: "Amount",
        headerStyle: () => {
          return { width: "8%" };
        },
        formatter: (cell, row) => {
          return currencyFormatter("$", cell, true);
        },
        //footer: columnData => currencyFormatter(this.currentUser.currencySymbol, columnData.reduce((acc, item) => acc + item, 0))
      },
      {
        dataField: "outstandingAmount",
        sort: true,
        text: "Outstanding Amount",
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cell, row) => {
          return currencyFormatter("$", cell, true);
        },
      },
      {
        dataField: "invoiceId",
        sort: true,
        text: "Payments",
        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: (cell, row) => {
          return row.invoicePayments != null
            ? row.invoicePayments
                .map(
                  (p) =>
                    `${moment(p.paymentDate).format(
                      "MM/DD/YYYY"
                    )} (${currencyFormatter("$", p.amountApplied)})`
                )
                .join(", ")
            : "";
        },
      },
      {
        dataField: "invoiceStatusId",
        text: "Status",
        sort: true,
        headerStyle: () => {
          return { width: "7%" };
        },
        formatter: (cell, row) => {
          if (this.currentUser.systemRoleId == 2) {
            return (
              <CustomInput
                type="select"
                id={`invoiceStatusSelect${row.invoiceId}`}
                name={`invoiceStatusSelect${row.invoiceId}`}
                value={row.invoiceStatusId}
                onChange={(e) => {
                  let copy = [...this.state.invoiceList];
                  let item = copy.find((x) => x.invoiceId == row.invoiceId);
                  if (item !== undefined) {
                    item.invoiceStatusId = e.target.value;
                    this.setState({ invoiceList: copy });
                    api.updateInvoice({ ...item }, this.currentUser.userId);
                  }
                }}
              >
                {this.state.invoiceStatusList
                  .filter((x) => x.invoiceStatusId >= 0)
                  .map((p) => {
                    return (
                      <option key={p.invoiceStatusId} value={p.invoiceStatusId}>
                        {p.name}
                      </option>
                    );
                  })}
              </CustomInput>
            );
          } else {
            let sts = this.state.invoiceStatusList.find(
              (x) => x.invoiceStatusId == row.invoiceStatusId
            );
            return sts != undefined && sts != null ? sts.name : "";
          }

          // let bg = "",
          //   text = "";
          // if (row.invoiceStatusId == 0) {
          //   bg = "orange";
          //   text = "Draft";
          // } else if (row.invoiceStatusId == 1) {
          //   bg = "green";
          //   text = "Final";
          // } else {
          //   bg = "green";
          //   text = "Submitted";
          // }
          // return (
          //   <label
          //     style={{
          //       backgroundColor: bg,
          //       padding: "2px",
          //       borderRadius: "3px",
          //       color: "white",
          //     }}
          //   >
          //     {text}
          //   </label>
          // );
        },
      },
    ];
    const expandRow = {
      renderer: (row) => (
        <div className="table-responsive">
          <BootstrapTable
            bootstrap4
            keyField="invoiceId"
            data={row.invoiceList}
            columns={invoiceColumns}
          />
        </div>
      ),
      // expanded: this.state.expandedIndexes,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faMinus} />
          );
        }
        return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPlus} />;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faMinus} />
          );
        }
        return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPlus} />;
      },
    };
    const partnerRowStyle = (row, rowIndex) => {
      const style = {};

      if (row.invoiceList !== undefined) {
        style.backgroundColor = "darkred";
        style.fontWeight = "bold";
        // style.fontSize='1.2em';
        style.color = "#fff";
      }

      return style;
    };
    const defaultSorted = [
      {
        dataField: "partnershipName",
        order: "asc",
      },
    ];
    return (
      <Fragment>
        <PageTitle heading="Invoices" />
        {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!this.state.isLoading && (
          <Fragment>
            <Row>
              {this.currentUser.systemRoleId == 2 && (
                <Col
                  md="12"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.goToInvoice(-1)}
                  >
                    Create New Invoice
                  </Button>
                  <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.toggleGenerateInvoicesModal()}
                  >
                    Generate Monthly Invoices
                  </Button>
                </Col>
              )}
            </Row>

            <Row>
              <Col md="3">
                {this.state.partnershipList.length > 0 && (
                  <CustomInput
                    type="select"
                    id="partnershipSelect"
                    name="partnershipSelect"
                    value={this.state.selectedPartnershipId}
                    onChange={(e) => this.onPartnerSelectionChange(e)}
                  >
                    <option key={0} value={-1}>
                      All{" "}
                      {this.currentUser.entityRoleId == 1
                        ? "Law Firms"
                        : "Clients"}
                    </option>
                    {this.state.partnershipList.map((p) => {
                      return (
                        <option key={p.partnershipId} value={p.partnershipId}>
                          {p.partnershipName}
                        </option>
                      );
                    })}
                  </CustomInput>
                )}
              </Col>
              <Col md="3">
                <CustomInput
                  type="select"
                  id="outstandingAmount"
                  name="outstandingAmount"
                  value={this.state.outstandingAmountFilter}
                  onChange={(e) => this.onOutstandingAmountFilterChange(e)}
                >
                  <option value={-1}>All Amounts</option>
                  <option value={0}>Zero</option>
                  <option value={1}>Higher than Zero</option>
                </CustomInput>
              </Col>
              <Col md="3">
                <CustomInput
                  type="select"
                  id="statusSelect"
                  name="statusSelect"
                  value={this.state.selectedInvoiceStatus}
                  onChange={(e) => this.onSelectedInvoiceStatusChange(e)}
                >
                  {this.state.invoiceStatusList.map((s) => {
                    return (
                      <option key={s.invoiceStatusId} value={s.invoiceStatusId}>
                        {s.name}
                      </option>
                    );
                  })}
                </CustomInput>
              </Col>
              <Col md="3">
                {this.state.reviewerList.length > 0 && (
                  <CustomInput
                    type="select"
                    id="reviewerList"
                    name="reviewerList"
                    value={this.state.selectedReviewer}
                    onChange={(e) => this.onSelectedReviewerChange(e)}
                  >
                    {this.state.reviewerList.map((r) => {
                      return (
                        <option key={r} value={r}>
                          {r}
                        </option>
                      );
                    })}
                  </CustomInput>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <div className="form-group">
                  <Label>Start Date</Label>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(e) => {
                      let date = e;
                      this.persistentFilters.setStartDate(e);
                      this.setState({ startDate: date }, this.loadInvoices);
                    }}
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md="3">
                <div className="form-group">
                  <Label>End Date</Label>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(e) => {
                      let date = e;
                      this.persistentFilters.setEndDate(date);
                      this.setState({ endDate: date }, this.loadInvoices);
                    }}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>

            <Row style={{ marginTop: "30px" }}>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <div className="table-responsive">
                      <BootstrapTable
                        bootstrap4
                        striped
                        bordered={false}
                        keyField="partnershipId"
                        data={this.state.invoiceGridList}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        expandRow={expandRow}
                        rowStyle={partnerRowStyle}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
        <GenerateInvoicesModal
          isOpen={this.state.showGenerateInvoicesModal}
          toggleModal={this.toggleGenerateInvoicesModal}
          partnershipList={this.state.partnershipList}
          reloadData={this.loadInvoices}
        />
      </Fragment>
    );
  }
}

export default InvoiceList;
