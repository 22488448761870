import React, { Fragment } from "react";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CardTitle,
  UncontrolledAlert,
  CustomInput,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import moment from "moment";
import moment_timezone from "moment-timezone";
import { api, userMatterRoles, userService } from "../../_services";
import EditActivityModal, {
  getDurationInHours,
} from "./Components/EditActivityModal";
import GeneralGrid from "../../Components/GeneralGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCalendarWeek,
  faInfo,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import {
  Calendar,
  DateLocalizer,
  momentLocalizer,
  globalizeLocalizer,
  move,
  Views,
  Navigate,
  components,
} from "react-big-calendar";
import { DateTimeHelper } from "../../_helpers/dateTimeHelper";
import RunningActivityHelper from "../../_helpers/runningActivityHelper";
import SuspendedActivitiesHelper from "../../_helpers/suspendedActivitiesHelper";
import { parseJSON } from "date-fns";
import ExpenseModal from "../Invoice/InvoiceSettings/Components/ExpenseModal";
import SelectMatterModal from "./Components/SelectMatterModal";
import { currencyFormatter } from "../../_services/currencyFormatter";
import Loader from "react-loaders";
import DatePicker from "react-datepicker";

class ActivityList extends React.Component {
  constructor(props) {
    super(props);
    this.newActivity = {
      activityId: -1,
      matterName: "Not selected",
      revisionReason: "forgot",
      description: "",
    };
    this.state = {
      isLoading: true,
      activityList: [],
      activityEvents: [],
      //   showMatterModal: false,
      activeTab: "Activities",
      showExpenseModal: false,
      showEditActivityModal: false,
      activityToEdit: this.newActivity,
      suspendedActivityList: [],
      otherBillersActivityList: [],
      showOtherBillersActivityList: true,
      currentDate: DateTimeHelper.getUsersNow(),
      expenseToEdit: null,
      matterList: [],
      partnershipList: [],
      selectedPartnershipId: -1,
      selectedMatterId: -1,
      expenseList: [],
      viewAsList: true,
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("day").toDate(),
    };
    this.currentUser = userService.getLoggedInUser();
    //moment_timezone.tz.setDefault(this.currentUser.timezoneIanaName);
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#" + event.end == null ? "darkred" : "lightblue";
    if (event.isHidden) backgroundColor = "#f39c12";
    if (event.endTime == null) backgroundColor = "lightgreen";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
      minHeight: "25px",
      fontSize: "80%",
    };
    return {
      style: style,
    };
  };

  componentDidMount() {
    this.runningActivityObservable =
      RunningActivityHelper.runningActivityObservable.subscribe((val) => {
        this.loadActivities();
      });
    this.loadMatters();
    api.getPartnershipListByUserId(this.currentUser.userId, 2).then((bp) => {
      api.getPartnershipListByUserId(this.currentUser.userId, 3).then((rp) => {
        let list = [
          ...bp,
          ...rp.filter(
            (x) =>
              bp.find((xx) => xx.partnershipId == x.partnershipId) == undefined
          ),
        ];
        this.setState({ partnershipList: list.filter((x) => x !== null) });
      });
    });
  }

  componentWillUnmount() {
    this.runningActivityObservable.unsubscribe();
  }

  loadMatters() {
    Promise.all([
      api.getMatterListByUserId(
        this.currentUser.userId,
        userMatterRoles.respLawyer.id
      ),
      api.getMatterListByUserId(
        this.currentUser.userId,
        userMatterRoles.biller.id
      ),
    ]).then(([list1, list2]) => {
      let ret = [];
      if (list1.length > 0) {
        list1.forEach((el) => {
          let found = list2.find((m) => {
            return m.userMatterId == el.userMatterId;
          });
          if (found !== undefined) list2.splice(list2.indexOf(found), 1);
        });
      }
      ret = [...list1, ...list2];
      ret = ret.sort((a, b) => (a.matterName > b.matterName ? 1 : -1));
      ret = ret.sort((a, b) =>
        a.partnershipName > b.partnershipName ? -1 : 1
      );
      ret = ret.map((x) => {
        var number =
          x.eBillerID_Lawfirm !== null && x.eBillerID_Lawfirm.length > 0
            ? " [" + x.eBillerID_Lawfirm + "]"
            : "";
        x.matterName = x.matterName + number;
        return x;
      });
      this.setState({
        matterList: ret,
      });
    });
  }

  loadActivities() {
    api.getActivityListByBillerId(this.currentUser.userId).then((data) => {
      api.getExpenseListByUserId(this.currentUser.userId).then((expList) => {
        data = data.map((activity) => {
          activity.start = moment(
            DateTimeHelper.getUsersDateTimeStringFromUtc(
              activity.startTime,
              "MM/DD/YYYY hh:mm A"
            )
          ).toDate();
          activity.end = moment(
            activity.endTime == null
              ? DateTimeHelper.getUsersNow("MM/DD/YYYY hh:mm A")
              : DateTimeHelper.getUsersDateTimeStringFromUtc(
                  activity.endTime,
                  "MM/DD/YYYY hh:mm A"
                )
          ).toDate();
          activity.title = `${activity.activityCodeTitle} (${activity.matterName})`;

          return activity;
        });
        let events = [...data];
        expList.forEach((exp) => {
          exp.start = moment(
            DateTimeHelper.getUsersDateTimeStringFromUtc(
              exp.expenseDate,
              "MM/DD/YYYY hh:mm A"
            )
          ).toDate();
          exp.end = moment(
            DateTimeHelper.getUsersDateTimeStringFromUtc(
              exp.expenseDate,
              "MM/DD/YYYY hh:mm A"
            )
          ).toDate();
          events.push({
            ...exp,
            title: exp.expenseName,
            start: moment(
              DateTimeHelper.getUsersDateTimeStringFromUtc(
                exp.expenseDate,
                "MM/DD/YYYY hh:mm A"
              )
            )
              .set({ h: 0, m: 0 })
              .toDate(),
            end: moment(
              DateTimeHelper.getUsersDateTimeStringFromUtc(
                exp.expenseDate,
                "MM/DD/YYYY hh:mm A"
              )
            )
              .set({ h: 1, m: 0 })
              .toDate(),
            isExpense: true,
          });
        });
        var res = [];
        events.forEach((event) => {
          if (event.start.getDate() !== event.end.getDate()) {
            let e = moment(event.start, "YYYY-MM-DDThh:mm:ss");
            e.set({ h: 23, m: 59 });
            let prevEnd = event.end;
            event.end = e.toDate();
            event.ended = `(ended ${moment(prevEnd).format("MM/DD hh:mm A")})`;
          }
          res.push(event);
        });

        let acts = data.sort(function (a, b) {
          return new Date(b.startTime) - new Date(a.startTime);
        });

        this.setState({
          activityList: acts,
          activityEvents: events,
          expenseList: expList.sort(function (a, b) {
            return new Date(b.expenseDate) - new Date(a.expenseDate);
          }),
          isLoading: false,
        });
      });
    });
    this.loadSuspendedActivities(1);
  }

  loadSuspendedActivities(includeHidden) {
    api
      .getSuspendedActivityListByBillerId(
        this.currentUser.userId,
        includeHidden
      )
      .then((data) => {
        this.setState({
          suspendedActivityList: data.filter((x) => {
            x.startTime = DateTimeHelper.getUsersDateTimeStringFromUtc(
              x.startTime
            );
            x.startTimeDate = x.startTime;
            x.endTime = DateTimeHelper.getUsersDateTimeStringFromUtc(x.endTime);
            return !x.isHidden;
          }),
        });
        SuspendedActivitiesHelper.setSuspendedActivities(data.length);
      });
    api
      .getSuspendedActivityListByResponsibleLawyerId(this.currentUser.userId)
      .then((data) => {
        let lst = data.map((x) => {
          x.startTime = DateTimeHelper.getUsersDateTimeStringFromUtc(
            x.startTime
          );
          x.startTimeDate = x.startTime;
          x.endTime = DateTimeHelper.getUsersDateTimeStringFromUtc(x.endTime);
          return x;
        });
        this.setState({
          otherBillersActivityList: lst,
        });
      });
  }

  //   toggleMatterModal = () => {
  //     this.setState({ showMatterModal: !this.state.showMatterModal });
  //   };

  toggleExpenseModal = () => {
    this.setState({ showExpenseModal: !this.state.showExpenseModal });
  };

  toggleEditActivityModal = () => {
    this.setState({ showEditActivityModal: !this.state.showEditActivityModal });
  };

  onActivitySelect = (act, e) => {
    if (act.isExpense) {
      this.setState({ expenseToEdit: act }, () => {
        this.toggleExpenseModal();
      });
    } else {
      this.setState({ activityToEdit: act }, () =>
        this.toggleEditActivityModal()
      );
    }
  };

  removeActivity = (activity, callback) => {
    activity.isDeleted = true;
    this.saveActivity(activity, callback);
  };

  hideActivity = (activity, callback) => {
    activity.isHidden = !activity.isHidden;
    this.saveActivity(activity, callback);
  };

  saveActivity = (activity, callback, addAnother) => {
    if (activity.activityId > -1) {
      api.updateActivity(activity).then((data) => {
        if (data !== undefined && data !== null && data !== "") {
          callback && callback(data);
        } else {
          this.loadActivities();
          if (!addAnother) {
            this.toggleEditActivityModal();
          }
          callback && callback("");
        }
      });
    } else {
      let act = {
        ...activity,
        startTime: activity.startTime,
        endTime: activity.endTime,
        authBillerId:
          activity.authBillerId != undefined && activity.authBillerId > 0
            ? activity.authBillerId
            : this.currentUser.userId,
        activityCodeId: activity.activityCodeId,
        matterId: activity.matterId,
        cost: 0,
        description: activity.description,
        password: activity.password,
        encryptedNotes: this.currentUser.encryptNotes,
        timePeriod: activity.timePeriod,
        isTimePeriodSet: activity.isTimePeriodSet,
        date: activity.date,
        explanationCodeID: activity.explanationCodeID,
      };
      api.startActivity(act).then((data) => {
        if (data !== undefined && data !== null && data !== "") {
          callback && callback(data);
        } else {
          this.loadActivities();
          if (!addAnother) {
            this.toggleEditActivityModal();
          }
          callback && callback("");
        }
      });
    }
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  onHiddenChange = () => {
    this.setState({
      showOtherBillersActivityList: !this.state.showOtherBillersActivityList,
    });
  };

  addNewExpense = () => {
    let newExpense = {
      expenseId: -1,
      invoiceId: 0,
      matterId: -1,
      matterName: "",
      expenseName: "",
      notes: "",
      expenseAmount: "",
      currencyId: 3,
      expenseDate: new Date(),
    };
    this.setState({ showExpenseModal: true, expenseToEdit: newExpense });
  };

  editExpense = (expense) => {
    this.setState({ expenseToEdit: expense });
  };

  //   onMatterSelect = (matter) => {
  //     this.setState({
  //       expenseToEdit: {
  //         ...this.state.expenseToEdit,
  //         matterId: matter.matterId,
  //         matterName: matter.matterName,
  //       },
  //     });
  //     this.toggleMatterModal();
  //   };

  customDayHeader = ({ date, label }) => {
    let timePerDay = this.getTimeByDate(date);

    return (
      <div
        className="my-custom-calendar-header"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <span>{label}</span>
        {timePerDay !== "" && (
          <span style={{ color: "black" }}>{timePerDay}</span>
        )}
      </div>
    );
  };

  customWeekHeader = ({ date, label }) => {
    let timePerDay = this.getTimeByDate(date);

    return (
      <div
        className="my-custom-calendar-header"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <span>{label}</span>
        {timePerDay !== "" && (
          <span style={{ color: "black" }}>{timePerDay}</span>
        )}
      </div>
    );
  };

  getTimeByDate = (date) => {
    let list = [...this.state.activityEvents];
    let start = moment(date).startOf("day").toDate();
    let end = moment(date).endOf("day").toDate();
    let secondsPerDay = list
      .filter((x) => x.start >= start && x.start <= end)
      .map((x) => moment(x.end).diff(moment(x.start), "seconds"));
    let totalSeconds = secondsPerDay.reduce((acc, current) => acc + current, 0);

    let ret = totalSeconds > 0 ? totalSeconds / 3600 : ""; //DateTimeHelper.showDurationFromSeconds(totalSeconds);

    return ret == "" ? "" : `${ret.toFixed(2)} h`;
    // let secondsPerDay = list
    //   .filter((x) => x.start >= start && x.start <= end)
    //   .map((x) => moment(x.end).diff(moment(x.start), "seconds"));
    // let totalSeconds = secondsPerDay.reduce((acc, current) => acc + current, 0);

    // return DateTimeHelper.showDurationFromSeconds(totalSeconds);
  };

  render() {
    const suspendedActivityColumns = [
      {
        dataField: "clientEntityName",
        text: "Client",
        sort: true,
        headerStyle: () => {
          return { width: "15%" };
        },
      },
      {
        dataField: "matterName",
        text: "Matter",
        sort: true,
        headerStyle: () => {
          return { width: "35%" };
        },
        formatter: (cell, row) => {
          return (
            <span>
              {cell}{" "}
              {this.currentUser.entityRoleId == 2 &&
                row.eBillerIdLawfirm != null &&
                row.eBillerIdLawfirm != "" &&
                `(${row.eBillerIdLawfirm})`}
              {this.currentUser.entityRoleId == 1 &&
                row.eBillerId != null &&
                row.eBillerId != "" &&
                `(${row.eBillerId})`}
            </span>
          );
        },
      },
      {
        dataField: "activityCodeTitle",
        text: "Activity",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              key={row.activityId}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.onActivitySelect(row);
              }}
            >
              {cellContent}
            </a>
          );
        },
      },
      {
        dataField: "startTime",
        text: "Date",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cellContent, row) => {
          return DateTimeHelper.getUsersDateTimeStringFromUtc(cellContent, "L");
        },
      },
      {
        dataField: "startTimeDate",
        text: "Time Start",
        sort: true,
        headerStyle: () => {
          return { width: "15%" };
        },
        formatter: (cellContent, row) => {
          return DateTimeHelper.getUsersDateTimeStringFromUtc(
            cellContent,
            "LT"
          );
        },
      },
      {
        dataField: "endTime",
        text: "Time End",
        sort: true,
        headerStyle: () => {
          return { width: "15%" };
        },
        formatter: (cellContent, row) => {
          return cellContent == null
            ? "???"
            : DateTimeHelper.getUsersDateTimeStringFromUtc(cellContent, "LT");
        },
      },
    ];
    const otherBillersSuspendedActivityColumns = [
      {
        dataField: "clientEntityName",
        text: "Client",
        sort: true,
        headerStyle: () => {
          return { width: "14%" };
        },
      },
      {
        dataField: "matterName",
        text: "Matter",
        sort: true,
        headerStyle: () => {
          return { width: "30%" };
        },
        formatter: (cell, row) => {
          return (
            <span>
              {cell}{" "}
              {this.currentUser.entityRoleId == 2 &&
                row.eBillerIdLawfirm != null &&
                row.eBillerIdLawfirm != "" &&
                `(${row.eBillerIdLawfirm})`}
              {this.currentUser.entityRoleId == 1 &&
                row.eBillerId != null &&
                row.eBillerId != "" &&
                `(${row.eBillerId})`}
            </span>
          );
        },
      },
      {
        dataField: "authBillerName",
        text: "Biller Name",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "activityCodeTitle",
        text: "Activity",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
      },
      {
        dataField: "startTime",
        text: "Date",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cellContent, row) => {
          return DateTimeHelper.getUsersDateTimeStringFromUtc(cellContent, "L");
        },
      },
      {
        dataField: "startTimeDate",
        text: "Time Start",
        sort: true,
        headerStyle: () => {
          return { width: "13%" };
        },
        formatter: (cellContent, row) => {
          return DateTimeHelper.getUsersDateTimeStringFromUtc(
            cellContent,
            "LT"
          );
        },
      },
      {
        dataField: "endTime",
        text: "Time End",
        sort: true,
        headerStyle: () => {
          return { width: "13%" };
        },
        formatter: (cellContent, row) => {
          return cellContent == null
            ? "???"
            : DateTimeHelper.getUsersDateTimeStringFromUtc(cellContent, "LT");
        },
      },
    ];
    const gridOptions = {
      sizePerPageList: [
        {
          text: "50",
          value: 50,
        },
      ],
      hideSizePerPage: false,
    };
    return (
      <Fragment>
        <Row style={{ marginBottom: "30px" }}>
          <Col md="12">
            <CustomInput
              type="checkbox"
              label="Show Suspended Activities for Billers"
              id={"show-hidden-items"}
              checked={this.state.showOtherBillersActivityList}
              onChange={this.onHiddenChange}
            />
          </Col>
        </Row>
        {this.state.showOtherBillersActivityList && (
          <Card className="mb-3">
            <CardBody>
              <CardTitle>Suspended Activities for Billers</CardTitle>
              {this.state.otherBillersActivityList.length > 0 && (
                <GeneralGrid
                  colWidth={12}
                  title=""
                  keyField="activityId"
                  defaultSortedColumn="startTime"
                  dataSource={this.state.otherBillersActivityList}
                  options={gridOptions}
                  columns={otherBillersSuspendedActivityColumns}
                />
              )}
              {this.state.otherBillersActivityList.length == 0 &&
                "No activities to display"}
            </CardBody>
          </Card>
        )}
        {this.state.suspendedActivityList.length > 0 && (
          <Card className="mb-3">
            <CardBody>
              <CardTitle>Suspended Activities</CardTitle>
              <Col md="12">
                <UncontrolledAlert color="danger">
                  <h4>
                    <FontAwesomeIcon icon={faInfo} /> Alert
                  </h4>
                  The items listed below were suspended because they were not
                  ended properly or overlap with another activity. They will not
                  be calculated as valid activities until they are updated.
                </UncontrolledAlert>
              </Col>
              <GeneralGrid
                colWidth={12}
                title=""
                keyField="activityId"
                defaultSortedColumn="startTime"
                dataSource={this.state.suspendedActivityList}
                options={gridOptions}
                columns={suspendedActivityColumns}
              />
            </CardBody>
          </Card>
        )}
        <Card className="mb-3">
          <CardBody>
            <CardTitle>All Activities</CardTitle>
            <Row style={{ marginBottom: "20px" }}>
              <Col md="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {" "}
                    <Button
                      color="primary"
                      onClick={() => {
                        this.setState(
                          { activityToEdit: this.newActivity },
                          this.toggleEditActivityModal()
                        );
                      }}
                    >
                      Create New Activity
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.addNewExpense();
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Create New Expense
                    </Button>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faCalendarWeek}
                      style={{
                        cursor: "pointer",
                        fontSize: "20px",
                        color: this.state.viewAsList ? "gray" : "maroon",
                      }}
                      onClick={() => this.setState({ viewAsList: false })}
                    />
                    <FontAwesomeIcon
                      icon={faList}
                      style={{
                        marginLeft: "20px",
                        cursor: "pointer",
                        fontSize: "20px",
                        color: this.state.viewAsList ? "maroon" : "gray",
                      }}
                      onClick={() => this.setState({ viewAsList: true })}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            {this.state.isLoading && (
              <Loader style={{}} type="ball-pulse" active />
            )}
            {!this.state.isLoading && !this.state.viewAsList && (
              <Calendar
                selectable
                localizer={momentLocalizer(moment_timezone)}
                events={this.state.activityEvents}
                startAccessor="start"
                endAccessor="end"
                defaultDate={new Date()}
                eventPropGetter={this.eventStyleGetter}
                onSelectEvent={this.onActivitySelect}
                views={["month", "week", "day"]}
                defaultView={"day"}
                components={{
                  event: EventComponent,
                  day: {
                    header: this.customDayHeader,
                  },
                  week: {
                    header: this.customWeekHeader,
                  },
                }}
              />
            )}
            {!this.state.isLoading && this.state.viewAsList && (
              <>
                <Row>
                  <Col md="3">
                    <div className="form-group">
                      <Label>Start Date</Label>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={(e) =>
                          this.setState({
                            startDate: moment(e).startOf("day").toDate(),
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(e) =>
                          this.setState({
                            endDate: moment(e).endOf("day").toDate(),
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <Label>Client</Label>
                      <CustomInput
                        type="select"
                        value={this.state.selectedPartnershipId}
                        onChange={(e) =>
                          this.setState({
                            selectedPartnershipId: e.target.value,
                            selectedMatterId: -1,
                          })
                        }
                      >
                        <option key={0} value={-1}>
                          All Clients
                        </option>
                        {this.state.partnershipList.map((p) => {
                          return (
                            <option
                              key={p.partnershipId}
                              value={p.partnershipId}
                            >
                              {p.partnershipName}
                            </option>
                          );
                        })}
                      </CustomInput>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="form-group">
                      <Label>Matter</Label>
                      <CustomInput
                        type="select"
                        value={this.state.selectedMatterId}
                        onChange={(e) =>
                          this.setState({
                            selectedMatterId: e.target.value,
                          })
                        }
                      >
                        <option key={0} value={-1}>
                          All Matters
                        </option>
                        {this.state.matterList
                          .filter(
                            (x) =>
                              this.state.selectedPartnershipId == -1 ||
                              x.partnershipId ==
                                this.state.selectedPartnershipId
                          )
                          .map((p) => {
                            return (
                              <option key={p.matterId} value={p.matterId}>
                                {p.matterName}
                              </option>
                            );
                          })}
                      </CustomInput>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                            color: "maroon",
                            fontWeight: "bold",
                          }}
                          className={
                            this.state.activeTab === "Activities"
                              ? "active"
                              : ""
                          }
                          onClick={() => {
                            this.toggleTab("Activities");
                          }}
                        >
                          Activities
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                            color: "maroon",
                            fontWeight: "bold",
                          }}
                          className={
                            this.state.activeTab === "Expenses" ? "active" : ""
                          }
                          onClick={() => {
                            this.toggleTab("Expenses");
                          }}
                        >
                          Expenses
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="Activities">
                        <GeneralGrid
                          style={{ marginLeft: "-20px", marginRight: "-20px" }}
                          title={`Activities (${this.state.activityList
                            .filter(
                              (x) =>
                                moment(x.start).toDate() <=
                                  this.state.endDate &&
                                moment(x.start).toDate() >=
                                  this.state.startDate &&
                                (this.state.selectedPartnershipId == -1 ||
                                  x.partnershipId ==
                                    this.state.selectedPartnershipId) &&
                                (this.state.selectedMatterId == -1 ||
                                  x.matterId == this.state.selectedMatterId)
                            )
                            .reduce(
                              (acc, item) =>
                                acc +
                                parseFloat(
                                  getDurationInHours(
                                    item.startTime,
                                    item.endTime
                                  )
                                ),
                              0
                            )
                            .toFixed(2)}) hours`}
                          keyField="activityId"
                          defaultSortedColumn="startTime"
                          dataSource={this.state.activityList.filter(
                            (x) =>
                              moment(x.start).toDate() <= this.state.endDate &&
                              moment(x.start).toDate() >=
                                this.state.startDate &&
                              (this.state.selectedPartnershipId == -1 ||
                                x.partnershipId ==
                                  this.state.selectedPartnershipId) &&
                              (this.state.selectedMatterId == -1 ||
                                x.matterId == this.state.selectedMatterId)
                          )}
                          options={gridOptions}
                          columns={[
                            {
                              dataField: "activityCodeTitle",
                              text: "Activity",
                              sort: true,
                              headerStyle: () => {
                                return { width: "15%" };
                              },
                              formatter: (cellContent, row) => {
                                return (
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e && e.preventDefault();
                                      this.setState(
                                        { activityToEdit: row },
                                        () => this.toggleEditActivityModal()
                                      );
                                    }}
                                  >
                                    {cellContent}
                                  </a>
                                );
                              },
                            },
                            {
                              dataField: "matterName",
                              text: "Matter",
                              sort: true,
                              headerStyle: () => {
                                return { width: "20%" };
                              },
                              formatter: (cell, row) => {
                                return (
                                  <span>
                                    {cell}{" "}
                                    {this.currentUser.entityRoleId == 2 &&
                                      row.eBillerIdLawfirm != null &&
                                      row.eBillerIdLawfirm != "" &&
                                      `(${row.eBillerIdLawfirm})`}
                                    {this.currentUser.entityRoleId == 1 &&
                                      row.eBillerId != null &&
                                      row.eBillerId != "" &&
                                      `(${row.eBillerId})`}
                                  </span>
                                );
                              },
                            },
                            {
                              dataField: "start",
                              text: "Date",
                              sort: true,
                              headerStyle: () => {
                                return { width: "15%" };
                              },
                              formatter: (cellContent, row) => {
                                return cellContent == null
                                  ? ""
                                  : moment(cellContent).format("MM/DD/YYYY");
                              },
                            },
                            {
                              dataField: "activityId",
                              text: "Duration",
                              sort: false,
                              headerStyle: () => {
                                return { width: "20%" };
                              },
                              formatter: (cellContent, row) => {
                                return (
                                  getDurationInHours(
                                    row.startTime,
                                    row.endTime
                                  ) + " hours"
                                );
                              },
                            },
                            {
                              dataField: "description",
                              text: "Description",
                              sort: true,
                              headerStyle: () => {
                                return { width: "40%" };
                              },
                            },
                          ]}
                        />
                      </TabPane>
                      <TabPane tabId="Expenses">
                        <GeneralGrid
                          style={{ marginLeft: "-20px", marginRight: "-20px" }}
                          title="Expenses"
                          keyField="expenseId"
                          defaultSortedColumn="vendorName"
                          dataSource={this.state.expenseList.filter(
                            (x) =>
                              moment(x.expenseDate).toDate() <=
                                this.state.endDate &&
                              moment(x.expenseDate).toDate() >=
                                this.state.startDate &&
                              (this.state.selectedPartnershipId == -1 ||
                                x.partnershipId ==
                                  this.state.selectedPartnershipId) &&
                              (this.state.selectedMatterId == -1 ||
                                x.matterId == this.state.selectedMatterId)
                          )}
                          options={gridOptions}
                          columns={[
                            {
                              dataField: "vendorName",
                              text: "Vendor",
                              sort: true,
                              headerStyle: () => {
                                return { width: "15%" };
                              },
                              formatter: (cellContent, row) => {
                                return (
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e && e.preventDefault();
                                      this.setState(
                                        { expenseToEdit: row },
                                        () => this.toggleExpenseModal()
                                      );
                                    }}
                                  >
                                    {cellContent}
                                  </a>
                                );
                              },
                            },
                            {
                              dataField: "matterName",
                              text: "Matter",
                              sort: true,
                              headerStyle: () => {
                                return { width: "20%" };
                              },
                            },
                            {
                              dataField: "start",
                              text: "Date",
                              sort: true,
                              headerStyle: () => {
                                return { width: "15%" };
                              },
                              formatter: (cellContent, row) => {
                                return cellContent == null
                                  ? ""
                                  : moment(cellContent).format("MM/DD/YYYY");
                              },
                            },
                            {
                              dataField: "expenseAmount",
                              text: "Amount",
                              sort: true,
                              headerStyle: () => {
                                return { width: "20%" };
                              },
                              formatter: (cellContent, row) => {
                                return currencyFormatter(
                                  "$",
                                  cellContent,
                                  true
                                );
                              },
                            },
                            {
                              dataField: "notes",
                              text: "Description",
                              sort: true,
                              headerStyle: () => {
                                return { width: "40%" };
                              },
                            },
                          ]}
                        />
                      </TabPane>
                    </TabContent>
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>

        <EditActivityModal
          showModal={this.state.showEditActivityModal}
          toggleModal={this.toggleEditActivityModal}
          activityId={this.state.activityToEdit.activityId}
          currentDate={this.state.currentDate}
          onRemove={this.removeActivity}
          onHide={this.hideActivity}
          onSave={this.saveActivity}
          showSaveAndAddAnother={true}
        />
        {this.state.expenseToEdit != null && (
          <ExpenseModal
            expenseObj={this.state.expenseToEdit}
            showModal={this.state.showExpenseModal}
            toggleModal={this.toggleExpenseModal}
            matterList={this.state.matterList}
            reload={() => this.loadActivities()}
          />
        )}
      </Fragment>
    );
  }
}

const EventComponent = (data) => {
  return (
    <span>
      <b>{data.event.activityCodeTitle}</b>{" "}
      {data.event.matterName != undefined
        ? `(${data.event.matterName})`
        : data.event.title}{" "}
      {data.event.ended == undefined ? "" : data.event.ended}
    </span>
  );
};

export default ActivityList;
