import React, { Component, Fragment } from 'react';
import {
    Button, Container, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput,
    Card, CardBody, Row, Col, CardTitle, UncontrolledAlert
} from 'reactstrap';
import Loader from 'react-loaders'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/fontawesome-free-solid"
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api, userService } from '../../_services';
import _ from 'lodash';
import MobileHelper from '../../_helpers/mobileHelper';
import PageTitle from '../../Layout/AppMain/PageTitle';
import swal from 'sweetalert';
class CategoryPackages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showModal: false,
            categoryPackageGridList: [],
            categoryPackageOriginalList: [],
            selectionActivityCodeList: [],
            selectedActivityCodeList: [],
            selectedPackageFilter: { packageId: 0 },
            packageToEdit: {
                activityCodes: [],
                description: '',
                entityId: '',
                isDeleted: false,
                name: '',
                packageId: -1
            },
            selectAllOnSelectionCodes: {
                id: 0,
                isSelected: false
            },
            selectAllOnSelectedCodes: {
                id: 1,
                isSelected: false
            }
        };
        this.newPackage = {
            activityCodes: [],
            description: '',
            entityId: '',
            isDeleted: false,
            name: '',
            packageId: -1
        }
        this.formRef = React.createRef();
        this.currentUser = userService.getLoggedInUser();
    }

    componentDidMount() {
        this.loadPackageList();
    }

    setDefaultValues(data) {
        let allActivityCodes = [];
        let list = data.map((p, i) => {
            let ret = p;
            ret.codes = (p.activityCodes == undefined || p.activityCodes == null) ? [] : p.activityCodes.map(ac => {
                allActivityCodes.push({ ...ac, isSelected: false });
                return ac.title;
            }).join(', ');
            return ret;
        }).filter(el => {
            return el !== undefined; //&& el.entityId !== 0;
        })

        let filteredCodes = allActivityCodes.reduce((acc, current) => {
            let x = acc.find(item => item.activityCodeId === current.activityCodeId);
            current.isVisible = true;
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        this.setState({
            categoryPackageGridList: list,
            categoryPackageOriginalList: data,
            selectionActivityCodeList: filteredCodes,
            selectedActivityCodeList: [],
            selectedPackageFilter: { packageId: 0 },
            selectAllOnSelectionCodes: {
                id: 0,
                isSelected: false
            },
            selectAllOnSelectedCodes: {
                id: 1,
                isSelected: false
            },
            isLoading: false
        });
    }

    loadPackageList() {
        api.getCategoryPackageListByEntityId(this.currentUser.entityId).then(data => {
            this.setDefaultValues(data);
        });
    }

    showModal(categoryPackage, e) {
        e && e.preventDefault();
        if (MobileHelper.isMobile())
            swal("This function is only available on larger screens.", {
                buttons: {
                    ok: {
                        text: "Ok",
                        value: "ok",
                        className: "btn-success"
                    }
                },
                icon: 'warning'
            });
        else
            this.setState({ showModal: !this.state.showModal, packageToEdit: categoryPackage }, () => { this.setSelectedCodes(categoryPackage) });
    }

    closeModal() {
        this.setDefaultValues(this.state.categoryPackageOriginalList);
        this.showModal(this.newPackage);
    }

    onSelectionChangeSelection(actCode, e) {
        this.setState(prevState => {
            const list = prevState.selectionActivityCodeList.map(item => {
                if (item.activityCodeId == actCode.activityCodeId) {
                    item.isSelected = !item.isSelected;
                }
                return item;
            });
            return {
                ...prevState,
                selectionActivityCodeList: list
            }
        })

    }
    onSelectionChangeSelected(actCode, e) {
        this.setState(prevState => {
            const list = prevState.selectedActivityCodeList.map(item => {
                if (item.activityCodeId == actCode.activityCodeId) {
                    item.isSelected = !item.isSelected;
                }
                return item;
            });
            return {
                ...prevState,
                selectedActivityCodeList: list
            }
        })

    }

    onPackageSelectionChange(packageId) {
        let actCodeList = [];
        if (packageId == 0) {
            actCodeList = this.state.selectionActivityCodeList.map(ac => {
                ac.isVisible = true;
                return ac;
            });
        } else {
            let actCodesToShow = this.state.categoryPackageOriginalList.map(p => {
                if (p.packageId == packageId) {
                    return p.activityCodes;
                }
            }).filter(el => {
                return el !== undefined;
            })[0];
            actCodeList = this.state.selectionActivityCodeList.map(ac => {
                let found = actCodesToShow.find(a => a.activityCodeId == ac.activityCodeId);
                if (found !== undefined) {
                    ac.isVisible = true;
                } else {
                    ac.isVisible = false;
                }
                return ac;
            })
        }
        this.setState({ selectionActivityCodeList: actCodeList, selectedPackageFilter: { packageId: packageId } });
    }

    setSelectedCodes(categoryPackage) {
        let selectionActivityCodeList = this.state.selectionActivityCodeList.map(ac => {
            if (categoryPackage.activityCodes.find(a => a.activityCodeId == ac.activityCodeId) != undefined)
                ac.isSelected = true;
            return ac;
        });
        this.setState(prevState => ({
            ...prevState,
            selectionActivityCodeList: selectionActivityCodeList
        }), () => {
            this.addSelectedCodes();
        })
    }

    addSelectedCodes() {
        let selected = this.state.selectionActivityCodeList.filter(ac => {
            return ac.isSelected == true;
        })
        let selectionList = this.state.selectionActivityCodeList.filter(ac => {
            if (!selected.includes(ac))
                return ac;
        });
        let selectedActivityCodeList = this.state.selectedActivityCodeList.concat(selected.map(ac => { ac.isSelected = false; return ac; }));
        this.setState({ selectedActivityCodeList: selectedActivityCodeList, selectionActivityCodeList: selectionList, selectAllOnSelectedCodes: { id: this.state.selectAllOnSelectedCodes.id, isSelected: false } });
    }

    removeSelectedCodes() {
        let selected = this.state.selectedActivityCodeList.filter(ac => {
            return ac.isSelected == true;
        });
        let selectedList = this.state.selectedActivityCodeList.filter(ac => {
            if (!selected.includes(ac))
                return ac;
        });
        let selectionActivityCodeList = this.state.selectionActivityCodeList.concat(selected.map(ac => { ac.isSelected = false; return ac; }));

        this.setState({ selectionActivityCodeList: selectionActivityCodeList, selectedActivityCodeList: selectedList, selectAllOnSelectionCodes: { id: this.state.selectAllOnSelectionCodes.id, isSelected: false } });
    }

    handleSubmit() {
        this.formRef.current.submitForm();
    }

    save() {
        if (this.state.packageToEdit.packageId == -1) {
            this.createPackage();
        } else {
            this.updatePackage();
        }
    }

    createPackage() {
        api.createPackage(this.state.packageToEdit).then(msg => {
            this.closeModal();
            this.loadPackageList();
        })
    }

    updatePackage() {
        api.updatePackage(this.state.packageToEdit).then(msg => {
            this.closeModal();
            this.loadPackageList();
        })
    }

    onSelectAllSelectionChange(type) {
        if (type == 0) { //select all in selectionActivityCodeList

            let list = this.state.selectionActivityCodeList.map(ac => {
                ac.isSelected = !this.state.selectAllOnSelectionCodes.isSelected;
                return ac;
            });
            this.setState({ selectionActivityCodeList: list, selectAllOnSelectionCodes: { id: this.state.selectAllOnSelectionCodes.id, isSelected: !this.state.selectAllOnSelectionCodes.isSelected } });
        } else { //selectedActivityCodeList
            let list = this.state.selectedActivityCodeList.map(ac => {
                ac.isSelected = !this.state.selectAllOnSelectedCodes.isSelected;
                return ac;
            });
            this.setState({ selectedActivityCodeList: list, selectAllOnSelectedCodes: { id: this.state.selectAllOnSelectedCodes.id, isSelected: !this.state.selectAllOnSelectedCodes.isSelected } });
        }
    }

    orderCodes(list, type) {
        let sortedList = _.sortBy(list, el => el.title);
        if (type == 0)
            this.setState({ selectionActivityCodeList: sortedList });
        else
            this.setState({ selectedActivityCodeList: sortedList });

    }

    changeDeletion() {
        this.setState(prevState => ({
            ...prevState,
            packageToEdit: {
                ...prevState.packageToEdit,
                isDeleted: !this.state.packageToEdit.isDeleted
            }
        }), () => this.updatePackage());
    }

    render() {

        const categoryPackageGridListColumns = [
            {
                dataField: 'name',
                text: 'Package Name',
                sort: true,
                headerStyle: () => {
                    return { width: "30%" };
                },
                formatter: (cellContent, row) => {
                    return (
                        row.entityId == 0 ? cellContent :
                            <a key={row.id} href="#" onClick={(e) => this.showModal(row, e)}>{cellContent}</a>
                    );
                }
            },
            {
                dataField: 'codes',
                text: 'Codes',
                headerStyle: () => {
                    return { width: "70%" };
                }
            }
        ];

        const defaultSorted = [{
            dataField: 'name',
            order: 'asc'
        }];

        const options = {
            sizePerPageList: [
                {
                    text: '50',
                    value: 50
                }
            ],
            hideSizePerPage: true
        }


        return (
            <Fragment>
                <PageTitle heading="Category Packages" />
                {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
                {!this.state.isLoading &&
                    <Container fluid>
                        <Row>
                            <Col md='12'>
                                <UncontrolledAlert color="warning">
                                    <h4><FontAwesomeIcon icon={Icons.faInfo} /> Alert</h4>
                                    This is an advanced administration area. Please contact LeGuard if you have any questions using this feature.
                                    </UncontrolledAlert>
                            </Col>
                            <Col md="12">
                                <Button className="mb-2 mr-2" color="primary" onClick={() => this.showModal(this.newPackage)}>Add New Package</Button>

                            </Col>
                        </Row>
                        <Row style={{ marginTop: '30px' }}>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <CardTitle>All Packages</CardTitle>
                                        <div className="table-responsive">
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="packageId"
                                                data={this.state.categoryPackageGridList}
                                                columns={categoryPackageGridListColumns}
                                                filter={filterFactory()}
                                                defaultSorted={defaultSorted}
                                                pagination={paginationFactory(options)}
                                            />
                                            {this.state.categoryPackageGridList.length == 0 && <p>No packages to display...</p>}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                }

                <Modal size="lg" isOpen={this.state.showModal} toggle={() => this.closeModal()} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={() => this.closeModal()}>{this.state.packageToEdit.packageId == -1 ? 'Add' : 'Edit'} package</ModalHeader>
                    <ModalBody>
                        <Formik ref={this.formRef}
                            initialValues={{ name: this.state.packageToEdit.name }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Name is required')
                            })}
                            onSubmit={({ name }, { setStatus, setSubmitting }) => {
                                setStatus();
                                this.setState(prevState => ({
                                    ...prevState,
                                    packageToEdit: {
                                        ...prevState.packageToEdit,
                                        name: name,
                                        entityId: this.currentUser.entityId,
                                        activityCodes: this.state.selectedActivityCodeList
                                    }
                                }), () => this.save());

                            }}
                            render={({ errors, status, touched, isSubmitting, submitForm }) => {

                                return (
                                    <Form >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-5">
                                                    <label htmlFor="packageSelect">Select from</label>
                                                    <CustomInput type="select" id="packageSelect" value={this.state.selectedPackageFilter.packageId}
                                                        name="packageSelect" onChange={(e) => this.onPackageSelectionChange(e.target.value)}>
                                                        <option key={0} value={0}>All Codes</option>
                                                        {this.state.categoryPackageOriginalList.map(cp => {
                                                            return <option key={cp.packageId} value={cp.packageId}>{cp.name}</option>
                                                        })}
                                                    </CustomInput>
                                                    <Card style={{ marginTop: '20px' }}>
                                                        <CardBody style={{ height: '300px', overflowY: 'auto' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30%">Select</th>
                                                                        <th width="70%" style={{ cursor: 'pointer' }}
                                                                            onClick={() => this.orderCodes(this.state.selectionActivityCodeList, 0)}>Activity Code <FontAwesomeIcon icon={Icons.faChevronUp}></FontAwesomeIcon></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <CustomInput type="checkbox" id={'selectAll0'} style={{ cursor: 'pointer' }}
                                                                                value={this.state.selectAllOnSelectionCodes.isSelected} checked={this.state.selectAllOnSelectionCodes.isSelected}
                                                                                onChange={() => this.onSelectAllSelectionChange(this.state.selectAllOnSelectionCodes.id)} />
                                                                        </td>
                                                                        <td>Select all</td>
                                                                    </tr>
                                                                    {this.state.selectionActivityCodeList.map(ac => {
                                                                        return (
                                                                            ac.isVisible &&
                                                                            <tr key={ac.activityCodeId}>
                                                                                <td>
                                                                                    <CustomInput type="checkbox" id={ac.activityCodeId} style={{ cursor: 'pointer' }}
                                                                                        value={ac.isSelected} checked={ac.isSelected}
                                                                                        onChange={(e) => this.onSelectionChangeSelection(ac, e)} />
                                                                                </td>
                                                                                <td>{ac.title}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <div className="col-2" style={{ display: 'grid', alignItems: 'center', paddingTop: '15%' }}>
                                                    <Button color="primary" onClick={() => this.addSelectedCodes()}>
                                                        Add  <FontAwesomeIcon icon={Icons.faChevronRight} />
                                                    </Button>
                                                    <Button color="primary" onClick={() => this.removeSelectedCodes()}>
                                                        <FontAwesomeIcon icon={Icons.faChevronLeft} /> Remove
                                                    </Button>
                                                </div>
                                                <div className="col-5">

                                                    <div className="form-group">
                                                        <label htmlFor="name">Package Name *</label>
                                                        <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')}
                                                            placeholder="Package Name..." />
                                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <Card style={{ marginTop: '20px' }}>
                                                        <CardBody style={{ height: '300px', overflowY: 'auto' }}>
                                                            <table style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30%">Select</th>
                                                                        <th width="70%" style={{ cursor: 'pointer' }}
                                                                            onClick={() => this.orderCodes(this.state.selectedActivityCodeList, 1)}>Activity Code <FontAwesomeIcon icon={Icons.faChevronUp}></FontAwesomeIcon></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <CustomInput type="checkbox" id={'selectAll1'} style={{ cursor: 'pointer' }}
                                                                                value={this.state.selectAllOnSelectedCodes.isSelected} checked={this.state.selectAllOnSelectedCodes.isSelected}
                                                                                onChange={() => this.onSelectAllSelectionChange(this.state.selectAllOnSelectedCodes.id)} />
                                                                        </td>
                                                                        <td>Select all</td>
                                                                    </tr>
                                                                    {this.state.selectedActivityCodeList.map(ac => {
                                                                        return (
                                                                            ac.isVisible &&
                                                                            <tr key={'s' + ac.activityCodeId}>
                                                                                <td>
                                                                                    <CustomInput type="checkbox" id={'s' + ac.activityCodeId} value={ac.isSelected} style={{ cursor: 'pointer' }}
                                                                                        checked={ac.isSelected} onChange={(e) => this.onSelectionChangeSelected(ac, e)} />
                                                                                </td>
                                                                                <td>{ac.title}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: '10px' }}>
                                                <Button color="link" onClick={() => this.closeModal()}>Cancel</Button>
                                                {this.state.packageToEdit.entityId > -1 &&
                                                    <Button color={this.state.packageToEdit.isDeleted ? 'success' : 'danger'} style={{ marginLeft: '.25rem' }}
                                                        onClick={() => this.changeDeletion()}>{this.state.packageToEdit.isDeleted ? 'Activate' : 'Inactivate'}</Button>}
                                                <Button color="primary" onClick={() => this.handleSubmit()} style={{ marginLeft: '.25rem' }}>Save</Button>{' '}
                                            </div>
                                        </div>


                                        {status &&
                                            <div className={'alert alert-danger'}>{status}</div>
                                        }
                                    </Form>
                                );
                            }}
                        />

                    </ModalBody>

                </Modal>

            </Fragment>
        );
    }
}

export default CategoryPackages;