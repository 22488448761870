import React, { Component, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import PageTitle from "../../../Layout/AppMain/PageTitle";
import { userService, api } from "../../../_services";
import GeneralGrid from "../../../Components/GeneralGrid";
import Moment from "react-moment";
import "moment-timezone";
import { BudgetModal } from "./Components/BudgetModal";
import AddEditUserModal from "../../Partnership/PatrnershipSettings/AddEditUserModal";
import { userMatterRolesArray } from "../../../_services";
import SelectActivityCodesModal from "./Components/SelectActivityCodesModal";
import EditActivityCodeModal from "./Components/EditActivityCodeModal";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { currencyFormatter } from "../../../_services/currencyFormatter";
import SearchUserModal from "../../../Components/SearchUserModal";
import CategoryModal from "../../Admin/Components/CategoryModal";
import MobileHelper from "../../../_helpers/mobileHelper";
import SelectChildMattersModal from "./Components/SelectChildMattersModal";
class MatterSettings extends Component {
  constructor(props) {
    super(props);

    this.currentUser = userService.getLoggedInUser();
    this.newBudget = {
      budgetId: -1,
      budgetName: "",
      startDate: "",
      endDate: "",
      amount: "",
      currencyId: this.currentUser.currencyId,
    };

    this.newUser = {
      userId: -1,
      partnershipId: -1,
      name: "",
      email: "",
      roleId: 2,
      rate: "",
      currencyId: this.currentUser.currencyId,
      sendEmail: false,
      isDeleted: false,
    };

    this.state = {
      showCategoryModal: false,
      showChildMattersModal: false,
      showBudgetModal: false,
      showAddEditUserModal: false,
      showSearchUserModal: false,
      showSelectActivityCodesModal: false,
      showEditActivityCodeModal: false,
      activityCodeListLoading: false,
      matter: {
        matterId: -1,
        matterName: "",
        partnershipId: -1,
        matterTypeId: -1,
        matterTypeId_Lawfirm: -1,
        geoCategoryId: -1,
        geoCategoryId_Lawfirm: -1,
        // businessActivityId: -1,
        // businessActivityId_Lawfirm: -1,
        eBillerId: "",
        allowFixedPrice: false,
        isDiscretionary: false,
        isDeleted: false,
      },
      partnershipList: [],
      matterTypeList: [],
      geoCategoryList: [],
      // businessActivityList: [],
      isMatterTypeValid: true,
      isBusinessActivityValid: true,
      isGeoCategoryValid: true,
      isPartnershipValid: true,
      budgetList: [],
      budgetToEdit: this.newBudget,
      currentBudget: this.newBudget,
      currencyList: [],
      userList: [],
      allUserList: [],
      userToEdit: this.newUser,
      // searchUserList: [],
      matterActivityCodeList: [],
      activityCodeList: [],
      packageList: [],
      childMatters: [],
      selectedPackageId: 0,
      editActivityCode: {
        matterActivityCodeId: -1,
        activityCodeId: -1,
        title: "",
        fixedPrice: "",
        currencyId: this.currentUser.currencyId,
        fpEditable: false,
        fpHidden: false,
        fpOnly: false,
      },
      canUserBill: false,
      masterMatterName: "",
    };

    this.matterId = parseInt(props.match.params.matterId);
    this.canSetState = true;
  }

  componentDidMount() {
    this.loadMatter(this.matterId);
    api
      .getPartnershipListByEntityId(
        this.currentUser.entityId,
        this.currentUser.userId
      )
      .then((data) => {
        this.setState({ partnershipList: data });
      });

    api.getCurrencyList().then((data) => {
      this.setState({ currencyList: data });
    });
    api.canUserBill(this.currentUser.userId).then((data) => {
      this.setState({ canUserBill: data });
    });
    api.getUserListByEntityId(this.currentUser.entityId).then((data) => {
      this.setState({ allUserList: data });
    });
  }

  loadChildMatters(matterId) {
    if (this.currentUser.entityRoleId == 1) {
      if (matterId > 0) {
        api.getChildMatterListByMatterId(matterId).then((data) => {
          this.setState({ childMatters: data });
        });
      }
    }
  }

  loadMatter(matterId) {
    if (matterId !== -1) {
      this.loadChildMatters(matterId);

      api.getMatterById(matterId).then((data) => {
        this.setState({ matter: data }, () => {
          this.loadLists();
        });

        if (data.masterMatterId !== 0) {
          api.getMatterById(data.masterMatterId).then((m) => {
            this.setState({ masterMatterName: m.matterName });
          });
        }
        // api.getUserListByPartnership(data.partnershipId, 0).then(list => {
        //     this.setState({ searchUserList: list });
        // })
        this.loadPackageList(data.partnershipId);
      });
      this.loadBudgetList(matterId);
      this.loadUserList(matterId);
      this.loadMatterActivityCodeList(matterId);
      //this.loadActivityCodeList(0, matterId);
    } else {
      api
        .getItemListByEntityId("MatterType", this.currentUser.entityId)
        .then((data) => {
          this.setState({ matterTypeList: data.filter((x) => !x.isDeleted) });
        });
      this.loadLists();
    }
  }

  loadLists() {
    api
      .getItemListByEntityId("MatterType", this.currentUser.entityId)
      .then((data) => {
        this.setState({
          matterTypeList: data.filter((x) => {
            if (this.currentUser.entityRoleId == 1) {
              return x.id == this.state.matter.matterTypeId || !x.isDeleted;
            } else {
              return (
                x.id == this.state.matter.matterTypeId_Lawfirm || !x.isDeleted
              );
            }
          }),
        });
      });
    api
      .getItemListByEntityId("GeoCategory", this.currentUser.entityId)
      .then((list) => {
        this.setState({
          geoCategoryList: list.filter((x) => {
            if (this.currentUser.entityRoleId == 1) {
              return x.id == this.state.matter.geoCategoryId || !x.isDeleted;
            } else {
              return (
                x.id == this.state.matter.geoCategoryId_Lawfirm || !x.isDeleted
              );
            }
          }),
        });
      });
    // api.getItemListByEntityId("BusinessActivity", this.currentUser.entityId).then(list => {
    //     this.setState({
    //         businessActivityList: list.filter(x => {
    //             if (this.currentUser.entityRoleId == 1) {
    //                 return (x.id == this.state.matter.businessActivityId || !x.isDeleted);
    //             } else {
    //                 return (x.id == this.state.matter.businessActivityId_Lawfirm || !x.isDeleted);
    //             }
    //         })
    //     });
    // });
  }

  loadBudgetList(matterId) {
    api.getBudgetListByMatterId(matterId).then((data) => {
      //see currentBudget to set on state to hilight
      //this.setCurrentBudget(data);
      let date = new Date();
      let currentBudget = data.find((budget) => {
        return (
          new Date(budget.startDate) <= date && date <= new Date(budget.endDate)
        );
      });
      this.setState({
        budgetList: data,
        currentBudget: currentBudget == null ? this.newBudget : currentBudget,
      });
    });
  }

  loadUserList(matterId, callback) {
    if (matterId > 0) {
      api.getUserListByMatterId(matterId).then((userMatterList) => {
        if (this.currentUser.entityRoleId == 2) {
          userMatterList = userMatterList.filter(
            (x) =>
              x.entityId == this.currentUser.entityId ||
              x.parentId == this.currentUser.entityId
          );
        }
        if (!this.state.matter.isDiscretionary)
          this.loadPartnershipViewers(
            userMatterList.map((u) => {
              u.rate = u.matterRate;
              return u;
            })
          );
        this.setState(
          {
            userList: userMatterList.map((u) => {
              u.rate = u.matterRate;
              return u;
            }),
          },
          () => callback && callback()
        );
      });
    }
  }

  loadPartnershipViewers(existingUserList) {
    api
      .getUserListByPartnership(this.state.matter.partnershipId, 1)
      .then((data) => {
        let viewers = data.map((x) => {
          x.matterRate = "";
          x.rate = x.matterRate;
          //x.roleId = 100;
          x.isReadOnly = true;
          x.userId = x.userId + 10000;
          return x;
        });
        viewers = viewers.filter(
          (x) => x.entityId == this.currentUser.entityId
        );
        if (viewers.length > 0) {
          let list = [...existingUserList, ...viewers];
          this.setState({ userList: list });
        }
      });
  }

  refreshUsers() {
    this.loadUserList(this.matterId, () => {
      if (this.state.userToEdit.userId !== -1) {
        this.toggleAddEditUserModal();
      }
    });
  }

  loadMatterActivityCodeList(matterId) {
    api.getActivityCodeListByMatterId(matterId).then((data) => {
      this.setState({ matterActivityCodeList: data });
    });
  }

  loadActivityCodeList(packageId, matterId) {
    this.setState({ activityCodeListLoading: true });

    api
      .getActivityCodeListByPackageMatterSelected(packageId, matterId)
      .then((data) => {
        let listWithoutDuplicates = data.reduce((unique, o) => {
          if (!unique.some((z) => z.activityCodeId == o.activityCodeId))
            unique.push(o);
          else {
            if (
              unique.some(
                (z) =>
                  z.activityCodeId == o.activityCodeId &&
                  z.selected == 0 &&
                  o.selected == 1
              )
            ) {
              unique = unique.filter(
                (x) => x.activityCodeId !== o.activityCodeId
              );
              unique.push(o);
            }
          }
          return unique;
        }, []);
        // console.log(x);
        this.setState({
          activityCodeList: listWithoutDuplicates,
          activityCodeListLoading: false,
        });
      });
  }

  loadPackageList(partnershipId) {
    api.getPackageListByPartnershipId(partnershipId).then((data) => {
      this.setState({ packageList: data });
    });
  }

  setCurrentBudget(budgetList) {
    let date = new Date();
    let currentBudget = budgetList.find((budget) => {
      return (
        new Date(budget.startDate) <= date && date <= new Date(budget.endDate)
      );
    });
    this.setState({
      currentBudget: currentBudget == null ? this.newBudget : currentBudget,
    });
  }

  validateMatter() {
    //handle validation
    let errorCount = 0;
    let objUpdate = {};

    if (this.state.matter.partnershipId == -1) {
      errorCount++;
      objUpdate.isPartnershipValid = false;
    }
    if (errorCount > 0) {
      this.setState(objUpdate);
    }
    return errorCount == 0;
  }

  handleSubmit = (e, errors, values) => {
    let isMatterValid = this.validateMatter();
    if (errors.length == 0 && isMatterValid) {
      this.saveMatter();
    }
  };

  saveMatter() {
    let matter = this.state.matter;
    if (this.currentUser.entityRoleId == 1) {
      matter.matterTypeId_Lawfirm = null;
      matter.geoCategoryId_Lawfirm = null;
      // matter.businessActivityId_Lawfirm = null;
    } else {
      matter.matterTypeId = null;
      // matter.businessActivityId = null;
      matter.geoCategoryId = null;
    }
    if (matter.matterId == -1) {
      //create
      api.createMatter(matter).then((id) => {
        if (("" + id).indexOf("Error") > -1) {
          swal(id.replace("Error", ""), {
            buttons: {
              ok: {
                text: "Ok",
                value: "ok",
                className: "btn-success",
              },
            },
            icon: "warning",
          });
        } else {
          toast("Matter saved successfuly", {
            autoClose: 3000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
          });
          this.matterId = id;
          this.props.history.replace({
            pathname: `/mattersettings/${id}`,
          });
          this.loadMatter(id);
          this.loadActivityCodeList(0, id);
        }
      });
    } else {
      //update
      api.updateMatter(matter).then((id) => {
        if (("" + id).indexOf("Error") > -1) {
          swal(id.replace("Error", ""), {
            buttons: {
              ok: {
                text: "Ok",
                value: "ok",
                className: "btn-success",
              },
            },
            icon: "warning",
          });
        } else {
          toast("Matter saved successfuly", {
            autoClose: 3000,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
          });

          if (matter.isDeleted) this.props.history.goBack();
          else this.loadMatterActivityCodeList(matter.matterId);
        }
      });
    }
  }

  changeDeletion() {
    this.setState(
      (prevState) => ({
        ...prevState,
        matter: {
          ...prevState.matter,
          isDeleted: !this.state.matter.isDeleted,
        },
      }),
      () => {
        this.saveMatter();
      }
    );
  }

  handleChange(prop, val, validationFlag, callback) {
    // || prop == 'businessActivityId' || prop == 'businessActivityId_Lawfirm'
    if (
      prop == "matterTypeId" ||
      prop == "matterTypeId_Lawfirm" ||
      prop == "geoCategoryId" ||
      prop == "geoCategoryId_Lawfirm" ||
      prop == "partnershipId"
    ) {
      if (validationFlag !== undefined) {
        this.setState((prevState) => ({
          ...prevState,
          [validationFlag]: true,
          matter: {
            ...prevState.matter,
            [prop]: val,
          },
        }));
      }
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          matter: {
            ...prevState.matter,
            [prop]: val,
          },
        }),
        callback && callback()
      );
    }
  }

  handleMasterMatterChange() {
    let val = !this.state.matter.isMasterMatter;
    if (!val) {
      swal({
        title: "Are you sure?",
        text: "Removing this as a Master will unlink the child matters.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.setState(
            (prev) => ({
              ...prev,
              matter: {
                ...prev.matter,
                isMasterMatter: val,
              },
            }),
            () => {
              if (this.state.matter.matterId > 0) {
                api
                  .resetAllMattersMasterMatter(this.state.matter.matterId)
                  .then((data) => {
                    this.saveMatter();
                    this.loadChildMatters(this.state.matter.matterId);
                  });
              }
            }
          );
        }
      });
    } else {
      this.setState(
        (prev) => ({
          ...prev,
          matter: {
            ...prev.matter,
            isMasterMatter: val,
          },
        }),
        () => {
          if (this.state.matter.matterId > 0) {
            this.saveMatter();
          }
        }
      );
    }
  }

  toggleCategoryModal = () => {
    if (MobileHelper.isMobile())
      swal("This function is only available on larger screens", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    else this.setState({ showCategoryModal: !this.state.showCategoryModal });
  };

  toggleBudgetModal = (budget) => {
    if (budget == undefined) {
      this.closeBudgetModal();
    } else if (this.state.matter.matterId > -1) {
      this.setState({
        showBudgetModal: !this.state.showBudgetModal,
        budgetToEdit: budget,
      });
    } else
      swal("Please save the matter first.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
  };
  toggleAddEditUserModal = (user) => {
    if (this.state.matter.matterId > -1) {
      let updatedUser = this.newUser;
      if (user !== undefined) {
        user.isSaved = true;
        updatedUser = user;
      }
      if (updatedUser.userId <= 0) {
        updatedUser.roleId = this.currentUser.entityRoleId == 1 ? 1 : 2;
      }
      //updatedUser.roleId = this.currentUser.entityRoleId == 1 ? 1 : 2;
      this.setState({
        showAddEditUserModal: !this.state.showAddEditUserModal,
        userToEdit: updatedUser,
      });
    } else
      swal("Please save the matter first.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
  };
  toggleSearchUserModal = (e) => {
    e && e.preventDefault();

    this.setState({
      showSearchUserModal: !this.state.showSearchUserModal,
    });
  };
  toggleSelectActivityCodesModal = () => {
    if (this.state.matter.matterId > -1) {
      if (!this.state.showSelectActivityCodesModal) {
        this.loadActivityCodeList(0, this.state.matter.matterId);
      }
      this.setState({
        showSelectActivityCodesModal: !this.state.showSelectActivityCodesModal,
      });
    } else {
      swal("Please save the matter first.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    }
  };
  toggleEditActivityCodeModal = (actCode, selectedPackageId) => {
    if (actCode !== undefined) {
      if (selectedPackageId == undefined) selectedPackageId = 0;
      this.setState({
        showEditActivityCodeModal: true,
        editActivityCode: actCode,
        selectedPackageId: selectedPackageId,
      });
    } else {
      this.setState({ showEditActivityCodeModal: false });
    }
  };
  saveBudget = (budget) => {
    budget.matterId = this.state.matter.matterId;
    if (budget.budgetId == -1) {
      api.createBudget(budget).then((id) => {
        this.loadBudgetList(this.state.matter.matterId);
        this.closeBudgetModal();
      });
    } else {
      api.updateBudget(budget).then((data) => {
        this.loadBudgetList(this.state.matter.matterId);
        this.closeBudgetModal();
      });
    }
  };

  closeBudgetModal() {
    this.setState({ showBudgetModal: false });
  }

  onAddEditUserSave = (user) => {
    user.partnershipId = this.state.matter.partnershipId;
    user.matterRate = user.rate;
    user.matterId = this.state.matter.matterId;
    user.matterName = this.state.matter.matterName;
    if (!user.isDeleted) {
      api.createUserMatter(user).then((data) => {
        if (data != "")
          swal(data, {
            buttons: {
              ok: {
                text: "Ok",
                value: "ok",
                className: "btn-success",
              },
            },
            icon: "warning",
          });
        else {
          this.loadUserList(this.state.matter.matterId);
          this.toggleAddEditUserModal();
          this.toggleSearchUserModal();
        }
      });
    } else {
      api.deleteUserMatter(user).then((data) => {
        this.loadUserList(this.state.matter.matterId);
        this.toggleAddEditUserModal();
        //this.toggleSearchUserModal();
      });
    }
  };

  onUserSelect = (user) => {
    if (user == undefined) user = this.newUser;
    //user.roleId = this.state.userToEdit.roleId;
    if (
      user.isPartnershipMember &&
      user.partnershipRate !== undefined &&
      user.partnershipRate > 0
    )
      user.rate = user.partnershipRate;
    this.setState({ userToEdit: user });
    this.toggleSearchUserModal();
  };

  onPackageSelectionChange = (e) => {
    let packageId = e.target.value;
    this.loadActivityCodeList(packageId, this.state.matter.matterId);
  };

  saveActivityCode = (actCode) => {
    if (actCode.matterActivityCodeId > -1) {
      api.updateMatterActivityCode(actCode).then((data) => {
        this.loadActivityCodeList(
          this.state.selectedPackageId,
          this.state.matter.matterId
        );
        this.loadMatterActivityCodeList(this.state.matter.matterId);
        this.toggleEditActivityCodeModal();
      });
    } else {
      api.createMatterActivityCode(actCode).then((data) => {
        this.loadActivityCodeList(
          this.state.selectedPackageId,
          this.state.matter.matterId
        );
        this.loadMatterActivityCodeList(this.state.matter.matterId);
        this.toggleEditActivityCodeModal();
      });
    }
  };

  onUsersSave = (userList) => {
    let list = userList.filter((x) => x.roleId !== -1);
    if (
      this.currentUser.entityRoleId !== 2 &&
      list.find((x) => x.userId == this.currentUser.userId && x.roleId > 1)
    ) {
      userService.setUserEntityRole(2);
      window.reload();
    }
    api
      .saveUserMatterAssociationList(
        this.currentUser.userId,
        this.state.matter.matterId,
        list
      )
      .then((data) => {
        this.loadUserList(this.state.matter.matterId);
        this.toggleSearchUserModal();
      });
  };

  onUsersSaveSilent = (userList) => {
    let list = userList.filter((x) => x.roleId !== -1);

    api
      .saveUserMatterAssociationList(
        this.currentUser.userId,
        this.state.matter.matterId,
        list
      )
      .then((data) => {
        this.loadUserList(this.state.matter.matterId);
      });
  };

  toggleChildMattersModal = () => {
    this.setState({ showChildMattersModal: !this.state.showChildMattersModal });
  };

  removeChildMatter = (matterId) => {
    api.updateMatterMasterMatterId(matterId, 0).then((d) => {
      this.loadChildMatters(this.state.matter.matterId);
    });
  };

  loadNextLawfirmMatterNumber = (partnershipId) => {
    api.getNextLawfirmMatterNumber(partnershipId).then((data) => {
      this.setState((prevState) => ({
        ...prevState,
        matter: {
          ...prevState.matter,
          eBillerId_Lawfirm: data,
        },
      }));
    });
  };

  render() {
    const budgetColumns = [
      {
        dataField: "budgetName",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
        formatter: (cellContent, row) => {
          return (
            <a
              key={row.budgetId}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.toggleBudgetModal(row);
              }}
            >
              {cellContent}
            </a>
          );
        },
        footer: "",
      },
      {
        dataField: "startDate",
        text: "Start",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
        formatter: (cellContent, row) => {
          return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
        },
        footer: (columnData) =>
          columnData.length > 0
            ? new Date(
                columnData.reduce((prev, curr) => {
                  return new Date(prev) > new Date(curr) ? curr : prev;
                })
              ).toLocaleDateString()
            : "",
      },
      {
        dataField: "endDate",
        text: "End",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
        formatter: (cellContent, row) => {
          return <Moment format="MM/DD/YYYY">{cellContent}</Moment>;
        },
        footer: (columnData) =>
          columnData.length > 0
            ? new Date(
                columnData.reduce((prev, curr) => {
                  return new Date(prev) < new Date(curr) ? curr : prev;
                })
              ).toLocaleDateString()
            : "",
      },
      {
        dataField: "amount",
        text: "Amount",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
        formatter: (cell, row) => {
          return currencyFormatter(row.currencySymbol, cell);
        },
        footer: (columnData) =>
          this.state.budgetList.length > 0
            ? currencyFormatter(
                this.state.budgetList[0].currencySymbol,
                columnData.reduce((acc, item) => acc + item, 0)
              )
            : columnData.reduce((acc, item) => acc + item, 0),
      },
    ];

    const childMatterColumns = [
      {
        dataField: "billerEntityName",
        text: "Lawfirm Name",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
      },
      {
        dataField: "matterName",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
      },
      {
        dataField: "responsibleLawyers",
        text: "Responsible Lawyers",
        sort: true,
        headerStyle: () => {
          return { width: "25%" };
        },
      },
      {
        dataField: "masterMatterId",
        text: "",
        headerStyle: () => {
          return { width: "25%" };
        },
        formatter: (cell, row) => {
          return (
            <Button
              color="danger"
              onClick={() => this.removeChildMatter(row.matterId)}
            >
              Remove
            </Button>
          );
        },
      },
    ];

    const rowStyleHighlightBudget = (row, rowIndex) => {
      const style = {};

      if (row.budgetId == this.state.currentBudget.budgetId) {
        //style.backgroundColor = 'yellow';
        style.fontWeight = "bold";
        style.color = "firebrick";
      }

      return style;
    };

    const userRowStyle = (row, rowIndex) => {
      const style = {};

      if (row.isReadOnly) {
        //style.backgroundColor = 'yellow';
        style.backgroundColor = "lightgray";
        style.color = "black";
      }

      return style;
    };

    const userColumns = [
      {
        dataField: "name",
        text: "Name",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cellContent, row) => {
          return (
            <Fragment>
              {row.isReadOnly && cellContent}
              {!row.isReadOnly && (
                <a
                  key={row.userId}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleAddEditUserModal(row);
                  }}
                >
                  {cellContent}
                </a>
              )}
            </Fragment>
          );
        },
      },
      {
        dataField: "roleId",
        text: "Role",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cellContent, row) => {
          // let id = parseInt(cellContent);
          // let ret = '';
          // if(id == userMatterRoles.authViewer.id)
          return row.isReadOnly
            ? this.currentUser.entityRoleId == 1
              ? "Law Firm " +
                userMatterRolesArray.find(
                  (el) => el.id == parseInt(cellContent)
                ).name
              : "Client " +
                userMatterRolesArray.find(
                  (el) => el.id == parseInt(cellContent)
                ).name
            : userMatterRolesArray.find((el) => el.id == parseInt(cellContent))
                .name;
          //row.isReadOnly ? 'Law Firm ' + userMatterRolesArray.find(el => el.id == parseInt(cellContent)).name : '' + userMatterRolesArray.find(el => el.id == parseInt(cellContent)).name
        },
      },
      {
        dataField: "matterRate",
        text: "Rate",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cell, row) => {
          return parseInt(row.roleId) == 1
            ? "N/A"
            : `${currencyFormatter(row.currencySymbol, cell, true)} / hr`;
        },
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        headerStyle: () => {
          return { width: "10%" };
        },
        formatter: (cell, row) => {
          let bg = "",
            text = "";
          if (row.status == 0) {
            bg = "orange";
            text = "Invited";
          } else {
            bg = "green";
            text = "Registered";
          }
          return (
            <label
              style={{
                backgroundColor: bg,
                padding: "2px",
                borderRadius: "3px",
                color: "white",
              }}
            >
              {text}
            </label>
          );
        },
      },
    ];

    const options = {
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
      ],
      hideSizePerPage: false,
    };

    return (
      <Fragment>
        <PageTitle heading={this.state.matter.matterName} />
        <Row style={{ marginTop: "30px" }}>
          <Col md="4">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Matter Settings</CardTitle>
                <AvForm onSubmit={this.handleSubmit}>
                  <Row>
                    <Col md="12">
                      <AvField
                        name="matterName"
                        label="Matter Name *"
                        value={this.state.matter.matterName}
                        onChange={(e) =>
                          this.handleChange("matterName", e.target.value)
                        }
                        placeholder="Enter..."
                        required
                      />

                      <div className="form-group">
                        <label htmlFor="partnership">
                          {this.currentUser.entityRoleId == 1
                            ? "Law Firm *"
                            : "Client *"}
                        </label>
                        <CustomInput
                          type="select"
                          id="partnershipId"
                          value={this.state.matter.partnershipId}
                          disabled={this.state.matter.matterId !== -1}
                          name="partnershipSelect"
                          onChange={(e) => {
                            let val = e.target.value;
                            this.handleChange(
                              "partnershipId",
                              val,
                              "isPartnershipValid"
                            );
                            if (
                              e.target.value != -1 &&
                              this.state.matter.matterId == -1
                            ) {
                              this.loadNextLawfirmMatterNumber(e.target.value);
                            }
                          }}
                        >
                          <option key={-1} value={-1}>
                            Select{" "}
                            {this.currentUser.entityRoleId == 1
                              ? "Law Firm"
                              : "Client"}
                          </option>
                          {this.state.partnershipList.map((partnership) => {
                            return (
                              <option
                                key={partnership.partnershipId}
                                value={partnership.partnershipId}
                              >
                                {partnership.partnershipName}
                              </option>
                            );
                          })}
                        </CustomInput>
                        {!this.state.isPartnershipValid && (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            Please select a{" "}
                            {this.currentUser.entityRoleId == 1
                              ? "Law Firm"
                              : "Client"}
                          </div>
                        )}
                      </div>
                      {this.state.matterTypeList.length > 0 && (
                        <div className="form-group">
                          <label htmlFor="matterType">Matter Type</label>
                          {this.currentUser.entityRoleId == 1 && (
                            <CustomInput
                              type="select"
                              id="matterTypeId"
                              value={this.state.matter.matterTypeId}
                              name="matterTypeId"
                              onChange={(e) =>
                                this.handleChange(
                                  "matterTypeId",
                                  e.target.value,
                                  "isMatterTypeValid"
                                )
                              }
                            >
                              <option key={-1} value={-1}>
                                Select Matter Type
                              </option>
                              {this.state.matterTypeList.map(
                                (matterTypeList) => {
                                  return (
                                    <option
                                      key={matterTypeList.id}
                                      value={matterTypeList.id}
                                    >
                                      {matterTypeList.name}
                                    </option>
                                  );
                                }
                              )}
                            </CustomInput>
                          )}
                          {this.currentUser.entityRoleId == 2 && (
                            <CustomInput
                              type="select"
                              id="matterTypeId_Lawfirm"
                              value={this.state.matter.matterTypeId_Lawfirm}
                              name="matterTypeId_Lawfirm"
                              onChange={(e) =>
                                this.handleChange(
                                  "matterTypeId_Lawfirm",
                                  e.target.value,
                                  "isMatterTypeValid"
                                )
                              }
                            >
                              <option key={-1} value={-1}>
                                Select Matter Type
                              </option>
                              {this.state.matterTypeList.map(
                                (matterTypeList) => {
                                  return (
                                    <option
                                      key={matterTypeList.id}
                                      value={matterTypeList.id}
                                    >
                                      {matterTypeList.name}
                                    </option>
                                  );
                                }
                              )}
                            </CustomInput>
                          )}
                          {!this.state.isMatterTypeValid && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              Please select a matter type
                            </div>
                          )}
                        </div>
                      )}
                      {this.state.geoCategoryList.length > 0 && (
                        <div className="form-group">
                          <label htmlFor="geoCategory">
                            Geographic Location
                          </label>
                          {this.currentUser.entityRoleId == 1 && (
                            <CustomInput
                              type="select"
                              id="geoCategoryId"
                              value={this.state.matter.geoCategoryId}
                              name="geoCategoryId"
                              onChange={(e) =>
                                this.handleChange(
                                  "geoCategoryId",
                                  e.target.value,
                                  "isGeoCategoryValid"
                                )
                              }
                            >
                              <option key={-1} value={-1}>
                                Select Geographic Location
                              </option>
                              {this.state.geoCategoryList.map((g) => {
                                return (
                                  <option key={g.id} value={g.id}>
                                    {g.name}
                                  </option>
                                );
                              })}
                            </CustomInput>
                          )}
                          {this.currentUser.entityRoleId == 2 && (
                            <CustomInput
                              type="select"
                              id="geoCategoryId_Lawfirm"
                              value={this.state.matter.geoCategoryId_Lawfirm}
                              name="geoCategoryId_Lawfirm"
                              onChange={(e) =>
                                this.handleChange(
                                  "geoCategoryId_Lawfirm",
                                  e.target.value,
                                  "isGeoCategoryValid"
                                )
                              }
                            >
                              <option key={-1} value={-1}>
                                Select Geographic Location
                              </option>
                              {this.state.geoCategoryList.map((g) => {
                                return (
                                  <option key={g.id} value={g.id}>
                                    {g.name}
                                  </option>
                                );
                              })}
                            </CustomInput>
                          )}
                          {!this.state.isGeoCategoryValid && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              Please select a geographic location
                            </div>
                          )}
                        </div>
                      )}
                      {/* <div className='form-group'>
                                                <label htmlFor="matterType">Business Activity</label>
                                                {this.currentUser.entityRoleId == 1 &&
                                                    <CustomInput type="select" id="businessActivityId" value={this.state.matter.businessActivityId}
                                                        name="businessActivityId" onChange={e => this.handleChange("businessActivityId", e.target.value, 'isBusinessActivityValid')}>
                                                        <option key={-1} value={-1}>Select Business Activity</option>
                                                        {this.state.businessActivityList.map(a => {
                                                            return <option key={a.id} value={a.id}
                                                            >{a.name}</option>
                                                        })}
                                                    </CustomInput>
                                                }
                                                {this.currentUser.entityRoleId == 2 &&
                                                    <CustomInput type="select" id="businessActivityId_Lawfirm" value={this.state.matter.businessActivityId_Lawfirm}
                                                        name="businessActivityId_Lawfirm" onChange={e => this.handleChange("businessActivityId_Lawfirm", e.target.value, 'isBusinessActivityValid')}>
                                                        <option key={-1} value={-1}>Select Business Activity</option>
                                                        {this.state.businessActivityList.map(a => {
                                                            return <option key={a.id} value={a.id}
                                                            >{a.name}</option>
                                                        })}
                                                    </CustomInput>
                                                }
                                                {!this.state.isBusinessActivityValid &&
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>Please select a business activity</div>
                                                }
                                            </div> */}

                      <AvField
                        name="eBillerId"
                        id="eBillerId"
                        label="Client Matter Number"
                        value={this.state.matter.eBillerId}
                        onChange={(e) =>
                          this.handleChange("eBillerId", e.target.value)
                        }
                        placeholder="Enter..."
                        // disabled={this.currentUser.entityRoleId == 2}
                      />
                      {/* <UncontrolledTooltip placement="top" target='eBillerId'>
                                                This is the Client's internal Billing number.  If you modify this make sure it is the ID number they provided you
                                                </UncontrolledTooltip> */}

                      <AvField
                        name="eBillerID_Lawfirm"
                        label="Lawfirm Matter Number"
                        value={this.state.matter.eBillerId_Lawfirm}
                        onChange={(e) =>
                          this.handleChange("eBillerId_Lawfirm", e.target.value)
                        }
                        placeholder="Enter..."
                        disabled={this.currentUser.entityRoleId == 1}
                      />

                      {this.currentUser.entityRoleId == 2 && (
                        <div className="form-group">
                          <label>Invoice Reviewer</label>
                          <CustomInput
                            type="select"
                            id="invoiceReviewerId"
                            value={this.state.matter.invoiceReviewerId}
                            name="invoiceReviewerId"
                            onChange={(e) =>
                              this.handleChange(
                                "invoiceReviewerId",
                                e.target.value
                              )
                            }
                          >
                            <option key={-1} value={-1}>
                              Select Invoice Reviewer
                            </option>
                            {this.state.allUserList.map((g) => {
                              return (
                                <option key={g.userId} value={g.userId}>
                                  {g.name}
                                </option>
                              );
                            })}
                          </CustomInput>
                        </div>
                      )}

                      <div className="form-group">
                        <CustomInput
                          type="checkbox"
                          id="isDiscretionary"
                          checked={this.state.matter.isDiscretionary}
                          label="This is a Discretionary Matter"
                          onChange={(e) => {
                            let resp = !this.state.matter.isDiscretionary;
                            //if (!resp)
                            this.handleChange(
                              "isDiscretionary",
                              resp,
                              undefined,
                              () =>
                                this.loadUserList(this.state.matter.matterId)
                            );
                            //else
                            //  this.handleChange('isDiscretionary', resp);
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <CustomInput
                          type="checkbox"
                          id="allowFixedPrice"
                          checked={this.state.matter.allowFixedPrice}
                          label="This Matter allows fixed fee Activities"
                          onChange={(e) =>
                            this.handleChange(
                              "allowFixedPrice",
                              !this.state.matter.allowFixedPrice
                            )
                          }
                        />
                      </div>

                      {this.currentUser.entityRoleId == 1 &&
                        this.state.matter.masterMatterId == 0 && (
                          <CustomInput
                            type="checkbox"
                            id="isMasterMatter"
                            checked={this.state.matter.isMasterMatter}
                            label="This is a Master Matter"
                            onChange={(e) => this.handleMasterMatterChange()}
                          />
                        )}
                      {this.state.masterMatterName !== "" && (
                        <p
                          style={{
                            fontWeight: "bold",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Part of Master Matter: {this.state.masterMatterName}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Button
                    color="link"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </Button>
                  {this.state.matter.matterId > -1 && (
                    <Button
                      color={this.state.matter.isDeleted ? "success" : "danger"}
                      style={{ marginLeft: ".25rem" }}
                      className="pull-right"
                      onClick={() => {
                        if (!this.state.matter.isDeleted) {
                          swal({
                            title: "Are you sure?",
                            text: "While a matter is inactivated, no bill can be performed on it.",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              this.changeDeletion();
                            }
                          });
                        } else {
                          this.changeDeletion();
                        }
                      }}
                    >
                      {this.state.matter.isDeleted ? "Activate" : "Inactivate"}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    type="submit"
                    style={{ marginLeft: ".25rem" }}
                  >
                    Save
                  </Button>{" "}
                </AvForm>
              </CardBody>
            </Card>
          </Col>

          {this.state.matter.matterId > 0 && (
            <GeneralGrid
              colWidth={8}
              title="Responsible Lawyers, Billers and Viewers"
              keyField="userId"
              dataSource={this.state.userList}
              columns={userColumns}
              defaultSortedColumn="name"
              showAddButton={true}
              buttonText={
                this.currentUser.entityRoleId == 2
                  ? "Add Responsible Lawyers, Billers and Viewers"
                  : "Add Viewers"
              }
              onAdd={() => this.toggleSearchUserModal()} //this.toggleAddEditUserModal(this.newUser)}
              rowStyle={userRowStyle}
            />
          )}
        </Row>

        {this.state.matter.matterId > 0 && (
          <Row style={{ marginTop: "30px" }}>
            <Col md="6">
              <GeneralGrid
                colWidth={12}
                title="Budget"
                keyField="budgetId"
                dataSource={this.state.budgetList}
                columns={budgetColumns}
                defaultSortedColumn="budgetName"
                showAddButton={true}
                buttonText="Add Budget Item"
                onAdd={() => this.toggleBudgetModal(this.newBudget)}
                rowStyle={rowStyleHighlightBudget}
              />
              {this.currentUser.entityRoleId == 1 &&
                this.state.matter.isMasterMatter && (
                  <GeneralGrid
                    colWidth={12}
                    title="Matters assigned to this Master Matter"
                    keyField="matterId"
                    dataSource={this.state.childMatters}
                    columns={childMatterColumns}
                    defaultSortedColumn="matterName"
                    showAddButton={true}
                    buttonText="Add Matters"
                    onAdd={() => this.toggleChildMattersModal()}
                  />
                )}
            </Col>
            <GeneralGrid
              colWidth={3}
              title="Activity Codes"
              keyField="activityCodeId"
              dataSource={this.state.matterActivityCodeList}
              columns={[
                {
                  dataField: "title",
                  text: "Activity Category",
                  sort: true,
                  headerStyle: () => {
                    return { width: "90%" };
                  },
                  formatter: (cellContent, row, i, data) => {
                    return data.id > -1 ? (
                      <a
                        key={row.matterActivityCodeId}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleEditActivityCodeModal(row);
                        }}
                      >
                        {cellContent}{" "}
                        {row.fpOnly == true
                          ? `(${currencyFormatter(
                              row.currencySymbol,
                              row.fixedPrice
                            )}) Fixed`
                          : ""}
                      </a>
                    ) : (
                      cellContent
                    );
                  },
                  formatExtraData: {
                    id: this.state.matter.matterId,
                    allowFixedPrice: this.state.matter.allowFixedPrice,
                  },
                },
              ]}
              defaultSortedColumn="title"
              buttonText="Edit Categories"
              onAdd={() => this.toggleCategoryModal()} //this.toggleSelectActivityCodesModal()}
              // specialCommand={() => this.toggleCategoryModal()}
              // specialButtonText="Edit Categories"
            />
          </Row>
        )}

        <BudgetModal
          showModal={this.state.showBudgetModal}
          budget={this.state.budgetToEdit}
          toggleModal={this.toggleBudgetModal}
          currencyList={this.state.currencyList}
          saveBudget={(budget) => this.saveBudget(budget)}
          budgetList={this.state.budgetList}
        />

        <AddEditUserModal
          matter={this.state.matter}
          canUserBill={this.state.canUserBill}
          currencyList={this.state.currencyList}
          showModal={this.state.showAddEditUserModal}
          toggleModal={this.toggleAddEditUserModal}
          showSearchUsersModal={() => this.toggleSearchUserModal()}
          user={this.state.userToEdit}
          onSave={(user) => this.onAddEditUserSave(user)}
          rateLabel="Rate (enter 0 if this biller only performs fixed fee activities)"
          refreshUsers={() => this.refreshUsers()}
        />

        {/* <SearchUsersModal size='lg'
                    showModal={this.state.showSearchUserModal}
                    toggleModal={() => this.toggleSearchUserModal()}
                    partnershipId={this.state.matter.partnershipId}
                    roleId={0}
                    onUserSelect={(user) => this.onUserSelect(user)}
                /> */}

        <SearchUserModal
          showModal={this.state.showSearchUserModal}
          toggleModal={() => this.toggleSearchUserModal()}
          partnershipId={this.state.matter.partnershipId}
          onUsersSave={this.onUsersSave}
          onUsersSaveSilent={this.onUsersSaveSilent}
          addNewUser={() => this.toggleAddEditUserModal()}
          matterId={this.state.matter.matterId}
        />

        <SelectChildMattersModal
          showModal={this.state.showChildMattersModal}
          toggleModal={() => this.toggleChildMattersModal()}
          matter={this.state.matter}
          refreshChildMatters={() =>
            this.loadChildMatters(this.state.matter.matterId)
          }
        />

        <SelectActivityCodesModal
          showModal={this.state.showSelectActivityCodesModal}
          toggleModal={this.toggleSelectActivityCodesModal}
          activityCodeList={this.state.activityCodeList}
          packageList={this.state.packageList}
          onPackageSelectionChange={this.onPackageSelectionChange}
          isLoading={this.state.activityCodeListLoading}
          selectedPackageId={this.state.selectedPackageId}
          toggleEditActivityCodeModal={this.toggleEditActivityCodeModal}
          matter={this.state.matter}
        />

        <EditActivityCodeModal
          showModal={this.state.showEditActivityCodeModal}
          toggleModal={this.toggleEditActivityCodeModal}
          actCode={this.state.editActivityCode}
          currencyList={this.state.currencyList}
          matter={this.state.matter}
          saveActivityCode={this.saveActivityCode}
        />

        <CategoryModal
          showModal={this.state.showCategoryModal}
          toggleModal={this.toggleCategoryModal}
          selectedCodes={this.state.matterActivityCodeList}
          matterId={this.state.matter.matterId}
          reloadCodes={() =>
            this.loadMatterActivityCodeList(this.state.matter.matterId)
          }
        />
      </Fragment>
    );
  }
}

export default MatterSettings;
