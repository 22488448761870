import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../Layout/AppMain/PageTitle";
import Loader from "react-loaders";
import { api, userService } from "../../_services";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  CustomInput,
  UncontrolledTooltip,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { currencyFormatter } from "../../_services/currencyFormatter";
import { faCheck } from "@fortawesome/fontawesome-free-solid";
import { toast } from "react-toastify";
import _ from "lodash";
import swal from "sweetalert";

class MatterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedPartnershipId: -1,
      partnershipList: [],
      matterList: [],
      originalList: [],
      partnerGridList: [],
      expandedIndexes: [],
      mattersStatus: 1,
      search: "",
    };
    this.newMatter = {
      matterId: -1,
    };
    this.currentUser = userService.getLoggedInUser();
    this.previousMatterId = "";
  }

  componentDidMount() {
    this.loadPartnershipList(this.currentUser.entityId);
  }

  loadPartnershipList(entityId) {
    api
      .getPartnershipListByEntityId(entityId, this.currentUser.userId)
      .then((data) => {
        this.setState({ partnershipList: data });
        this.loadMatterList(0, entityId);
      });
  }

  loadMatterList(partnershipId, entityId) {
    api
      .getMatterListByPartnershipIdEntityId(
        partnershipId,
        entityId,
        this.currentUser.userId
      )
      .then((data) => {
        this.setState({ matterList: data }, () =>
          this.populateGridDataSource()
        );
      });
  }

  populateGridDataSource() {
    let partners = [...this.state.partnershipList];
    let deletedFlag = this.state.mattersStatus == 1 ? false : true;
    partners = partners.map((p) => {
      p.matterList = this.state.matterList
        .filter((m) => m.partnershipId == p.partnershipId)
        .filter(
          (x) =>
            this.state.mattersStatus == 0 ||
            (this.state.mattersStatus !== 0 && x.isDeleted == deletedFlag)
        );
      return p;
    });
    this.setState({
      originalList: [...partners],
      partnerGridList: [...partners],
      isLoading: false,
    });
    this.setExpandedIndexes();
  }

  onPartnershipSelectionChange = (e) => {
    let partnershipId = e.target.value;
    this.setState({ selectedPartnershipId: partnershipId });
    // if (partnershipId == -1)
    //     partnershipId = 0;
    //this.filterPartnerships(partnershipId, this.state.partnerGridList);
  };

  filterPartnerships(list) {
    if (this.state.selectedPartnershipId == -1) {
      var orgLst = JSON.parse(JSON.stringify(this.state.originalList));
      let lst = orgLst.filter((x) => x.matterList.length > 0);
      if (this.state.search !== "") {
        lst = lst.filter(
          (x) =>
            x.partnershipName.toLowerCase().indexOf(this.state.search) > -1 ||
            x.matterList.find(
              (m) => m.matterName.toLowerCase().indexOf(this.state.search) > -1
            ) !== undefined ||
            x.matterList.find(
              (x) => x.eBillerId.indexOf(this.state.search) > -1
            ) !== undefined ||
            x.matterList.find(
              (x) => x.eBillerId_Lawfirm.indexOf(this.state.search) > -1
            ) !== undefined
        );
        lst.forEach((p) => {
          p.matterList = p.matterList.filter(
            (x) =>
              x.matterName.toLowerCase().indexOf(this.state.search) > -1 ||
              x.eBillerId.indexOf(this.state.search) > -1 ||
              x.eBillerId_Lawfirm.indexOf(this.state.search) > -1
          );
        });
      }
      return lst;
    } else {
      var orgLst = JSON.parse(JSON.stringify(this.state.originalList));
      let ret = orgLst.filter(
        (x) => x.partnershipId == this.state.selectedPartnershipId
      );
      if (this.state.search !== "") {
        ret = ret.filter(
          (x) =>
            x.partnershipName.toLowerCase().indexOf(this.state.search) > -1 ||
            x.matterList.find(
              (m) => m.matterName.toLowerCase().indexOf(this.state.search) > -1
            ) !== undefined ||
            x.matterList.find(
              (x) => x.eBillerId.toLowerCase().indexOf(this.state.search) > -1
            ) !== undefined ||
            x.matterList.find(
              (x) =>
                x.eBillerId_Lawfirm.toLowerCase().indexOf(this.state.search) >
                -1
            ) !== undefined
        );
        ret.forEach((p) => {
          p.matterList = p.matterList.filter(
            (x) =>
              x.matterName.toLowerCase().indexOf(this.state.search) > -1 ||
              x.eBillerId.indexOf(this.state.search) > -1 ||
              x.eBillerId_Lawfirm.indexOf(this.state.search) > -1
          );
        });
      }
      return ret;
    }
  }

  goToSettings(matter, e) {
    e && e.preventDefault();

    this.props.history.push({
      pathname: `/mattersettings/${matter.matterId}`,
      //state: {partnership: partnership}
    });
  }

  setExpandedIndexes() {
    let ret = [];
    let withMatters = this.state.partnerGridList.filter(
      (x) => x.matterList.length > 0
    );
    withMatters.forEach((el) => {
      if (this.state.partnerGridList.indexOf(el) > -1)
        ret.push(this.state.partnerGridList.indexOf(el));
    });
    this.setState({ expandedIndexes: ret });
  }

  onMatterStatusChange(e) {
    var selection = parseInt(e.target.value);
    // var flag = selection == 1 ? false : true;
    // let partnerGridList = [...this.state.originalList];
    // var newList = [];
    // partnerGridList.forEach(element => {
    //     element.matterList = element.matterList.filter(x=> selection == 0 || (selection !== 0 && x.isDeleted == flag));
    //     newList.push(element);
    // });
    this.setState({ mattersStatus: selection }, () =>
      this.populateGridDataSource()
    );
  }

  onMatterNumberFocus = (e) => {
    this.hideMatterNumberActionButtons();
    this.previousMatterId = e.target.id;
    document.getElementById(e.target.id + "-save").style.display =
      "inline-block";
    document.getElementById(e.target.id + "-cancel").style.display =
      "inline-block";
  };

  saveMatter = (matterId) => {
    var grid = this.state.partnerGridList;
    document.getElementById(matterId + "-save").disabled = true;
    for (var i = 0; i < grid.length; i++) {
      if (grid[i].matterList != undefined && grid[i].matterList.length > 0) {
        var mat = grid[i].matterList.find(function (el) {
          return el.matterId == matterId;
        });
        if (mat != undefined && mat != null) {
          api.updateMatter(mat).then((id) => {
            if (("" + id).indexOf("Error") > -1) {
              swal(id.replace("Error", ""), {
                buttons: {
                  ok: {
                    text: "Ok",
                    value: "ok",
                    className: "btn-success",
                  },
                },
                icon: "warning",
              });
            } else {
              toast("Matter Number saved successfuly", {
                autoClose: 1000,
                type: toast.TYPE.SUCCESS,
                hideProgressBar: false,
              });
              this.hideMatterNumberActionButtons();
            }
          });
        }
      }
    }
  };

  cancelMatterNumberEdit = (matterId, prop) => {
    if (this.previousMatterId == matterId) {
      var initialMatter = this.state.matterList.find(function (el) {
        return el.matterId == matterId;
      });
      this.changeMatterNumber(matterId, prop, initialMatter[prop]);
    }
    this.hideMatterNumberActionButtons();
  };

  hideMatterNumberActionButtons = () => {
    var actionButtons = document.getElementsByClassName(
      "matterNumberActionButtons"
    );
    if (actionButtons != null && actionButtons.length > 0) {
      for (var i = 0; i < actionButtons.length; i++) {
        actionButtons[i].style.display = "none";
      }
    }
  };

  changeMatterNumber = (matterId, prop, val, callback) => {
    var grid = JSON.parse(JSON.stringify(this.state.partnerGridList));
    for (var i = 0; i < grid.length; i++) {
      if (grid[i].matterList != undefined && grid[i].matterList.length > 0) {
        var mat = grid[i].matterList.find(function (el) {
          return el.matterId == matterId;
        });
        if (mat != undefined && mat != null) {
          mat[prop] = val;
          break;
        }
      }
    }

    this.setState(
      { originalList: grid, partnerGridList: grid },
      () => callback && callback(matterId)
    );
  };

  keepActionButtonsVisible = (matterId) => {
    document.getElementById("mockButton").focus();
    document.getElementById(matterId).focus();
  };

  handleSearchChange = (event) => {
    let search = event.target.value;
    this.setState({ search: search.toLowerCase() });
  };

  render() {
    const columns = [
      {
        dataField: "partnershipName",
        text: this.currentUser.entityRoleId == 1 ? "Law Firm" : "Client",
        sort: true,
        headerStyle: () => {
          return { width: "100%" };
        },
        formatter: (cell, row) => {
          let admins =
            row.adminNames == null || row.adminNames == ""
              ? []
              : row.adminNames.split(",");
          let avs =
            row.authViewerNames == null || row.authViewerNames == ""
              ? []
              : row.authViewerNames.split(",");
          if (avs.length > 0) {
            for (var i = 0; i < avs.length; i++) {
              avs[i] = avs[i].trim();
            }
          }
          let names = _.union(admins, avs);
          let viewers =
            this.currentUser.entityRoleId == 2 || names.length == 0
              ? ""
              : `(viewers: ${names.join(", ")})`;
          return `${cell} ${
            row.managingUserName == null
              ? ""
              : `(managing user: ${row.managingUserName})`
          } ${viewers}`;
        },
      },
    ];

    const partnerRowStyle = (row, rowIndex) => {
      const style = {};

      if (row.matterList !== undefined) {
        //style.backgroundColor = 'darkred';
        style.fontWeight = "bold";
        // style.fontSize = 'large';
        //style.color = '#fff';
      }

      return style;
    };

    const defaultSorted = [
      {
        dataField: "partnershipName",
        order: "asc",
      },
    ];

    const options = {
      sizePerPageList: [
        {
          text: "50",
          value: 50,
        },
      ],
      hideSizePerPage: true,
    };

    const matterColumns = [
      {
        dataField: "matterName",
        text: "Matter",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cellContent, row) => {
          return (
            <Link to={`/mattersettings/${row.matterId}`}>{cellContent}</Link>
            // <a key={row.userId} href="#" onClick={(e) => { e.preventDefault(); this.toggleAddEditUserModal(row); }}>{cellContent}</a>
          );
        },
        footer: "",
      },
      {
        dataField: "responsibleLawyers",
        text: "Responsible Lawyer",
        sort: true,
        headerStyle: () => {
          return { width: "15%" };
        },
        footer: "",
      },
      {
        dataField: "eBillerId_Lawfirm",
        text: "Lawfirm Matter Number",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cell, row) => {
          if (this.currentUser.entityRoleId == 2) {
            return (
              <span key={"key-matter-" + row.matterId}>
                <input
                  id={row.matterId}
                  value={cell}
                  onChange={(e) =>
                    this.changeMatterNumber(
                      row.matterId,
                      "eBillerId_Lawfirm",
                      e.target.value,
                      this.keepActionButtonsVisible
                    )
                  }
                  className="matterNumberInput"
                  onFocus={this.onMatterNumberFocus}
                />
                <button
                  id={row.matterId + "-save"}
                  className="matterNumberActionButtons"
                  style={{ backgroundColor: "white", display: "none" }}
                  onClick={() => this.saveMatter(row.matterId)}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer", color: "green" }}
                    icon={faCheck}
                  />
                </button>
                <button
                  id={row.matterId + "-cancel"}
                  className="matterNumberActionButtons"
                  style={{ backgroundColor: "white", display: "none" }}
                  onClick={() =>
                    this.cancelMatterNumberEdit(
                      row.matterId,
                      "eBillerId_Lawfirm"
                    )
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer", color: "red" }}
                    icon={faTimes}
                  />
                </button>
              </span>
            );
          } else {
            return cell;
          }
        },
        footer: "",
      },
      {
        dataField: "eBillerId",
        text: "Client Matter Number",
        sort: true,
        headerStyle: () => {
          return { width: "20%" };
        },
        formatter: (cell, row) => {
          if (this.currentUser.entityRoleId == 1) {
            return (
              <span key={"key2-matter-" + row.matterId}>
                <input
                  id={row.matterId}
                  value={cell}
                  onChange={(e) =>
                    this.changeMatterNumber(
                      row.matterId,
                      "eBillerId",
                      e.target.value,
                      this.keepActionButtonsVisible
                    )
                  }
                  className="matterNumberInput"
                  onFocus={this.onMatterNumberFocus}
                />
                <button
                  id={row.matterId + "-save"}
                  className="matterNumberActionButtons"
                  style={{ backgroundColor: "white", display: "none" }}
                  onClick={() => this.saveMatter(row.matterId)}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer", color: "green" }}
                    icon={faCheck}
                  />
                </button>
                <button
                  id={row.matterId + "-cancel"}
                  className="matterNumberActionButtons"
                  style={{ backgroundColor: "white", display: "none" }}
                  onClick={() =>
                    this.cancelMatterNumberEdit(row.matterId, "eBillerId")
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer", color: "red" }}
                    icon={faTimes}
                  />
                </button>
              </span>
            );
          } else {
            return cell;
          }
        },
        footer: "",
      },
      {
        dataField: "activeBudgetAmount",
        sort: true,
        text: "Budget",
        headerStyle: () => {
          return { width: "12%" };
        },
        formatter: (cell, row) => {
          return currencyFormatter(row.currencySymbol, cell);
        },
        footer: (columnData) =>
          currencyFormatter(
            this.currentUser.currencySymbol,
            columnData.reduce((acc, item) => acc + item, 0)
          ),
      },
      {
        dataField: "currentSpend",
        sort: true,
        text: "Current Spend",
        headerStyle: () => {
          return { width: "13%" };
        },
        formatter: (cell, row) => {
          return currencyFormatter(row.currencySymbol, cell);
        },
        footer: (columnData) =>
          currencyFormatter(
            this.currentUser.currencySymbol,
            columnData.reduce((acc, item) => acc + item, 0)
          ),
      },
    ];

    const expandRow = {
      renderer: (row) => (
        <div className="table-responsive">
          <BootstrapTable
            bordered={false}
            striped
            bootstrap4
            keyField="matterId"
            data={row.matterList}
            columns={matterColumns}
          />
        </div>
      ),
      // expanded: this.state.expandedIndexes,
      showExpandColumn: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return (
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faMinus} />
          );
        }
        return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPlus} />;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faMinus} />
          );
        }
        return <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faPlus} />;
      },
    };
    return (
      <Fragment>
        <button id="mockButton" style={{ display: "none" }} />
        <PageTitle heading="Manage Matters" />
        {this.state.isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!this.state.isLoading && (
          <Fragment>
            <Row>
              <Col md="12">
                <Button
                  id="createMatterBtn"
                  className="mb-2 mr-2"
                  color="primary"
                  onClick={() => this.goToSettings(this.newMatter)}
                >
                  Create New Matter
                </Button>
                <UncontrolledTooltip placement="top" target="createMatterBtn">
                  Either a client or a law firm can create a new matter. Please
                  coordinate with each other regarding who will do this.
                </UncontrolledTooltip>
              </Col>
              <Col md="9">
                {this.state.partnershipList.length > 0 && (
                  <CustomInput
                    type="select"
                    id="partnershipSelect"
                    name="partnershipSelect"
                    onChange={this.onPartnershipSelectionChange}
                  >
                    <option key={0} value={-1}>
                      All{" "}
                      {this.currentUser.entityRoleId == 2
                        ? "Clients"
                        : "Law Firms"}
                    </option>
                    {this.state.partnershipList.map((partnership) => {
                      return (
                        <option
                          key={partnership.partnershipId}
                          value={partnership.partnershipId}
                        >
                          {partnership.partnershipName}
                        </option>
                      );
                    })}
                  </CustomInput>
                )}
              </Col>
              <Col md="3">
                <CustomInput
                  type="select"
                  id="activeSelect"
                  value={this.state.mattersStatus}
                  name="activeSelect"
                  onChange={(e) => this.onMatterStatusChange(e)}
                >
                  <option key={0} value={0}>
                    All Matters
                  </option>
                  <option key={1} value={1}>
                    Active Matters
                  </option>
                  <option key={2} value={2}>
                    Inactive Matters
                  </option>
                </CustomInput>
              </Col>
            </Row>
            <Row style={{ marginTop: "30px" }}>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col md="4">
                        {/* <label>Search:</label> */}
                        <input
                          type="search"
                          placeholder="Search..."
                          className="form-control input-sm leguard-search-input"
                          value={this.state.search}
                          onChange={this.handleSearchChange}
                        />
                      </Col>
                    </Row>
                    <div className="table-responsive">
                      <BootstrapTable
                        striped
                        bordered={false}
                        bootstrap4
                        keyField="partnershipId"
                        data={this.filterPartnerships(
                          this.state.partnerGridList
                        )}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        expandRow={expandRow}
                        rowStyle={partnerRowStyle}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default MatterList;
