import React, {Fragment} from 'react';
import MegaMenuFooter from './Components/FooterMegaMenu';

class AppFooter extends React.Component {
    constructor(props){
        super(props);
    }
    render() {


        return (
            <Fragment>
                <div className="app-footer">
                    <div className="app-footer__inner" style={this.props.margin !== undefined ? {margin: this.props.margin} : {}}>
                        <div className="app-footer-left">
                            {/* <FooterDots/> */}
                            <a href='#' style={{fontWeight: 'bold'}}>LeGuard</a>
                        </div>
                        <div className="app-footer-right">
                            <span style={{fontWeight: 'bold'}}>Version 2.0.0</span><br/>
                            <span style={{fontWeight: 'bold'}}>{' '}{process.env.REACT_APP_ENVIRONMENT}</span>
                            {/* <span>{' '}2.4.0</span> */}
                        </div>
                    </div>
                </div>
            </Fragment>
        )}
}

export default AppFooter;