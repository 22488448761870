import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Card, CardBody, UncontrolledAlert, UncontrolledTooltip } from 'reactstrap';
import PageTitle from '../../Layout/AppMain/PageTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { api, userService } from '../../_services';
import { GeneralForm } from '../../Components/GeneralForm';
import {
    toast
} from 'react-toastify';
import Loader from 'react-loaders';
import swal from 'sweetalert';

const UserProfile = ({ match }) => {

    let [user, setUser] = useState({});
    let [currencyList, setCurrencyList] = useState([]);
    let [timezoneList, setTimezoneList] = useState([]);
    let [userTypeList, setUserTypeList] = useState([]);
    let [propTypes, setPropTypes] = useState([]);
    let [isLoading, setIsLoading] = useState(true);
    const currentUser = userService.getLoggedInUser();
    const successToastOpts = {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
        hideProgressBar: false
    };
    useEffect(() => {
        Promise.all([getUser(currentUser.userId), api.getUserTypes(), api.getCurrencyList(), api.getTimezoneList()])
            .then(([user, userTypes, currencies, timezones]) => {
                //setUser(user);
                setUserTypeList(userTypes);
                setCurrencyList(currencies);
                setTimezoneList(timezones);
                setIsLoading(false);

                setPropTypes([{
                    type: 'input',
                    property: 'name',
                    label: 'Name *',
                    width: 12,
                    isRequired: true
                },
                {
                    type: 'input',
                    fieldType: 'email',
                    property: 'email',
                    label: 'Email *',
                    isRequired: true
                },
                {
                    type: 'input',
                    fieldType: 'email',
                    property: 'secondaryEmail1',
                    label: 'Secondary Email 1',
                    isRequired: false,
                    isVisibleFunc: (obj) => {
                        return obj.secondaryEmail1 !== undefined && obj.secondaryEmail1 !== "";
                    }
                },
                {
                    type: 'input',
                    fieldType: 'email',
                    property: 'secondaryEmail2',
                    label: 'Secondary Email 2',
                    isRequired: false,
                    isVisibleFunc: (obj) => {
                        return obj.secondaryEmail2 !== undefined && obj.secondaryEmail2 !== "";
                    }
                },
                {
                    type: 'input',
                    fieldType: 'email',
                    property: 'secondaryEmail3',
                    label: 'Secondary Email 3',
                    isRequired: false,
                    isVisibleFunc: (obj) => {
                        return obj.secondaryEmail3 !== undefined && obj.secondaryEmail3 !== "";
                    }
                },
                {
                    type: 'dropdown',
                    property: 'currencyId',
                    label: 'Default Currency',
                    dropdownData: currencies.map(x => {
                        x.id = x.currencyId;
                        x.name = x.displayValue;
                        return x;
                    }),
                    positioning: 'right'
                },
                {
                    type: 'dropdown',
                    property: 'timezoneId',
                    label: 'Default Timezone',
                    dropdownData: timezones.map(x => {
                        x.id = x.timezoneId;
                        x.name = x.winDisplayName;
                        return x;
                    }),
                    positioning: 'left'
                },
                {
                    type: 'input',
                    property: 'postal',
                    label: 'Zip / Postal code',
                    positioning: 'left'
                },
                {
                    type: 'dropdown',
                    property: 'userTypeId',
                    label: 'Biller Type',
                    dropdownData: userTypes,
                    positioning: 'right',
                    isVisibleFunc: (obj) => {
                        if (obj.entityRoleId !== undefined && obj.entityRoleId == 2)
                            return true;
                        return false;
                    }
                },
                {
                    type: 'dropdown',
                    property: 'encryptNotes',
                    label: 'Encrypt Notes',
                    dropdownData: [{ id: false, name: 'No' }, { id: true, name: 'Yes' }],
                    positioning: 'left',
                    isVisibleFunc: (obj) => {
                        return currentUser.entityRoleId == 2;
                    },
                }
                ]);

            })

    }, [])

    const getUser = (userId) => {
        return api.getUserByUserId(userId).then(data => {
            setIsLoading(false);
            if (data.secondaryEmails !== undefined && data.secondaryEmails !== null && data.secondaryEmails !== "") {
                let emails = data.secondaryEmails.split(',');
                emails.forEach((e, i) => {
                    data[`secondaryEmail${i + 1}`] = e;
                })
            }
            setUser(data);
        })
    }

    const save = (user) => {
        var secondaryEmails = [];
        for (var i = 1; i <= 3; i++) {
            let email = user[`secondaryEmail${i}`];
            if (email !== undefined && email !== null && email !== "") {
                secondaryEmails.push(email);
            }
        }

        user.encryptNotes = user.encryptNotes == "true";

        if (secondaryEmails.length > 0) {
            user.secondaryEmails = secondaryEmails.join(",");
        } else {
            user.secondaryEmails = "";
        }

        api.updateUser(user).then(data => {
            if (data == "") {
                toast("User Profile saved successfuly", successToastOpts);
                userService.updateUser(user);
            } else {
                swal(data, {
                    buttons: {
                        ok: {
                            text: "Ok",
                            value: "ok",
                            className: "btn-success"
                        }
                    },
                    icon: 'warning'
                }).then((value) => cancel());
            }
        })
    }

    const cancel = () => {
        getUser(currentUser.userId);
    }

    const showAnotherSecondaryEmail = () => {
        let props = [...propTypes];
        let secondaryEmails = props.filter(function (el) {
            return el.property.indexOf('secondaryEmail') > -1;
        })
        for (var i = 0; i < secondaryEmails.length; i++) {
            if (!secondaryEmails[i].isVisibleFunc(user)) {
                secondaryEmails[i].isVisibleFunc = (user) => true;
                break;
            }
        }
        setPropTypes(props);
    }

    return (
        <Fragment>
            <PageTitle heading="User Profile" />
            <Row>
                <Col md="8">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <Col md="12">
                                <UncontrolledAlert color="warning">
                                    <h4><FontAwesomeIcon icon={faInfo} /> Alert</h4>
                                    All information will be shown in your Default Timezone, regardless of your actual location
                                </UncontrolledAlert>
                                {currentUser.entityRoleId == 2 &&
                                    <UncontrolledAlert color="danger">
                                        <h4><FontAwesomeIcon icon={faInfo} /> Alert</h4>
                                        Please secure your password as encrypted notes cannot be recovered without that password.  LeGuard does not store your password
                                </UncontrolledAlert>
                                }
                            </Col>
                            {isLoading && <Loader style={{}} type="ball-pulse" active />}
                            {!isLoading &&
                                <GeneralForm
                                    data={user}
                                    propertyTypes={propTypes}
                                    onCancel={() => cancel()}
                                    showSave={true}
                                    onSave={(user) => save(user)}
                                    showAnotherSecondaryEmail={() => showAnotherSecondaryEmail()}
                                />
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    );
}

export default UserProfile;