import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import Login from '../Views/Login';
import { PrivateRoute } from '../Components/PrivateRoute';
import Main from './main';
import UserRegistration from '../Views/User/UserRegistration';
import GeneralRegistration from '../Views/User/GeneralRegistration';

const App = () => {
  const isIE = window.MSCompatibleInfo != null

  return (
    <Fragment>
      {isIE && <UncontrolledAlert color="danger">
        <h4><FontAwesomeIcon icon={faInfo} /> Alert</h4>
        LeGuard supports Chrome, Edge and Firefox browsers, please use one of these in order to log in the system.
      </UncontrolledAlert>
      }
      {!isIE &&
        <Switch>
          <Route path='/login' component={Login}></Route>
          <Route path='/userRegistration/:userId' component={UserRegistration} />
          <Route path='/generalRegistration' component={GeneralRegistration} />
          <PrivateRoute path='/' component={Main}></PrivateRoute>
          {/* <Redirect component=></Redirect> */}
          <Route component={Main}></Route>
        </Switch>
      }
    </Fragment>
  );
};

export default App;