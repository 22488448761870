import { BehaviorSubject } from 'rxjs';


const activitySubject = new BehaviorSubject(null);


const RunningActivityHelper = {
    runningActivityObservable: activitySubject.asObservable(),
    runningActivity: activitySubject.value,
    setRunningActivity
}


function setRunningActivity(activity) {
    if (activitySubject.value !== activity)
        activitySubject.next(activity);
}

function getRunningActivity() {
    return activitySubject.value;
}


export default RunningActivityHelper;