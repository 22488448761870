import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import GeneralGrid from "../../../Components/GeneralGrid";
import { api, userService } from "../../../_services";
import Loader from "react-loaders";
import swal from "sweetalert";

const SelectFromAllMattersModal = ({
  showModal,
  toggleModal,
  onMatterSelect,
}) => {
  let [matterList, setMatterList] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  const [selectedMatter, setSelectedMatter] = useState(null);
  const [showRateModal, setShowRateModal] = useState(false);
  const toggleRateModal = () => setShowRateModal(!showRateModal);
  const currentUser = userService.getLoggedInUser();
  const gridOptions = {
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
    ],
    hideSizePerPage: true,
  };

  useEffect(() => {
    if (showModal) {
      setSelectedMatter(null);
      loadMatters();
    }
  }, [showModal]);

  const loadMatters = () => {
    setIsLoading(true);
    api.getMatterListByBillerEntityId(currentUser.entityId).then(
      (data) => {
        setMatterList(
          data
            .map((x) => {
              var number =
                x.eBillerId_Lawfirm !== null && x.eBillerId_Lawfirm.length > 0
                  ? " [" + x.eBillerId_Lawfirm + "]"
                  : "";
              x.matterName = x.matterName + number;
              return x;
            })
            .sort(sortMatters)
        );
        setIsLoading(false);
      },
      (err) => setIsLoading(false)
    );
  };

  const sortMatters = (ob1, ob2) => {
    if (ob1.partnershipName > ob2.partnershipName) {
      return 1;
    } else if (ob1.partnershipName < ob2.partnershipName) {
      return -1;
    }

    // Else go to the 2nd item
    if (ob1.matterName < ob2.matterName) {
      return -1;
    } else if (ob1.matterName > ob2.matterName) {
      return 1;
    } else {
      return 0;
    }
  };

  const selectMatter = (matter) => {
    setSelectedMatter(matter);
    api.getUserMatter(matter.matterId, currentUser.userId).then((data) => {
      if (data == undefined || data == null || data.matterId == undefined || data.matterId <= 0) {
        toggleRateModal();
      } else {
        toggleModal();
        onMatterSelect(matter);
      }
    });
  };

  const onRateSave = (rate) => {
    api
      .createUserMatter({
        userId: currentUser.userId,
        name: currentUser.name,
        email: currentUser.email,
        roleId: 2,
        matterId: selectedMatter.matterId,
        matterRate: rate,
        currencyId: 3,
      })
      .then((r) => {
        toggleRateModal();
        toggleModal();
        onMatterSelect(selectedMatter);
      });
  };

  return (
    <Modal
      size="lg"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Select Matter</ModalHeader>
      <ModalBody>
        {isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!isLoading && (
          <Row>
            <Col>
              <GeneralGrid
                colWidth={12}
                title=""
                keyField="matterId"
                defaultSortedColumn="matterName"
                dataSource={matterList}
                options={gridOptions}
                filterColumns={["matterName"]}
                columns={[
                  {
                    dataField: "partnershipName",
                    text: "Client",
                    sort: true,
                    headerStyle: () => {
                      return { width: "40%" };
                    },
                  },
                  {
                    dataField: "matterName",
                    text: "Matter",
                    sort: true,
                    headerStyle: () => {
                      return { width: "60%" };
                    },
                    formatter: (cellContent, row) => {
                      return (
                        <a
                          key={row.userMatterId}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            selectMatter(row);
                          }}
                        >
                          {cellContent}
                        </a>
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: "30px" }}>
          <Col md="12">
            <Button color="link" onClick={() => toggleModal()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <RateModal
        showModal={showRateModal}
        toggleModal={toggleRateModal}
        onSave={onRateSave}
      />
    </Modal>
  );
};

const RateModal = ({ showModal, toggleModal, onSave }) => {
  const [rate, setRate] = useState("");

  useEffect(() => {
    if (showModal) {
      setRate("");
    }
  }, [showModal]);

  const save = () => {
    if (rate == "" || rate < 0) {
      swal("Please assign a rate.", {
        buttons: {
          ok: {
            text: "Ok",
            value: "ok",
            className: "btn-success",
          },
        },
        icon: "warning",
      });
    } else {
      onSave(rate);
    }
  };

  return (
    <Modal
      size="sm"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Enter Rate</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div className="form-group">
              <label>Rate ($)</label>
              <input
                className="form-control"
                type="number"
                placeholder="$ Amount..."
                value={rate}
                onChange={(e) => setRate(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col md="12">
            <Button color="link" onClick={() => toggleModal()}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => save()}>
              Save
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SelectFromAllMattersModal;
