import React, { Component, Fragment, useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader, Card, CardBody, Table, Input,
    ModalBody, Row, Col, CustomInput, CardTitle, ModalFooter
} from 'reactstrap';

import { api, userService, userMatterRoles } from '../_services';
import Loader from 'react-loaders';

const SearchUserModal = ({ showModal, toggleModal, partnershipId, onUsersSave, onUsersSaveSilent, addNewUser, matterId, isOnPartnership }) => {

    let [userList, setUserList] = useState([]);
    let [isLoading, setIsLoading] = useState(true);
    let [currencyList, setCurrencyList] = useState([]);
    let [search, setSearch] = useState('');
    let currentUser = userService.getLoggedInUser();
    useEffect(() => {
        if (showModal) {
            setIsLoading(true);
            if (matterId == undefined || matterId == null) {
                api.getUserListByUserIdIncludePartnership(currentUser.userId, partnershipId).then(data => {
                    setIsLoading(false);
                    setUserList(data);
                });
            } else {
                api.getUserListByUserIdIncludePartnershipForMatter(currentUser.userId, partnershipId, matterId).then(data => {
                    setIsLoading(false);
                    setUserList(data);
                })
            }
            api.getCurrencyList().then(data => {
                setCurrencyList(data);
            })
        }
    }, [showModal]);

    // const selectUser = (user, e) => {
    //     e && e.preventDefault();
    //     onUserSelect(user);
    // }

    const changeUserRole = (userId, roleId) => {
        let user = userList.find(x => x.userId == userId);
        user.roleId = user.roleId == roleId ? -1 : roleId;
        let lst = [...userList];
        let idx = lst.findIndex(x => x.userId == userId);
        lst[idx] = user;
        setUserList(lst);
    }

    return (
        <Modal size="xl" isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>Search Users</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md='4'>
                        <Input placeholder='Search...' type='search' value={search} onChange={e => setSearch(e.target.value)} style={{ marginBottom: '3px' }} />
                    </Col>
                    <Col md='8'>
                        <Button color='primary' className="pull-right" onClick={() => { onUsersSaveSilent(userList); addNewUser(); }}>Add New User</Button>{' '}
                    </Col>
                </Row>
                <Row>
                    <Col md='12'>
                        {isLoading && <Loader style={{}} type="ball-pulse" active />}
                        {!isLoading &&
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        {/* <th width='7%'>Select</th> */}
                                        <th >Name</th>
                                        <th >Email</th>
                                        {/* <th >Entity</th> */}
                                        <th >In Partnership</th>
                                        <th >Viewer</th>
                                        {currentUser.entityRoleId !== 1 &&
                                            <Fragment>
                                                {!isOnPartnership &&
                                                    <th>Resp Lawyer</th>
                                                }
                                                <th>Biller</th>
                                                <th>Rate / h</th>
                                                <th>Currency</th>
                                            </Fragment>
                                        }
                                    </tr>

                                </thead>
                                <tbody>
                                    {userList.length > 0 &&
                                        userList
                                            .filter(x => (x.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || x.email.toLowerCase().indexOf(search.toLowerCase()) > -1))
                                            .map(u => {
                                                return (
                                                    <tr key={u.userId}>
                                                        {/* <td>
                                                    <CustomInput type="checkbox" id={`s-${u.isSelected}`} checked={u.isSelected}
                                                        onChange={(e) => {
                                                            let user = userList.find(x=>x.userId == u.userId);
                                                            user.isSelected = !user.isSelected;
                                                            let lst = [...userList];
                                                            let idx = lst.findIndex(x=>x.userId == u.userId);
                                                            lst[idx] = user;
                                                            setUserList(lst);
                                                        }}
                                                    />
                                                </td> */}
                                                        <td>{u.name}</td>
                                                        <td>{u.email}</td>
                                                        {/* <td>{u.entityName}</td> */}
                                                        <td>{u.isPartnershipMember ? 'Yes' : 'No'}</td>
                                                        <td>
                                                            <CustomInput type="checkbox" id={`vv-${u.userId}`} checked={u.roleId !== null && u.roleId == 1}
                                                                onChange={(e) => {
                                                                    changeUserRole(u.userId, 1);
                                                                }}
                                                            />
                                                        </td>
                                                        {currentUser.entityRoleId !== 1 &&
                                                            <Fragment>
                                                                {!isOnPartnership &&
                                                                    <td>
                                                                        <CustomInput type="checkbox" id={`m-${u.userId}`} checked={u.roleId !== null && u.roleId == 3}
                                                                            onChange={(e) => {
                                                                                changeUserRole(u.userId, 3);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <CustomInput type="checkbox" id={`mm-${u.userId}`} checked={u.roleId !== null && ((!isOnPartnership && u.roleId == 2) || (isOnPartnership && (u.roleId == 2 || u.roleId == 3)))}
                                                                        onChange={(e) => {
                                                                            changeUserRole(u.userId, 2);
                                                                        }}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    {(u.roleId == 2 || u.roleId == 3) &&
                                                                        <Input type="number" value={u.rate} readOnly={(u.userPartnershipId !== undefined && u.userPartnershipId > 0) || (u.userMatterId !== undefined && u.userMatterId > 0) ? true : false}
                                                                            onChange={(e) => {
                                                                                let user = userList.find(x => x.userId == u.userId);
                                                                                user.rate = e.target.value;
                                                                                let lst = [...userList];
                                                                                let idx = lst.findIndex(x => x.userId == u.userId);
                                                                                lst[idx] = user;
                                                                                setUserList(lst);
                                                                            }}
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {(u.roleId == 2 || u.roleId == 3) &&
                                                                        <CustomInput type="select" id={`m-${u.userId}-currencySelect`}
                                                                            name={`${u.userId}-currencySelect`} className='form-control'
                                                                            onChange={(e) => {
                                                                                let user = userList.find(x => x.userId == u.userId);
                                                                                user.currencyId = e.target.value;
                                                                                let lst = [...userList];
                                                                                let idx = lst.findIndex(x => x.userId == u.userId);
                                                                                lst[idx] = user;
                                                                                setUserList(lst);
                                                                            }}
                                                                            disabled={(u.userPartnershipId !== undefined && u.userPartnershipId > 0) || (u.userMatterId !== undefined && u.userMatterId > 0) ? true : false}
                                                                            value={u.currencyId}>
                                                                            {currencyList.map(currency => {
                                                                                return <option key={`currency${u.userId}-${currency.currencyId}`} value={currency.currencyId}>{currency.displayValue}</option>
                                                                            })}
                                                                        </CustomInput>
                                                                    }
                                                                </td>
                                                            </Fragment>
                                                        }
                                                    </tr>
                                                )
                                            })
                                    }


                                </tbody>
                            </Table>
                        }
                    </Col>

                </Row>
                <Row>
                    <Col md="12">
                        <Button color="link" onClick={() => toggleModal()}>Cancel</Button>
                        <Button color="primary" onClick={() => onUsersSave(userList)}>Save</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export default SearchUserModal;