import React, { useState, useEffect } from 'react';
import {
    Modal, ModalHeader,
    ModalBody
} from 'reactstrap';
import { GeneralForm } from '../../../../Components/GeneralForm';
import { api, userService } from '../../../../_services';

const EditActivityCodeModal = ({ isLoading, showModal, toggleModal, matter, actCode, currencyList, saveActivityCode }) => {

    let initialState = {
        matterActivityCodeId: -1,
        activityCodeId: -1,
        title: '',
        fixedPrice: '',
        currencyId: userService.getLoggedInUser().currencyId,
        fpEditable: false,
        fpHidden: false,
        fpOnly: false,
    }
    let [activityCode, setActivityCode] = useState(initialState);

    useEffect(() => {
        if (actCode !== undefined && actCode.activityCodeId !== undefined && actCode.activityCodeId > -1)
            api.getMatterActivityCodeByMatterIdActivityCodeId(matter.matterId, actCode.activityCodeId).then(data => {
                setActivityCode({ ...data, currencyId: userService.getLoggedInUser().currencyId });
            })
    }, [actCode]);

    const onSave = (ac) => {
        ac.activityCodeId = actCode.activityCodeId;
        ac.matterId = matter.matterId;
        saveActivityCode(ac);
    }

    const formProps = [
        {
            type: 'checkbox',
            property: 'fpOnly',
            label: 'Allow fixed fee for this Activity',
            isVisibleFunc: (obj) =>{
                return matter.allowFixedPrice;
            },
            width: 12
        },
        {
            type: 'input',
            fieldType: 'number',
            property: 'fixedPrice',
            label: 'Fixed Fee Amount *',
            isVisibleFunc: (obj) => {
                return obj.fpOnly == true ? true : false;
            }
        },
        {
            type: 'dropdown',
            property: 'currencyId',
            label: 'Default Currency *',
            isVisible: true,
            isVisibleFunc: (obj) => {
                return obj.fpOnly == true ? true : false;
            },
            dropdownData: currencyList.map(x => {
                x.id = x.currencyId;
                x.name = x.displayValue;
                return x;
            })
        },
        {
            type: 'checkbox',
            property: 'fpHidden',
            label: 'Allow Fee to be viewed by biller',
            isVisibleFunc: (obj) => {
                return obj.fpOnly == true ? true : false;
            }
        },
        {
            type: 'checkbox',
            property: 'fpEditable',
            label: 'Allow Fee to be changed by biller',
            isVisibleFunc: (obj) => {
                return obj.fpHidden == true ? true : false;
            }
        }
    ]

    return (
        <Modal size="md" isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
            <ModalHeader toggle={() => toggleModal()}>Activity Code: {activityCode.title}</ModalHeader>
            <ModalBody>
                <GeneralForm data={activityCode}
                    propertyTypes={formProps}
                    showSave={true}
                    onSave={onSave}
                    saveButtonText={activityCode.matterActivityCodeId > -1 ? 'Save' : 'Add'}
                    onDelete={activityCode.matterActivityCodeId > -1 ? onSave : undefined}
                    showDelete={activityCode !== undefined && activityCode.matterActivityCodeId !== undefined && activityCode.matterActivityCodeId > -1}
                    onCancel={() => toggleModal()}
                />
               
            </ModalBody>
        </Modal>
    );
}

export default EditActivityCodeModal;