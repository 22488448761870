import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  CardBody,
  ModalBody,
  Row,
  Col,
  CustomInput,
  CardTitle,
} from "reactstrap";
import GeneralGrid from "../../../Components/GeneralGrid";
import { api, userService, userMatterRoles } from "../../../_services";
import Loader from "react-loaders";
import SelectFromAllMattersModal from "./SelectFromAllMattersModal";

const SelectMatterModal = ({ showModal, toggleModal, onMatterSelect }) => {
  let [matterList, setMatterList] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  const [showSelectFromAllMattersModal, setShowSelectFromAllMattersModal] =
    useState(false);
  const toggleShowSelectFromAllMattersModal = () =>
    setShowSelectFromAllMattersModal(!showSelectFromAllMattersModal);
  const currentUser = userService.getLoggedInUser();
  const gridOptions = {
    sizePerPageList: [
      {
        text: "100",
        value: 100,
      },
    ],
    hideSizePerPage: true,
  };

  useEffect(() => {
    if (showModal) {
      loadMatters();
    }
  }, [showModal]);

  const loadMatters = () => {
    setIsLoading(true);
    if (currentUser.systemRoleId == 2) {
      api.getMatterListByUserId(currentUser.userId, 0, true).then((data) => {
        setIsLoading(false);
        setMatterList(data.sort(sortMatters));
      });
    } else {
      Promise.all([
        api.getMatterListByUserId(
          currentUser.userId,
          userMatterRoles.respLawyer.id
        ),
        api.getMatterListByUserId(
          currentUser.userId,
          userMatterRoles.biller.id
        ),
      ]).then(([list1, list2]) => {
        let ret = [];
        if (list1.length > 0) {
          list1.forEach((el) => {
            let found = list2.find((m) => {
              return m.userMatterId == el.userMatterId;
            });
            if (found !== undefined) list2.splice(list2.indexOf(found), 1);
          });
        }
        ret = [...list1, ...list2];
        ret = ret.sort(sortMatters);

        ret = ret.map((x) => {
          var number =
            x.eBillerID_Lawfirm !== null && x.eBillerID_Lawfirm.length > 0
              ? " [" + x.eBillerID_Lawfirm + "]"
              : "";
          x.matterName = x.matterName + number;
          return x;
        });
        setIsLoading(false);
        setMatterList(ret);
      });
    }
  };

  const sortMatters = (ob1, ob2) => {
    if (ob1.partnershipName > ob2.partnershipName) {
      return 1;
    } else if (ob1.partnershipName < ob2.partnershipName) {
      return -1;
    }

    // Else go to the 2nd item
    if (ob1.matterName < ob2.matterName) {
      return -1;
    } else if (ob1.matterName > ob2.matterName) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <Modal
      size="lg"
      isOpen={showModal}
      toggle={() => toggleModal()}
      backdrop="static"
    >
      <ModalHeader toggle={() => toggleModal()}>Start Activity</ModalHeader>
      <ModalBody>
        {isLoading && <Loader style={{}} type="ball-pulse" active />}
        {!isLoading && (
          <Row>
            <Col>
              <GeneralGrid
                colWidth={12}
                title=""
                keyField="matterId"
                defaultSortedColumn="matterName"
                dataSource={matterList}
                options={gridOptions}
                filterColumns={["matterName"]}
                headerButton={() => (
                  <Button
                    color="primary"
                    style={{ float: "right" }}
                    onClick={toggleShowSelectFromAllMattersModal}
                  >
                    View all matters
                  </Button>
                )}
                columns={[
                  {
                    dataField: "partnershipName",
                    text: "Client",
                    sort: true,
                    headerStyle: () => {
                      return { width: "40%" };
                    },
                  },
                  {
                    dataField: "matterName",
                    text: "Matter",
                    sort: true,
                    headerStyle: () => {
                      return { width: "60%" };
                    },
                    formatter: (cellContent, row) => {
                      return (
                        <a
                          key={row.userMatterId}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            onMatterSelect(row);
                          }}
                        >
                          {cellContent}
                        </a>
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        )}
        <Row style={{ marginTop: "30px" }}>
          <Col md="12">
            <Button color="link" onClick={() => toggleModal()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <SelectFromAllMattersModal
        showModal={showSelectFromAllMattersModal}
        toggleModal={toggleShowSelectFromAllMattersModal}
        onMatterSelect={onMatterSelect}
      />
    </Modal>
  );
};

export default SelectMatterModal;
