import React, { Fragment, useState, useEffect } from 'react';
import { CustomInput, Button, Row, Col } from 'reactstrap';

const GeneralCheckboxList = ({ list, headers, props, keyProperty, postSelectAllAction, selected, onSave }) => {
    // headers = [
    //     {
    //         label:'',
    //         width:''
    //     }
    // ]

    let [allSelected, setAllSelected] = useState(false);
    let [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (list && list.length > 0){
            setDataSource(list);
            setAllSelected(selected == undefined ? false : selected);
        }
    }, [list])


    return (
        <Fragment>
            <Row>
                <Col md="12" style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <table className="table-responsive" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th width="10%">Select</th>
                                {headers.map(x => {
                                    return <th key={x.label} width={x.width}>{x.label}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <CustomInput type="checkbox" id={'selectAll0'} style={{ marginLeft: '3px', cursor: 'pointer' }}
                                        value={allSelected}
                                        checked={allSelected}
                                        onChange={() => {
                                            let ds = [...dataSource];
                                            postSelectAllAction(!allSelected);
                                            setAllSelected(!allSelected);
                                            setDataSource(ds.map(x => { x.isSelected = !allSelected; return x; }));

                                        }}
                                    />
                                </td>
                                <td style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        let ds = [...dataSource];
                                        postSelectAllAction(!allSelected);
                                        setAllSelected(!allSelected);
                                        setDataSource(ds.map(x => { x.isSelected = !allSelected; return x; }));
                                    }}
                                >Select all</td>
                            </tr>
                            {dataSource.map(x => {
                                return <tr key={x[keyProperty]}>
                                    <td>
                                        <CustomInput type="checkbox" id={x[keyProperty]} style={{ cursor: 'pointer' }}
                                            value={x.isSelected} checked={x.isSelected}
                                            onChange={(event) => {
                                                let ds = [...dataSource];
                                                let item = ds.find(d => d[keyProperty] == x[keyProperty]);
                                                item.isSelected = !item.isSelected;
                                                let index = ds.findIndex(d => d[keyProperty] == item[keyProperty]);
                                                ds[index] = item;
                                                setDataSource(ds);
                                            }}
                                        />
                                    </td>
                                    {props.map((p, i) => {
                                        return <td key={`${x[keyProperty]}-${i}`}>{typeof p === 'function' ? p(x) : x[p]}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row style={{marginTop: '30px'}}>
                <Col md="12">
                    <Button className="pull-right" color="primary" onClick={() => onSave(dataSource)}>Save</Button>
                </Col>
            </Row>
        </Fragment >
    );
}

export default GeneralCheckboxList;