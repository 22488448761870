import React, { Fragment, useEffect, useState } from 'react';
import {
    Button, Modal, ModalHeader,
    ModalBody, Row, Col, CustomInput
} from 'reactstrap';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { userService, api } from '../../../../_services';
import { currencyFormatter } from '../../../../_services/currencyFormatter';
import swal from 'sweetalert';

const EditRateModal = ({ showModal, toggleModal, userId, partnership, matter, currency, refreshUsers }) => {

    let [rateObj, setRateObj] = useState([]);

    let [existingRate, setExistingRate] = useState('');
    let [existingRateDate, setExistingRateDate] = useState(null);

    let [specificRate, setSpecificRate] = useState('');
    let [specificRateStartDate, setSpecificRateStartDate] = useState(null);
    let [specificRateEndDate, setSpecificRateEndDate] = useState(null);

    let [newFutureRate, setNewFutureRate] = useState('');
    let [newFutureRateDate, setNewFutureRateDate] = useState(null);

    let [selectedDateRange, setSelectedDateRange] = useState(0);

    const currentUser = userService.getLoggedInUser();

    useEffect(() => {
        if (showModal) {
            if (partnership !== undefined) {
                api.getPartnershipRate(partnership.partnershipId, userId).then(data => {
                    setRateObj(data);
                    setExistingRateDate(null);
                    setNewFutureRateDate(null);
                    setSpecificRate('');
                    setExistingRate('');
                    setNewFutureRate('');
                    setSelectedDateRange(0);
                })
            } else {
                api.getMatterRate(matter.matterId, userId).then(data => {
                    setRateObj(data);
                })
            }
        }
    }, [showModal]);

    const getDates = (dateRange) => {
        let startDate, endDate;
        let userNow = moment.tz('UTC').tz(currentUser.timezoneIanaName);
        switch (parseInt(dateRange)) {
            case 0: //from today
                startDate = userNow.format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 1: //from yesterday
                startDate = userNow.clone().subtract(1, 'days').format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 2: //from last 7 days
                startDate = userNow.clone().subtract(7, 'days').format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 3: //from last 30 days
                startDate = userNow.clone().subtract(30, 'days').format("YYYY-MM-DD");
                endDate = userNow.format("YYYY-MM-DD");
                break;
            case 4: //this month
                startDate = userNow.clone().startOf('month').format("YYYY-MM-DD");
                endDate = userNow.clone().endOf('month').format("YYYY-MM-DD");
                break;
            case 5: //last month
                startDate = userNow.clone().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                endDate = userNow.clone().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                break;
            case 6: //custom
                startDate = moment(specificRateStartDate).format("YYYY-MM-DD");
                endDate = moment(specificRateEndDate).format("YYYY-MM-DD");
                break;
        }
        return { startDate, endDate };
    }

    const onFutureRateSave = () => {
        if (partnership !== undefined) {
            api.insertPartnershipRate(rateObj[0].userPartnershipId, newFutureRate, newFutureRateDate, currency.currencyId, userId).then(data => {
                toggleModal();
                refreshUsers && refreshUsers();
            })
        } else {
            api.insertMatterRate(rateObj[0].userMatterId, currency.currencyId, newFutureRate, newFutureRateDate, userId).then(data => {
                toggleModal();
                refreshUsers && refreshUsers();
            })
        }

    }

    const updateRate = () => {

        if (partnership !== undefined) {
            if (rateObj[0].matterRatesCount == 0) {
                api.updatePartnershipRate(partnership.partnershipId, userId, existingRate, existingRateDate).then(data => {
                    toggleModal();
                    refreshUsers && refreshUsers();
                })
            } else {
                swal({
                    title: "There are some matter rates saved for this person on this partnership. What would you like to do?",
                    buttons: {
                        saveAll: {
                            text: "Update Partnership Rate and all Matters related to this Partnership",
                            value: 'saveAll'
                        },
                        saveOnly: {
                            text: "Update the Partnership Rate only - don’t change existing Matters and Activities",
                            value: 'saveOnly'
                        }
                    }
                }).then(value => {
                    if (value == 'saveAll') {
                        api.updatePartnershipRateAndMatters(userId, rateObj[0].userPartnershipId, partnership.partnershipId, existingRate, currency.currencyId, existingRateDate).then(data => {
                            toggleModal();
                            refreshUsers && refreshUsers();
                        })
                    } else if (value == 'saveOnly') {
                        api.updatePartnershipRate(partnership.partnershipId, userId, existingRate, existingRateDate).then(data => {
                            toggleModal();
                            refreshUsers && refreshUsers();
                        })
                    }
                })

            }
        } else {
            swal({
                buttons: {
                    saveAll: {
                        text: "Update the Rate for this Matter, the Partnership and any other Matters related to the Partnership",
                        value: 'saveAll'
                    },
                    savePart: {
                        text: "Update the Rate for this Matter AND the Partnership but don’t change other Matters",
                        value: 'savePart'
                    },
                    saveOnly: {
                        text: "Update the rate for this Matter only",
                        value: 'saveOnly'
                    }
                }
            }).then(value => {
                if (value == 'saveAll') {
                    api.updatePartnershipRateAndMatters(userId, rateObj[0].userPartnershipId, matter.partnershipId, existingRate, currency.currencyId, existingRateDate).then(data => {
                        toggleModal();
                        refreshUsers && refreshUsers();
                    })
                } else if (value == 'savePart') {
                    api.updateMatterRateAndPartnership(matter.matterId, matter.partnershipId, userId, existingRate, existingRateDate).then(data => {
                        toggleModal();
                        refreshUsers && refreshUsers();
                    })
                } else if (value == 'saveOnly') {
                    api.updateMatterRate(matter.matterId, userId, existingRate, existingRateDate).then(data => {
                        toggleModal();
                        refreshUsers && refreshUsers();
                    })
                }
            })
        }
    }

    const updateRateDateRange = () => {
        let dates = getDates(selectedDateRange);
        if (partnership !== undefined) {
            api.insertPartnershipRateDateRange(userId, rateObj[0].userPartnershipId, partnership.partnershipId, specificRate, currency.currencyId, dates.startDate, dates.endDate).then(data => {
                toggleModal();
                refreshUsers && refreshUsers();
            })
        } else {
            api.insertMatterRateDateRange(rateObj[0].userMatterId, currency.currencyId, specificRate, dates.startDate, dates.endDate, userId).then(data => {
                toggleModal();
                refreshUsers && refreshUsers();
            })
        }
    }

    return (
        <Fragment>
            <style>{`
                .swal-button-container{
                    width:100%;
                }
                .swal-button-container button{
                    width:100%;
                }
            `}</style>
            <Modal isOpen={showModal} toggle={() => toggleModal()} backdrop="static">
                <ModalHeader toggle={() => toggleModal()}></ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md="12">
                            {partnership &&
                                <h5 style={{ fontWeight: 'bold' }}>{'Partnership: ' + partnership.partnershipName}</h5>
                            }
                            {matter &&
                                <h5 style={{ fontWeight: 'bold' }}>{'Matter: ' + matter.matterName}</h5>
                            }
                            {rateObj && rateObj.length > 0 &&
                                rateObj.map((x, i) => {
                                    return <p>{i == 0 && 'Rate (s): '}{currencyFormatter(currency ? currency.symbol : "$", x.rate, true)} effective from {moment(x.effectiveDate).format('MM/DD/YYYY')}</p>
                                })
                            }
                        </Col>
                    </Row>
                    <div className="divider"></div>
                    <Row>
                        <Col md="12">
                            <p>Setup Future Rate</p>
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label htmlFor="futureRate">New Future Rate</label>
                                        <input className="form-control" type="number"
                                            placeholder="Amount..." value={newFutureRate}
                                            onChange={(e) => setNewFutureRate(e.target.value)}
                                            name="futureRate" id="futureRate"
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label htmlFor="futureRateDate">Rate Change Date</label>
                                        <DatePicker className="form-control"
                                            selected={newFutureRateDate}
                                            onChange={(date) => setNewFutureRateDate(date)}
                                            placeholderText="Start date..."
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Button color="primary" onClick={() => onFutureRateSave()}>Save</Button>
                        </Col>
                    </Row>
                    <div className="divider"></div>
                    <Row>
                        <Col md="12">
                            <p>Modify Existing Rate Back</p>

                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label htmlFor="existingRate">Updated Rate</label>
                                        <input className="form-control" type="number"
                                            placeholder="Amount..." value={existingRate}
                                            onChange={(e) => setExistingRate(e.target.value)}
                                            name="existingRate" id="existingRate"
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="form-group">
                                        <label htmlFor="existingRateDate">From Date going forward</label>
                                        <DatePicker className="form-control"
                                            selected={existingRateDate}
                                            onChange={(date) => setExistingRateDate(date)}
                                            placeholderText="Start date..."
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Button color="primary" onClick={() => updateRate()}>Save</Button>
                        </Col>
                    </Row>
                    <div className="divider"></div>
                    <Row>
                        <Col md="12">
                            <p>Set Specific Rate for Date Range</p>
                            <Row>
                                <Col md="6">
                                    <div className="form-group">
                                        <label htmlFor="specificRate">Updated Rate</label>
                                        <input className="form-control" type="number"
                                            placeholder="Amount..." value={specificRate}
                                            onChange={(e) => setSpecificRate(e.target.value)}
                                            name="specificRate" id="specificRate"
                                        />
                                    </div>
                                </Col>
                                <Col md="6">
                                    <label htmlFor="dateRange">Date Range</label>
                                    <CustomInput type="select" id="dateRange"
                                        name="dateRange" value={selectedDateRange}
                                        onChange={(e) => setSelectedDateRange(e.target.value)}>
                                        <option key={`dr-${0}`} value={0}>Today</option>
                                        <option key={`dr-${1}`} value={1}>Yesterday</option>
                                        <option key={`dr-${2}`} value={2}>Last 7 Days</option>
                                        <option key={`dr-${3}`} value={3}>Last 30 Days</option>
                                        <option key={`dr-${4}`} value={4}>This Month</option>
                                        <option key={`dr-${5}`} value={5}>Last Month</option>
                                        <option key={`dr-${6}`} value={6}>Custom Range</option>
                                    </CustomInput>
                                </Col>
                            </Row>
                            {selectedDateRange == 6 &&
                                <Row>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="specificRateStartDate">Start Date</label>
                                        <DatePicker className="form-control"
                                            selected={specificRateStartDate}
                                            onChange={(date) => setSpecificRateStartDate(date)}
                                            placeholderText="Start date..."
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="specificRateEndDate">End Date</label>
                                        <DatePicker className="form-control"
                                            selected={specificRateEndDate}
                                            onChange={(date) => setSpecificRateEndDate(date)}
                                            placeholderText="Start date..."
                                        />
                                    </div>
                                </Row>
                            }
                            <Button color="primary" onClick={() => updateRateDateRange()}>Save</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>

        </Fragment>
    );
}

export default EditRateModal;