import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { api } from "../../../../_services";
import * as Compress from "compress.js";
import { AvForm, AvField } from "availity-reactstrap-validation";

const AvatarAddress = ({ partnershipId, entity, setEntity }) => {
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    if (
      partnershipId != undefined &&
      partnershipId != null &&
      partnershipId > 0
    ) {
      api.getPartnershipById(partnershipId).then((data) => {
        if (data != undefined && data != null) {
          api.getEntityById(data.billerEntityId).then((data) => {
            setEntity(data);
          });
        }
      });
    }
  }, [partnershipId]);

  const handleLogoChangeClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    api.uploadEntityLogo(entity.entityId, fileUploaded).then((data) => {
      setEntity({ ...entity, logoUrl: data });
    });
  };

  const handleLogoRemoveClick = () => {
    setEntity({
      ...entity,
      logoUrl: "",
    });
  };

  return (
    <Row>
      {entity != null && (
        <Col>
          <Row>
            <Col>
              {entity.logoUrl !== undefined &&
                entity.logoUrl !== null &&
                entity.logoUrl !== "" && (
                  <>
                    <img
                      width={150}
                      height={100}
                      // className="rounded-circle"
                      src={entity.logoUrl}
                      alt="avatar"
                    />
                    <Button
                      color="primary"
                      onClick={handleLogoChangeClick}
                      style={{ margin: "5px" }}
                    >
                      Change Logo
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleLogoRemoveClick}
                      style={{ margin: "5px" }}
                    >
                      Remove Logo
                    </Button>
                  </>
                )}
              {(!entity ||
                entity.logoUrl == undefined ||
                entity.logoUrl == "") && (
                <Button
                  color="primary"
                  onClick={handleLogoChangeClick}
                  style={{ margin: "5px" }}
                >
                  Add Logo
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <AvField
                type="text"
                name="billingAddress"
                label="Billing Address"
                value={entity.address}
                onChange={(e) => {
                  setEntity({ ...entity, address: e.target.value });
                }}
                placeholder="Address..."
              />
            </Col>
          </Row>
          <input
            type="file"
            accept="image/*"
            ref={hiddenFileInput}
            onChange={handleInputChange}
            style={{ display: "none" }}
          />
        </Col>
      )}
    </Row>
  );
};

export default AvatarAddress;
