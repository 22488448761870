import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Modal,
  ModalHeader,
  Card,
  CardBody,
  ModalBody,
  Row,
  Col,
  CustomInput,
  CardTitle,
} from "reactstrap";
import Loader from "react-loaders";

const GeneralGrid = ({
  colWidth,
  title,
  keyField,
  dataSource,
  columns,
  defaultSorted,
  options,
  buttonText,
  onAdd,
  rowStyle,
  filterColumns,
  style,
  headerButton,
}) => {
  const originalData = dataSource;
  let [data, setDataSource] = useState(dataSource);

  useEffect(() => {
    setDataSource(dataSource);
  }, [dataSource]);

  const handleSearchChange = (e) => {
    if (e.target.value !== "") {
      let filteredData = originalData.filter((el) => {
        let flag = false;
        filterColumns.forEach((fc) => {
          if (
            el[fc] !== null &&
            el[fc].toLowerCase().indexOf(e.target.value) > -1
          ) {
            flag = true;
            return;
          }
        });
        return flag;
      });
      setDataSource(filteredData);
    } else {
      setDataSource(originalData);
    }
  };

  const defaultOptions = {
    sizePerPageList: [
      {
        text: "50",
        value: 50,
      },
    ],
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  return (
    <Fragment>
      <Col md={colWidth} style={style}>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>{title}</CardTitle>
            <Row>
              <Col md="4">
                {filterColumns && filterColumns.length > 0 && (
                  <input
                    type="search"
                    placeholder="Search..."
                    className="form-control input-sm leguard-search-input"
                    onChange={handleSearchChange}
                  />
                )}
              </Col>
              <Col md="8">
                {headerButton && headerButton()}
              </Col>
            </Row>
            <div className="table-responsive">
              <BootstrapTable
                striped
                bordered={false}
                bootstrap4
                keyField={keyField}
                data={data}
                columns={columns}
                defaultSorted={defaultSorted !== undefined ? defaultSorted : []}
                pagination={
                  options !== undefined &&
                  data.length > options.sizePerPageList[0].value
                    ? paginationFactory(options)
                    : data.length > defaultOptions.sizePerPageList[0].value
                    ? paginationFactory(defaultOptions)
                    : null
                }
                rowStyle={rowStyle}
                ignoreSinglePage={true}
              />
            </div>
            {buttonText && (
              <Button color="primary" onClick={onAdd}>
                {buttonText}
              </Button>
            )}
            {/* {specialCommand &&
                            <Button color="primary" className='pull-right' onClick={specialCommand}>{specialButtonText}</Button>
                        } */}
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

GeneralGrid.propTypes = {
  colWidth: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  defaultSorted: PropTypes.array,
  options: PropTypes.object,
  buttonText: PropTypes.string,
  onAdd: PropTypes.func,
  rowStyle: PropTypes.func,
  filterColumns: PropTypes.array,
};

export default GeneralGrid;
