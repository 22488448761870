import React, { useEffect, useState, useMemo, Fragment } from "react";
import { useForm } from "react-hook-form";
import { CustomInput, Form, Row, Col, Button, Input } from "reactstrap";
import MomentUtils from '@date-io/moment';
import Loading from './Loading';
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorElement } from "./ErrorElement";

export const GeneralForm = ({
  data,
  propertyTypes,
  onSave, saveButtonText,
  onDelete,
  onCancel,
  showSave,
  customValidatorRules,
  customButton,
  showAnotherSecondaryEmail,
  showTermsAndConditions }) => {

  // let propTypes = [{
  //     type: 'input',
  //     fieldType: '',  //text, email, password
  //     property: 'partnershipName',
  //     label: 'Name',
  //     isVisible: true,
  //     isReadonly: false,
  //     isRequired: false,
  //     dropdownData: []
  // }]
  let [formData, setFormData] = useState(data);
  const [validationSchema, setValidationSchema] = useState(yup.object({
    placeholder: yup.string()
  }));
  const [resolver, setResolver] = useState(() => yupResolver(validationSchema));
  const { handleSubmit, register, reset, setValue, watch, formState: { errors } } = useForm({
    defaultValues: useMemo(() => {
      return formData;
    }, [formData]),
    resolver: resolver
  });
  let [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setFormData(data);
    reset(data);
  }, [reset, data])

  useEffect(() => {
    // Retrieve the entire form data object
    const subscription = watch((value) => setFormData(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    let yupObj = {};
    let rules = {};
    customValidatorRules?.forEach((v) => {
      rules[v.property] = v;
    });

    if (propertyTypes && propertyTypes.length > 0) {
      propertyTypes.forEach(x => {
        const rule = rules[x.property];
        if (rule) {
          if (x.isRequired) {
            yupObj[x.property] = yup.string()
              .required("This field is required")
              .test(`test-${x.property}`, rule.errorMessage, function (value, options) {
                return rule.validate(options.parent); // options.parent is actual form data
              });
          } else {
            yupObj[x.property] = yup.string()
              .test(`test-${x.property}`, rule.errorMessage, function (value, options) {
                return rule.validate(options.parent); // options.parent is actual form data
              });
          }
        } else {
          yupObj[x.property] = x.isRequired ? yup.string().required("This field is required") : yup.string();
        }
      });

      const updatedSchema = yup.object(yupObj);
      setValidationSchema(updatedSchema);
      setResolver(() => yupResolver(updatedSchema));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyTypes]);

  const handleOnChange = (e, propType) => {
    propType.onChange && propType.onChange(e);
  };

  const getCheckbox = (propType) => {
    const {ref, ...rest} = register(propType.property);
    return (propType.isVisibleFunc === undefined || propType.isVisibleFunc(data)) &&
      <div key={`form-element-${propType.property}`} className="form-group col-md-12">
        <CustomInput type="checkbox"
          id={propType.property}
          label={propType.label}
          innerRef={ref}
          onChange={e => handleOnChange(e, propType)}
          {...rest}
        />
      </div>
  }

  const getDropdown = (propType) => {
    const {ref, ...rest} = register(propType.property);
    return (propType.isVisibleFunc === undefined || propType.isVisibleFunc(data)) &&
      <div key={`form-element-${propType.property}`} className="form-group col-md-12">
        <label htmlFor={propType.property}>{propType.label}</label>
        <CustomInput type="select"
          innerRef={ref}
          id={propType.property}
          className='form-control'
          disabled={propType.isReadonly}
          onChange={e => handleOnChange(e, propType)}
          name={propType.property}
          {...rest}
          >
          {propType.dropdownData.map(role => {
            return <option key={role.id} value={role.id}
            >{role.name}</option>
          })}
        </CustomInput>
        <ErrorElement message={errors[propType.property]?.message} />
      </div>

  }

  const getDate = (propType) => {
    const {ref, ...rest} = register(propType.property);
    return (
      <div key={`form-element-${propType.property}`} className="form-group col-md-12">
        <label>{propType.label}</label>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            format="MM/dd/yyyy"
            InputProps={{ className: 'form-control' }}
            innerRef={ref}
            onChange={e => handleOnChange(e, propType)}
            {...rest}
          />
        </MuiPickersUtilsProvider>
        <ErrorElement message={errors[propType.property]?.message} />
      </div>
    )
  }

  const getDateTime = (propType) => {
    const {ref, ...rest} = register(propType.property);
    return (
      <div key={`form-element-${propType.property}`} className="form-group col-md-12">
        <label>{propType.label}</label>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            InputProps={{ className: 'form-control' }}
            innerRef={ref}
            onChange={e => handleOnChange(e, propType)}
            {...rest}
          />
        </MuiPickersUtilsProvider>
        <ErrorElement message={errors[propType.property]?.message} />
      </div>
    )
  }

  const getInput = (propType) => {
    const isRequired = propType.isRequired !== undefined ? propType.isRequired : false;
    const { ref, ...rest } = register(propType.property, { required: isRequired });
    return (
      (propType.isVisibleFunc === undefined || propType.isVisibleFunc(data)) &&
      <div key={`form-element-${propType.property}`} >
        <div className="form-group col-md-12">
          <label htmlFor={propType.property}>{propType.label}</label>
          <Input
            name={propType.property}
            readOnly={propType.isReadonly !== undefined ? propType.isReadonly : false}
            placeholder={propType.placeholder !== undefined ? propType.placeholder : 'Enter...'}
            type={propType.fieldType !== undefined ? propType.fieldType : 'text'}
            invalid={errors[propType.property]?.message !== undefined && errors[propType.property]?.message !== ''}
            innerRef={ref}
            onChange={e => handleOnChange(e, propType)}
            {...rest}
          />
          <ErrorElement message={errors[propType.property]?.message} />
          {showAnotherSecondaryEmail && propType.property === 'email' &&
            <Button color="primary" onClick={showAnotherSecondaryEmail}>Add secondary email</Button>
          }
          {propType.specialElement !== undefined && propType.specialElement}
        </div>
      </div>
    )
  }

  const getField = (propType) => {
    switch (propType.type) {
        case 'input':
            return getInput(propType);
        case 'date':
            return getDate(propType);
        case 'datetime':
            return getDateTime(propType);
        case 'checkbox':
            return getCheckbox(propType);
        case 'dropdown':
            return getDropdown(propType);
        default:
            return null;
    }
  }

  const onSubmit = (submittedData) => {
    setIsLoading(true);
    onSave(submittedData);
    setIsLoading(false);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="12">
          {propertyTypes.filter(x => x.positioning === undefined).map(p => {
            return getField(p)
          })}
        </Col>
      </Row>

      <Row>
        <Col md="6">
          {propertyTypes.filter(x => x.positioning === 'left').map(p => { return getField(p) })}
        </Col>
        <Col md="6">
          {propertyTypes.filter(x => x.positioning === 'right').map(p => { return getField(p) })}
        </Col>
      </Row>

      {showTermsAndConditions &&
        <Row style={{ marginBottom: '30px' }}>
          <Col md="12">
            <span style={{ padding: '0px 15px 0px 15px' }}>By Registering, you agree to <b><span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={showTermsAndConditions}>LeGuard's Terms and Conditions</span></b></span>
          </Col>
        </Row>
      }

      {onCancel &&
        <Button color="link" onClick={onCancel}>Cancel</Button>
      }
      {onDelete &&
        <Button color={data.isDeleted ? 'success' : 'danger'} style={{ marginLeft: '.25rem' }} className='pull-right'
          onClick={() => { data.isDeleted = !data.isDeleted; onDelete(data); }}
        >{data.isDeleted ? 'Activate' : 'Inactivate'}</Button>
      }
      {onSave && showSave &&
        <Fragment>
          <Button color="primary" type="submit" style={{ marginLeft: '.25rem' }} disabled={isLoading}>{saveButtonText == undefined ? 'Save' : saveButtonText}</Button>
          <Loading isLoading={isLoading} />
        </Fragment>
      }
      {customButton &&
        <Button color="danger" className="pull-right" onClick={() => { data.status = -1; onSave(data); }}>I don't want to Register for this</Button>
      }
    </Form>
  );
}