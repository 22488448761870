import React, { Fragment } from 'react';
import { UncontrolledAlert, Button, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { userService } from '../../_services';

class RegistrationComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.currentUser = userService.getLoggedInUser();

    }

    logout = () => {
        this.props.history.push({ pathname: '/logout' });
    }

    manageMatters = () => {
        this.props.history.push({ pathname: '/matter' })
    }

    manageAlerts = () => {

    }

    render() {
        return (
            <Fragment>
                <UncontrolledAlert color="success">
                    <h4><FontAwesomeIcon icon={faInfo} /> Registered!</h4>
                    <p>Congratulations in Registering for LeGuard. XYZ Company is being notified that you are now active in the system. You have been made an administrator on this account, so below are some tips to get started:</p>
                    <p><b>Navigation</b> - All Navigation is handled by the menu on the left of your screen. </p>
                    <p><b>Setup your Alerts</b> - The LeGuard system sends out email alerts for specific events. To control which ones you get go to the the Profile menu --> Set Alert Preferences </p>
                    <p><b>Setup your Company</b> - If your company has multiple levels you can set that up under the Administation menu --> Manage Company </p>
                    <p><b>Setup your Users</b> - If others will use the system set them up --> Manage Users </p>
                    <p><b>Start working on Matters</b> - Go to Manage Matters to create new matters and start tracking your time.</p>
                </UncontrolledAlert>
                <Row style={{ marginTop: '20px' }}>
                    {this.currentUser.canManageMatters &&
                        <Button color="primary" onClick={() => this.manageMatters()}>Manage Matters</Button>
                    }
                    <Button color="primary" style={{ marginLeft: '.25rem' }} onClick={() => this.manageAlerts()}>Setup My Alerts</Button>
                    <Button color="danger" style={{ marginLeft: '.25rem' }} onClick={() => this.logout()}>Logout</Button>
                </Row>
            </Fragment>
        );
    }
}

export default RegistrationComplete;