import React, { useEffect, useState } from "react";
import { Col, CustomInput, Input, Label, Modal, Row } from "reactstrap";
import { api, userService } from "../../_services";
import moment from "moment";
import DatePicker from "react-datepicker";
import { currencyFormatter } from "../../_services/currencyFormatter";
import Loader from "react-loaders";
import { toast } from "react-toastify";

const GenerateInvoicesModal = ({
  isOpen,
  toggleModal,
  partnershipList,
  reloadData,
}) => {
  const currentUser = userService.getLoggedInUser();
  const [matters, setMatters] = useState([]);
  const [allMattersSelected, setAllMattersSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "months").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "months").endOf("month").toDate()
  );
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (
      isOpen &&
      partnershipList != undefined &&
      partnershipList != null &&
      partnershipList.length > 0
    ) {
      loadData(startDate, endDate, true);
      setAllMattersSelected(true);
    } else if (!isOpen) {
      setMatters([]);
      setStartDate(moment().subtract(1, "months").startOf("month").toDate());
      setEndDate(moment().subtract(1, "months").endOf("month").toDate());
      setAllMattersSelected(false);
    }
  }, [isOpen, partnershipList]);

  const loadData = (start, end, selectAll) => {
    setIsLoading(true);
    api
      .getMatterListWithActivitiesOrExpensesByPartnershipIdsDateRange(
        partnershipList.map((x) => x.partnershipId).join(","),
        moment(start).format("YYYY-MM-DDTHH:mm:ss"),
        moment(end).format("YYYY-MM-DDTHH:mm:ss")
      )
      .then((data) => {
        setMatters(
          data.map((x) => {
            if (
              selectAll ||
              matters.find((m) => m.matterId == x.matterId && m.isSelected) !=
                undefined
            ) {
              x.isSelected = true;
            }
            return x;
          })
        );
        setIsLoading(false);
      });
  };

  const save = () => {
    setIsSubmitting(true)
    api
      .generateMattersInvoices(
        currentUser.userId,
        matters.filter((x) => x.isSelected),
        moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
        moment(endDate).format("YYYY-MM-DDTHH:mm:ss")
      )
      .then((r) => {
        setIsSubmitting(false)
        toggleModal();
        toast("Invoices generated successfuly.", {
          autoClose: 3000,
          type: toast.TYPE.SUCCESS,
          hideProgressBar: false,
        });
        reloadData();
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={() => {
        toggleModal();
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Generate Invoices
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col md="6">
            <div className="form-group">
              <Label>Start Date</Label>
              <DatePicker
                selected={startDate}
                onChange={(e) => {
                  setStartDate(e);
                  loadData(e, endDate);
                }}
                className="form-control"
              />
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <Label>End Date</Label>
              <DatePicker
                selected={endDate}
                onChange={(e) => {
                  setEndDate(e);
                  loadData(startDate, e);
                }}
                className="form-control"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoading && <Loader style={{}} type="ball-pulse" active />}
            {!isLoading && (
              <table className="table table-striped" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <CustomInput
                        id={`generate-all-matters-cbx`}
                        type="checkbox"
                        style={{ cursor: "pointer" }}
                        checked={allMattersSelected}
                        onClick={() => {
                          setMatters(
                            matters.map((m) => {
                              m.isSelected = !allMattersSelected;
                              return m;
                            })
                          );
                          setAllMattersSelected(!allMattersSelected);
                        }}
                      />
                    </th>
                    <th>Client</th>
                    <th>Matter</th>
                    <th>Billing MatterID</th>
                    <th>Amount Billed</th>
                    <th>Expense Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {matters.map((m, idx) => (
                    <tr key={idx}>
                      <td>
                        <CustomInput
                          id={`checkbox-${idx}`}
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          checked={m.isSelected}
                          onClick={() => {
                            let copy = [...matters];
                            let mat = copy.find(
                              (x) => x.matterId == m.matterId
                            );
                            mat.isSelected = !mat.isSelected;
                            setMatters(copy);
                          }}
                        />
                      </td>
                      <td>{m.clientEntityName}</td>
                      <td>{m.matterName}</td>
                      <td>{m.eBillerId_Lawfirm}</td>
                      <td>{currencyFormatter("$", m.activitiesTotal)}</td>
                      <td>{currencyFormatter("$", m.expensesTotal)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      </div>
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary save-user"
          onClick={save}
          disabled={isSubmitting}
        >
          Generate Invoices
        </button>
      </div>
    </Modal>
  );
};

export default GenerateInvoicesModal;
